import { CurrentMonthOverview } from "../../../../aws/budgetar-back/src/types/reporting.type";
import {
  fetchIncomes,
  fetchCategories,
  fetchAccounts,
} from "../api/fetch.redux";
import { fetchAdminBudget } from "../api/fetch.redux";
import { ReportingApi } from "../api/reporting.api";
import { IncomesTable } from "../components/incomes/IncomesTable";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewIncomeDialog } from "../components/utility/dialogs/NewIncomeDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { logout } from "../components/utils.api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAccounts } from "../redux/reducers/accountsSlice";
import {
  selectAdminBudgetId,
  selectBudgetToUse,
} from "../redux/reducers/budgetSlice";
import { selectCategories } from "../redux/reducers/categoriesSlice";
import { selectSimpleIncomes } from "../redux/reducers/incomesSlice";
import {
  setAllExpensesAndIncomes,
  setCurrentMonthOverview,
  setSavingsToday,
} from "../redux/reducers/reportingSlice";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface IncomesPageProps {
  openHowToDialog: () => void;
}
export const Incomes = (props: IncomesPageProps) => {
  const { openHowToDialog } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const [openNewIncomeDialog, setOpenNewIncomeDialog] = useState(false);
  const [filter, setFilter] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: incomes,
    loaded: areIncomesLoaded,
    loading: areIncomesLoading,
  } = useSelector(selectSimpleIncomes);
  const {
    data: categories,
    loaded: areCategoriesLoaded,
    loading: areCategoriesLoading,
  } = useSelector(selectCategories);
  const {
    data: accounts,
    loaded: areAccountsLoaded,
    loading: areAccountsLoading,
  } = useSelector(selectAccounts);

  const isDataLoaded = () =>
    !areIncomesLoading && !areCategoriesLoading && !areAccountsLoading;

  const newIncomeAdded = () => {
    fetchIncomes(budgetId, dispatch, false);

    ReportingApi.loadAllExpensesAndIncomes(
      budgetId,
      {
        onSuccess: (data: any) => {
          dispatch(setAllExpensesAndIncomes(data));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getSavingsToday(
      budgetId,
      {
        onSuccess: (savingsToday: number) => {
          dispatch(setSavingsToday({ savingsToday }));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getCurrentMonthOverview(
      budgetId,
      {
        onSuccess: (currentMonthOverview: CurrentMonthOverview) => {
          dispatch(setCurrentMonthOverview({ currentMonthOverview }));
        },
      },
      () => logout(dispatch, navigate)
    );

    toast.success("New income added.");
  };

  const handleFilterChange = (newInput: string) => {
    setFilter(newInput);
  };

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchIncomes(budgetId, dispatch, areIncomesLoaded);
      fetchCategories(budgetId, dispatch, areCategoriesLoaded);
      fetchAccounts(budgetId, dispatch, areAccountsLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  return (
    <Box>
      <LoadingDialog
        key={uuid()}
        open={areIncomesLoading}
        toDisplay="incomes"
      />
      <BudgetarToast />
      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <SecondaryButton size="small" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            Back
          </SecondaryButton>
        </Box>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewIncomeDialog(true)}
        >
          <AddIcon />
          Add income
        </Button>

        <Box sx={{ paddingLeft: "10px" }} />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}

        <Box sx={{ paddingLeft: "10px" }}>
          <TextField
            placeholder="Filter"
            onChange={(e) => handleFilterChange(e.target.value)}
          ></TextField>
        </Box>
      </Box>

      <NewIncomeDialog
        open={openNewIncomeDialog}
        setOpen={setOpenNewIncomeDialog}
        onSuccess={newIncomeAdded}
        onFailed={() => toast.error("Error while adding income.")}
      />
      {isDataLoaded() ? (
        <IncomesTable
          items={incomes}
          categories={categories}
          accounts={accounts}
          filters={{ filter }}
        />
      ) : (
        <Box>Loading data...</Box>
      )}
    </Box>
  );
};
