import { GenericDropdown } from "./GenericDropdown";
import { Currency } from "@backend/currency.type";

interface CurrencyDropdownInterface {
  onCurrencySelected: (newCurrency: Currency) => void;
  preferredCurrency?: Currency;
  label?: string;
}

export const CurrencyDropdown = (props: CurrencyDropdownInterface) => {
  const { onCurrencySelected, preferredCurrency, label } = props;

  const currencies = Object.keys(Currency).sort((a, b) => {
    if (a === preferredCurrency) return -1;
    if (b === preferredCurrency) return 1;
    return 0;
  });

  return (
    <GenericDropdown
      label={label || "Preferred currency"}
      options={currencies}
      onSelection={onCurrencySelected}
    />
  );
};
