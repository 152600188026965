import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV03 = () => {
  return (
    <Release version="v0.3.0" releaseDate="2023-04-10">
      <ReleaseNotesSection title="Subscriptions">
        <ReleaseNotesEntry>Enable subscriptions</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="UI/UX">
        <ReleaseNotesEntry>Update how-to guide</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Misc.">
        <ReleaseNotesEntry>
          Add dialog to confirm subscription payment
        </ReleaseNotesEntry>
        <ReleaseNotesEntry>Fix base e-mail template</ReleaseNotesEntry>
        <ReleaseNotesEntry>Clarify reporting charts</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
