import {
  confirmPasswordRecovery,
  requestPasswordRecovery,
} from "../../api/auth";
import { AuthCommon, AuthParentInterface } from "./AuthCommon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function RecoverComponent(props: AuthParentInterface) {
  const { setError, setPending, setRecoverPasswordDisplay } = props;

  const [submitCode, setSubmitCode] = useState(false);

  const navigate = useNavigate();

  const requestCode = (email: string, password: string) =>
    requestPasswordRecovery(email, {
      onSuccess: (response: any) => {
        if (response.data && response.data.errorMessage) {
          setError(response.data.errorMessage);
          return;
        }

        setSubmitCode(true);
        toast.info("Code sent. Please check your inbox.");
        setPending(false);
      },
      onFailed: (err: any) => {
        setPending(false);
        setError(err);
      },
    });

  const submitNewPassword = (email: string, password: string, code?: string) =>
    confirmPasswordRecovery({ email, password }, code || "000000", {
      onSuccess: (response: any) => {
        if (response.data && response.data.result === "OK") {
          toast.success("Password succesfully changed. Redirecting...");

          setTimeout(() => {
            setRecoverPasswordDisplay(false);
            setPending(false);
            navigate("/auth");
          }, 3000);
          return;
        }

        if (response.data && response.data.errorMessage) {
          setPending(false);
          setError(response.data.errorMessage);
          return;
        }

        if (response.data.error.message) {
          setPending(false);
          setError(response.data.error.message);
          return;
        }
      },
      onFailed: (err: any) => {
        setPending(false);
        setError(err);
      },
    });

  const showCodeRequest = () => (
    <AuthCommon
      title={"Password recovery"}
      submitTitle={"Request code"}
      submittingString={"Requesting code..."}
      alternative={"I remembered my password!"}
      parent={props}
      subComponent={{ action: requestCode }}
      descriptionText="Enter your e-mail below to receive a reset code."
      hideAllPasswords={true}
      hideForgotPassword={true}
    />
  );

  const showCodeSubmit = () => (
    <AuthCommon
      title={"Change password"}
      submitTitle={"Submit"}
      submittingString={"Submitting..."}
      alternative={"I remembered my password!"}
      parent={{ ...props, doublePasswordInput: true }}
      subComponent={{ action: submitNewPassword }}
      descriptionText="Enter the code you received via e-mail."
      hideForgotPassword={true}
      hideEmail={true}
      codeMode={true}
    />
  );

  return submitCode ? showCodeSubmit() : showCodeRequest();
}
