import { fetchAdminBudget } from "../../api/fetch.redux";
import { ReportingApi } from "../../api/reporting.api";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../redux/reducers/budgetSlice";
import {
  selectSummarizedExpensesPerWeek,
  selectSummedExpensesPerMonth,
  setLoading,
  setSummarizedExpensesPerWeek,
  setSummedExpensesPerMonth,
} from "../../redux/reducers/reportingSlice";
import { CustomChartOptions, CenterTextPlugin } from "../charts/chart-plugins";
import { LoadingDialog } from "../utility/dialogs/LoadingDialog";
import { logout } from "../utils.api";
import { useMediaQuery } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { useEffect } from "react";
import { Chart } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export function ExpensesPerWeekChart() {
  const matches = useMediaQuery("(min-width:300px)");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: expensesPerWeek,
    loaded: expensesPerWeekLoaded,
    loading: expensesPerWeekLoading,
  } = useAppSelector(selectSummarizedExpensesPerWeek);
  const {
    data: monthlyExpenses,
    loaded: monthlyExpensesLoaded,
    loading: monthlyExpensesLoading,
  } = useAppSelector(selectSummedExpensesPerMonth);
  const averageMonthlyExpenses =
    monthlyExpenses.reduce((acc, e) => acc + e.sum, 0) / monthlyExpenses.length;

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!expensesPerWeekLoaded) {
        dispatch(
          setLoading({ key: "summarizedExpensesPerWeek", status: true })
        );

        ReportingApi.getSummarizedExpensesPerWeek(
          budgetId,
          {
            onSuccess: (summarizedExpensesPerWeek: number[]) =>
              dispatch(
                setSummarizedExpensesPerWeek({ summarizedExpensesPerWeek })
              ),
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!monthlyExpensesLoaded) {
        dispatch(setLoading({ key: "summarizedExpensesPerDay", status: true }));

        ReportingApi.loadSummedExpensesPerMonth(
          budgetId,
          {
            onSuccess: (summedExpensesPerMonth) =>
              dispatch(setSummedExpensesPerMonth({ summedExpensesPerMonth })),
          },
          () => logout(dispatch, navigate),
          12
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, []);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Expenses per week",
      },
    },
  };

  const labels = [
    { date: "W-2", expenses: 0 },
    { date: "W-1", expenses: 0 },
    { date: "Last week", expenses: 0 },
    { date: "This week", expenses: 0 },
  ].map((e) => e.date);

  let expensesPerWeekToUse = expensesPerWeek;
  if (expensesPerWeek.join("") === "0000") {
    expensesPerWeekToUse = [];
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Expenses",
        data: expensesPerWeekToUse,
        backgroundColor: "darkred",
        type: "bar" as const,
        order: 1,
      },
      {
        label: "Weekly average",
        data: expensesPerWeekToUse.map((e) => averageMonthlyExpenses / 4),
        borderColor: "white",
        type: "line" as const,
        order: 0,
      },
    ],
  };

  const props = {
    ...(!matches && { height: 400 }),
  };

  if (monthlyExpensesLoading || expensesPerWeekLoading)
    return <LoadingDialog key={uuid()} open={true} toDisplay="data" />;

  return (
    <Chart
      {...props}
      type="bar"
      options={{ ...options, ...CustomChartOptions } as any}
      data={data}
      plugins={[CenterTextPlugin as any]}
    />
  );
}
