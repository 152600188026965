import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { CreditWithBalance } from "@backend/credit.type";
import { createSlice } from "@reduxjs/toolkit";

interface CreditsState {
  credits: SingleLoad<CreditWithBalance[]>;
}

export const initialState: CreditsState = {
  credits: singleLoadDefaultValue([]),
};

export const creditsSlice = createSlice({
  name: "credits",
  initialState,
  reducers: {
    setCredits: (
      state: CreditsState,
      action: { payload: { credits: CreditWithBalance[] } }
    ) => {
      const { credits } = action.payload;

      state.credits = getSingleLoadResponse(credits);
    },
    setCreditsLoading: (
      state: CreditsState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.credits.loading = status;
    },
    logout: (state: CreditsState) => {
      return initialState;
    },
  },
});

export const { setCredits, setCreditsLoading, logout } = creditsSlice.actions;

export const creditsState = (state: RootState) => state.credits;

export const selectCredits = (
  state: RootState
): SingleLoad<CreditWithBalance[]> => creditsState(state).credits;
export const selectCreditsLoading = (state: RootState): boolean =>
  creditsState(state).credits.loading;

export const CreditsReducer = creditsSlice.reducer;
