import { dateSorter } from "../..//imported-logic/common";
import { SingleInvestmentsTableRow } from "./SingleInvestmentsTableRow";
import { Investment, InvestmentPriceEntry } from "@backend/investment.type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useState } from "react";

export const InvestmentsTable = (props: {
  investments: Investment[];
  investmentsPrices: InvestmentPriceEntry[];
  filters: { filter?: string };
}) => {
  const matches = useMediaQuery("(min-width:600px)");

  const { investments, investmentsPrices } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);

  const { filter } = props.filters;

  const filteredInvestments = () =>
    investments.filter((inv) => {
      const filterable = [inv.investmentName, inv.purchaseDate];

      return (
        !filter ||
        !filter.length ||
        filterable.some((e) => e.toLowerCase().includes(filter.toLowerCase()))
      );
    });

  const mappedItems = [...filteredInvestments()].map((investment) => {
    const pricesSorted = investmentsPrices
      .filter((e) => e.investmentName === investment.investmentName)
      .sort((a, b) => dateSorter(b.date, a.date));

    const latestPrice = pricesSorted.length
      ? pricesSorted[0]
      : {
          price: 0,
          date: DateTime.now().toISO(),
          investmentName: investment.investmentName,
        };

    return (
      <SingleInvestmentsTableRow
        key={investment.SK}
        investment={investment}
        latestPriceEntry={latestPrice}
        matches={matches}
      />
    );
  });

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginateItems = () => {
    const from = page * rowsPerPage;
    const to = from + rowsPerPage;

    return mappedItems.slice(from, to);
  };

  const itemsToDisplay = paginateItems();

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <TableContainer component={Paper}>
        <Table aria-label="investments table">
          <TableHead>
            {matches ? (
              <TableRow hover={true}>
                <TableCell>
                  <Typography textAlign="center">Asset name</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Purchase date</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Quantity purchased</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Purchase price</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Latest unit price</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Entry fee</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Exit fee</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableHead>
          {mappedItems.length ? (
            [
              <TableBody>{itemsToDisplay}</TableBody>,
              <TableFooter>
                <TableRow hover={true}>
                  <TablePagination
                    count={mappedItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 25]}
                    backIconButtonProps={{
                      color: "secondary",
                    }}
                    nextIconButtonProps={{ color: "secondary" }}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "page number",
                      },
                    }}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </TableRow>
              </TableFooter>,
            ]
          ) : (
            <Typography>No items recorded.</Typography>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
