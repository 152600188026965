import { ButtonProps } from "@mui/material";
import Button from "@mui/material/Button";

export const SuperAccentButton = (props: ButtonProps) => {
  return (
    <Button
      variant="contained"
      sx={{ backgroundColor: "black", color: "orange" }}
      {...props}
    />
  );
};
