import { fetchAdminBudget } from "../../../api/fetch.redux";
import { ReportingApi } from "../../../api/reporting.api";
import { BudgetarToast } from "../../../components/utility/misc/BudgetarToast";
import { logout } from "../../../components/utils.api";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../../redux/reducers/budgetSlice";
import {
  selectInvestmentsNetWorth,
  setAllExpensesAndIncomes,
  setInvestmentsNetWorth,
  setSavingsEstimate,
} from "../../../redux/reducers/reportingSlice";
import {
  selectSavingsEstimates,
  SavingsEstimateMonths,
} from "../../../redux/reducers/reportingSlice";
import { selectAllExpensesAndIncomes } from "../../../redux/reducers/reportingSlice";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../../util/Formatter";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export interface AllExpensesAndIncomes {
  expensesSum: number;
  incomesSum: number;
  savingsSum: number;
}

export const SavingsProjectionsTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const currency = useAppSelector(selectCurrency);
  const { data: investmentsNetWorth, loaded: investmentsNetWorthLoaded } =
    useAppSelector(selectInvestmentsNetWorth);
  const { data: savingsEstimates, loaded: savingsEstimatesLoaded } =
    useAppSelector(selectSavingsEstimates);
  const { data: allExpensesAndIncomes, loaded: allExpensesAndIncomesLoaded } =
    useAppSelector(selectAllExpensesAndIncomes);

  const sixMonthEstimate = savingsEstimates[6];
  const twelveMonthEstimate = savingsEstimates[12];
  const fiveYearEstimate = savingsEstimates[60];
  const tenYearEstimate = savingsEstimates[120];

  const savingsAndInvestments =
    allExpensesAndIncomes.savingsSum + investmentsNetWorth;
  const f = (n: number) => formatNumberDispatch(n, currency);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!investmentsNetWorthLoaded) {
        ReportingApi.getInvestmentsNetWorth(
          budgetId,
          {
            onSuccess: (investmentsNetWorth: number) =>
              dispatch(setInvestmentsNetWorth({ investmentsNetWorth })),
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!savingsEstimatesLoaded) {
        [6, 12, 60, 120].map((e) =>
          ReportingApi.getSavingsEstimate(
            budgetId,
            e,
            {
              onSuccess: (estimate: number) =>
                dispatch(
                  setSavingsEstimate({
                    months: e as SavingsEstimateMonths,
                    estimate,
                  })
                ),
            },
            () => logout(dispatch, navigate)
          )
        );
      }

      if (!allExpensesAndIncomesLoaded) {
        ReportingApi.loadAllExpensesAndIncomes(
          budgetId,
          {
            onSuccess: (data: any) => {
              dispatch(setAllExpensesAndIncomes(data));
            },
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  return (
    <Box sx={{ display: "flex", paddingTop: "10px" }}>
      <BudgetarToast />
      <TableContainer component={Paper}>
        <Table aria-label="goals table">
          <TableHead>
            <TableRow hover={true}>
              <TableCell align="right">
                Savings now - {DateTime.now().toFormat("dd LLL yyyy")}
              </TableCell>
              <TableCell align="right">Est. M+6</TableCell>
              <TableCell align="right">Est. Y+1</TableCell>
              <TableCell align="right">Est. Y+5</TableCell>
              <TableCell align="right">Est. Y+10</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={0}>
              <TableCell align="right">{f(savingsAndInvestments)}</TableCell>
              <TableCell align="right">{f(sixMonthEstimate)}</TableCell>
              <TableCell align="right">{f(twelveMonthEstimate)}</TableCell>
              <TableCell align="right">{f(fiveYearEstimate)}</TableCell>
              <TableCell align="right">{f(tenYearEstimate)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
