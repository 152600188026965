import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV015 = () => {
  return (
    <Release version="v0.1.5" releaseDate="2023-01-04">
      <ReleaseNotesSection title="Expenses reporting">
        <ReleaseNotesEntry>Add expenses-per-day chart</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Technical">
        <ReleaseNotesEntry>Enable multi-account deployment</ReleaseNotesEntry>
        <ReleaseNotesEntry>Optimize data-fetching</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
