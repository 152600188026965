import { RootState } from "../store";
import { Currency } from "@backend/currency.type";
import { createSlice } from "@reduxjs/toolkit";

interface SettingsState {}

export const initialState: SettingsState = {};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
});

// export const { setCurrency } = settingsSlice.actions;

export const settingsState = (state: RootState) => state.settings;

// export const selectCurrency = (state: RootState) =>
//   settingsState(state).currency;

export const SettingsReducer = settingsSlice.reducer;
