import { AssetAPI } from "../../../api/assets.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAssets } from "../../../redux/reducers/assetsSlice";
import { uniqueArray } from "../../../util/Functions";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const NewAssetPriceDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [assetName, setAssetName] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [date, setDate] = React.useState<DateTime>(DateTime.now());

  const budgetId = getBudgetId(useAppSelector);
  const { data: assets } = useAppSelector(selectAssets);
  const assetNames = uniqueArray(assets.map((e) => e.assetName));

  const handleClose = () => {
    setOpen(false);
  };

  const handleAssetNameChange = (newName: string) => setAssetName(newName);

  const handlePriceChange = (newPrice: string) => setPrice(Number(newPrice));

  const canSubmit = () => {
    return assetName && date && price;
  };

  const reset = () => {
    setAssetName("");
    setPrice(0);
    setDate(DateTime.now());
  };

  const submit = () => {
    addAssetPrice();
    handleClose();
    reset();
  };

  const addAssetPrice = async () => {
    AssetAPI.addAssetPrice(
      budgetId,
      {
        assetSK: assets.find((e) => e.assetName === assetName)?.SK || "",
        price,
        date: date.toMillis(),
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>Add asset price</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <Autocomplete
              fullWidth
              id="asset-name"
              disableClearable
              options={assetNames}
              onChange={(e, value) => handleAssetNameChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enter asset name"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  fullWidth
                />
              )}
            />

            <BudgetarDatePicker
              onSelect={(date: Date) =>
                setDate(DateTime.fromISO(date.toISOString()))
              }
            />

            <TextField
              placeholder="Price"
              variant="outlined"
              onChange={(e) => handlePriceChange(e.target.value)}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Add asset price
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
