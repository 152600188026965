import { Callbacks } from "../types/util";
import { postRequest, getRequest } from "./index";
import {
  AddAssetPriceInterface,
  CreateAssetPayload,
} from "@backend/asset.type";

const getAssetUrl = (route: string, budgetId: string) =>
  `${budgetId}/asset/${route}`;

async function createAsset(
  budgetId: string,
  args: CreateAssetPayload,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getAssetUrl("create", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface DeleteAssetArgs {
  assetSK: string;
}
async function deleteAsset(
  budgetId: string,
  args: DeleteAssetArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getAssetUrl("delete", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getAssets(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/assets`, logoutCallback, onSuccess, onFailed);
}

async function addAssetPrice(
  budgetId: string,
  args: AddAssetPriceInterface,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getAssetUrl("price/add", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export const AssetAPI = {
  createAsset,
  getAssets,
  deleteAsset,
  addAssetPrice,
};
