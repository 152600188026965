import { useMediaQuery } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  LineController,
  BarController,
} from "chart.js";
import { DateTime } from "luxon";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
export const NetWorthEvolutionGraph = (props: {
  netWorthPerMonth: { date: number; netWorth: number }[];
}) => {
  const matches = useMediaQuery("(min-width:400px");

  const { netWorthPerMonth } = props;

  const datasets = [
    {
      label: "",
      data: netWorthPerMonth.map((e) => e.netWorth),
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
      type: "line" as const,
      order: 1,
    },
    {
      label: "",
      data: netWorthPerMonth.map((e) => 0),
      fill: true,
      backgroundColor: "darkred",
      tension: 0.1,
      type: "line" as const,
      order: 0,
    },
  ];

  const labels: string[] = netWorthPerMonth.map((e) =>
    DateTime.fromMillis(Number(e.date)).toUTC().toISODate()
  );
  const data = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Net worth per month",
      },
    },
    scales: {
      y1: {
        type: "linear" as const,
        display: true,
        position: "right" as const,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const props1 = {
    ...(!matches && { height: 400 }),
  };
  return <Chart {...props1} type="line" options={options} data={data} />;
};
