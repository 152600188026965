import { ExpensesPerDayChart } from "./ExpensesPerDayChart";
import { ExpensesPerMonthChart } from "./ExpensesPerMonthChart";
import { ExpensesPerWeekChart } from "./ExpensesPerWeekChart";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";

const RadioComponent = (props: {
  selectedValue: string;
  setState: (newState: "global-average" | "per-period") => void;
}) => {
  const handleRadioChange = (event: any) => {
    props.setState(event.target.value);
  };

  return (
    <RadioGroup value={props.selectedValue} onChange={handleRadioChange}>
      <FormControlLabel
        value="global-average"
        control={<Radio />}
        label="Global average"
      />
      <FormControlLabel
        value="per-period"
        control={<Radio />}
        label="Average per day/month"
      />
    </RadioGroup>
  );
};

export const ExpensesReporting = () => {
  const [selectedValue, setSelectedValue] = useState<
    "per-period" | "global-average"
  >("per-period");

  return (
    <Grid container xs={12}>
      <Grid item xs={12} md={12}>
        <RadioComponent
          selectedValue={selectedValue}
          setState={setSelectedValue}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ExpensesPerDayChart displayMode={selectedValue} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ExpensesPerWeekChart />
      </Grid>
      <Grid item xs={12} md={12}>
        <ExpensesPerMonthChart displayMode={selectedValue} />
      </Grid>
    </Grid>
  );
};
