import { useAppSelector } from "../../../redux/hooks";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../../util/Formatter";
import { optionalTruncate } from "../../../util/Functions";
import { AttributeNameType } from "./CategoriesReportingBasicTable";
import { ReportingCategory } from "@backend/category.type";
import { TableCell, TableRow, Typography, Grid } from "@mui/material";

export const SingleCategoriesReportingTableRow = (props: {
  category: ReportingCategory & {
    monthlyBudget: number;
    totalPlannedExpenditure: number;
    averageMonthlyExpenditure: number;
    monthlyDeviation: number;
  };
  budgetDisplay: "simple" | "normalized";
  matches: boolean;
  sortingAttribute: AttributeNameType;
}) => {
  const { category, budgetDisplay, matches, sortingAttribute } = props;

  const {
    categoryName,
    periodBudget,
    assignedPeriod,
    total,
    monthlyBudget,
    totalPlannedExpenditure,
    averageMonthlyExpenditure,
  } = category;

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  const getFontWeight = (attribute: string) =>
    attribute === sortingAttribute ? 900 : 400;

  if (!matches) {
    return (
      <TableRow hover={true}>
        <TableCell>
          <Grid container sx={{ padding: "5px" }}>
            <Grid item xs={6}>
              <Typography
                color="common.orange"
                textAlign={"left"}
                fontWeight={getFontWeight("categoryName")}
              >
                {optionalTruncate(categoryName, 15)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">
                {budgetDisplay === "simple"
                  ? `${f(periodBudget)} ${assignedPeriod.toLowerCase()}`
                  : `${f(monthlyBudget)} / month`}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                textAlign={"center"}
                fontWeight={getFontWeight("total")}
              >
                Spent {f(total)} / {f(totalPlannedExpenditure)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                textAlign={"center"}
                fontWeight={getFontWeight("averageMonthlyExpenditure")}
              >
                Avg. {f(averageMonthlyExpenditure)} / month
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow hover={true}>
      <TableCell></TableCell>
      <TableCell>{categoryName}</TableCell>
      <TableCell>
        {budgetDisplay === "simple"
          ? `${f(periodBudget)} ${assignedPeriod.toLowerCase()}`
          : `${f(monthlyBudget)} monthly`}
      </TableCell>
      <TableCell>
        {f(total)} / {f(totalPlannedExpenditure)}
      </TableCell>
      <TableCell>{f(averageMonthlyExpenditure)}</TableCell>
      <TableCell>
        {((100 * total) / totalPlannedExpenditure).toFixed(2)} %
      </TableCell>
    </TableRow>
  );
};

export const ShowAllReportingCategoriesRow = (props: {
  collapsed: boolean;
  setCollapsed: (newCollapsed: boolean) => void;
}) => {
  const { collapsed, setCollapsed } = props;
  return (
    <TableRow hover={true}>
      <TableCell onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? "Show all" : "Hide"}
      </TableCell>
    </TableRow>
  );
};
