import { Callbacks } from "../types/util";
import { refreshIdToken } from "./auth";
import { getBackendUrl } from "./config";
import axios from "axios";

export const buildHeaders = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    // "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
  };
};

export const postRequest = async (
  route: string,
  data: any,
  logoutCallback: () => void,
  successCallback?: (data: any) => void,
  failedCallback?: (err: string) => void
) => {
  axios
    .post(getBackendUrl(route), data, {
      headers: buildHeaders(),
    })
    .then((res) => {
      if (successCallback) successCallback(res);

      return res;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.message === "Token validation failed.") {
          return refreshIdToken({
            onSuccess: () => {
              postRequest(
                route,
                data,
                logoutCallback,
                successCallback,
                failedCallback
              );
            },
            onFailed: () => {
              if (failedCallback) failedCallback(error.response);
            },
          });
        } else if (error.response.data.message === "Token invalid.") {
          logoutCallback();
        } else {
          if (failedCallback) failedCallback(error.response.data.message);
        }
      } else if (error.request) {
        // request made, no response
        if (failedCallback) failedCallback(error.response);
      } else {
        if (failedCallback) failedCallback(error);

        return error;
      }
    });
};

export const getRequest = (
  route: string,
  logoutCallback: () => void,
  successCallback?: (data: any) => void,
  failedCallback?: (err: string) => void
) => {
  axios
    .get(getBackendUrl(route), {
      headers: buildHeaders(),
    })
    .then((response) => {
      if (response.data && response.data.errorMessage) {
        if (failedCallback) failedCallback(response.data.errorMessage);
      } else {
        const { data } = response;

        if (successCallback) successCallback(data);

        return data;
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message === "Token validation failed.") {
          return refreshIdToken({
            onSuccess: () => {
              getRequest(
                route,
                logoutCallback,
                successCallback,
                failedCallback
              );
            },
            onFailed: () => {
              if (failedCallback) failedCallback(err.response);
            },
          });
        } else if (err.response.data.message === "Token invalid.") {
          logoutCallback();
        } else {
          if (failedCallback) failedCallback(err.response);
        }
      } else if (err.request) {
        // request made, no response
        if (failedCallback) failedCallback(err.response);
      } else {
        // something else
        if (failedCallback) failedCallback(err);

        return err;
      }
    });
};

export interface SimpleRequestParams {
  responseKey?: string;
}
export async function sendSimpleRequest(
  url: string,
  urlCreateCallback: (string: any) => string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void,
  params?: SimpleRequestParams
) {
  const finalUrl = urlCreateCallback(url);
  getRequest(
    finalUrl,
    logoutCallback,
    (response) => {
      try {
        const responseObject = response.data ? response.data : response;

        const toReturn = params?.responseKey
          ? responseObject[params.responseKey]
          : responseObject;

        if (onSuccess) onSuccess(toReturn);

        return toReturn;
      } catch (error) {
        console.error(
          `[api/reporting/sendSimpleRequest] Error while retrieving ${finalUrl}: ${error}`
        );
        if (onFailed) onFailed(error);
      }
    },
    onFailed
  );
}
