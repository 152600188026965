import { CategoryAPI } from "../../api/categories/categories.api";
import { getPeriodMultiplier } from "../../imported-logic/common";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { sortValues } from "../../util/Functions";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { loadCategories, getBudgetId, logout } from "../utils.api";
import {
  SingleCategoriesTableRow,
  TotalCategoriesAmountRow,
} from "./SingleCategoriesTableRow";
import { ConvertedCategory, UpdateCategoryData } from "@backend/category.type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const CategoriesTable = (props: {
  categories: ConvertedCategory[];
  label: string;
}) => {
  const matches = useMediaQuery("(min-width:600px)");

  const { categories, label } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const budgetId = getBudgetId(useAppSelector);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [direction, setFilterDirection] = useState<"ASC" | "DESC">("DESC");

  const toggleDirection = () =>
    setFilterDirection(direction === "ASC" ? "DESC" : "ASC");

  const updateCategory = (
    categorySK: string,
    updateCategoryData: UpdateCategoryData,
    cb: () => void
  ) => {
    CategoryAPI.updateCategory(
      budgetId,
      { categorySK, ...updateCategoryData },
      {
        onSuccess: () => {
          toast.success("Category updated.");
          loadCategories(budgetId, dispatch);
          cb();
        },
        onFailed: (err) => {
          toast.error(err);
        },
      },
      () => logout(dispatch, navigate)
    );
  };

  const deleteCategory = (categorySK: string) => {
    CategoryAPI.deleteCategory(
      budgetId,
      { categorySK },
      {
        onSuccess: () => {
          toast.success("Category deleted.");
          loadCategories(budgetId, dispatch);
        },
        onFailed: (err) => toast.error(err),
      },
      () => logout(dispatch, navigate)
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const mappedItems = categories
    .sort((a, b) => sortValues(a.categoryName, b.categoryName, direction))
    .map((category, idx) => (
      <SingleCategoriesTableRow
        matches={matches}
        key={idx}
        category={category}
        updateCategoryCall={updateCategory}
        deleteCategoryCall={deleteCategory}
      />
    ));

  const paginateItems = () => {
    const from = page * rowsPerPage;
    const to = from + rowsPerPage;

    return mappedItems.slice(from, to);
  };

  const itemsToDisplay = paginateItems();

  const totalYearly = categories.reduce(
    (acc, e) =>
      acc +
      e.convertedPeriodBudget * 12 * getPeriodMultiplier(e.assignedPeriod),
    0
  );

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <BudgetarToast />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="categories table">
          <TableHead>
            {matches ? (
              <TableRow hover={true}>
                <TableCell>
                  <Typography textAlign={"center"} onClick={toggleDirection}>
                    Category
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign={"center"}>
                    Period calculation
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign={"right"}>Period budget</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign={"center"}>Essential</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign={"right"}>Yearly</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign={"center"}>Actions</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableHead>
          {mappedItems.length ? (
            [
              <TableBody>
                {[
                  ...itemsToDisplay,
                  <TotalCategoriesAmountRow
                    total={totalYearly}
                    matches={matches}
                  />,
                ]}
              </TableBody>,
              <TableFooter>
                <TableRow hover={true}>
                  <TablePagination
                    count={mappedItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 25]}
                    backIconButtonProps={{
                      color: "secondary",
                    }}
                    nextIconButtonProps={{ color: "secondary" }}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "page number",
                      },
                    }}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </TableRow>
              </TableFooter>,
            ]
          ) : (
            <Box>
              <Typography>No {label.toLowerCase()} categories</Typography>{" "}
            </Box>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
