import { DateTime } from "luxon";

import {
  Mortgage,
  MortgageCalculationInput,
  MortgageScheduledPayment,
} from "../types/mortgage.type";

export function calculateMortgage(input: MortgageCalculationInput): Mortgage {
  const {
    principal,
    interestRateType,
    termMonths,
    interestRatePerAnnum,
    effectiveStartDate,
  } = input;

  const monthlyInterestRate = interestRatePerAnnum / 100 / 12;
  const monthlyPayment =
    (principal *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, termMonths)) /
    (Math.pow(1 + monthlyInterestRate, termMonths) - 1);
  const totalOutstanding = monthlyPayment * termMonths;

  const scheduledPayments = calculateScheduledPayments({
    termMonths,
    monthlyPayment,
    monthlyInterestRate,
    principal,
    effectiveStartDate,
  });
  const totalInterest = scheduledPayments.reduce(
    (acc, e) => acc + e.interest,
    0
  );

  if (!scheduledPayments.length) {
    throw new Error(
      "No scheduled payments generated. Please check input parameters."
    );
  }

  return {
    principal: principal,
    interestRateType,
    interestRatePerAnnum,
    termMonths,
    totalInterest,
    totalOutstanding,
    effectiveStartDate,
    monthlyPayment: scheduledPayments[0].amount,
    paymentSchedule: {
      creationDate: effectiveStartDate,
      scheduledPayments,
    },
  };
}

function calculateScheduledPayments(input: {
  termMonths: number;
  monthlyPayment: number;
  monthlyInterestRate: number;
  principal: number;
  effectiveStartDate: string;
}): Array<MortgageScheduledPayment> {
  const {
    principal,
    termMonths,
    monthlyPayment,
    monthlyInterestRate,
    effectiveStartDate,
  } = input;

  const payments: MortgageScheduledPayment[] = [];
  let remainder = principal;

  for (let i = 1; i <= termMonths; i++) {
    const payment = calculateScheduledPayment({
      paymentIndex: i,
      monthlyPayment,
      monthlyInterestRate,
      effectiveStartDate,
      remainder,
    });
    remainder -= payment.principal;

    payments.push(payment);
  }

  return payments;
}

function calculateScheduledPayment(input: {
  remainder: number;
  paymentIndex: number;
  monthlyPayment: number;
  monthlyInterestRate: number;
  effectiveStartDate: string;
}): MortgageScheduledPayment {
  const {
    paymentIndex,
    monthlyPayment,
    monthlyInterestRate,
    effectiveStartDate,
    remainder,
  } = input;

  const nextPaymentAmount = monthlyPayment;
  const paymentInterest = remainder * monthlyInterestRate;
  const paymentPrincipal = nextPaymentAmount - paymentInterest;
  const date = DateTime.fromISO(effectiveStartDate)
    .plus({ month: paymentIndex })
    .toISODate();

  return {
    date,
    amount: nextPaymentAmount,
    interest: paymentInterest,
    principal: paymentPrincipal,
  };
}
