import {
  fetchCredits,
  fetchCategories,
  fetchAccounts,
  fetchAdminBudget,
} from "../api/fetch.redux";
import { CreditsTable } from "../components/credits/CreditsTable";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewCreditDialog } from "../components/utility/dialogs/NewCreditDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAccounts } from "../redux/reducers/accountsSlice";
import {
  selectAdminBudgetId,
  selectBudgetToUse,
} from "../redux/reducers/budgetSlice";
import { selectCategories } from "../redux/reducers/categoriesSlice";
import { selectCredits } from "../redux/reducers/creditsSlice";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface CreditsPageProps {
  openHowToDialog: () => void;
}
export const Credits = (props: CreditsPageProps) => {
  // const { openHowToDialog } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const [openNewCreditDialog, setOpenNewCreditDialog] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: credits,
    loaded: areCreditsLoaded,
    loading: areCreditsLoading,
  } = useAppSelector(selectCredits);
  const { loaded: areCategoriesLoaded } = useAppSelector(selectCategories);
  const { loaded: areAccountsLoaded } = useAppSelector(selectAccounts);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchCredits(budgetId, dispatch, areCreditsLoaded);
      fetchCategories(budgetId, dispatch, areCategoriesLoaded);
      fetchAccounts(budgetId, dispatch, areAccountsLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const newCreditsCreated = () => {
    fetchCredits(budgetId, dispatch, false);
    fetchAccounts(budgetId, dispatch, false);
    fetchCategories(budgetId, dispatch, false);

    toast.success("New credit created.");
  };

  return (
    <Box>
      <LoadingDialog
        key={uuid()}
        open={areCreditsLoading}
        toDisplay="credits"
      />
      <BudgetarToast />

      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <SecondaryButton size="small" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            Back
          </SecondaryButton>
        </Box>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewCreditDialog(true)}
          sx={{ marginRight: "10px" }}
        >
          <AddIcon />
          New credit
        </Button>

        <Box sx={{ paddingLeft: "10px" }} />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            // onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}
      </Box>

      <NewCreditDialog
        budgetId={budgetId}
        open={openNewCreditDialog}
        setOpen={setOpenNewCreditDialog}
        onSuccess={newCreditsCreated}
        onFailed={() => toast.error("Error while creating credit.")}
      />

      {credits ? (
        <CreditsTable
          credits={credits}
          refreshCredits={() => fetchCredits(budgetId, dispatch, false)}
        />
      ) : (
        <Box>No credits</Box>
      )}
    </Box>
  );
};
