import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV013 = () => {
  return (
    <Release version="v0.1.3" releaseDate="2022-12-18">
      <ReleaseNotesSection title="Goals utility">
        <ReleaseNotesEntry>Add guideline goals</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Backend">
        <ReleaseNotesEntry>Architectural changes to backend</ReleaseNotesEntry>
        <ReleaseNotesEntry>Refactor calculations</ReleaseNotesEntry>
        <ReleaseNotesEntry>Move all calculations to backend</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Reporting">
        <ReleaseNotesEntry>Add expense and savings trends</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add investments net value at date</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
