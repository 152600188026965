import { SavingsMonthlyChart } from "./SavingsMonthlyChart";
import { SavingsSummary } from "./SavingsSummary";
import { Grid } from "@mui/material";

export const SavingsReporting = () => {
  return (
    <Grid container xs={12} spacing={1}>
      <Grid item xs={12} md={6}>
        <SavingsMonthlyChart />
      </Grid>

      <Grid item xs={12} md={6}>
        <SavingsSummary />
      </Grid>
    </Grid>
  );
};
