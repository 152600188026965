import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV018 = () => {
  return (
    <Release version="v0.1.8" releaseDate="2023-01-22">
      <ReleaseNotesSection title="Technical">
        <ReleaseNotesEntry>Optimize database reads</ReleaseNotesEntry>
        <ReleaseNotesEntry>Optimize API requests to backend</ReleaseNotesEntry>
        <ReleaseNotesEntry>Optimize data caching at frontend</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="UI">
        <ReleaseNotesEntry>
          Fix NaN values showing in frontend
        </ReleaseNotesEntry>
        <ReleaseNotesEntry>Improve mobile UI</ReleaseNotesEntry>
        <ReleaseNotesEntry>Improve responsive layouts</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
