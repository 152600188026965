import { Callbacks } from "../types/util";
import { getRequest, postRequest } from "./index";
import { LookupKey } from "@backend/subscription.type";

const getSubscriptionUrl = (route: string, budgetId: string) =>
  `${budgetId}/subscription/${route}`;

async function getCurrentSubscription(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(
    getSubscriptionUrl("current", budgetId),
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function submitSession(
  budgetId: string,
  lookup_key: LookupKey,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  postRequest(
    getSubscriptionUrl("stripe/create-session", budgetId),
    { lookup_key },
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function cancelSubscription(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  postRequest(
    getSubscriptionUrl("stripe/cancel-subscription", budgetId),
    {},
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export const SubscriptionAPI = {
  getCurrentSubscription,
  submitSession,
  cancelSubscription,
};
