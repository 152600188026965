import { fetchAdminBudget } from "../../../api/fetch.redux";
import { ReportingApi } from "../../../api/reporting.api";
import { LoadingDialog } from "../../../components/utility/dialogs/LoadingDialog";
import { GenericDropdown } from "../../../components/utility/dropdowns/GenericDropdown";
import { logout } from "../../../components/utils.api";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../../redux/reducers/budgetSlice";
import {
  selectReportingCategories,
  setLoading,
  setLoadingReportingCategories,
  setReportingCategories,
} from "../../../redux/reducers/reportingSlice";
import {
  setMetadata,
  selectMetadata,
} from "../../../redux/reducers/reportingSlice";
import { CategoriesReportingTable } from "./CategoriesReportingTable";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

export type CategoriesReportingPeriod =
  | "All-time"
  | "Last month"
  | "Last 3 months"
  | "Last 6 months"
  | "Last year";

export const CategoriesReporting = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [budgetDisplay, setBudgetDisplay] = useState<"simple" | "normalized">(
    "simple"
  );
  const [displayFrom, setDisplayFrom] = useState<number | undefined>(undefined);
  const [displayTo, setDisplayTo] = useState<number | undefined>(
    DateTime.now().toMillis()
  );

  const [periodChoice, setPeriodChoice] =
    useState<CategoriesReportingPeriod>("All-time");

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const reportingCategories = useAppSelector(selectReportingCategories);
  const { loaded: isMetadataLoaded, loading: isMetadataLoading } =
    useAppSelector(selectMetadata);

  const handlePeriodSelection = (choice: CategoriesReportingPeriod) => {
    const now = DateTime.now().minus({ month: 1 }).endOf("month");

    let from;
    let to = now.toMillis();

    switch (choice) {
      case "Last month": {
        from = DateTime.now().startOf("month");
        to = DateTime.now().toMillis();
        break;
      }
      case "Last 3 months": {
        from = now.minus({ months: 2 }).startOf("month");
        break;
      }
      case "Last 6 months": {
        from = now.minus({ months: 5 }).startOf("month");
        break;
      }
      case "Last year": {
        from = now.minus({ months: 11 }).startOf("month");
        break;
      }
    }

    setDisplayFrom(from?.toMillis());
    setDisplayTo(to);

    if (!reportingCategories[choice].loaded) {
      dispatch(setLoadingReportingCategories({ period: choice, status: true }));

      ReportingApi.getReportingCategories(
        budgetId,
        from?.toMillis() || 0,
        to,
        {
          onSuccess: (reportingCategories: any) => {
            dispatch(
              setReportingCategories({ reportingCategories, period: choice })
            );
          },
        },
        () => logout(dispatch, navigate)
      );
    }

    setPeriodChoice(choice);
  };

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!reportingCategories["All-time"].loaded) {
        dispatch(
          setLoadingReportingCategories({ period: "All-time", status: true })
        );

        ReportingApi.getReportingCategories(
          budgetId,
          0,
          DateTime.now().toMillis(),
          {
            onSuccess: (reportingCategories: any) =>
              dispatch(
                setReportingCategories({
                  reportingCategories,
                  period: "All-time",
                })
              ),
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!isMetadataLoaded) {
        dispatch(setLoading({ key: "metadata", status: true }));

        ReportingApi.getFirstEntryAndMonthsSince(
          budgetId,
          {
            onSuccess: (data: {
              firstEntry: any;
              monthsSinceFirstEntry: number;
            }) => {
              dispatch(setMetadata(data));
            },
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const formatDate = (
    keyword: string,
    date: number | undefined,
    suffix?: string
  ): string =>
    date
      ? `${keyword} ${DateTime.fromMillis(date).toFormat("yyyy/MM/dd")} ${
          date && suffix ? suffix : ""
        }`
      : "";

  return (
    <Box sx={{ width: "100%" }}>
      <LoadingDialog
        key={uuid()}
        open={isMetadataLoading || reportingCategories[periodChoice].loading}
      />

      <Grid container sx={{ padding: "10px" }} xs={12}>
        <Grid item xs={4}>
          <GenericDropdown
            label="Period"
            options={[
              "All-time",
              "Last month",
              "Last 3 months",
              "Last 6 months",
              "Last year",
            ]}
            onSelection={handlePeriodSelection}
          />
          <Typography>
            {formatDate("", displayFrom, "-")} {formatDate("", displayTo)}
          </Typography>
        </Grid>

        <Grid xs={6}>
          <Typography textAlign="right">
            Essential exp. / month:{" "}
            {
              reportingCategories[periodChoice].data
                .targetEssentialExpensesPerMonth
            }
          </Typography>
        </Grid>
      </Grid>

      <Grid container sx={{ padding: "10px" }} xs={12}>
        <CategoriesReportingTable
          {...reportingCategories[periodChoice].data}
          budgetDisplay={budgetDisplay}
        />
      </Grid>
    </Box>
  );
};
