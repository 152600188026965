import { getCredentialsFromSessionStorage } from "../../api/auth/auth.util";
import { useAppSelector } from "../../redux/hooks";
import { selectCurrentUser } from "../../redux/reducers/userSlice";

const withAuth = (WrappedComponent: any) => (props: any) => {
  let authenticated = true;

  const currentUser = useAppSelector(selectCurrentUser);

  if (!currentUser) {
    const { token } = getCredentialsFromSessionStorage();

    if (!token) {
      authenticated = false;
    }
  } else {
    if (!currentUser.token) {
      authenticated = false;
    }
  }

  return <WrappedComponent {...props} authenticated={authenticated} />;
};

export default withAuth;
