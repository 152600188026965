import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV031 = () => {
  return (
    <Release version="v0.3.1" releaseDate="2023-04-12">
      <ReleaseNotesSection title="UI/UX">
        <ReleaseNotesEntry>Update authentication page</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add subscription status to header</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Accounts">
        <ReleaseNotesEntry>
          Add alert when no primary account set
        </ReleaseNotesEntry>
        <ReleaseNotesEntry>Update primary account display</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
