import { Deletable, Identifiable } from "./common.type";
import { Currency } from "./currency.type";

export enum AccountType {
  BANKACCOUNT = "BANKACCOUNT",
  CREDITCARD = "CREDITCARD",
  CASH = "CASH",
  INVESTMENTS = "INVESTMENTS",
  LOAN = "LOAN",
  MORTGAGE = "MORTGAGE",
  OTHER = "OTHER",
  DISCRETIONARY = "DISCRETIONARY",
  CREDITING = "CREDITING",
}

export interface Account extends Identifiable, Deletable {
  accountName: string;
  accountType: AccountType;
  startingBalance?: number;
  startingBalanceCurrency?: Currency;
  isPrimary?: string;
  monthlyAllowance?: number;
  rollingBalance?: number;
  monthlyAllowanceCurrency?: Currency;
}

export interface AccountWithBalance extends Account {
  accountBalance: number;
}

export interface UpdateAccountData {
  newName?: string | null;
  newType?: string | null;
  newPrimary?: string | null;
  newStartingBalance?: number | null;
  newRollingBalance?: number | null;
  newMonthlyAllowance?: number | null;
}

export interface RawAccount extends Account {
  PK: string;
}

export interface CreateAccountInterface extends CreateAccountPayload {
  budgetId: string;
}

export interface CreateAccountPayload {
  accountName: string;
  accountType: AccountType;
  startingBalance: number;
  startingBalanceCurrency: Currency | null;
  isPrimary?: boolean;
  monthlyAllowance: number;
}
