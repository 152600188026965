import {
  fetchEvents,
  fetchAdminBudget,
  fetchIncomes,
  fetchExpenses,
} from "../api/fetch.redux";
import { EventsTable } from "../components/events/EventsTable";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewEventDialog } from "../components/utility/dialogs/NewEventDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { getBudgetId } from "../components/utils.api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAccounts } from "../redux/reducers/accountsSlice";
import { selectAdminBudgetId } from "../redux/reducers/budgetSlice";
import { selectCategories } from "../redux/reducers/categoriesSlice";
import { selectEvents } from "../redux/reducers/eventsSlice";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button, Typography, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface EventsPageProps {
  openHowToDialog: () => void;
}
export const Events = (props: EventsPageProps) => {
  const { openHowToDialog } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const [openNewEventDialog, setOpenNewEventDialog] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = getBudgetId(useAppSelector);
  const {
    data: events,
    loaded: areEventsLoaded,
    loading: areEventsLoading,
  } = useSelector(selectEvents);
  const { loaded: areExpensesLoaded, loading: areExpensesLoading } =
    useSelector(selectAccounts);
  const { loaded: areIncomesLoaded, loading: areIncomesLoading } =
    useSelector(selectCategories);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchEvents(budgetId, dispatch, areEventsLoaded);
      fetchExpenses(budgetId, dispatch, areExpensesLoaded);
      fetchIncomes(budgetId, dispatch, areIncomesLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const refresh = () => {
    fetchEvents(budgetId, dispatch, false);
  };

  const newEventCreated = () => {
    refresh();
    toast.success("New event created.");
  };

  const ButtonLabel = (props: any) => (
    <Typography fontSize={{ xs: 10, md: 16 }}>{props.text}</Typography>
  );

  return (
    <Box>
      <LoadingDialog key={uuid()} open={areEventsLoading} toDisplay="events" />
      <BudgetarToast />
      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <SecondaryButton size="small" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            Back
          </SecondaryButton>
        </Box>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewEventDialog(true)}
          sx={{ marginRight: "10px" }}
        >
          <AddIcon />
          <Typography fontSize={{ xs: 10, md: 20 }}>
            <ButtonLabel text="New event" />
          </Typography>
        </Button>

        <Box sx={{ paddingLeft: "10px" }} />

        <Divider orientation="vertical" />

        <Box sx={{ paddingLeft: "10px" }} />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}
      </Box>

      <Box></Box>

      {events ? [<EventsTable events={events} />] : [<Box>No events</Box>]}

      <Box>
        <NewEventDialog
          open={openNewEventDialog}
          setOpen={setOpenNewEventDialog}
          onSuccess={newEventCreated}
          onFailed={() => toast.error("Error while creating event.")}
        />
      </Box>
    </Box>
  );
};
