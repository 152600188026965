import { useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../util/Formatter";
import { TrendsProjectionsSummarized } from "@backend/trend.type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";

export const CurrentPeriodTrends = (props: TrendsProjectionsSummarized) => {
  const {
    expensesTrend,
    savingsTrend,
    lastMonthExpenses,
    lastMonthSavings,
    currentMonthExpenses,
    currentMonthSavings,
    projectedMonthExpenses,
    projectedMonthSavings,
  } = props;

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  const savingsTrendToDisplay =
    lastMonthSavings < 0 && savingsTrend < 0 && projectedMonthSavings > 0
      ? savingsTrend * -1
      : savingsTrend;

  return (
    <Box>
      <Box
        sx={{
          paddingTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TableContainer component={Paper}>
          <Table aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell align="center">
                  <Typography>
                    Expenses - last month: {f(lastMonthExpenses)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>
                    Savings - last month: {f(lastMonthSavings)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover={true}>
                <TableCell align="center">
                  <Typography>
                    Expenses trend: {expensesTrend.toFixed(2)}%
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>
                    Savings trend: {savingsTrendToDisplay.toFixed(2)}%
                  </Typography>
                </TableCell>
              </TableRow>{" "}
              <TableRow hover={true}>
                <TableCell align="center">
                  <Typography>
                    Projected expenses - this month: {f(projectedMonthExpenses)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>
                    Projected savings - this month: {f(projectedMonthSavings)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow hover={true}>
                <TableCell align="center">
                  <Typography>
                    Expenses - this month: {f(currentMonthExpenses)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>
                    Savings - this month: {f(currentMonthSavings)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
