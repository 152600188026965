import {
  fetchCurrentSubscription,
  fetchAdminBudget,
} from "../../../api/fetch.redux";
import { ReportingApi } from "../../../api/reporting.api";
import { LoadingDialog } from "../../../components/utility/dialogs/LoadingDialog";
import { GenericDropdown } from "../../../components/utility/dropdowns/GenericDropdown";
import { logout } from "../../../components/utils.api";
import { useAppSelector } from "../../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../../redux/reducers/budgetSlice";
import {
  selectMetadata,
  selectReportMetadata,
  setMetadata,
  setReportsMetadata,
} from "../../../redux/reducers/reportingSlice";
import { selectCurrentSubscription } from "../../../redux/reducers/subscriptionSlice";
import { PremiumFeatureDisclaimer } from "../../PremiumFeatureDisclaimer";
import BlurredComponent from "../../common/BlurredComponent";
import { AccentButton } from "../../utility/buttons/AccentButton";
import { MonthlyReportChart } from "./MonthlyReportChart";
import { YearlyReportChart } from "./YearlyReportChart";
import { ReportsMetadataResponse } from "@backend/reporting.type";
import { SubscriptionTier } from "@backend/subscription.type";
import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

export const ReportsReporting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: currentSubscription,
    loaded: isCurrentSubscriptionLoaded,
    loading: isCurrentSubscriptionLoading,
  } = useAppSelector(selectCurrentSubscription);
  const {
    data: { firstEntry },
    loaded: isMetadataLoaded,
    loading: isMetadataLoading,
  } = useAppSelector(selectMetadata);
  const {
    data: reportsMetadata,
    loaded: areReportsMetadataLoaded,
    loading: isReportsMetadataLoading,
  } = useAppSelector(selectReportMetadata);

  const now = DateTime.now();

  const [retrieveMonthly, setRetrieveMonthly] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(
    now.minus({ month: 1 }).monthLong
  );
  const [selectedYear, setSelectedYear] = useState(now.year);
  const [submittedMonth, setSubmittedMonth] = useState<number>(
    now.minus({ month: 1 }).month
  );
  const [submittedYear, setSubmittedYear] = useState(now.year);

  const refreshReportMetadata = () =>
    ReportingApi.getReportsMetadata(
      budgetId,
      {
        onSuccess: (data: ReportsMetadataResponse) => {
          dispatch(setReportsMetadata({ reportsMetadata: data }));
        },
      },
      () => logout(dispatch, navigate)
    );

  const getYearlyReport = (_year: number) => {
    setRetrieveMonthly(false);
    setSubmittedYear(_year);
    refreshReportMetadata();
  };

  const getMonthlyReport = (_year: number, _month: number) => {
    setRetrieveMonthly(true);
    setSubmittedYear(_year);
    setSubmittedMonth(_month);
    refreshReportMetadata();
  };

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchCurrentSubscription(budgetId, dispatch, isCurrentSubscriptionLoaded);

      if (!isMetadataLoaded) {
        ReportingApi.getFirstEntryAndMonthsSince(
          budgetId,
          {
            onSuccess: (data: {
              firstEntry: any;
              monthsSinceFirstEntry: number;
            }) => {
              dispatch(setMetadata(data));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!areReportsMetadataLoaded) {
        refreshReportMetadata();
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  if (
    isCurrentSubscriptionLoading ||
    !isCurrentSubscriptionLoaded ||
    isReportsMetadataLoading
  )
    return <LoadingDialog key={uuid()} open={true} />;

  const DatesInput = () => {
    const firstEntryYear = DateTime.fromMillis(firstEntry).year;
    const yearNow = now.year;

    const yearOptions: { label: number; stored: boolean }[] = [];
    for (var i = firstEntryYear; i <= yearNow; i++) {
      const _year = i;
      const item = {
        label: _year,
        stored: !!reportsMetadata.yearly.find((e) => Number(e.year) === _year),
      };

      yearOptions.push(item);
    }

    const monthsOptions = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].map((label) => {
      const monthReports = reportsMetadata.monthly.filter(
        (e) => Number(e.year) === selectedYear
      );

      const stored = !!monthReports.find(
        (monthlyEntry) => monthlyEntry.month === label
      );

      return { label, stored };
    });

    return (
      <Grid container xs={12}>
        <Grid item xs={6} sx={{ paddingBottom: "20px" }}>
          <GenericDropdown
            label="month"
            onSelection={setSelectedMonth}
            options={monthsOptions}
            initialOptionIndex={monthsOptions.findIndex(
              (e) => e.label === selectedMonth
            )}
            fullWidth={false}
            optionId="label"
            optionValue={"label"}
          />
        </Grid>

        <Grid item xs={6}>
          <AccentButton
            variant="contained"
            onClick={() =>
              getMonthlyReport(
                selectedYear,
                DateTime.fromFormat(
                  `${selectedMonth} 10, ${selectedYear}`,
                  "MMMM dd, yyyy"
                ).month
              )
            }
            disabled={
              selectedYear > DateTime.now().year ||
              (selectedYear === DateTime.now().year &&
                monthsOptions.findIndex((e) => e.label === selectedMonth) + 1 >=
                  DateTime.now().month)
            }
          >
            Get monthly report
          </AccentButton>
        </Grid>

        <Grid item xs={6} sx={{ paddingBottom: "20px" }}>
          <GenericDropdown
            label="year"
            onSelection={setSelectedYear}
            options={yearOptions}
            initialOptionIndex={yearOptions.findIndex(
              (e) => e.label === selectedYear
            )}
            fullWidth={false}
            optionId="label"
            optionValue={"label"}
          />
        </Grid>

        <Grid item xs={6}>
          <AccentButton
            variant="contained"
            onClick={() => getYearlyReport(selectedYear)}
            disabled={selectedYear >= DateTime.now().year}
          >
            Get yearly report
          </AccentButton>
        </Grid>
      </Grid>
    );
  };

  const hasFreeTier =
    currentSubscription.subscriptionTier === SubscriptionTier.FREE;

  return (
    <Box sx={{ width: "100%" }}>
      <LoadingDialog
        key={uuid()}
        open={isCurrentSubscriptionLoading || isMetadataLoading}
      />

      {hasFreeTier
        ? [
            <Grid item xs={12} textAlign={"center"}>
              <Typography fontSize={20}>
                Reports allow you to see a detailed summary over a specific
                month or year.
              </Typography>
            </Grid>,
            <Grid item xs={12} textAlign={"center"}>
              <Typography fontSize={20}>
                They show overviews of spending, incomes, investment and asset
                movements, as well as net worth evolution and much more.
              </Typography>
            </Grid>,
            <PremiumFeatureDisclaimer />,
          ]
        : null}

      <BlurredComponent blurred={hasFreeTier}>
        <Grid container>
          <Grid item xs={12}>
            <DatesInput />
          </Grid>

          <Grid item xs={12}>
            {retrieveMonthly ? (
              <MonthlyReportChart month={submittedMonth} year={submittedYear} />
            ) : (
              <YearlyReportChart year={submittedYear} />
            )}
          </Grid>
        </Grid>
      </BlurredComponent>
    </Box>
  );
};
