import { Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const Text = styled(
  Typography,
  {}
)((extraProps: { fontWeight?: number }) => ({
  ...extraProps,
  textAlign: "center",
}));

export const AboutContact = () => {
  return (
    <Grid container xs={12} alignItems="center">
      <Grid item xs={12} textAlign="center">
        <Text>
          For any questions, inquiries or suggestions, do not hesitate to
          contact us at
        </Text>
      </Grid>
      <br />

      <Grid item xs={12} textAlign="center">
        <Text color="orange" textAlign="center">
          support@budgetar.io
        </Text>
      </Grid>
      <br />
      <br />

      <Grid item xs={12} textAlign="center">
        <Text>We try to respond as quickly as possible!</Text>
      </Grid>
    </Grid>
  );
};
