import Release from "./Release";
import { ReleaseNotesEntry } from "./ReleaseNotesEntry";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV01a = () => {
  return (
    <Release version="v0.1" releaseDate="2022-10-01">
      <ReleaseNotesSection title="Incomes and expenses">
        <ReleaseNotesEntry>Create income category</ReleaseNotesEntry>
        <ReleaseNotesEntry>Create expense category</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add incomes</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add expenses</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Accounts">
        <ReleaseNotesEntry>Add an account</ReleaseNotesEntry>
        <ReleaseNotesEntry>
          Add account types - bank account, credit card and cash
        </ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Investments">
        <ReleaseNotesEntry>Add an investment purchase</ReleaseNotesEntry>
        <ReleaseNotesEntry>
          Add price at a specific time for an investment
        </ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Goals">
        <ReleaseNotesEntry>Savings goals</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Reporting">
        <ReleaseNotesEntry>Savings summarized overview</ReleaseNotesEntry>
        <ReleaseNotesEntry>Categories summarized overview</ReleaseNotesEntry>
        <ReleaseNotesEntry>Investments price value report</ReleaseNotesEntry>
        <ReleaseNotesEntry>Net worth charts</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
