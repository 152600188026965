import { AccentButton } from "../buttons/AccentButton";
import { SecondaryButton } from "../buttons/SecondaryButton";
import { DialogActions, DialogTitle, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export const ConfirmActionDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  title: string;
  texts: string[];
  onConfirm: () => void;
  onCancel: () => void;
  titleColor?: string;
  ConfirmButtonIcon?: any;
}) => {
  const {
    open,
    setOpen,
    title,
    texts,
    onConfirm,
    onCancel,
    titleColor,
    ConfirmButtonIcon,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const submit = () => {
    onConfirm();
    handleClose();
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography fontSize={20} color={titleColor ?? "primary"}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {texts.map((text) => (
            <Typography
              key={text}
              textAlign={"center"}
              sx={{ paddingBottom: "10px" }}
            >
              {text}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <AccentButton
            variant="contained"
            onClick={submit}
            endIcon={ConfirmButtonIcon ? <ConfirmButtonIcon /> : null}
          >
            Confirm
          </AccentButton>

          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        </DialogActions>
      </Dialog>
    );
  };

  return renderDialog();
};
