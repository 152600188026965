import { appTheme } from "../../Theme";
import { ThemeContext } from "../../ThemeContext";
import {
  fetchCurrentSubscription,
  fetchAdminBudget,
} from "../../api/fetch.redux";
import { SubscriptionAPI } from "../../api/subscription.api";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { logout as accountsLogout } from "../../redux/reducers/accountsSlice";
import { logout as assetsLogout } from "../../redux/reducers/assetsSlice";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
  switchToAdmin,
} from "../../redux/reducers/budgetSlice";
import { logout as categoriesLogout } from "../../redux/reducers/categoriesSlice";
import { logout as expensesLogout } from "../../redux/reducers/expensesSlice";
import { logout as goalsLogout } from "../../redux/reducers/goalsSlice";
import { logout as incomesLogout } from "../../redux/reducers/incomesSlice";
import { logout as investmentsLogout } from "../../redux/reducers/investmentsSlice";
import { logout as loansLogout } from "../../redux/reducers/loansSlice";
import { logout as reportingLogout } from "../../redux/reducers/reportingSlice";
import { logout as subscriptionLogout } from "../../redux/reducers/subscriptionSlice";
import { selectCurrentSubscription } from "../../redux/reducers/subscriptionSlice";
import { Callbacks } from "../../types/util";
import { delay } from "../../util/Functions";
import { SecondaryButton } from "../utility/buttons/SecondaryButton";
import { ConfirmActionDialog } from "../utility/dialogs/ConfirmActionDialog";
import { LoadingDialog } from "../utility/dialogs/LoadingDialog";
import { logout } from "../utils.api";
import { FreeSubscriptionTierViewCard } from "./FreeSubscriptionTierViewCard";
import { PremiumSubscriptionTierViewCard } from "./PremiumSubscriptionTierViewCard";
import { LookupKey, SubscriptionTier } from "@backend/subscription.type";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Grid, Typography, Box, Card } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

const budgetarSubscriptionImage =
  require("../../../src/budgetar-subscription-image.png").default;

export const Subscriptions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ctx = useContext(ThemeContext);

  const {
    data: adminBudget,
    loaded: isAdminBudgetLoaded,
    loading: isBudgetIdLoading,
  } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: currentSubscription,
    loaded: isCurrentSubscriptionLoaded,
    loading: isCurrentSubscriptionLoading,
  } = useAppSelector(selectCurrentSubscription);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    if (isAdminBudgetLoaded) {
      fetchAdminBudget(dispatch, isAdminBudgetLoaded);
      fetchCurrentSubscription(budgetId, dispatch, isCurrentSubscriptionLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const redirectToStripe = (lookupKey: LookupKey, cbs: Callbacks) => {
    const { onSuccess, onFailed } = cbs;

    SubscriptionAPI.submitSession(
      budgetId,
      lookupKey,
      {
        onSuccess: (response: { data: string }) => {
          if (onSuccess) onSuccess(response.data);

          window.location.href = response.data;
        },
        onFailed: (err: string) => {
          if (onFailed) onFailed(err);
        },
      },
      () => logout(dispatch, navigate)
    );
  };

  const reload = () => {
    dispatch(accountsLogout());
    dispatch(assetsLogout());
    dispatch(categoriesLogout());
    dispatch(expensesLogout());
    dispatch(goalsLogout());
    dispatch(incomesLogout());
    dispatch(investmentsLogout());
    dispatch(loansLogout());
    dispatch(reportingLogout());
    dispatch(subscriptionLogout());
  };

  if (
    isCurrentSubscriptionLoading ||
    isBudgetIdLoading ||
    !isCurrentSubscriptionLoaded ||
    !isAdminBudgetLoaded
  ) {
    return <LoadingDialog open={true} key={uuid()} />;
  }

  if (budgetId !== adminBudget) {
    return (
      // TODO improve some time in the future
      <Box>
        <Typography>
          This feature is only available for budget administrators.
        </Typography>

        <Typography>You cannot use it while using budget accesses.</Typography>

        <Typography>
          Please switch to your budget to be able to manage your subscription.
        </Typography>

        <SecondaryButton
          onClick={async () => {
            toast.info("Switching back to your budget...");

            await delay(1000, () => {
              dispatch(switchToAdmin());

              ctx.setTheme(appTheme);

              reload();

              toast.success("Switch done.");
            });
          }}
        >
          Switch budget
        </SecondaryButton>
      </Box>
    );
  }

  const renderFreeSubscription = () => (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      xs={12}
      maxWidth={"500px"}
    >
      <Grid item xs={4} spacing={2}>
        <Box paddingBottom={"20px"}>
          <FreeSubscriptionTierViewCard />
        </Box>

        <Box paddingTop={"20px"}>
          <PremiumSubscriptionTierViewCard
            redirectToStripe={redirectToStripe}
          />
        </Box>
      </Grid>
    </Grid>
  );

  const renderPremiumSubscription = () => (
    <Card sx={{ maxWidth: "500px" }}>
      <ConfirmActionDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        title={"Cancel subscription"}
        texts={[
          "Are you sure you want to cancel your subscription?",
          " \n",
          `You will be able to use all Premium benefits until ${DateTime.fromMillis(
            currentSubscription.endDate
          ).toISODate()}, and then we will downgrade you to the Free tier.`,
          " \n",
          `We will no longer charge you after ${DateTime.fromMillis(
            currentSubscription.endDate
          ).toISODate()}.`,
        ]}
        onConfirm={() =>
          SubscriptionAPI.cancelSubscription(
            adminBudget,
            {
              onSuccess: () => {
                toast.success("Subscription cancellation request submitted.");
              },
              onFailed: (err: any) => {
                toast.error(`Could not cancel subscription - ${err}`);
              },
            },
            () => logout(dispatch, navigate)
          )
        }
        onCancel={() => setOpenConfirmDialog(false)}
        titleColor="orange"
      />
      <Grid container xs={12} maxWidth={"500px"}>
        <Grid item xs={10}>
          <Typography
            textAlign={"left"}
            fontSize={22}
            sx={{ marginLeft: "30px", marginTop: "20px" }}
            color="orange"
          >
            Your subscription
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <StarBorderIcon sx={{ color: "orange", marginTop: "25px" }} />
        </Grid>

        <Grid item xs={12}>
          <img
            style={{ display: "block", margin: "auto" }}
            width={"50%"}
            src={budgetarSubscriptionImage}
            alt="Budgetar Premium logo"
          />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography>Current package: PREMIUM</Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "0px" }}>
          <Typography>
            Billing period: {currentSubscription.subscriptionDuration}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "40px", marginBottom: "20px" }}>
          {currentSubscription.isCanceled ? (
            <Box>
              <Typography>
                Active until{" "}
                {DateTime.fromMillis(currentSubscription.endDate).toFormat(
                  "dd MMM yyyy"
                )}
              </Typography>
            </Box>
          ) : (
            <Typography>
              Next payment due on{" "}
              {DateTime.fromMillis(currentSubscription.endDate).toFormat(
                "dd MMM yyyy"
              )}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "0px" }}>
          <SecondaryButton
            variant="contained"
            onClick={() => setOpenConfirmDialog(true)}
            disabled={currentSubscription.isCanceled}
          >
            {currentSubscription.isCanceled
              ? "Subscription cancelled"
              : "Cancel subscription"}
          </SecondaryButton>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "20px", marginBottom: "10px" }}>
          <Typography>
            Member since{" "}
            {DateTime.fromMillis(currentSubscription.startDate).toFormat(
              "dd MMM yyyy"
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {currentSubscription.subscriptionTier === SubscriptionTier.FREE
        ? renderFreeSubscription()
        : renderPremiumSubscription()}
    </Grid>
  );
};
