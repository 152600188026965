import {
  fetchAdminBudget,
  fetchCurrentSubscription,
} from "../../../api/fetch.redux";
import { ReportingApi } from "../../../api/reporting.api";
import { LoadingDialog } from "../../../components/utility/dialogs/LoadingDialog";
import { BudgetarToast } from "../../../components/utility/misc/BudgetarToast";
import { logout } from "../../../components/utils.api";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../../redux/reducers/budgetSlice";
import {
  selectNetWorth,
  setDefaultNetworth,
  setLoading,
  setNetworth,
  setNetworthPerMonth,
  selectNetWorthPerMonth,
} from "../../../redux/reducers/reportingSlice";
import { selectCurrentSubscription } from "../../../redux/reducers/subscriptionSlice";
import { PremiumFeatureDisclaimer } from "../../PremiumFeatureDisclaimer";
import BlurredComponent from "../../common/BlurredComponent";
import { NetWorthEvolutionGraph } from "../../networth/NetWorthEvolutionGraph";
import { NetWorthPieChart } from "./NetWorthPieChart";
import { NetWorthInterface } from "@backend/reporting.type";
import { SubscriptionTier } from "@backend/subscription.type";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

export const NetWorthReporting = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: currentSubscription,
    loaded: isCurrentSubscriptionLoaded,
    loading: isCurrentSubscriptionLoading,
  } = useAppSelector(selectCurrentSubscription);

  const {
    data: netWorth,
    loaded: netWorthLoaded,
    loading: isNetWorthLoading,
  } = useAppSelector(selectNetWorth);
  const {
    data: netWorthPerMonth,
    loaded: netWorthPerMonthLoaded,
    loading: isNetWorthPerMonthLoading,
  } = useAppSelector(selectNetWorthPerMonth);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchCurrentSubscription(budgetId, dispatch, isCurrentSubscriptionLoaded);

      if (!netWorthLoaded) {
        dispatch(setLoading({ key: "netWorth", status: true }));

        ReportingApi.getNetworth(
          budgetId,
          {
            onSuccess: (netWorth: NetWorthInterface) =>
              dispatch(setNetworth({ netWorth })),
            onFailed: (err: any) => {
              console.error(err);
              dispatch(setDefaultNetworth()); // TODO set also error
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!netWorthPerMonthLoaded) {
        dispatch(setLoading({ key: "netWorthPerMonth", status: true }));

        ReportingApi.getNetworthPerMonth(
          budgetId,
          {
            onSuccess: (
              netWorthPerMonth: { date: number; netWorth: number }[]
            ) => dispatch(setNetworthPerMonth({ netWorthPerMonth })),
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  if (
    isCurrentSubscriptionLoading ||
    !isCurrentSubscriptionLoaded ||
    isNetWorthLoading ||
    isNetWorthPerMonthLoading
  )
    return <LoadingDialog key={uuid()} open={true} />;

  const hasFreeTier =
    currentSubscription.subscriptionTier === SubscriptionTier.FREE;

  return (
    <Box>
      <BudgetarToast />

      {hasFreeTier
        ? [
            <Grid item xs={12} textAlign={"center"}>
              <Typography fontSize={20}>
                Net worth reporting shows your current aggregated net worth, as
                well as the evolution of your net worth, month-per-month.
              </Typography>
            </Grid>,
            <PremiumFeatureDisclaimer />,
          ]
        : null}

      <BlurredComponent blurred={hasFreeTier}>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <NetWorthPieChart {...netWorth} />
          </Grid>
          <Grid item xs={12} md={6}>
            <NetWorthEvolutionGraph netWorthPerMonth={netWorthPerMonth} />
          </Grid>
        </Grid>
      </BlurredComponent>
    </Box>
  );
};
