import { Deletable, Identifiable } from "./common.type";
import { Currency } from "./currency.type";
import { Period } from "./period.type";

export enum CategoryType {
  EXPENSE = "EXPENSE",
  INCOME = "INCOME",
}

export interface Category extends Identifiable, Deletable {
  categoryName: string;
  periodBudget: number;
  periodBudgetCurrency: Currency;
  assignedPeriod: Period;
  categoryType: CategoryType;
  isEssential?: boolean;
}

export interface ConvertedCategory extends Category {
  convertedPeriodBudget: number;
}

export interface UpdateCategoryData {
  newName: string | null;
  newAmount: number | null;
  newPeriod: string | null;
  newType: string | null;
  newEssentialValue: boolean;
}

export interface ReportingCategory extends Category {
  total: number;
  delta: number;
}

export interface SimplifiedCategory {
  SK: string;
  assignedPeriod: Period;
  periodBudget: number;
}

export interface BuiltReportingCategoriesInterface {
  reportingCategoriesExpense: ReportingCategory[];
  reportingCategoriesIncome: ReportingCategory[];
  targetEssentialExpensesPerMonth: number;
}

export interface CreateCategoryPayload {
  categoryName: string;
  periodBudget: number;
  periodBudgetCurrency: Currency;
  assignedPeriod: Period;
  isEssential: boolean;
  deleteProtected: boolean;
}
