import { roadmapQuarters } from "./RoadmapQuarters";
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";

export interface IRoadmapItem {
  targetDate: DateTime;
  label: string;
}

export interface IRoadmapQuarter {
  quarterLabel: string;
  roadmapItems: IRoadmapItem[];
}

const RoadmapItem = ({ item }: { item: IRoadmapItem }) => {
  return (
    <Box>
      <Typography>{item.label}</Typography>
    </Box>
  );
};

const RoadmapQuarter = ({ quarter }: { quarter: IRoadmapQuarter }) => {
  const items = quarter.roadmapItems.filter(
    (e) => e.targetDate.diff(DateTime.now()).toMillis() > 0
  );

  if (!items.length) return null;

  return (
    <Box>
      <Box sx={{ display: "flex", paddingBottom: "5px" }}>
        <Typography fontWeight="900">
          {quarter.quarterLabel}
          {items.map((e) => (
            <RoadmapItem key={e.label} item={e} />
          ))}
        </Typography>
      </Box>

      <Box sx={{ paddingBottom: "5px" }} />
      <Divider />
      <Box sx={{ paddingTop: "5px" }} />
    </Box>
  );
};

export const AboutRoadmap = () => {
  return (
    <Box>
      {roadmapQuarters.map((e) => (
        <RoadmapQuarter key={e.quarterLabel} quarter={e} />
      ))}
    </Box>
  );
};
