import { fetchLoans } from "../../api/fetch.redux";
import { LoanAPI } from "../../api/loans.api";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { formatNumberDispatch } from "../../util/Formatter";
import { ActionButton } from "../common/ActionButton";
import { AddLoanInterestFee } from "../utility/dialogs/AddLoanInterestFeeDialog";
import { AddLoanPaymentDialog } from "../utility/dialogs/AddLoanPaymentDialog";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { getBudgetId } from "../utils.api";
import { LoanPopulated } from "@backend/loan.type";
import PaidIcon from "@mui/icons-material/Paid";
import PaymentIcon from "@mui/icons-material/Payment";
import { TableCell, TableRow, Card, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { toast } from "react-toastify";

export const SingleLoansTableRow = (props: {
  loan: LoanPopulated;
  matches: boolean;
}) => {
  const { loan, matches } = props;
  const {
    SK,
    loanName,
    loanPrincipal,
    interestRate,
    months,
    monthlyPayment,
    remainingBalance,
    currency,
  } = loan;

  const [openLoanPaymentDialog, setOpenLoanPaymentDialog] = useState(false);
  const [openLoanInterestFeeDialog, setOpenLoanInterestFeeDialog] =
    useState(false);

  const appDispatch = useAppDispatch();

  const budgetId = getBudgetId(useAppSelector);

  const format = (n: number) => formatNumberDispatch(n, currency);

  const renderAddLoanPaymentDialog = () => (
    <AddLoanPaymentDialog
      loanSK={SK}
      open={openLoanPaymentDialog}
      setOpen={setOpenLoanPaymentDialog}
      addLoanPaymentAction={LoanAPI.addLoanPayment}
      onSuccess={() => {
        toast.success("Loan payment added.");
        setOpenLoanPaymentDialog(false);
        fetchLoans(budgetId, appDispatch, false);
      }}
      onFailed={() => {
        toast.error("Could not add loan payment.");
        setOpenLoanPaymentDialog(false);
      }}
    />
  );

  const renderAddInterestPaymentDialog = () => (
    <AddLoanInterestFee
      loanSK={SK}
      open={openLoanInterestFeeDialog}
      setOpen={setOpenLoanInterestFeeDialog}
      addLoanInterestFeeAction={LoanAPI.addLoanInterestFee}
      onSuccess={() => {
        toast.success("Loan interest fee added.");
        setOpenLoanPaymentDialog(false);
        fetchLoans(budgetId, appDispatch, false);
      }}
      onFailed={() => {
        toast.error("Could not add loan interest fee.");
        setOpenLoanPaymentDialog(false);
      }}
    />
  );

  const renderActionButtons = (mini = false) => [
    <ActionButton
      Icon={PaymentIcon}
      onIconClick={() => setOpenLoanPaymentDialog(true)}
      tooltip="Add loan payment"
    />,
    <ActionButton
      Icon={PaidIcon}
      onIconClick={() => setOpenLoanInterestFeeDialog(true)}
      tooltip="Add interest fee"
    />,
  ];

  const renderFullSize = () => {
    return (
      <TableRow hover={true} key={SK}>
        <TableCell>
          <Typography textAlign="center">{loanName}</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign="right">{format(loanPrincipal)}</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign="right">{interestRate.toFixed(2)}%</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign="right">{months}</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign="right">{format(monthlyPayment)}</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign="right">
            {format(monthlyPayment * months)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign="right">{format(remainingBalance)}</Typography>
        </TableCell>
        <TableCell align="center">{...renderActionButtons()}</TableCell>

        <BudgetarToast />
        {renderAddLoanPaymentDialog()}
        {renderAddInterestPaymentDialog()}
      </TableRow>
    );
  };

  const renderMini = () => {
    return (
      <TableRow hover={false} key={SK}>
        <TableCell>
          <Card>
            <Grid container>
              <Grid item xs={12} sx={{ paddingBottom: "10px" }}>
                <Typography textAlign="center">{loanName}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  {format(loanPrincipal)} at {interestRate}% for {months} months
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  Remaining balance: {format(remainingBalance)}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider light={true}></Divider>
              </Grid>

              <Grid item xs={12}>
                {...renderActionButtons(true)}
              </Grid>
            </Grid>
          </Card>
        </TableCell>

        <BudgetarToast />
        {renderAddLoanPaymentDialog()}
        {renderAddInterestPaymentDialog()}
      </TableRow>
    );
  };

  return matches ? renderFullSize() : renderMini();
};
