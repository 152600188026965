import { ExpensesReporting } from "../components/reporting/ExpensesReporting";
import { IncomesReporting } from "../components/reporting/IncomesReporting";
import { CategoriesReporting } from "../components/reporting/categories/CategoriesReporting";
import { InvestmentsReporting } from "../components/reporting/investments/InvestmentsReporting";
import { NetWorthReporting } from "../components/reporting/netWorth/NetWorthReporting";
import { ReportsReporting } from "../components/reporting/reports/ReportsReporting";
import { SavingsReporting } from "../components/reporting/savings/SavingsReporting";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Box } from "@mui/system";
import { useState } from "react";

function TabPanel(props: {
  children: JSX.Element;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Reporting = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <BudgetarToast />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="Savings" {...a11yProps(0)} />
          <Tab label="Incomes" {...a11yProps(1)} />
          <Tab label="Expenses" {...a11yProps(2)} />
          <Tab label="Categories" {...a11yProps(3)} />
          <Tab label="Investments" {...a11yProps(4)} />
          <Tab
            icon={<StarBorderIcon />}
            iconPosition="end"
            label="Net worth"
            {...a11yProps(5)}
          />
          <Tab
            icon={<StarBorderIcon />}
            iconPosition="end"
            label="Reports"
            {...a11yProps(6)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <SavingsReporting />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <IncomesReporting />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <ExpensesReporting />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <CategoriesReporting />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <InvestmentsReporting />
      </TabPanel>

      <TabPanel value={value} index={5}>
        <NetWorthReporting />
      </TabPanel>

      <TabPanel value={value} index={6}>
        <ReportsReporting />
      </TabPanel>

      {/* <TabPanel value={value} index={1}>
        <ProjectionsReporting />
      </TabPanel> */}
      {/* <TabPanel value={value} index={1}></TabPanel>
      <TabPanel value={value} index={2}></TabPanel> */}
    </Box>
  );
};
