import { BudgetItemsAPI } from "../../api/budget-items/budget-items.api";
import { ExpenseAPI } from "../../api/expenses/expenses.api";
import { fetchExpenses } from "../../api/fetch.redux";
import { ReportingApi } from "../../api/reporting.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setCurrentMonthOverview } from "../../redux/reducers/reportingSlice";
import { Callbacks } from "../../types/util";
import { CommonBudgetEntriesTableProps } from "../budget-entries/BudgetEntriesTable";
import { BudgetEntriesTable } from "../budget-entries/BudgetEntriesTable";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { getBudgetId, logout } from "../utils.api";
import { CurrentMonthOverview } from "@backend/reporting.type";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const ExpensesTable = (props: CommonBudgetEntriesTableProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const budgetId = getBudgetId(useAppSelector);

  const refreshItems = () => fetchExpenses(budgetId, dispatch, false);

  const onItemsUpdate = () => {
    refreshItems();

    ReportingApi.getCurrentMonthOverview(
      budgetId,
      {
        onSuccess: (currentMonthOverview: CurrentMonthOverview) => {
          dispatch(setCurrentMonthOverview({ currentMonthOverview }));
        },
      },
      () => logout(dispatch, navigate)
    );
  };

  const deleteItem = (args: { itemSK: string }, cbs: Callbacks) =>
    ExpenseAPI.deleteExpense(
      budgetId,
      { expenseSK: args.itemSK },
      {
        onSuccess: () => {
          toast.success("Expense deleted.");

          onItemsUpdate();

          if (cbs.onSuccess) cbs.onSuccess({});
        },
        onFailed: (err) => toast.error(err),
      },
      () => logout(dispatch, navigate)
    );

  const updateItem = (args: { itemSK: string }, cbs: Callbacks) =>
    ExpenseAPI.updateExpense(
      budgetId,
      { expenseSK: args.itemSK, ...args },
      {
        onSuccess: () => {
          toast.success("Item updated.");

          onItemsUpdate();

          if (cbs.onSuccess) cbs.onSuccess({});
        },
        onFailed: (err) => {
          toast.error(err);
          if (cbs.onFailed) cbs.onFailed({});
        },
      },
      () => logout(dispatch, navigate)
    );

  const cloneItem = (args: { itemSK: string }, cbs: Callbacks) =>
    BudgetItemsAPI.cloneItem(
      budgetId,
      { ...args },
      {
        onSuccess: () => {
          toast.success("Expense cloned.");

          onItemsUpdate();

          if (cbs.onSuccess) cbs.onSuccess({});
        },
        onFailed: (err: any) => {
          toast.error(err);
          if (cbs.onFailed) cbs.onFailed({});
        },
      },
      () => logout(dispatch, navigate)
    );

  const loadItems = () => {
    refreshItems();
  };

  return (
    <Box>
      <BudgetEntriesTable
        {...props}
        actions={{
          deleteItem,
          updateItem,
          cloneItem,
          loadItems,
        }}
        categoryTypeFilter="expense"
      />{" "}
      <BudgetarToast />
    </Box>
  );
};
