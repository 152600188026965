import { CircularProgress, DialogTitle, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";

export const LoadingDialog = (props: { open: boolean; toDisplay?: string }) => {
  const { open, toDisplay } = props;

  const [takingLong, setTakingLong] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setTakingLong(true);
      }, 8000);
    }
  }, [open]);

  const renderDialog = () => {
    return (
      <Dialog open={open}>
        <DialogTitle sx={{ textAlign: "center" }}>
          <CircularProgress />
          <Typography fontSize="20px">
            Loading {toDisplay || "data"}...
          </Typography>

          {takingLong ? (
            <Typography fontSize="20px">It is taking a bit long...</Typography>
          ) : null}
        </DialogTitle>
      </Dialog>
    );
  };

  return renderDialog();
};
