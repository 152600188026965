import { useAppSelector } from "../../../redux/hooks";
import { selectAccounts } from "../../../redux/reducers/accountsSlice";
import { GenericDropdown } from "./GenericDropdown";
import { Account, AccountType } from "@backend/account.type";

export const AccountsDropdown = (props: {
  accountSelected: any;
  label?: string;
  accountTypeFilter?: string;
  exclusiveAccountTypeFilters?: AccountType[];
  initialOptionIndex?: number;
  options?: any[];
  disabled?: boolean;
  fullWidth?: boolean;
}) => {
  const {
    accountSelected,
    label,
    accountTypeFilter,
    initialOptionIndex,
    options,
    disabled,
    fullWidth = true,
    exclusiveAccountTypeFilters = [],
  } = props;

  const { data: accounts } = useAppSelector(selectAccounts);
  let filteredAccounts = accounts;

  filteredAccounts = filteredAccounts
    .filter(
      (e: Account) =>
        (!accountTypeFilter || e.accountType === accountTypeFilter) &&
        !exclusiveAccountTypeFilters.includes(e.accountType)
    )
    .sort((a, b) => a.accountName.localeCompare(b.accountName));

  const sortedOptions = options
    ? options.sort((a, b) => a.accountName.localeCompare(b.accountName))
    : filteredAccounts;

  return (
    <GenericDropdown
      label={label || "Account"}
      options={sortedOptions}
      onSelection={accountSelected}
      optionId={"SK"}
      optionValue={"accountName"}
      initialOptionIndex={initialOptionIndex}
      disabledOverride={disabled}
      fullWidth={fullWidth}
    />
  );
};
