import { Identifiable } from "./common.type";

export interface SubscriptionItemBase {
  budgetId: string;
  subscriptionId: string;
  subscriptionTier: SubscriptionTier;
  startDate: number;
  endDate: number;
  subscriptionDuration: SubscriptionDuration;
  subscriptionStatus: SubscriptionStatus;
  //
  statusUpdatedAt: number;
  isCanceled?: boolean;
}

export interface SubscriptionItem extends Identifiable, SubscriptionItemBase {}

export enum SubscriptionDuration {
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum SubscriptionTier {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
}

export enum SubscriptionStatus {
  DUE = "DUE",
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  ACTIVE = "ACTIVE",
  TERMINATED = "TERMINATED",
}

export const TierConfig: Record<
  SubscriptionTier,
  { priceMonthly: number; priceYearly: number; advantages: string[] }
> = {
  [SubscriptionTier.FREE]: {
    priceMonthly: 0.0,
    priceYearly: 0.0,
    advantages: [
      "Unlimited accounts and categories",
      "Basic reporting",
      "Soft limit of 100 entries per month, sufficient for regular use",
    ],
  },
  [SubscriptionTier.PREMIUM]: {
    priceMonthly: 1.99,
    priceYearly: 9.99,
    advantages: [
      "Unlimited monthly entries for heavy use",
      "Detailed yearly and monthly reporting",
      "No ads",
    ],
  },
};

export type LookupKey = "premium-monthly" | "premium-yearly";

export const SubscriptionDurationLookupKeyMapping: Record<
  LookupKey,
  SubscriptionDuration
> = {
  "premium-monthly": SubscriptionDuration.MONTH,
  "premium-yearly": SubscriptionDuration.YEAR,
};

// API stuff below

export interface CreateSessionPayload {
  lookup_key: LookupKey;
}

export interface CancelSubscriptionPayload {}

export interface SubscriptionToCancel extends Identifiable {
  subscriptionId: string;
  cancelationDate: number;
}
