import { getTextSize } from "../../style/fonts";
import { Callbacks } from "../../types/util";
import { formatNumberDispatch } from "../../util/Formatter";
import { optionalTruncate } from "../../util/Functions";
import { BudgetarDatePicker } from "../utility/BudgetarDatePicker";
import { SecondaryButton } from "../utility/buttons/SecondaryButton";
import { AccountsDropdown } from "../utility/dropdowns/AccountsDropdown";
import { CategoriesDropdown } from "../utility/dropdowns/CategoriesDropdown";
import {
  UpdateBudgetEntryItemData,
  PopulatedBudgetEntry,
} from "@backend/common.type";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Box,
  TextField,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";

export const SingleBudgetEntryTableRow = (props: {
  matches: boolean;
  categoryTypeFilter: "expense" | "income";
  item: PopulatedBudgetEntry;
  deleteItemCall: (itemSK: string) => void;
  cloneItemCall: (itemSK: string, newDate: number, cbs: Callbacks) => void;
  updateItemCall: (
    itemSK: string,
    args: UpdateBudgetEntryItemData,
    cbs: Callbacks
  ) => void;
}) => {
  const {
    categoryTypeFilter,
    item,
    deleteItemCall,
    updateItemCall,
    cloneItemCall,
    matches,
  } = props;
  const {
    SK,
    account,
    budgetEntryName,
    budgetEntryAmount,
    budgetEntryDate,
    budgetEntryCategory,
    currency,
  } = item;

  const [editing, setEditing] = useState(false);
  const [
    updatingBudgetEntryPopulatedBudgetEntry,
    setUpdatingBudgetEntryPopulatedBudgetEntry,
  ] = useState(false);
  //
  const [newBudgetEntryName, setNewBudgetEntryName] = useState<string | null>(
    null
  );
  const [newBudgetEntryAmount, setNewBudgetEntryAmount] = useState<
    number | null
  >(null);
  const [newCategoryId, setNewCategoryId] = useState<string | null>(null);
  const [newAccountId, setNewAccountId] = useState<string | null>(null);
  const [newDate, setNewDate] = useState<string | null>(null);
  const [actionsOpened, setActionsOpened] = useState<boolean>(false);
  //
  const toggleEditing = () => setEditing(!editing);

  const updateSuccessfulCallback = () => {
    setUpdatingBudgetEntryPopulatedBudgetEntry(false);
    setEditing(false);
  };

  const submitEditing = () => {
    setUpdatingBudgetEntryPopulatedBudgetEntry(true);
    updateItemCall(
      SK,
      {
        newBudgetEntryName,
        newBudgetEntryAmount,
        newCategoryId,
        newAccountId,
        newDate,
      },
      { onSuccess: updateSuccessfulCallback }
    );
  };

  const cancelEditing = () => {
    setEditing(false);
    setNewBudgetEntryName(null);
    setNewBudgetEntryAmount(null);
  };

  const f = (n: number) => formatNumberDispatch(n, currency);

  const renderNameCell = (mini = false) => {
    if (editing) {
      return (
        <Box>
          <TextField
            label="New item name"
            variant="outlined"
            value={newBudgetEntryName ?? budgetEntryName}
            onChange={(e) => setNewBudgetEntryName(e.target.value)}
            sx={{ paddingTop: "10px" }}
            disabled={updatingBudgetEntryPopulatedBudgetEntry}
          />
        </Box>
      );
    }

    return (
      <Box onClick={mini ? () => {} : toggleEditing}>
        <Typography
          textAlign={mini ? "left" : "center"}
          fontSize={getTextSize(mini)}
          fontWeight={900}
        >
          {optionalTruncate(budgetEntryName, 20)}
        </Typography>
      </Box>
    );
  };

  const renderAmountCell = (mini = false) => {
    if (editing) {
      return (
        <Box>
          <TextField
            label="New amount"
            variant="outlined"
            value={newBudgetEntryAmount ?? budgetEntryAmount}
            onChange={(e) => setNewBudgetEntryAmount(Number(e.target.value))}
            type="number"
            sx={{ paddingTop: "10px" }}
            disabled={updatingBudgetEntryPopulatedBudgetEntry}
          />
        </Box>
      );
    }

    return (
      <Box onClick={toggleEditing}>
        <Typography
          textAlign={mini ? "right" : "right"}
          fontSize={getTextSize(mini)}
          fontWeight={900}
        >
          {f(budgetEntryAmount)}
        </Typography>
      </Box>
    );
  };

  const renderDateCell = (mini = false) => {
    if (!editing) {
      return (
        <Box onClick={toggleEditing}>
          <Typography
            textAlign={mini ? "right" : "center"}
            fontSize={getTextSize(mini)}
          >
            {DateTime.fromISO(
              new Date(budgetEntryDate).toISOString()
            ).toISODate()}
          </Typography>
        </Box>
      );
    }

    return <BudgetarDatePicker onSelect={setNewDate} />;
  };

  const renderCategoryCell = (mini = false) => {
    if (!editing) {
      return (
        <Box onClick={mini ? () => {} : toggleEditing}>
          <Typography
            textAlign={mini ? "left" : "center"}
            fontSize={getTextSize(mini)}
          >
            {optionalTruncate(budgetEntryCategory.categoryName)}
          </Typography>
        </Box>
      );
    }

    return (
      <CategoriesDropdown
        categoryTypeFilter={categoryTypeFilter}
        categorySelected={setNewCategoryId}
        disabled={updatingBudgetEntryPopulatedBudgetEntry}
      />
    );
  };

  const renderAccountCell = (mini = false) => {
    if (!editing) {
      return (
        <Box onClick={mini ? () => {} : toggleEditing}>
          <Typography textAlign={"center"} fontSize={getTextSize(mini)}>
            {account.accountName}
          </Typography>
        </Box>
      );
    }

    return (
      <AccountsDropdown
        accountSelected={setNewAccountId}
        disabled={updatingBudgetEntryPopulatedBudgetEntry}
      />
    );
  };

  const renderActionsCell = (mini = false) => {
    if (editing) {
      return (
        <Box>
          <Box sx={{ padding: "5px" }}>
            <SecondaryButton onClick={submitEditing} variant="contained">
              Update
            </SecondaryButton>
          </Box>
          <Box sx={{ padding: "5px" }}>
            <Button onClick={cancelEditing}>Cancel</Button>
          </Box>
        </Box>
      );
    }

    return [
      <IconButton>
        <ModeEditIcon onClick={() => setEditing(true)} color="warning" />
      </IconButton>,
      <IconButton>
        <DeleteIcon onClick={() => deleteItemCall(SK)} color="warning" />
      </IconButton>,
      <IconButton>
        <FileCopyIcon
          color="warning"
          onClick={() => cloneItemCall(SK, DateTime.now().toMillis(), {})}
        />
      </IconButton>,
    ];
  };

  const renderFullSize = () => {
    return (
      <TableRow hover={true} key={SK}>
        <TableCell>{renderNameCell()}</TableCell>
        <TableCell>{renderCategoryCell()}</TableCell>
        <TableCell>{renderAmountCell()}</TableCell>
        <TableCell>{renderDateCell()}</TableCell>
        <TableCell>{renderAccountCell()}</TableCell>
        <TableCell align="center">{renderActionsCell()}</TableCell>
      </TableRow>
    );
  };

  const renderMini = () => {
    return (
      <TableRow hover={false} onClick={() => setActionsOpened(!actionsOpened)}>
        <TableCell>
          <Grid container>
            <Grid item xs={6}>
              <Typography textAlign={"left"}>{renderNameCell(true)}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography textAlign={"right"}>
                {renderAmountCell(true)}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography textAlign={"left"}>
                {renderCategoryCell(true)}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography textAlign={"right"}>
                {renderDateCell(true)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {actionsOpened ? renderActionsCell(true) : null}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  return matches ? renderFullSize() : renderMini();
};
