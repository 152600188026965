import { useAppSelector } from "../../../redux/hooks";
import { selectSummarizedInvestments } from "../../../redux/reducers/reportingSlice";
import {
  ReportingInvestmentsSummaryRow,
  SingleInvestmentsReportingExpenseTableRow,
} from "./SingleInvestmentsReportingTableRow";
import { SingleInvestmentsReportingExpenseEmptyTableRow } from "./SingleInvestmentsReportingTableRow";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";

export const InvestmentsReportingTable = () => {
  const matches = useMediaQuery("(min-width:600px)");

  const { data: summarizedInvestments } = useAppSelector(
    selectSummarizedInvestments
  );

  const {
    totalCapitalInvested,
    currentValue,
    totalFees,
    totalValueDelta,
    totalExitFees,
    reportingInvestments,
  } = summarizedInvestments;

  const rows = reportingInvestments.map((investment, idx) => (
    <SingleInvestmentsReportingExpenseTableRow
      key={idx}
      investment={investment}
      matches={matches}
    />
  ));

  if (!rows.length)
    rows.push(<SingleInvestmentsReportingExpenseEmptyTableRow />);

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <Grid container sx={{ padding: "10px" }} xs={12} spacing={2}>
        <Grid item xs={12}>
          {reportingInvestments ? (
            <TableContainer component={Paper}>
              <Table aria-label="investments reporting table">
                <TableHead>
                  {matches ? (
                    <TableRow hover={true}>
                      <TableCell>Asset</TableCell>
                      <TableCell>Cost basis</TableCell>
                      <TableCell>Owned</TableCell>
                      <TableCell>Cost basis total</TableCell>
                      <TableCell>Current price</TableCell>
                      <TableCell>Current value</TableCell>
                      <TableCell>Purchase fees</TableCell>
                      <TableCell>Value delta</TableCell>
                      <TableCell>Delta percentage</TableCell>
                      <TableCell>Break even price</TableCell>
                      <TableCell>Exit fees</TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {rows}
                  <ReportingInvestmentsSummaryRow
                    totalCapitalInvested={totalCapitalInvested}
                    totalCurrentValue={currentValue}
                    totalFees={totalFees}
                    totalValueDelta={totalValueDelta}
                    totalExitFees={totalExitFees}
                    matches={matches}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box>
              <Typography>No reporting category data</Typography>{" "}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
