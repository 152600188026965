import { CurrentMonthOverview } from "../../../../aws/budgetar-back/src/types/reporting.type";
import {
  fetchInvestments,
  fetchInvestmentPrices,
  fetchAdminBudget,
  fetchSoldInvestments,
  fetchAccounts,
  fetchCategories,
  fetchIncomes,
} from "../api/fetch.redux";
import { ReportingApi } from "../api/reporting.api";
import { TabPanel } from "../components/about-us/AboutTabs";
import { InvestmentsTable } from "../components/investments/InvestmentsTable";
import { SoldInvestmentsTable } from "../components/investments/SoldInvestmentsTable";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewInvestmentDialog } from "../components/utility/dialogs/NewInvestmentDialog";
import { NewInvestmentPriceDialog } from "../components/utility/dialogs/NewInvestmentPriceDialog";
import { SellInvestmentDialog } from "../components/utility/dialogs/SellInvestmentDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { getBudgetId, logout } from "../components/utils.api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAccounts } from "../redux/reducers/accountsSlice";
import { selectAdminBudgetId } from "../redux/reducers/budgetSlice";
import { selectCategories } from "../redux/reducers/categoriesSlice";
import {
  selectCurrentPrices,
  selectInvestments,
  selectSoldInvestments,
} from "../redux/reducers/investmentsSlice";
import {
  setAllExpensesAndIncomes,
  setCurrentMonthOverview,
  setSavingsToday,
} from "../redux/reducers/reportingSlice";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SellIcon from "@mui/icons-material/Sell";
import {
  Button,
  Typography,
  TextField,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface InvestmentsPageProps {
  openHowToDialog: () => void;
}
export const Investments = (props: InvestmentsPageProps) => {
  const { openHowToDialog } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const [openNewInvestmentDialog, setOpenNewInvestmentDialog] = useState(false);
  const [openSellInvestmentDialog, setOpenSellInvestmentDialog] =
    useState(false);
  const [openNewInvestmentPriceDialog, setOpenNewInvestmentPriceDialog] =
    useState(false);
  const [filter, setFilter] = useState("");
  const [value, setValue] = useState(0);
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleFilterChange = (newInput: string) => {
    setFilter(newInput);
  };

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = getBudgetId(useAppSelector);
  const {
    data: investments,
    loaded: areInvestmentsLoaded,
    loading: areInvestmentsLoading,
  } = useSelector(selectInvestments);
  const {
    data: soldInvestments,
    loaded: areSoldInvestmentsLoaded,
    loading: areSoldInvestmentsLoading,
  } = useSelector(selectSoldInvestments);
  const {
    data: investmentsPrices,
    loaded: areInvestmentPricesLoaded,
    loading: areInvestmentPricesLoading,
  } = useSelector(selectCurrentPrices);
  const { loaded: areAccountsLoaded, loading: areAccountsLoading } =
    useSelector(selectAccounts);
  const { loaded: areCategoriesLoaded, loading: areCategoriesLoading } =
    useSelector(selectCategories);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchInvestments(budgetId, dispatch, areInvestmentsLoaded);
      fetchSoldInvestments(budgetId, dispatch, areSoldInvestmentsLoaded);
      fetchInvestmentPrices(budgetId, dispatch, areInvestmentPricesLoaded);
      fetchAccounts(budgetId, dispatch, areAccountsLoaded);
      fetchCategories(budgetId, dispatch, areCategoriesLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const refresh = () => {
    fetchInvestments(budgetId, dispatch, false);
    fetchSoldInvestments(budgetId, dispatch, false);
    fetchInvestmentPrices(budgetId, dispatch, false);

    ReportingApi.loadAllExpensesAndIncomes(
      budgetId,
      {
        onSuccess: (data: any) => {
          dispatch(setAllExpensesAndIncomes(data));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getSavingsToday(
      budgetId,
      {
        onSuccess: (savingsToday: number) => {
          dispatch(setSavingsToday({ savingsToday }));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getCurrentMonthOverview(
      budgetId,
      {
        onSuccess: (currentMonthOverview: CurrentMonthOverview) => {
          dispatch(setCurrentMonthOverview({ currentMonthOverview }));
        },
      },
      () => logout(dispatch, navigate)
    );
  };

  const newInvestmentsCreated = () => {
    refresh();
    toast.success("New investment created.");
  };

  const newInvestmentPriceAdded = () => {
    refresh();
    toast.success("New investment price added created.");
  };

  const investmentSold = () => {
    refresh();
    fetchIncomes(budgetId, dispatch, false);

    toast.success("Investment sold.");
  };

  const ButtonLabel = (props: any) => (
    <Typography fontSize={{ xs: 10, md: 16 }}>{props.text}</Typography>
  );

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <LoadingDialog
        key={uuid()}
        open={
          areInvestmentsLoading ||
          areInvestmentPricesLoading ||
          areSoldInvestmentsLoading
        }
        toDisplay="investments"
      />
      <BudgetarToast />
      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <SecondaryButton size="small" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            Back
          </SecondaryButton>
        </Box>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewInvestmentDialog(true)}
          sx={{ marginRight: "10px" }}
        >
          <AddIcon />
          <Typography fontSize={{ xs: 10, md: 20 }}>
            <ButtonLabel text="New investment" />
          </Typography>
        </Button>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewInvestmentPriceDialog(true)}
        >
          <AddIcon />
          <ButtonLabel text="Add investment price" />
        </Button>

        <Box sx={{ paddingLeft: "10px" }} />

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenSellInvestmentDialog(true)}
        >
          <SellIcon />
          <ButtonLabel text="Sell investment" />
        </Button>

        <Divider orientation="vertical" />

        <Box sx={{ paddingLeft: "10px" }} />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}

        <Box sx={{ paddingLeft: "10px" }}>
          <TextField
            placeholder="Filter"
            onChange={(e) => handleFilterChange(e.target.value)}
          ></TextField>
        </Box>
      </Box>

      <Box></Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Investments" {...a11yProps(0)} />
          <Tab label="Sold investments" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        {investments
          ? [
              <InvestmentsTable
                investments={investments}
                investmentsPrices={investmentsPrices}
                filters={{ filter }}
              />,
            ]
          : [<Box>No investments</Box>]}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {soldInvestments
          ? [<SoldInvestmentsTable soldInvestments={soldInvestments} />]
          : [<Box>No investments sold yet</Box>]}
      </TabPanel>

      <Box>
        <NewInvestmentDialog
          open={openNewInvestmentDialog}
          setOpen={setOpenNewInvestmentDialog}
          onSuccess={newInvestmentsCreated}
          onFailed={() => toast.error("Error while creating investment.")}
        />

        <NewInvestmentPriceDialog
          open={openNewInvestmentPriceDialog}
          setOpen={setOpenNewInvestmentPriceDialog}
          onSuccess={newInvestmentPriceAdded}
          onFailed={() => toast.error("Error while adding investment price.")}
        />

        <SellInvestmentDialog
          open={openSellInvestmentDialog}
          setOpen={setOpenSellInvestmentDialog}
          onSuccess={investmentSold}
          onFailed={() => toast.error("Error while selling investment.")}
        />
      </Box>
    </Box>
  );
};
