import { useAppSelector } from "../../../redux/hooks";
import { SavingsSummary } from "../../../redux/reducers/reportingSlice";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../../util/Formatter";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";

interface SavingsSummaryTableInterface {
  savingsSummary: SavingsSummary;
}
export const SavingsSummaryTable = (props: SavingsSummaryTableInterface) => {
  const {
    plannedExpense,
    plannedIncome,
    plannedSavings,
    actualExpense,
    actualIncome,
    actualSavings,
    monthsSinceFirstEntry,
  } = props.savingsSummary;

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  return (
    <Box sx={{ display: "flex", paddingTop: "10px" }}>
      <TableContainer component={Paper}>
        <Table aria-label="goals table">
          <TableHead>
            <TableRow hover={true}>
              <TableCell align="right" />
              <TableCell align="right">
                Planned (over {monthsSinceFirstEntry} months)
              </TableCell>
              <TableCell align="right">Actual</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={0}>
              <TableCell align="right">Expense</TableCell>
              <TableCell align="right">{f(plannedExpense)}</TableCell>
              <TableCell align="right">{f(actualExpense)}</TableCell>
            </TableRow>

            <TableRow key={1}>
              <TableCell align="right">Income</TableCell>
              <TableCell align="right">{f(plannedIncome)}</TableCell>
              <TableCell align="right">{f(actualIncome)}</TableCell>
            </TableRow>

            <TableRow key={2}>
              <TableCell align="right">Savings</TableCell>
              <TableCell align="right">{f(plannedSavings)}</TableCell>
              <TableCell align="right">{f(actualSavings)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
