import { Callbacks } from "./../types/util";
import { postRequest, getRequest } from "./index";

const getGoalUrl = (route: string, budgetId: string) =>
  `${budgetId}/goal/${route}`;

interface AddGoalArgs {
  goalName: string;
  amount: number;
}

async function createGoal(
  budgetId: string,
  args: AddGoalArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getGoalUrl("create", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface DeleteGoalArgs {
  goalSK: string;
}
async function deleteGoal(
  budgetId: string,
  args: DeleteGoalArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getGoalUrl("delete", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getGoals(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/goals`, logoutCallback, onSuccess, onFailed);
}

export const GoalAPI = {
  createGoal,
  getGoals,
  deleteGoal,
};
