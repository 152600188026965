import { Callbacks } from "../types/util";
import { sendSimpleRequest, SimpleRequestParams } from "./index";

const getReportingUrl = (route: string, budgetId: string) =>
  `${budgetId}/reporting/${route}`;

async function loadSavingsSummary(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "savings/summary", cbs, logoutCallback);
}

async function loadSummedExpensesPerMonth(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void,
  months: number = 10
) {
  let url = "summed/expenses";
  if (months) url = url.concat(`/${months}`);

  request(budgetId, url, cbs, logoutCallback);
}

async function loadSummedIncomesPerMonth(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void,
  months: number = 10
) {
  let url = "summed/incomes";
  if (months) url = url.concat(`/${months}`);

  request(budgetId, url, cbs, logoutCallback);
}

async function loadSummedSavingsPerMonth(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void,
  months: number = 10
) {
  let url = "summed/savings";
  if (months) url = url.concat(`/${months}`);

  request(budgetId, url, cbs, logoutCallback);
}

async function loadNetSavingsPerMonthTotal(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "net/savings", cbs, logoutCallback);
}

async function loadAllExpensesAndIncomes(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "data/base/all", cbs, logoutCallback);
}

async function getSavingsEstimate(
  budgetId: string,
  months: number,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, `estimate/savings/${months}`, cbs, logoutCallback);
}

async function getTargetMonthlyExpenses(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "target/expenses/monthly", cbs, logoutCallback);
}

async function getTargetMonthlyIncomes(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "target/incomes/monthly", cbs, logoutCallback);
}

async function getActualMonthlyIncomes(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "actual/incomes/monthly", cbs, logoutCallback);
}

async function getActualMonthlyExpenses(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "actual/expenses/monthly", cbs, logoutCallback);
}

async function getTargetTotalExpenses(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "target/expenses/total", cbs, logoutCallback);
}

async function getTargetTotalIncomes(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "target/incomes/total", cbs, logoutCallback);
}

async function getActualTotalIncomes(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "actual/incomes/total", cbs, logoutCallback);
}

async function getActualTotalExpenses(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "actual/expenses/total", cbs, logoutCallback);
}

async function getActualTotalSavings(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "actual/savings/total", cbs, logoutCallback);
}

async function getTargetMonthlySavings(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "target/savings/monthly", cbs, logoutCallback);
}

async function getFirstEntryAndMonthsSince(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "first/entry/metadata", cbs, logoutCallback);
}

async function getInvestmentsNetWorth(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "actual/networth/investments", cbs, logoutCallback);
}

async function getNetworth(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "actual/networth/all", cbs, logoutCallback);
}

async function getNetworthPerMonth(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, `actual/networth/per-month`, cbs, logoutCallback);
}

async function getReportingCategories(
  budgetId: string,
  from: number,
  to: number,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, `categories/${from}/${to}`, cbs, logoutCallback);
}

async function getExpensesAverageMonthlyTrend(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "trend/monthly/expenses/all", cbs, logoutCallback);
}

async function getTrendsProjectionsOverview(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "trend/projection/summarized", cbs, logoutCallback);
}

async function getSummarizedInvestments(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "investments/summarized", cbs, logoutCallback);
}

async function getInvestmentsEvolution(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "investments/evolution/market-value", cbs, logoutCallback);
}

async function getInvestmentsCapitalEvolution(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "investments/evolution/capital", cbs, logoutCallback);
}

async function getSavingsToday(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "savings/today", cbs, logoutCallback);
}

async function getSummarizedExpensesPerDay(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "expenses/summarized/per-day", cbs, logoutCallback);
}

async function getSummarizedExpensesPerWeek(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, "expenses/summarized/per-week", cbs, logoutCallback);
}

async function getYearlyReport(
  budgetId: string,
  year: number,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, `reports/yearly/${year}`, cbs, logoutCallback);
}

async function getMonthlyReport(
  budgetId: string,
  year: number,
  month: number,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, `reports/monthly/${year}/${month}`, cbs, logoutCallback);
}

async function getCurrentMonthOverview(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, `overview/monthly/current`, cbs, logoutCallback);
}

async function getReportsMetadata(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, `reports/metadata`, cbs, logoutCallback);
}

async function getAverageExpensesPerWeekDay(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, `average/expenses/per-week-day`, cbs, logoutCallback);
}

async function getAverageExpensesPerMonth(
  budgetId: string,
  cbs: Callbacks,
  logoutCallback: () => void
) {
  request(budgetId, `average/expenses/per-month`, cbs, logoutCallback);
}

async function request(
  budgetId: string,
  url: string,
  cbs: Callbacks,
  logoutCallback: () => void,
  params?: SimpleRequestParams
) {
  return sendSimpleRequest(
    url,
    (urlString) => getReportingUrl(urlString, budgetId),
    cbs,
    logoutCallback,
    params
  );
}

export const ReportingApi = {
  loadSavingsSummary,
  loadSummedExpensesPerMonth,
  getInvestmentsCapitalEvolution,
  loadSummedIncomesPerMonth,
  loadNetSavingsPerMonthTotal,
  loadAllExpensesAndIncomes,
  getSavingsEstimate,
  loadSummedSavingsPerMonth,
  getTargetMonthlyExpenses,
  getTargetMonthlyIncomes,
  getActualMonthlyIncomes,
  getActualMonthlyExpenses,
  getFirstEntryAndMonthsSince,
  getTargetTotalExpenses,
  getTargetTotalIncomes,
  getActualTotalIncomes,
  getActualTotalExpenses,
  getActualTotalSavings,
  getTargetMonthlySavings,
  getInvestmentsNetWorth,
  getNetworth,
  getReportingCategories,
  getExpensesAverageMonthlyTrend,
  getTrendsProjectionsOverview,
  getSummarizedInvestments,
  getInvestmentsEvolution,
  getSavingsToday,
  getSummarizedExpensesPerDay,
  getSummarizedExpensesPerWeek,
  getYearlyReport,
  getNetworthPerMonth,
  getCurrentMonthOverview,
  getMonthlyReport,
  getReportsMetadata,
  getAverageExpensesPerWeekDay,
  getAverageExpensesPerMonth,
};
