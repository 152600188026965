import {
  AddMortgagePaymentData,
  CreateMortgageInput,
} from "../../../../aws/budgetar-back/src/types/mortgage.type";
import { Callbacks } from "../types/util";
import { postRequest, getRequest } from "./index";

const getMortgageUrl = (route: string, budgetId: string) =>
  `${budgetId}/mortgage/${route}`;

async function createMortgage(
  budgetId: string,
  args: CreateMortgageInput,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getMortgageUrl("create", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getMortgages(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/mortgages`, logoutCallback, onSuccess, onFailed);
}

async function addMortgagePayment(
  budgetId: string,
  args: AddMortgagePaymentData,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  postRequest(
    `${budgetId}/mortgages/payments/add`,
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export const MortgageAPI = {
  createMortgage,
  getMortgages,
  addMortgagePayment,
};
