import { fetchCategories, fetchAdminBudget } from "../api/fetch.redux";
import CategoriesTabs from "../components/categories/CategoriesTabs";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewCategoryDialog } from "../components/utility/dialogs/NewCategoryDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  selectAdminBudgetId,
  selectBudgetToUse,
} from "../redux/reducers/budgetSlice";
import { selectCategories } from "../redux/reducers/categoriesSlice";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface CategoriesPageProps {
  openHowToDialog: () => void;
}
export const Categories = (props: CategoriesPageProps) => {
  const { openHowToDialog } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const [openNewCategoryDialog, setOpenNewCategoryDialog] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: categories,
    loaded: areCategoriesLoaded,
    loading: areCategoriesLoading,
  } = useSelector(selectCategories);

  const newCategoriesCreated = () => {
    fetchCategories(budgetId, dispatch, false);
    toast.success("New category created.");
  };

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchCategories(budgetId, dispatch, areCategoriesLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [isBudgetIdLoaded]);

  const validateInput = (categoryName: string, categoryType: string) => {
    if (
      categories.find(
        (e) =>
          e.categoryName === categoryName &&
          e.categoryType.toLowerCase() === categoryType.toLowerCase()
      )
    ) {
      return "Category already exists.";
    }

    return null;
  };

  return (
    <Box>
      <LoadingDialog
        key={uuid()}
        open={areCategoriesLoading}
        toDisplay="categories"
      />
      <BudgetarToast />
      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <SecondaryButton size="small" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            Back
          </SecondaryButton>
        </Box>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewCategoryDialog(true)}
        >
          <AddIcon />
          New category
        </Button>

        <Box sx={{ paddingLeft: "10px" }} />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}
      </Box>

      <NewCategoryDialog
        open={openNewCategoryDialog}
        setOpen={setOpenNewCategoryDialog}
        onSuccess={newCategoriesCreated}
        onFailed={(err: any) =>
          toast.error(err || `Error while creating category.`)
        }
        validate={(name: string, type: string) => validateInput(name, type)}
      />

      <CategoriesTabs />
    </Box>
  );
};
