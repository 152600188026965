import { CategoriesReportingCategoryTable } from "./CategoriesReportingBasicTable";
import { BuiltReportingCategoriesInterface } from "@backend/category.type";
import { Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

interface Base {
  budgetDisplay: "simple" | "normalized";
}

export const CategoriesReportingTable = (
  props: BuiltReportingCategoriesInterface & Base
) => {
  const matches = useMediaQuery("(min-width:760px)");

  const {
    reportingCategoriesExpense,
    reportingCategoriesIncome,
    budgetDisplay,
  } = props;

  const [collapsed, setCollapsed] = useState(true);

  const commons = {
    matches,
    collapsed,
    setCollapsed,
    budgetDisplay,
  };

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <Grid container sx={{ padding: "10px" }} xs={12} spacing={2}>
        <Grid item xs={12}>
          <CategoriesReportingCategoryTable
            {...commons}
            reportingCategoriesCategory={reportingCategoriesExpense}
            label="Expense categories"
          />
        </Grid>

        <Grid item xs={12}>
          <CategoriesReportingCategoryTable
            {...commons}
            reportingCategoriesCategory={reportingCategoriesIncome}
            label="Income categories"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
