import { redirectNotAuthenticated } from "../../api/auth/auth.util";
import withAuth from "../auth/AuthenticatedHOC";
import ScreenOverlay from "../screenOverlay/ScreenOverlay";
import TopNavigationBar from "../topNavigationBar/TopNavigationBar";
import "./AppContent.css";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

interface AppContentProps {
  authenticated?: boolean;
  openAboutDialog: boolean;
  setOpenAboutDialog: (newValue: boolean) => void;
}
function AppContent(props: AppContentProps) {
  const { authenticated, openAboutDialog, setOpenAboutDialog } = props;

  const [overlayVisible, setOverlayVisible] = useState(false);
  const [isHamburgerClosed, setHamburgerClosed] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      redirectNotAuthenticated(navigate);
    }
  }, []);

  const toggleScreenOverlay = () => {
    setOverlayVisible(!overlayVisible);
    setHamburgerClosed(!isHamburgerClosed);
  };

  const overlayItemSelected = () => {
    toggleScreenOverlay();
    setHamburgerClosed(true);
  };

  const inRootPath = () =>
    ["", "/", "/home", "/auth"].includes(location.pathname);

  const inAuth = () => ["/auth"].includes(location.pathname);

  const renderContent = () => (
    <Box>
      {overlayVisible ? (
        <ScreenOverlay onItemSelect={overlayItemSelected} />
      ) : (
        <Outlet />
      )}
    </Box>
  );

  return (
    <Box>
      <TopNavigationBar
        onHamburgerClick={toggleScreenOverlay}
        isHamburgerClosed={isHamburgerClosed}
        backArrowVisible={!inRootPath()}
        hamburgerHidden={inAuth()}
        goBackCallback={() => navigate("/")}
        openAboutDialog={openAboutDialog}
        setOpenAboutDialog={setOpenAboutDialog}
      />
      <Box>{renderContent()}</Box>
    </Box>
  );
}

export default withAuth(AppContent);
