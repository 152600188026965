import { InvestmentAPI } from "../../../api/investments.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectInvestments,
  selectCurrentPrices,
} from "../../../redux/reducers/investmentsSlice";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../../util/Formatter";
import { uniqueArray } from "../../../util/Functions";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { InvestmentPriceEntry } from "@backend/investment.type";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const NewInvestmentPriceDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  const budgetId = getBudgetId(useAppSelector);
  const [investmentName, setInvestmentName] = React.useState("");
  const [latestInvestmentPriceItem, setLatestInvestmentPriceItem] =
    React.useState<InvestmentPriceEntry | undefined>(undefined);
  const [price, setPrice] = React.useState(0);
  const [date, setDate] = React.useState(DateTime.now().toISO());

  const { data: investments } = useAppSelector(selectInvestments);
  const { data: investmentPrices } = useAppSelector(selectCurrentPrices);
  const investmentNames = uniqueArray(investments.map((e) => e.investmentName));

  const handleClose = () => {
    setOpen(false);
  };

  const handleInvestmentNameChange = (newName: string) => {
    setInvestmentName(newName);

    const latestPrice = investmentPrices
      .filter((e) => e.investmentName === newName)
      .reverse()[0];
    setLatestInvestmentPriceItem(latestPrice);
  };

  const handlePriceChange = (newPrice: string) => setPrice(Number(newPrice));

  const canSubmit = () => {
    return investmentName && date && price;
  };

  const reset = () => {
    setInvestmentName("");
    setPrice(0);
    setDate(DateTime.now().toISO());
  };

  const submit = () => {
    addInvestmentPrice();
    handleClose();
    reset();
  };

  const addInvestmentPrice = async () => {
    InvestmentAPI.addInvestmentPrice(
      budgetId,
      {
        investmentName,
        price,
        date,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const LatestInvestmentPriceItemView = () => {
    const item = latestInvestmentPriceItem;

    if (item) {
      return (
        <Box>
          <Typography>
            Last known price: {f(item.price)} @ {item.date.split("T")[0]}
          </Typography>
        </Box>
      );
    }

    return <Box></Box>;
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>Add investment price</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <Autocomplete
              fullWidth
              id="investment-name"
              disableClearable
              options={investmentNames}
              onChange={(e, value) => handleInvestmentNameChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enter investment name"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  fullWidth
                />
              )}
            />

            <LatestInvestmentPriceItemView />

            <BudgetarDatePicker onSelect={setDate} />

            <TextField
              placeholder="Price"
              variant="outlined"
              onChange={(e) => handlePriceChange(e.target.value)}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Add investment price
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
