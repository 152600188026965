import { AssetAPI } from "../../api/assets.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { loadAssets, getBudgetId, logout } from "../utils.api";
import { SingleAssetTableRow } from "./SingleAssetTableRow";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const AssetsTable = (props: { assets: any[] }) => {
  const { assets } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const budgetId = getBudgetId(useAppSelector);

  const sortedAssets = [...assets].sort((a, b) => a.amount - b.amount);

  const deleteAsset = (assetSK: string) => {
    AssetAPI.deleteAsset(
      budgetId,
      { assetSK },
      {
        onSuccess: () => {
          toast.success("Asset deleted.");
          loadAssets(budgetId, dispatch);
        },
        onFailed: (err) => toast.error(err),
      },
      () => logout(dispatch, navigate)
    );
  };

  const rows = sortedAssets.map((asset) => (
    <SingleAssetTableRow
      key={asset.SK}
      asset={asset}
      deleteAssetCall={deleteAsset}
    />
  ));

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <BudgetarToast />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="assets table">
          <TableHead>
            <TableRow hover={true}>
              <TableCell>
                <Typography textAlign="center">Asset name</Typography>
              </TableCell>
              <TableCell>
                <Typography textAlign="center">Purchased on</Typography>
              </TableCell>
              <TableCell>
                <Typography textAlign="right">Current value</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
