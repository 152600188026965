import { colorGenerator, borderColorGenerator } from "../../../util/Functions";
import { ReportingCategory } from "@backend/category.type";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

interface CategoriesReportingCategoryPieChartProps {
  label: string;
  reportingCategoryCategories: ReportingCategory[];
}
export const CategoriesReportingCategoryPieChart = (
  props: CategoriesReportingCategoryPieChartProps
) => {
  const { label, reportingCategoryCategories } = props;

  const labels: string[] = [];
  const totalIncomes: number[] = [];

  [...reportingCategoryCategories]
    .sort((a, b) => b.total - a.total)
    .slice(0, Math.min(10, reportingCategoryCategories.length))
    .forEach((cat) => {
      labels.push(cat.categoryName);
      totalIncomes.push(cat.total ?? 0);
    });

  return (
    <CategoriesReportingPieChart
      mainLabel={label}
      labels={labels}
      dataPoints={totalIncomes}
    />
  );
};

interface CategoriesReportingPieChartProps {
  mainLabel: string;
  labels: string[];
  dataPoints: number[];
}
const CategoriesReportingPieChart = (
  props: CategoriesReportingPieChartProps
) => {
  const { mainLabel, labels, dataPoints } = props;

  const datasets: any = [
    {
      label: mainLabel,
      data: dataPoints,
      backgroundColor: colorGenerator(dataPoints.length),
      borderColor: borderColorGenerator(dataPoints.length),
      borderWidth: 1,
    },
  ];

  const data = { labels, datasets };

  return <Pie data={data} />;
};
