import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { SimpleIncome } from "@backend/income.type";
import { createSlice } from "@reduxjs/toolkit";

interface SimpleIncomesState {
  incomes: SingleLoad<SimpleIncome[]>;
}

export const initialState: SimpleIncomesState = {
  incomes: singleLoadDefaultValue([]),
};

export const incomesSlice = createSlice({
  name: "incomes",
  initialState,
  reducers: {
    setSimpleIncomes: (
      state: SimpleIncomesState,
      action: { payload: { incomes: SimpleIncome[] } }
    ) => {
      const { incomes } = action.payload;

      state.incomes = getSingleLoadResponse(incomes);
    },
    setIncomesLoading: (
      state: SimpleIncomesState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.incomes.loading = status;
    },
    logout: (state: SimpleIncomesState) => {
      return initialState;
    },
  },
});

export const { setSimpleIncomes, setIncomesLoading, logout } =
  incomesSlice.actions;

export const incomesState = (state: RootState) => state.incomes;

export const selectSimpleIncomes = (
  state: RootState
): SingleLoad<SimpleIncome[]> => incomesState(state).incomes;
export const selectIncomesLoading = (state: RootState) =>
  incomesState(state).incomes.loading;

export const SimpleIncomesReducer = incomesSlice.reducer;
