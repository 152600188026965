import { fetchGoals, fetchAdminBudget } from "../api/fetch.redux";
import { GoalsTable } from "../components/goals/GoalsTable";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewGoalDialog } from "../components/utility/dialogs/NewGoalDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { getBudgetId } from "../components/utils.api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAdminBudgetId } from "../redux/reducers/budgetSlice";
import { selectGoals } from "../redux/reducers/goalsSlice";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface GoalsPageProps {
  openHowToDialog: () => void;
}
export const Goals = (props: GoalsPageProps) => {
  const { openHowToDialog } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");
  const [openNewGoalDialog, setOpenNewGoalDialog] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = getBudgetId(useAppSelector);
  const {
    data: goals,
    loaded: areGoalsLoaded,
    loading: areGoalsLoading,
  } = useSelector(selectGoals);

  const newGoalsCreated = () => {
    fetchGoals(budgetId, dispatch, false);
    toast.success("New goal created.");
  };

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchGoals(budgetId, dispatch, areGoalsLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  return (
    <Box>
      <LoadingDialog key={uuid()} open={areGoalsLoading} toDisplay="goals" />
      <BudgetarToast />
      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <SecondaryButton size="small" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            Back
          </SecondaryButton>
        </Box>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewGoalDialog(true)}
        >
          <AddIcon />
          New goal
        </Button>

        <Box sx={{ paddingLeft: "10px" }} />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}
      </Box>

      <NewGoalDialog
        open={openNewGoalDialog}
        setOpen={setOpenNewGoalDialog}
        onSuccess={newGoalsCreated}
        onFailed={() => toast.error("Error while creating goal.")}
      />
      {goals ? <GoalsTable goals={goals} /> : <Box>No goals</Box>}
    </Box>
  );
};
