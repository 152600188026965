import { useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../util/Formatter";
import { EventWithTotal } from "@backend/event.type";
import { TableCell, TableRow, Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";

export const SingleEventsTableRow = (props: {
  event: EventWithTotal;
  deleteEventCall: (eventSK: string) => void;
}) => {
  const { event } = props;
  const { eventName, total } = event;

  const currency = useAppSelector(selectCurrency);

  const format = (n: number) => formatNumberDispatch(n, currency);

  return (
    <TableRow key={eventName}>
      <TableCell align="center">
        <Box>
          <Typography>{eventName}</Typography>
        </Box>
      </TableCell>

      <TableCell align="center">
        <Box>
          <Typography>{format(total)}</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};
