import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { Currency } from "@backend/currency.type";
import { UserProfile } from "@backend/user.type";
import { createSlice } from "@reduxjs/toolkit";

export interface CurrentUser {
  username: string;
  email: string;
  token?: string;
}

export interface UserState {
  currentUser: CurrentUser | null;
  userProfile: SingleLoad<UserProfile>;
}

export const initialState: UserState = {
  currentUser: null,
  userProfile: singleLoadDefaultValue({
    email: "",
    isActivated: true,
    preferredCurrency: Currency.EUR,
  }),
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (
      state: UserState,
      action: { payload: { email: string; username: string; token: string } }
    ) => {
      const { email, username, token } = action.payload;

      state.currentUser = { email, username, token };
    },
    setUserProfile: (
      state: UserState,
      action: { payload: { userProfile: UserProfile } }
    ) => {
      state.userProfile = getSingleLoadResponse(action.payload.userProfile);
    },
    setUserProfileLoading: (
      state: UserState,
      action: { payload: { loading: boolean } }
    ) => {
      state.userProfile.loading = action.payload.loading;
    },
    setPreferredCurrency: (
      state: UserState,
      action: { payload: { newCurrency: Currency } }
    ) => {
      state.userProfile.data.preferredCurrency = action.payload.newCurrency;
    },

    logout: (state: UserState) => {
      return initialState;
    },
  },
});

export const {
  setPreferredCurrency,
  setCurrentUser,
  setUserProfile,
  setUserProfileLoading,
  logout,
} = userSlice.actions;

export const userState = (state: RootState) => state.user;

export const selectCurrentUser = (state: RootState): CurrentUser | null =>
  userState(state).currentUser;
export const selectUserProfile = (state: RootState): SingleLoad<UserProfile> =>
  userState(state).userProfile;
export const selectCurrency = (state: RootState): Currency =>
  userState(state).userProfile.data.preferredCurrency;

export const selectCurrentToken = (state: RootState) =>
  selectCurrentUser(state)?.token;

export const UserReducer = userSlice.reducer;
