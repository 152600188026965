interface Threshold {
  percentageLimit: number;
  color: string;
}

export const ProgressBar = (props: {
  completedPercentage: number;
  label: string;
  bottomThreshold?: Threshold;
  upperThreshold?: Threshold;
}) => {
  const { completedPercentage, label, upperThreshold, bottomThreshold } = props;

  const defaultfillColor = "#7db9cc";
  const fillColor =
    bottomThreshold && completedPercentage < bottomThreshold.percentageLimit
      ? bottomThreshold.color
      : upperThreshold && completedPercentage > upperThreshold.percentageLimit
      ? upperThreshold.color
      : defaultfillColor;

  const percentage = Math.min(
    100,
    completedPercentage < 0 ? 100 : completedPercentage
  );

  const containerStyles = {
    height: 20,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    marginBottom: 10,
  };

  const fillerStyles = {
    height: 20,
    width: `${percentage}%`,
    backgroundColor: fillColor,
    borderRadius: 50,
    transition: "width 1s ease-in-out",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${label}`}</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
