import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV023 = () => {
  return (
    <Release version="v0.2.3" releaseDate="2023-03-28">
      <ReleaseNotesSection title="Technical">
        <ReleaseNotesEntry>Add more reports caching</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Developer QoL">
        <ReleaseNotesEntry>Improve data generator script</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
