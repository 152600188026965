import { InvestmentAPI } from "../../../api/investments.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccounts } from "../../../redux/reducers/accountsSlice";
import { selectCategories } from "../../../redux/reducers/categoriesSlice";
import { selectInvestments } from "../../../redux/reducers/investmentsSlice";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { uniqueArray } from "../../../util/Functions";
import { BudgetarPopover } from "../../common/BudgetarPopover";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { AccountsDropdown } from "../dropdowns/AccountsDropdown";
import { CategoriesDropdown } from "../dropdowns/CategoriesDropdown";
import { CurrencyDropdown } from "../dropdowns/CurrencyDropdown";
import { AccountType } from "@backend/account.type";
import { CategoryType } from "@backend/category.type";
import { Currency } from "@backend/currency.type";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const NewInvestmentDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const budgetId = getBudgetId(useAppSelector);
  const preferredCurrency = useAppSelector(selectCurrency);
  const { data: investments } = useAppSelector(selectInvestments);
  const expenseCategories = useAppSelector(selectCategories).data.filter(
    (e) => e.categoryType.toLowerCase() === CategoryType.EXPENSE.toLowerCase()
  );
  const { data: accounts } = useAppSelector(selectAccounts);
  const investmentAccounts = accounts.filter(
    (e) => e.accountType === AccountType.INVESTMENTS
  );
  const nonInvestmentAccounts = accounts.filter(
    (e) => e.accountType !== AccountType.INVESTMENTS
  );

  const investmentAccountIdx = investmentAccounts.findIndex((e) =>
    e.accountName.toLowerCase().includes("invest")
  );

  const investmentExpenseCategoryIdx = expenseCategories.findIndex((e) =>
    e.categoryName.toLowerCase().includes("invest")
  );

  const [investmentName, setInvestmentName] = React.useState("");
  const [purchaseDate, setPurchaseDate] = React.useState(
    DateTime.now().toISODate()
  );
  const [amountPurchased, setAmountPurchased] = React.useState(0);
  const [purchasePrice, setPurchasePrice] = React.useState(0);
  const [entryFee, setEntryFee] = React.useState(0);
  const [exitFee, setExitFee] = React.useState(0);
  const [investmentAccountId, setInvestmentAccountId] = React.useState(
    investmentAccountIdx > -1 ? investmentAccounts[investmentAccountIdx].SK : ""
  );
  const [userExpenseCategoryId, setUserExpenseCategoryId] = React.useState(
    investmentExpenseCategoryIdx > -1
      ? expenseCategories[investmentExpenseCategoryIdx].SK
      : expenseCategories.length
      ? expenseCategories[0].SK
      : ""
  );
  const [expenseAccountId, setExpenseAccountId] = React.useState(
    nonInvestmentAccounts.length ? nonInvestmentAccounts[0].SK : ""
  );
  const [investmentCurrency, setInvestmentCurrency] =
    React.useState(preferredCurrency);

  const investmentNames = uniqueArray(investments.map((e) => e.investmentName));

  const handleClose = () => {
    setOpen(false);
  };

  const handleInvestmentNameChange = (newName: string) =>
    setInvestmentName(newName);

  const handleAmountChange = (newAmount: string) =>
    setAmountPurchased(Number(newAmount));

  const canSubmit = () => {
    return (
      investmentName &&
      purchaseDate &&
      amountPurchased &&
      purchasePrice &&
      investmentAccountId &&
      userExpenseCategoryId &&
      expenseAccountId
    );
  };

  const reset = () => {
    setInvestmentName("");
    setPurchaseDate(DateTime.now().toISODate());
    setAmountPurchased(0);
    setPurchasePrice(0);
    setEntryFee(0);
    setExitFee(0);
    setInvestmentAccountId(
      investmentAccountIdx > -1
        ? investmentAccounts[investmentAccountIdx].SK
        : ""
    );
    setUserExpenseCategoryId(
      investmentExpenseCategoryIdx > -1
        ? expenseCategories[investmentExpenseCategoryIdx].SK
        : expenseCategories.length
        ? expenseCategories[0].SK
        : ""
    );
    setExpenseAccountId(
      nonInvestmentAccounts.length ? nonInvestmentAccounts[0].SK : ""
    );
    setInvestmentCurrency(preferredCurrency);
  };

  const submit = () => {
    createInvestment();
    handleClose();
    reset();
  };

  const createInvestment = async () => {
    InvestmentAPI.addInvestment(
      budgetId,
      {
        budgetId,
        investmentName,
        purchaseDate,
        amountPurchased,
        purchasePrice,
        entryFee,
        exitFee,
        investmentAccountId,
        userExpenseCategoryId,
        expenseAccountId,
        currency: investmentCurrency,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New investment</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <Autocomplete
              fullWidth
              freeSolo
              id="investment-name"
              disableClearable
              options={investmentNames}
              onChange={(e, value) => handleInvestmentNameChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enter investment name"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  fullWidth
                />
              )}
            />

            <BudgetarDatePicker onSelect={setPurchaseDate} />

            <Grid container xs={12} spacing={2} sx={{ paddingTop: "20px" }}>
              <Grid item xs={12} md={6} sx={{ paddingTop: "20px" }}>
                <TextField
                  label="Quantity"
                  variant="outlined"
                  onChange={(e) => handleAmountChange(e.target.value)}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6} sx={{ paddingTop: "20px" }}>
                <TextField
                  label="Purchase price"
                  variant="outlined"
                  onChange={(e) => setPurchasePrice(Number(e.target.value))}
                  type="number"
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={2} sx={{ paddingTop: "20px" }}>
              <Grid item xs={12} md={6} sx={{ paddingTop: "20px" }}>
                <TextField
                  label="Entry fee (%)"
                  variant="outlined"
                  onChange={(e) => setEntryFee(Number(e.target.value))}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ paddingTop: "20px" }}>
                <TextField
                  label="Exit fee (%)"
                  variant="outlined"
                  onChange={(e) => setExitFee(Number(e.target.value))}
                  type="number"
                />
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ paddingTop: "10px" }}>
              <Grid item xs={12}>
                <CurrencyDropdown
                  label="Investment currency"
                  onCurrencySelected={setInvestmentCurrency}
                  preferredCurrency={investmentCurrency}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ paddingTop: "30px" }}>
              <Grid item xs={11}>
                <AccountsDropdown
                  accountSelected={setInvestmentAccountId}
                  accountTypeFilter={AccountType.INVESTMENTS}
                  label="Investment account"
                  initialOptionIndex={investmentAccountIdx}
                />
              </Grid>
              <Grid item xs={1} sx={{ padding: "10px" }}>
                <BudgetarPopover
                  IconOpen={<HelpOutlineIcon />}
                  IconClosed={<HelpOutlineIcon />}
                  label=""
                >
                  <Box>
                    <Typography sx={{ padding: "10px" }}>
                      Investment account with which to register this invesment.
                    </Typography>
                  </Box>
                </BudgetarPopover>
              </Grid>
            </Grid>

            <Grid container sx={{ paddingTop: "10px" }}>
              <Grid item xs={11}>
                <AccountsDropdown
                  accountSelected={setExpenseAccountId}
                  label="Expense account"
                  options={nonInvestmentAccounts}
                />
              </Grid>
              <Grid item xs={1} sx={{ padding: "10px" }}>
                <BudgetarPopover
                  IconOpen={<HelpOutlineIcon />}
                  IconClosed={<HelpOutlineIcon />}
                  label=""
                >
                  <Box>
                    <Typography sx={{ padding: "10px" }}>
                      Expense account with which to register the expense that
                      represents the purchase of this investment.
                    </Typography>
                  </Box>
                </BudgetarPopover>
              </Grid>
            </Grid>

            <Grid container sx={{ paddingTop: "10px" }}>
              <Grid item xs={11}>
                <CategoriesDropdown
                  categorySelected={setUserExpenseCategoryId}
                  categoryTypeFilter="expense"
                  label="Expense category"
                  initialOptionIndex={investmentExpenseCategoryIdx}
                />
              </Grid>
              <Grid item xs={1} sx={{ padding: "10px" }}>
                <BudgetarPopover
                  IconOpen={<HelpOutlineIcon />}
                  IconClosed={<HelpOutlineIcon />}
                  label=""
                >
                  <Box>
                    <Typography sx={{ padding: "10px" }}>
                      Expense category under which to put the expense that
                      represents the purchase of this investment.
                    </Typography>
                  </Box>
                </BudgetarPopover>
              </Grid>
            </Grid>

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Create investment
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
