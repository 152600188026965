import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccounts } from "../../../redux/reducers/accountsSlice";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { Callbacks } from "../../../types/util";
import { formatNumberDispatch } from "../../../util/Formatter";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { AccountsDropdown } from "../dropdowns/AccountsDropdown";
import { AddMortgagePaymentData, MortgageDB } from "@backend/mortgage.type";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const NewMortgagePaymentDialog = (props: {
  mortgage: MortgageDB;
  open: boolean;
  action: (
    budgetId: string,
    args: AddMortgagePaymentData,
    { onSuccess, onFailed }: Callbacks,
    logoutCallback: () => void
  ) => void;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { mortgage, action, open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currency = useAppSelector(selectCurrency);
  const { data: accounts } = useAppSelector(selectAccounts);
  const f = (n: number) => formatNumberDispatch(n, currency);

  const budgetId = getBudgetId(useAppSelector);
  const paymentAmount = mortgage.monthlyPayment;

  const [date, setDate] = useState(DateTime.now().toISODate());

  const [expenseAccountId, setExpenseAccountId] = React.useState(
    accounts.length ? accounts[0].SK : ""
  );

  const handleClose = () => {
    setOpen(false);
  };

  const canSubmit = () => {
    return date && paymentAmount && expenseAccountId;
  };

  const reset = () => {
    setDate(DateTime.now().toISODate());
  };

  const submit = () => {
    action(
      budgetId,
      {
        mortgageId: mortgage.id,
        amount: paymentAmount,
        paymentDate: date,
        expenseAccountId,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
    handleClose();
    reset();
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>Add mortgage payment</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <Typography color="darkgray">
              Total borrowed: {f(mortgage.totalOutstanding)}
            </Typography>

            <BudgetarDatePicker
              onSelect={(date: Date) =>
                setDate(date.toISOString().split("T")[0])
              }
            />

            <AccountsDropdown accountSelected={setExpenseAccountId} />

            <TextField
              label="Payment amount"
              variant="outlined"
              value={paymentAmount.toFixed(2)}
              // disabled={mortgage.interestRateType === InterestRateType.FIXED}
              // onChange={(e) => handleAmountChange(e.target.value)}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Add payment
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
