import { Typography } from "@mui/material";

interface ActionButtonProps {
  fontSize?: number;
  children: string;
  textAlign?: any;
  onClick?: any;
}

export const SecondaryTypography = (props: ActionButtonProps) => {
  const { fontSize, children, textAlign, onClick } = props;

  return (
    <Typography
      fontSize={fontSize || 14}
      color={"#7bb2cb"}
      textAlign={textAlign || "center"}
      onClick={onClick ? onClick : () => {}}
    >
      {children}
    </Typography>
  );
};
