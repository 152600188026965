import { AnySet } from "immer/dist/internal";
import { createContext, useContext, useState } from "react";

const FocusContext = createContext({
  focused: false,
  onFocus: () => {},
  onBlur: () => {},
});

export const FocusProvider = (props: any) => {
  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const sampleContext = { focused, onFocus, onBlur };

  return (
    <FocusContext.Provider value={sampleContext}>
      {props.children}
    </FocusContext.Provider>
  );
};

const withFocus = (WrappedComponent: any) => (props: any) => {
  const ctx = useContext(FocusContext);

  return (
    <WrappedComponent
      {...props}
      focused={ctx.focused}
      onFocus={ctx.onFocus}
      onBlur={ctx.onBlur}
    />
  );
};

export default withFocus;
