import { RootState } from "../store";
import {
  getSingleLoadResponse,
  SingleLoad,
  singleLoadDefaultValue,
} from "../util";
import {
  SubscriptionItemBase,
  SubscriptionTier,
  SubscriptionDuration,
  SubscriptionStatus,
} from "@backend/subscription.type";
import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

interface SubscriptionState {
  currentSubscription: SingleLoad<SubscriptionItemBase>;
}

export const initialState: SubscriptionState = {
  currentSubscription: singleLoadDefaultValue({
    budgetId: "placeholder",
    subscriptionId: "placeholder",
    subscriptionTier: SubscriptionTier.FREE,
    startDate: 0,
    endDate: DateTime.now().plus({ year: 1 }).toMillis(),
    subscriptionDuration: SubscriptionDuration.YEAR,
    activated: true,
    subscriptionStatus: SubscriptionStatus.ACTIVE,
    statusUpdatedAt: 0,
  }),
};

export const settingsSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setCurrentSubscription: (
      state: SubscriptionState,
      action: { payload: { subscriptionItem: SubscriptionItemBase } }
    ) => {
      state.currentSubscription = getSingleLoadResponse(
        action.payload.subscriptionItem
      );
    },
    setCurrentSubscriptionLoading: (
      state: SubscriptionState,
      action: { payload: { loading: boolean } }
    ) => {
      state.currentSubscription.loading = action.payload.loading;
    },
    logout: (state: SubscriptionState) => {
      return initialState;
    },
  },
});

export const { setCurrentSubscription, setCurrentSubscriptionLoading, logout } =
  settingsSlice.actions;

export const subscriptionState = (state: RootState) => state.subscription;

export const selectCurrentSubscription = (state: RootState) =>
  subscriptionState(state).currentSubscription;

export const SubscriptionReducer = settingsSlice.reducer;
