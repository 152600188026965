import { shiftArrayElements } from "../../util/Functions";
import { CustomChartOptions, CenterTextPlugin } from "../charts/chart-plugins";
import { LoadingDialog } from "../utility/dialogs/LoadingDialog";
import { ExpensesPerPeriodChartProps } from "./ExpensesPerDayChart";
import { capitalize, useMediaQuery } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { DateTime } from "luxon";
import { Chart } from "react-chartjs-2";
import { v4 as uuid } from "uuid";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface ItemsPerMonthCartProps extends ExpensesPerPeriodChartProps {
  items: { sum: number; date: string }[];
  monthPerMonthItems?: Record<string, { averageExpensesPerMonth: number }>;
  itemsLoading: boolean;
  label: string;
  chartColor: string;
}

export function ItemsPerMonthChart(props: ItemsPerMonthCartProps) {
  const matches = useMediaQuery("(min-width:300px)");

  const {
    items,
    monthPerMonthItems,
    itemsLoading,
    label,
    chartColor,
    displayMode,
  } = props;

  const averageMonthlyItems =
    items.reduce((acc, e) => acc + e.sum, 0) / items.length;

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `${capitalize(label)} per month`,
      },
    },
  };

  const labels = items.map(({ date }) =>
    DateTime.fromISO(`${date}-01`).toFormat("MMM yyyy")
  );

  const datasets: any[] = [
    {
      label: capitalize(label),
      data: items.map((e) => e.sum),
      backgroundColor: chartColor,
      type: "bar" as const,
      order: 2,
    },
  ];

  if (displayMode === "global-average") {
    datasets.push({
      label: "Monthly average",
      data: items.map((e) => averageMonthlyItems),
      borderColor: "white",
      type: "line" as const,
      order: 1,
    });
  }

  if (monthPerMonthItems && displayMode === "per-period") {
    datasets.push({
      label: "Average per month",
      data: shiftArrayElements(
        Object.values(monthPerMonthItems).map((e) => e.averageExpensesPerMonth),
        12 - DateTime.now().month
      ),
      borderColor: "white",
      type: "line" as const,
      order: 0,
    });
  }

  const data = {
    labels,
    datasets,
  };

  const childProps = {
    ...(!matches && { height: 400 }),
  };

  if (itemsLoading || itemsLoading)
    return <LoadingDialog key={uuid()} open={true} toDisplay="data" />;

  return (
    <Chart
      {...childProps}
      type="bar"
      options={{ ...options, ...CustomChartOptions } as any}
      data={data}
      plugins={[CenterTextPlugin as any]}
    />
  );
}
