import {
  setGrantedAccesses,
  setGrantedAccessesLoading,
  setReceivedBudgetAccesses,
  setReceivedBudgetIdAccessesLoading,
} from "../redux/reducers/accessRoleSlice";
import {
  setAccounts,
  setAccountsLoading,
} from "../redux/reducers/accountsSlice";
import { setAssets, setAssetsLoading } from "../redux/reducers/assetsSlice";
import {
  setAdminBudgetId,
  setAdminBudgetIdLoading,
} from "../redux/reducers/budgetSlice";
import {
  setCategories,
  setCategoriesLoading,
} from "../redux/reducers/categoriesSlice";
import { setCredits, setCreditsLoading } from "../redux/reducers/creditsSlice";
import { setEvents, setEventsLoading } from "../redux/reducers/eventsSlice";
import {
  setExpensesLoading,
  setSimpleExpenses,
} from "../redux/reducers/expensesSlice";
import { setGoals, setGoalsLoading } from "../redux/reducers/goalsSlice";
import {
  setIncomesLoading,
  setSimpleIncomes,
} from "../redux/reducers/incomesSlice";
import {
  setInvestments,
  setInvestmentsLoading,
  setInvestmentsPrices,
  setInvestmentsPricesLoading,
  setSoldInvestments,
  setSoldInvestmentsLoading,
} from "../redux/reducers/investmentsSlice";
import { setLoans, setLoansLoading } from "../redux/reducers/loansSlice";
import {
  setMortgages,
  setMortgagesLoading,
} from "../redux/reducers/mortgagesSlice";
import {
  setCurrentSubscription,
  setCurrentSubscriptionLoading,
} from "../redux/reducers/subscriptionSlice";
import {
  setUserProfile,
  setUserProfileLoading,
} from "../redux/reducers/userSlice";
import { AppDispatch } from "../redux/store";
import { Callbacks } from "../types/util";
import { AccessRoleAPI } from "./access-roles/access-roles.api";
import { AccountAPI } from "./accounts/accounts.api";
import { AssetAPI } from "./assets.api";
import { CategoryAPI } from "./categories/categories.api";
import { CreditAPI } from "./credit.api";
import { EventAPI } from "./events.api";
import { ExpenseAPI } from "./expenses/expenses.api";
import { GoalAPI } from "./goals.api";
import { IncomeAPI } from "./incomes/incomes.api";
import { InvestmentAPI } from "./investments.api";
import { LoanAPI } from "./loans.api";
import { MortgageAPI } from "./mortgages.api";
import { SubscriptionAPI } from "./subscription.api";
import { getAdminUser, getUserProfile } from "./users";
import { AnyAction } from "@reduxjs/toolkit";

function budgetFetchCall(
  budgetId: string,
  dispatch: AppDispatch,
  call: (budgetId: string, cbs: Callbacks) => void,
  resultKey: string,
  setResultsReduxCall: (data: any) => AnyAction
) {
  return call(budgetId, {
    onSuccess: (result: any) => {
      dispatch(setResultsReduxCall({ [resultKey]: result }));
    },
  });
}

function nonBudgetFetchCall(
  dispatch: AppDispatch,
  call: (cbs: Callbacks) => void,
  resultKey: string,
  setResultsReduxCall: (data: any) => AnyAction
) {
  return call({
    onSuccess: (result: any) => {
      dispatch(setResultsReduxCall({ [resultKey]: result }));
    },
  });
}

export function fetch(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean,
  call:
    | ((budgetId: string, cbs: Callbacks, logoutCallback: () => void) => void)
    | ((cbs: Callbacks) => void),
  resultKey: string,
  setResultsReduxCall: (data: any) => AnyAction,
  setLoadingAction: any
) {
  const callToMake = () =>
    budgetId && budgetId.length > 0
      ? budgetFetchCall(
          budgetId,
          dispatch,
          call as (budgetId: string, cbs: Callbacks) => void,
          resultKey,
          setResultsReduxCall
        )
      : nonBudgetFetchCall(
          dispatch,
          call as (cbs: Callbacks) => void,
          resultKey,
          setResultsReduxCall
        );

  if (!loaded) {
    dispatch(setLoadingAction({ status: true }));

    callToMake();
  }
}

export function fetchAdminBudget(dispatch: AppDispatch, loaded: boolean) {
  return fetch(
    "",
    dispatch,
    loaded,
    getAdminUser as any,
    "budget",
    setAdminBudgetId,
    setAdminBudgetIdLoading
  );
}

export function fetchCurrentSubscription(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    SubscriptionAPI.getCurrentSubscription,
    "subscriptionItem",
    setCurrentSubscription,
    setCurrentSubscriptionLoading
  );
}

export function fetchAccessibleBudgets(dispatch: AppDispatch, loaded: boolean) {
  return fetch(
    "",
    dispatch,
    loaded,
    AccessRoleAPI.getAccessibleBudgets as any,
    "budgetAccesses",
    setReceivedBudgetAccesses,
    setReceivedBudgetIdAccessesLoading
  );
}

export function fetchGrantedAccesses(dispatch: AppDispatch, loaded: boolean) {
  return fetch(
    "",
    dispatch,
    loaded,
    AccessRoleAPI.getGrantedAccessRoles as any,
    "grantedAccesses",
    setGrantedAccesses,
    setGrantedAccessesLoading
  );
}

export function fetchUserProfile(dispatch: AppDispatch, loaded: boolean) {
  return fetch(
    "",
    dispatch,
    loaded,
    getUserProfile as any,
    "userProfile",
    setUserProfile,
    setUserProfileLoading
  );
}

export function fetchAccounts(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    AccountAPI.getAccounts,
    "accounts",
    setAccounts,
    setAccountsLoading
  );
}

export function fetchCategories(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    CategoryAPI.getCategories,
    "categories",
    setCategories,
    setCategoriesLoading
  );
}

export function fetchExpenses(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    ExpenseAPI.getExpenses,
    "expenses",
    setSimpleExpenses,
    setExpensesLoading
  );
}

export function fetchIncomes(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    IncomeAPI.getIncomes,
    "incomes",
    setSimpleIncomes,
    setIncomesLoading
  );
}

export function fetchInvestments(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    InvestmentAPI.getInvestments,
    "investments",
    setInvestments,
    setInvestmentsLoading
  );
}

export function fetchSoldInvestments(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    InvestmentAPI.getSoldInvestments,
    "soldInvestments",
    setSoldInvestments,
    setSoldInvestmentsLoading
  );
}

export function fetchInvestmentPrices(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    InvestmentAPI.getInvestmentsPrices,
    "investmentsPrices",
    setInvestmentsPrices,
    setInvestmentsPricesLoading
  );
}

export function fetchGoals(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    GoalAPI.getGoals,
    "goals",
    setGoals,
    setGoalsLoading
  );
}

export function fetchAssets(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    AssetAPI.getAssets,
    "assets",
    setAssets,
    setAssetsLoading
  );
}

export function fetchLoans(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    LoanAPI.getLoans,
    "loans",
    setLoans,
    setLoansLoading
  );
}

export function fetchCredits(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    CreditAPI.getCredits,
    "credits",
    setCredits,
    setCreditsLoading
  );
}

export function fetchEvents(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    EventAPI.getEvents,
    "events",
    setEvents,
    setEventsLoading
  );
}

export function fetchMortgages(
  budgetId: string,
  dispatch: AppDispatch,
  loaded: boolean
) {
  return fetch(
    budgetId,
    dispatch,
    loaded,
    MortgageAPI.getMortgages,
    "mortgages",
    setMortgages,
    setMortgagesLoading
  );
}
