import { useAppSelector } from "../../../redux/hooks";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../../util/Formatter";
import { padNumber, optionalTruncate } from "../../../util/Functions";
import { ReportComponentSelector } from "./ReportComponentSelector";
import { YearlyReport } from "@backend/reporting.type";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMediaQuery, Card } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useState } from "react";

export enum ValueType {
  PERCENT,
}

export const AccentRow = (data: {
  title: string;
  secondColumn?: string;
  columnsCount?: number;
}) => {
  const { columnsCount = 0 } = data;

  const columns = [];
  for (var i = 0; i < columnsCount; i++) {
    columns.push(<TableCell />);
  }

  return (
    <TableRow>
      <TableCell width={"400px"}>
        <Typography fontSize={20} textAlign={"left"}>
          {data.title}
        </Typography>
      </TableCell>

      {data.secondColumn ? (
        <TableCell>
          <Typography textAlign={"center"}>{data.secondColumn}</Typography>
        </TableCell>
      ) : null}

      {columns}
    </TableRow>
  );
};

export const SimpleRow = (data: {
  f: (str: any) => string;
  columns: {
    text: string | number;
    isValue?: boolean;
    valueType?: ValueType;
    align?: "left" | "center" | "right";
    explicitPlus?: boolean;
  }[];
}) => (
  <TableRow>
    {data.columns.map((e) => {
      const {
        text,
        valueType,
        isValue = false,
        align = "center",
        explicitPlus = false,
      } = e;

      const textToRender = isValue
        ? valueType === ValueType.PERCENT
          ? `${explicitPlus && (text as number) > 0 ? "+" : ""}${text}%`
          : `${explicitPlus && (text as number) > 0 ? "+" : ""}${data.f(
              text as number
            )}`
        : text;

      return (
        <TableCell>
          <Typography textAlign={align}>{textToRender}</Typography>
        </TableCell>
      );
    })}
  </TableRow>
);

export const EmptyRow = (props: { columnsCount?: number }) => {
  const { columnsCount = 1 } = props;

  const columns = [];
  for (var i = 0; i < columnsCount; i++) {
    columns.push(<TableCell />);
  }

  return <TableRow>{columns}</TableRow>;
};

export const YearlyReportTable = (props: { report: YearlyReport }) => {
  const matches = useMediaQuery("(min-width:700px)");
  const matchesMini = useMediaQuery("(min-width:350)");

  const { report } = props;

  const allComponents = [
    "Totals",
    "Most expensive items",
    "Most expensive categories",
    "Top months",
    "Yearly comparison",
    "Investments",
    "Assets",
    "Goals",
    "Net worth",
  ];

  const [visibleComponents, setVisibleComponents] = useState(allComponents);

  const HideableComponent = (props: { componentKey: string; Component: any }) =>
    visibleComponents.includes(props.componentKey)
      ? { ...props.Component }
      : null;

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  const {
    year,
    highestExpenseMonth,
    highestIncomeMonth,
    mostExpensiveCategories,
    topFiveMostExpensivePurchases,
    //
    totalExpense,
    totalIncome,
    totalSavings,
    //
    totalExpensePreviousYear,
    totalIncomePreviousYear,
    totalExpenseVsPreviousYear,
    totalIncomeVsPreviousYear,

    newInvestments: { capitalInvested, valueAtEndOfYear, capitalVsValueDelta },

    assetsData,
    goalsData,
    netWorthData: {
      netWorthEndOfLastYear,
      netWorthEndOfThisYear,
      netWorthLastVsThisYear,
    },
  } = report;

  const monthNumberToLabel = (month: number): string =>
    DateTime.fromISO(`${year}-${padNumber(month)}-01T00:00:00.000`).monthLong;

  const renderMostExpensivePurchases = () =>
    topFiveMostExpensivePurchases.map((e, idx) => (
      <SimpleRow
        f={f}
        columns={[
          {
            text: `#${idx + 1} - ${
              matchesMini ? e.label : optionalTruncate(e.label, 8)
            }`,
            align: "left",
          },
          {
            text: DateTime.fromMillis(e.date).toISODate().slice(5),
            align: "center",
          },
          {
            text: e.amount,
            isValue: true,
          },
        ]}
      />
    ));

  const renderMostExpensiveCategories = () =>
    mostExpensiveCategories.map((e, idx) => (
      <SimpleRow
        f={f}
        columns={[
          { text: `#${idx + 1} - ${e.categoryName}`, align: "left" },
          { text: `${e.total} `, isValue: true, align: "right" },
        ]}
      />
    ));

  const LeftTable = () =>
    matches ? (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell width={"400px"} />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <AccentRow title="Totals" columnsCount={1} />
              <SimpleRow
                f={f}
                columns={[
                  { text: `Income ` },
                  { text: totalIncome, isValue: true, align: "right" },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  { text: `Expense ` },
                  { text: totalExpense, isValue: true, align: "right" },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  { text: `Savings ` },
                  {
                    text: totalSavings,
                    isValue: true,
                    explicitPlus: true,
                    align: "right",
                  },
                ]}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Box sx={{ paddingBottom: "10px", margin: "5px" }}>
        <Card>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={18} lineHeight={2.5}>
                Totals
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={4}>Incomes</Grid>
            <Grid xs={4}>Expenses</Grid>
            <Grid xs={4}>Savings</Grid>
            <Grid xs={4}>{f(totalIncome)}</Grid>
            <Grid xs={4}>{f(totalExpense)}</Grid>
            <Grid xs={4}>{f(totalSavings)}</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Card>
      </Box>
    );

  const LeftMidTable = () =>
    matches ? (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell width={"400px"} />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <AccentRow title="Top months" columnsCount={1} />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Income: ${monthNumberToLabel(
                      highestIncomeMonth.month
                    )}`,
                  },
                  {
                    text: highestIncomeMonth.amount,
                    isValue: true,
                    align: "right",
                  },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Expense: ${monthNumberToLabel(
                      highestExpenseMonth.month
                    )}`,
                  },
                  {
                    text: highestExpenseMonth.amount,
                    isValue: true,
                    align: "right",
                  },
                ]}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Box sx={{ paddingBottom: "10px", margin: "5px" }}>
        <Card>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={18} lineHeight={2.5}>
                Top months
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={6}>Income</Grid>
            <Grid xs={6}>Expense</Grid>
            <Grid xs={3}>{monthNumberToLabel(highestIncomeMonth.month)}</Grid>
            <Grid xs={3}>{f(highestIncomeMonth.amount)}</Grid>
            <Grid xs={3}>{monthNumberToLabel(highestExpenseMonth.month)}</Grid>
            <Grid xs={3}>{f(highestExpenseMonth.amount)}</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Card>
      </Box>
    );

  const MiddleTable = () => (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="goals table">
          <TableHead>
            <TableRow hover={true}>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            <AccentRow title="Most expensive purchases" columnsCount={2} />
            {renderMostExpensivePurchases()}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const RightTable = () => (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="goals table">
          <TableHead>
            <TableRow hover={true}>
              <TableCell width={"400px"} />
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            <AccentRow title="Most expensive categories" columnsCount={1} />
            {renderMostExpensiveCategories()}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const BottomLeftTable = () =>
    matches ? (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <AccentRow
                title={`Comparison ${year - 1} vs. ${year}`}
                columnsCount={3}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: "",
                  },
                  {
                    text: `${year - 1}`,
                  },
                  {
                    text: `${year}`,
                  },
                  {
                    text: "change",
                  },
                ]}
              />

              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Income`,
                  },
                  {
                    text: totalIncomePreviousYear,
                    isValue: true,
                  },
                  {
                    text: totalIncome,
                    isValue: true,
                  },
                  {
                    text: totalIncomeVsPreviousYear.toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                  },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Expense`,
                  },
                  {
                    text: totalExpensePreviousYear,
                    isValue: true,
                  },
                  {
                    text: totalExpense,
                    isValue: true,
                  },
                  {
                    text: totalExpenseVsPreviousYear.toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                  },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Net worth`,
                  },
                  {
                    text: netWorthEndOfLastYear,
                    isValue: true,
                  },
                  {
                    text: netWorthEndOfThisYear,
                    isValue: true,
                  },
                  {
                    text: (netWorthLastVsThisYear * 100).toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                  },
                ]}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Box sx={{ paddingBottom: "10px", margin: "5px" }}>
        <Card>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={18} lineHeight={2.5}>
                {`${year - 1} vs. ${year}`}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={4}>Last year</Grid>
            <Grid xs={4}>This year</Grid>
            <Grid xs={4}>Change</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>Expenses</Grid>

            <Grid xs={4}>{f(totalExpensePreviousYear)}</Grid>
            <Grid xs={4}>{f(totalExpense)}</Grid>
            <Grid xs={4}>{totalExpenseVsPreviousYear.toFixed(0)}%</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <Typography></Typography>
            </Grid>
            <Grid xs={12}>Incomes</Grid>

            <Grid xs={4}>{f(totalIncomePreviousYear)}</Grid>
            <Grid xs={4}>{f(totalIncome)}</Grid>
            <Grid xs={4}>{totalIncomeVsPreviousYear.toFixed(0)}%</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12}>Net worth</Grid>

            <Grid xs={4}>{f(netWorthEndOfLastYear)}</Grid>
            <Grid xs={4}>{f(netWorthEndOfThisYear)}</Grid>
            <Grid xs={4}>{(netWorthLastVsThisYear * 100).toFixed(0)}%</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Card>
      </Box>
    );

  const BottomRightTable = () =>
    matches ? (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <AccentRow title={`Investments`} columnsCount={1} />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `New capital investments`,
                  },
                  {
                    text: capitalInvested,
                    isValue: true,
                    align: "right",
                  },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Latest known value of investments`,
                  },
                  {
                    text: valueAtEndOfYear,
                    isValue: true,
                    align: "right",
                  },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Latest value vs. capital invested`,
                  },
                  {
                    text: (capitalVsValueDelta * 100).toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                    align: "right",
                  },
                ]}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Box sx={{ paddingBottom: "10px", margin: "5px" }}>
        <Card>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={20} lineHeight={2.5}>
                Investments
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12}>New capital investments</Grid>
            <Grid xs={12}>{f(capitalInvested)}</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <Typography></Typography>
            </Grid>

            <Grid xs={12}>Latest known value of investments</Grid>
            <Grid xs={12}>{f(valueAtEndOfYear)}</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12}>
              <Typography>Latest value vs. capital invested</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography>{(capitalVsValueDelta * 100).toFixed(0)}%</Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Card>
      </Box>
    );

  const Bottom2LeftTable = () =>
    matches ? (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <AccentRow title={`Assets`} columnsCount={1} />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Assets acquired`,
                  },
                  {
                    text: assetsData.assetsAcquired,
                    isValue: false,
                    align: "right",
                  },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Assets value at start of year`,
                  },
                  {
                    text: assetsData.assetsValueAtStartOfYear,
                    isValue: true,
                    align: "right",
                  },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Assets value at end of year`,
                  },
                  {
                    text: assetsData.assetsValueAtEndOfYear,
                    isValue: true,
                    align: "right",
                  },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Asset value change %`,
                  },
                  {
                    text: (
                      assetsData.assetsValuePercentageChange * 100
                    ).toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                    align: "right",
                  },
                ]}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Box sx={{ paddingBottom: "10px", margin: "5px" }}>
        <Card>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={20} lineHeight={2.5}>
                Assets
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12}>Assets acquired</Grid>
            <Grid xs={12}>{assetsData.assetsAcquired}</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <Typography></Typography>
            </Grid>

            <Grid xs={12}>Assets value at start of year</Grid>
            <Grid xs={12}>{f(assetsData.assetsValueAtStartOfYear)}</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12}>Assets value at end of year</Grid>
            <Grid xs={12}>{f(assetsData.assetsValueAtEndOfYear)}</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12}>
              <Typography>End of year vs. start of year value</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography>
                {(assetsData.assetsValuePercentageChange * 100).toFixed(0)}%
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Card>
      </Box>
    );

  const Bottom2MidTable = () =>
    matches ? (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <AccentRow title={`Goals`} columnsCount={1} />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Goals reached`,
                  },
                  {
                    text: goalsData.goalsReached,
                    isValue: false,
                    align: "right",
                  },
                ]}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Box sx={{ paddingBottom: "10px", margin: "5px" }}>
        <Card>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={20} lineHeight={2.5}>
                Goals
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12}>Goals reached</Grid>
            <Grid xs={12}>{goalsData.goalsReached}</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <Typography></Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Card>
      </Box>
    );

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Typography fontSize={30}>Yearly report - {year}</Typography>
      </Grid>

      <Grid item xs={12}>
        <ReportComponentSelector
          componentLabels={allComponents}
          setSelectedComponentsParent={setVisibleComponents}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <HideableComponent componentKey="Totals" Component={<LeftTable />} />

        <HideableComponent
          componentKey="Top months"
          Component={<LeftMidTable />}
        />
      </Grid>

      <HideableComponent
        componentKey="Most expensive items"
        Component={
          <Grid item xs={12} md={4}>
            <MiddleTable />
          </Grid>
        }
      />

      <HideableComponent
        componentKey="Most expensive categories"
        Component={
          <Grid item xs={12} md={4}>
            <RightTable />
          </Grid>
        }
      />

      <HideableComponent
        componentKey="Yearly comparison"
        Component={
          <Grid item xs={12} md={6}>
            <BottomLeftTable />
          </Grid>
        }
      />

      <HideableComponent
        componentKey="Investments"
        Component={
          <Grid item xs={12} md={4}>
            <BottomRightTable />
          </Grid>
        }
      />

      <HideableComponent
        componentKey="Assets"
        Component={
          <Grid item xs={12} md={4}>
            <Bottom2LeftTable />
          </Grid>
        }
      />

      <HideableComponent
        componentKey="Goals"
        Component={
          <Grid item xs={12} md={4}>
            <Bottom2MidTable />
          </Grid>
        }
      />
    </Grid>
  );
};
