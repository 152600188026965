import { formatNumberDispatch } from "../../util/Formatter";
import { ActionButton } from "../common/ActionButton";
import { PopulatedAsset } from "@backend/asset.type";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableCell, TableRow, Typography } from "@mui/material";
import { DateTime } from "luxon";

export const SingleAssetTableRow = (props: {
  asset: PopulatedAsset;
  deleteAssetCall: (assetSK: string) => void;
}) => {
  const { asset, deleteAssetCall } = props;
  const { SK, assetName, currentValue, purchaseDate, currency } = asset;

  const format = (n: number) => formatNumberDispatch(n, currency);

  return (
    <TableRow hover={true} key={SK}>
      <TableCell>
        <Typography textAlign="center">{assetName}</Typography>
      </TableCell>
      <TableCell>
        <Typography textAlign="center">
          {DateTime.fromMillis(purchaseDate).toISO().split("T")[0]}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography textAlign="right">{format(currentValue)}</Typography>
      </TableCell>
      <TableCell align="center">
        <ActionButton
          Icon={DeleteIcon}
          onIconClick={() => deleteAssetCall(SK)}
        />
      </TableCell>
    </TableRow>
  );
};
