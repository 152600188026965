import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV011 = () => {
  return (
    <Release version="v0.1.1" releaseDate="2022-10-10">
      <ReleaseNotesSection title="Categories and accounts">
        {" "}
        <ReleaseNotesEntry>
          Add generic accounts and categories
        </ReleaseNotesEntry>
        <ReleaseNotesEntry>
          Edit-protected accounts and categories
        </ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Reporting">
        {" "}
        <ReleaseNotesEntry>
          Expenses vs. incomes vs. savings chart
        </ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
