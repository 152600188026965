import { Callbacks } from "../../types/util";
import { postRequest, getRequest } from "../index";
import {
  CreateIncomePayload,
  UpdateIncomeInterface,
} from "@backend/income.type";

const getIncomeUrl = (route: string, budgetId: string) =>
  `${budgetId}/income/${route}`;

async function addIncome(
  budgetId: string,
  args: CreateIncomePayload,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getIncomeUrl("add", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export interface UpdateIncomeArgs extends UpdateIncomeInterface {}
async function updateIncome(
  budgetId: string,
  args: UpdateIncomeArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getIncomeUrl("update", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface DeleteIncomeArgs {
  incomeSK: string;
}
async function deleteIncome(
  budgetId: string,
  args: DeleteIncomeArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getIncomeUrl("delete", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getIncomes(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/incomes`, logoutCallback, onSuccess, onFailed);
}

interface MoveIncomeToCategory {
  itemSK: string;
  targetCategorySK: string;
}

async function moveIncomeToCategory(
  budgetId: string,
  args: MoveIncomeToCategory,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getIncomeUrl("move/category", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export const IncomeAPI = {
  addIncome,
  deleteIncome,
  getIncomes,
  moveIncomeToCategory,
  updateIncome,
};
