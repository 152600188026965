import { fetchAdminBudget } from "../../api/fetch.redux";
import { ReportingApi } from "../../api/reporting.api";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../redux/reducers/budgetSlice";
import {
  selectSummedIncomesPerMonth,
  setLoading,
  setSummedIncomesPerMonth,
} from "../../redux/reducers/reportingSlice";
import { logout } from "../utils.api";
import { ItemsPerMonthChart } from "./ItemsPerMonthChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export function IncomesPerMonthChart() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: monthlyIncomes,
    loaded: monthlyIncomesLoaded,
    loading: monthlyIncomesLoading,
  } = useAppSelector(selectSummedIncomesPerMonth);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!monthlyIncomesLoaded) {
        dispatch(setLoading({ key: "summedIncomesPerMonth", status: true }));

        ReportingApi.loadSummedIncomesPerMonth(
          budgetId,
          {
            onSuccess: (summedIncomesPerMonth) =>
              dispatch(setSummedIncomesPerMonth({ summedIncomesPerMonth })),
          },
          () => logout(dispatch, navigate),
          12
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, []);

  return (
    <ItemsPerMonthChart
      items={monthlyIncomes}
      itemsLoading={monthlyIncomesLoading}
      label="incomes"
      chartColor="green"
    />
  );
}
