import { LoanAPI } from "../../../api/loans.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCategories } from "../../../redux/reducers/categoriesSlice";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { BudgetarPopover } from "../../common/BudgetarPopover";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { CategoriesDropdown } from "../dropdowns/CategoriesDropdown";
import { CurrencyDropdown } from "../dropdowns/CurrencyDropdown";
import { LoanStatus } from "@backend/loan.type";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const NewLoanDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const budgetId = getBudgetId(useAppSelector);
  const preferredCurrency = useAppSelector(selectCurrency);
  const { data: categories } = useAppSelector(selectCategories);

  const [loanName, setLoanName] = useState("");
  const [loanPrincipal, setLoanPrincipal] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [months, setMonths] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [creationDate, setCreationDate] = useState(DateTime.now().toISODate());
  const [loanCurrency, setLoanCurrency] = useState(preferredCurrency);

  const [incomeCategoryId, setIncomeCategoryId] = useState(
    categories.length ? categories[0].SK : ""
  );
  const [
    updatingBudgetEntryPopulatedBudgetEntry,
    setUpdatingBudgetEntryPopulatedBudgetEntry,
  ] = useState(false);

  const handleClose = () => {
    setOpen(false);

    setLoanCurrency(preferredCurrency);
  };

  const submit = () => {
    createLoan();
    handleClose();
  };

  const createLoan = async () => {
    LoanAPI.addLoan(
      budgetId,
      {
        loanName,
        loanPrincipal,
        interestRate,
        months,
        monthlyPayment,
        creationDate,
        loanAccountId: "",
        incomeCategoryId,
        status: LoanStatus.OPEN,
        currency: loanCurrency,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const canSubmit = () => {
    return (
      loanName &&
      loanName.length &&
      loanPrincipal &&
      months &&
      incomeCategoryId &&
      incomeCategoryId.length &&
      monthlyPayment
    );
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New loan</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Loan label"
              variant="outlined"
              onChange={(e) => setLoanName(e.target.value)}
              sx={{ paddingTop: "10px" }}
            />

            <TextField
              placeholder="Principal"
              variant="outlined"
              onChange={(e) => setLoanPrincipal(Number(e.target.value))}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <TextField
              placeholder="Interest rate"
              variant="outlined"
              onChange={(e) => setInterestRate(Number(e.target.value))}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <TextField
              placeholder="Months"
              variant="outlined"
              onChange={(e) => setMonths(Number(e.target.value))}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <TextField
              placeholder="Monthly payment"
              variant="outlined"
              onChange={(e) => setMonthlyPayment(Number(e.target.value))}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <Grid container xs={12} sx={{ paddingTop: "10px" }}>
              <Grid item xs={12}>
                <CurrencyDropdown
                  label="Loan currency"
                  onCurrencySelected={setLoanCurrency}
                  preferredCurrency={loanCurrency}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ paddingTop: "10px" }}>
              <Grid item xs={10}>
                <CategoriesDropdown
                  categorySelected={setIncomeCategoryId}
                  categoryTypeFilter="income"
                  label="Income category"
                />
              </Grid>
              <Grid item xs={1} sx={{ padding: "10px" }}>
                <BudgetarPopover
                  IconOpen={<HelpOutlineIcon />}
                  IconClosed={<HelpOutlineIcon />}
                  label=""
                >
                  <Box>
                    <Typography sx={{ padding: "10px" }}>
                      Income category under which to put the loan amount as
                      income.
                    </Typography>
                  </Box>
                </BudgetarPopover>
              </Grid>
            </Grid>

            <BudgetarDatePicker onSelect={setCreationDate} />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Create loan
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
