import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV05 = () => {
  return (
    <Release version="v0.5.0" releaseDate="2022-05-03">
      <ReleaseNotesSection title="Multi-currency">
        <ReleaseNotesEntry>Add multi-currency support</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
