import { Callbacks } from "../types/util";
import { postRequest, getRequest } from "./index";

const getEventUrl = (route: string, budgetId: string) =>
  `${budgetId}/event/${route}`;

interface AddEventArgs {
  eventName: string;
}

async function createEvent(
  budgetId: string,
  args: AddEventArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getEventUrl("create", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface DeleteEventArgs {
  eventSK: string;
}
async function deleteEvent(
  budgetId: string,
  args: DeleteEventArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getEventUrl("delete", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getEvents(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/events`, logoutCallback, onSuccess, onFailed);
}

export const EventAPI = {
  createEvent,
  getEvents,
  deleteEvent,
};
