import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccounts } from "../../../redux/reducers/accountsSlice";
import { selectCategories } from "../../../redux/reducers/categoriesSlice";
import { Callbacks } from "../../../types/util";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { AccountsDropdown } from "../dropdowns/AccountsDropdown";
import { CategoriesDropdown } from "../dropdowns/CategoriesDropdown";
import { AddLoanPaymentServiceInterface } from "@backend/loan.type";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const AddLoanPaymentDialog = (props: {
  loanSK: string;
  open: boolean;
  setOpen: (newValue: boolean) => void;
  addLoanPaymentAction: (
    budgetId: string,
    args: AddLoanPaymentServiceInterface,
    { onSuccess, onFailed }: Callbacks,
    logoutCallback: () => void
  ) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { loanSK, open, setOpen, addLoanPaymentAction, onSuccess, onFailed } =
    props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: categories } = useAppSelector(selectCategories);
  const { data: accounts } = useAppSelector(selectAccounts);

  const [paymentAmount, setPaymentAmount] = React.useState(0);
  const [userExpenseCategoryId, setUserExpenseCategoryId] = React.useState(
    categories.length ? categories[0].SK : ""
  );
  const [expenseAccountId, setExpenseAccountId] = React.useState(
    accounts.length ? accounts[0].SK : ""
  );
  const [paymentDate, setPaymentDate] = React.useState(
    DateTime.now().toISODate()
  );

  const budgetId = getBudgetId(useAppSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const submit = () => {
    createLoan();
    handleClose();
  };

  const createLoan = async () => {
    addLoanPaymentAction(
      budgetId,
      {
        loanSK,
        paymentAmount,
        paymentDate,
        userExpenseCategoryId,
        expenseAccountId,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const canSubmit = () => {
    return (
      paymentAmount &&
      userExpenseCategoryId &&
      userExpenseCategoryId.length &&
      expenseAccountId &&
      expenseAccountId.length
    );
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New loan payment</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Payment amount"
              variant="outlined"
              onChange={(e) => setPaymentAmount(Number(e.target.value))}
              sx={{ paddingTop: "10px" }}
            />
            <Box sx={{ paddingBottom: "10px" }} />
            <CategoriesDropdown
              categorySelected={setUserExpenseCategoryId}
              categoryTypeFilter={"expense"}
            />
            <Box sx={{ paddingBottom: "10px" }} />
            <AccountsDropdown accountSelected={setExpenseAccountId} />
            <Box sx={{ paddingBottom: "10px" }} />
            <BudgetarDatePicker onSelect={setPaymentDate} />
            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Add loan payment
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
