import { formatNumberDispatch } from "../../util/Formatter";
import { SoldInvestment } from "@backend/investment.type";
import { TableCell, TableRow, Grid, Card, Typography } from "@mui/material";
import { DateTime } from "luxon";

export const SingleSoldInvestmentTableRow = (props: {
  investment: SoldInvestment;
  matches: boolean;
}) => {
  const { investment, matches } = props;
  const {
    investmentName,
    investmentCurrency,
    quantitySold,
    salePrice,
    saleDate,
  } = investment;

  const format = (n: number) => formatNumberDispatch(n, investmentCurrency);

  const renderFullSize = () => {
    return (
      <TableRow hover={true} key={investmentName}>
        <TableCell>
          <Typography textAlign={"center"}>{investmentName}</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"center"}>
            {DateTime.fromISO(new Date(saleDate).toISOString()).toISODate()}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"right"}>{quantitySold.toFixed(3)}</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"right"}>{format(salePrice)}</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"right"}>
            {format(salePrice * quantitySold)}
          </Typography>
        </TableCell>
        {/* <TableCell>{investmentAccountId}</TableCell>
        <TableCell>{userExpenseCategoryId}</TableCell>
        <TableCell>{expenseAccountId}</TableCell> */}
      </TableRow>
    );
  };

  const renderMini = () => {
    return (
      <TableRow hover={false}>
        <TableCell>
          <Card>
            <Grid container>
              <Grid item xs={12} sx={{ paddingBottom: "5px" }}>
                <Typography textAlign={"center"}>{investmentName}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  Sold {quantitySold} @ {format(salePrice)}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </TableCell>
      </TableRow>
    );
  };

  return matches ? renderFullSize() : renderMini();
};
