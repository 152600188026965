import { fetchUserProfile } from "../../api/fetch.redux";
import { useAppSelector } from "../../redux/hooks";
import { selectUserProfile } from "../../redux/reducers/userSlice";
import { capitalizeWord } from "../../util/Functions";
import { logout } from "../utils.api";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CategoryIcon from "@mui/icons-material/Category";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HouseIcon from "@mui/icons-material/House";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LogoutIcon from "@mui/icons-material/Logout";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { Box, Button, Typography, useMediaQuery, Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface ScreenOverlayProps {
  onItemSelect: () => void;
}

interface ButtonItem {
  path: string;
  Icon: any;
}

const ScreenOverlay = (props: ScreenOverlayProps) => {
  const matches = useMediaQuery("(min-width:600px)");
  const widthGreaterThan425 = useMediaQuery("(min-width:425px)");
  // TODO finish implementing propery layout

  const { onItemSelect } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: userProfile,
    loaded: userProfileLoaded,
    loading: userProfileLoading,
  } = useAppSelector(selectUserProfile);

  useEffect(() => {
    fetchUserProfile(dispatch, userProfileLoaded);
  }, [userProfileLoaded]);

  const goTo = (path: string) => {
    onItemSelect();
    navigate(`/${path}`);
  };

  const generateOverlayButtonItem = ({ path }: ButtonItem) => ({
    title: capitalizeWord(path),
    path,
  });

  const logoutUser = () => {
    onItemSelect();
    logout(dispatch, navigate);
  };

  const marginBottom = "40px";

  const buttonGroups: ButtonItem[][] = [
    [
      { path: "categories", Icon: CategoryIcon },
      { path: "accounts", Icon: AccountBalanceIcon },
    ],
    [
      { path: "expenses", Icon: ShoppingCartIcon },
      { path: "incomes", Icon: LocalAtmIcon },
      { path: "events", Icon: LocalAtmIcon },
    ],
    [
      { path: "investments", Icon: ShowChartIcon },
      { path: "assets", Icon: ApartmentIcon },
    ],
    [
      { path: "loans", Icon: RequestQuoteIcon },
      { path: "mortgages", Icon: HouseIcon },
      { path: "credits", Icon: CreditCardIcon },
    ],
    [{ path: "goals", Icon: SportsScoreIcon }],
    [{ path: "reporting", Icon: AssessmentIcon }],
    [{ path: "settings", Icon: SettingsIcon }],
  ];

  const items = buttonGroups.map((buttonGroup, idx) => {
    return (
      <Box
        sx={{
          marginBottom,
          display: "flex",
          flexDirection: matches ? "row" : "column",
          alignContent: "center",
          justifyItems: "center",
          alignItems: "center",
        }}
        key={idx}
      >
        {buttonGroup.map((button, idx) => {
          const { Icon } = button;
          const { title, path } = generateOverlayButtonItem(button);

          return (
            <Button
              key={idx}
              sx={{
                minWidth: "200px",
                marginBottom: idx !== buttonGroup.length - 1 ? "5px" : null,
                margin: "5px",
              }}
              variant="outlined"
              onClick={() => goTo(path)}
              endIcon={<Icon />}
            >
              {title}
            </Button>
          );
        })}
      </Box>
    );
  });

  const AdLeft = () => (
    <Box>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9155195627216594"
      ></script>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-format="fluid"
        data-ad-layout-key="-73+ed+2i-1n-4w"
        data-ad-client="ca-pub-9155195627216594"
        data-ad-slot="3951444950"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </Box>
  );
  const AdRight = () => (
    <Box>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9155195627216594"
      ></script>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-format="fluid"
        data-ad-layout-key="-73+ed+2i-1n-4w"
        data-ad-client="ca-pub-9155195627216594"
        data-ad-slot="3951444950"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </Box>
  );

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        alignContent: "center",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <Grid xs={4}>
        <AdLeft />
      </Grid>

      <Grid
        xs={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          alignContent: "center",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <Typography color="gray">
          {userProfile ? `${userProfile.email}` : "Not signed in"}
        </Typography>
        {!userProfile.isActivated ? (
          <Typography color="red">Not activated</Typography>
        ) : null}
        <Button
          sx={{
            minWidth: "200px",
            marginBottom,
            marginTop: "20px",
          }}
          variant="outlined"
          endIcon={<DashboardIcon />}
          color="info"
          onClick={() => goTo("")}
        >
          Dashboard
        </Button>

        {items}

        <Button
          sx={{
            minWidth: "200px",
            marginBottom,
            marginTop: "10px",
          }}
          variant="outlined"
          endIcon={<LogoutIcon />}
          onClick={() => logoutUser()}
          color="info"
        >
          Logout
        </Button>
      </Grid>

      <Grid xs={4}>
        <AdRight />
      </Grid>
    </Grid>
  );
};

export default ScreenOverlay;
