import { Callbacks } from "../types/util";
import { postRequest, getRequest } from "./index";
import {
  CreateInvestmentInterface,
  InvestmentPriceEntry,
  SellInvestmentApiRequestPayload,
} from "@backend/investment.type";

const getInvestmentUrl = (route: string, budgetId: string) =>
  `${budgetId}/investment/${route}`;
const getInvestmentsUrl = (route: string, budgetId: string) =>
  `${budgetId}/investments/${route}`;

async function addInvestment(
  budgetId: string,
  args: CreateInvestmentInterface,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getInvestmentUrl("add", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface AddInvestmentPriceArgs extends InvestmentPriceEntry {}
async function addInvestmentPrice(
  budgetId: string,
  args: AddInvestmentPriceArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getInvestmentsUrl("price/add", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getInvestments(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/investments`, logoutCallback, onSuccess, onFailed);
}

async function getInvestmentsPrices(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(
    getInvestmentsUrl("prices", budgetId),
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function sellInvestment(
  budgetId: string,
  args: SellInvestmentApiRequestPayload,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getInvestmentUrl("sell", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getSoldInvestments(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(
    `${budgetId}/investments/sold`,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export const InvestmentAPI = {
  addInvestment,
  getInvestments,
  addInvestmentPrice,
  getInvestmentsPrices,
  getSoldInvestments,
  sellInvestment,
};
