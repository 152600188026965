import { SecondaryButton } from "../utility/buttons/SecondaryButton";
import { Typography, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const Text = styled(
  Typography,
  {}
)((extraProps: { fontWeight?: number }) => ({
  ...extraProps,
  textAlign: "left",
}));

const SubtitleText = styled(
  Typography,
  {}
)((extraProps: { fontWeight?: number }) => ({
  ...extraProps,
  textAlign: "left",
  color: "orange",
  fontSize: 18,
}));

export const AboutHowTo = (props: {
  closeDialog: () => void;
  hideButtons?: boolean;
}) => {
  const { closeDialog, hideButtons } = props;

  const navigate = useNavigate();

  const HelperButton = (props: { text: string; path: string }) =>
    hideButtons ? null : (
      <Grid item xs={12} alignSelf={"center"}>
        <SecondaryButton
          onClick={() => {
            closeDialog();
            navigate(props.path);
          }}
        >
          {props.text}
        </SecondaryButton>
      </Grid>
    );

  return (
    <Box>
      <Grid
        container
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <SubtitleText>Main menu</SubtitleText>
          <Text>
            You will find the most important pages via clicking the menu icon in
            the top-right corner. From there, you can navigate to the different
            pages and define your categories, accounts, incomes, expenses and
            more.
          </Text>
        </Grid>

        <Grid item sx={{ paddingTop: "20px" }}>
          <SubtitleText>Accounts</SubtitleText>
          <Text>
            To start with, you should define your accounts. There are multiple
            account types available, meant to be used according to the nature of
            your account.
          </Text>
          <Text>
            Accounts and account types are used to calculate your net worth in a
            more detailed way, by showing you a breakdown per account type. The
            more accurately your movements are stored, the more detailed your
            reports will be.
          </Text>
        </Grid>

        <HelperButton text="To accounts" path="/accounts/?source=howto" />

        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <SubtitleText>Categories</SubtitleText>
          <Text>
            You should also define some expense and income categories. This will
            help you to later on to group your expenses and incomes.
          </Text>
        </Grid>

        <HelperButton text="To categories" path="/categories/?source=howto" />

        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <SubtitleText>Expenses and incomes</SubtitleText>
          <Text>
            Once you have setup your accounts and categories, you can start
            inputing your expenses and incomes. You can use the dedicated page,
            or you can do it directly from your dashboard.
          </Text>
          <Text>
            Each income/expense entry has a category and an account under which
            it will be recorded, as well as a date and amount.
          </Text>
        </Grid>
      </Grid>

      {hideButtons ? null : (
        <Grid
          container
          xs={12}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={6}
            alignContent={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
            style={{ textAlign: "center" }}
          >
            <SecondaryButton
              onClick={() => {
                closeDialog();
                navigate("/incomes/?source=howto");
              }}
            >
              To incomes
            </SecondaryButton>
          </Grid>

          <Grid
            item
            xs={6}
            alignContent={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
            style={{ textAlign: "center" }}
          >
            <SecondaryButton
              onClick={() => {
                closeDialog();
                navigate("/expenses/?source=howto");
              }}
            >
              To expenses
            </SecondaryButton>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <SubtitleText>Investments</SubtitleText>
          <Text>
            The system also supports investments. You can add your investments
            via the Investments page.
          </Text>
          <Text>
            Adding an investment will automatically create an expense item.
          </Text>
          <Text>
            For each investment, you can add an investment price at a specific
            date. This allows you to keep a detailed track of your investment
            movements over time.
          </Text>
        </Grid>

        <HelperButton text="To investments" path="/investments/?source=howto" />

        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <SubtitleText>Assets</SubtitleText>
          <Text>
            Alongside your investments you can store your assets. Assets work in
            a similar way to investments, by first creating them in the system,
            and adding their estimate valuation over time. This way you can keep
            track of your asset net value evolution.
          </Text>
        </Grid>

        <HelperButton text="To assets" path="/assets/?source=howto" />

        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <SubtitleText>Loans</SubtitleText>
          <Text>
            Loans allow your to keep track of your active loans, payments done,
            and remaining balances. They use their own dedicated account type.
          </Text>
        </Grid>

        <HelperButton text="To loans" path="/loans/?source=howto" />

        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <SubtitleText>Reporting</SubtitleText>
          <Text>
            Finally, to consult the different charts and visualizations of your
            data, navigate to the Reporting page, where you will find all the
            various reporting charts and graphs.
          </Text>
        </Grid>

        <HelperButton text="To reporting" path="/reporting/?source=howto" />
      </Grid>
    </Box>
  );
};
