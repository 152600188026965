import { themeOptions } from "../../ThemeConfig";
import {
  fetchAdminBudget,
  fetchCurrentSubscription,
} from "../../api/fetch.redux";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../redux/reducers/budgetSlice";
import { selectCurrentSubscription } from "../../redux/reducers/subscriptionSlice";
import { SecondaryTypography } from "../common/SecondaryTypography";
import { AboutDialog } from "../utility/dialogs/AboutDialog";
import "./TopNavigationBar.css";
import { SubscriptionTier } from "@backend/subscription.type";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import TollIcon from "@mui/icons-material/Toll";
import { IconButton, Typography, Grid, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Squash as Hamburger } from "hamburger-react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface TopNavigationBarProps {
  onHamburgerClick: () => void;
  backArrowVisible: boolean;
  goBackCallback: () => void;
  isHamburgerClosed: boolean;
  hamburgerHidden?: boolean;
  openAboutDialog: boolean;
  setOpenAboutDialog: (newValue: boolean) => void;
}

const TopNavigationBar = (props: TopNavigationBarProps) => {
  const matches400 = useMediaQuery("(min-width:400px)");
  const {
    onHamburgerClick,
    backArrowVisible,
    goBackCallback,
    isHamburgerClosed,
    hamburgerHidden,
    openAboutDialog,
    setOpenAboutDialog,
  } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const goToSettings = () =>
    navigate("/settings", { state: { forSubscription: 1 } });

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: currentSubscription,
    loaded: isCurrentSubscriptionLoaded,
    loading: isCurrentSubscriptionLoading,
  } = useAppSelector(selectCurrentSubscription);

  const SubscriptionTierComponent = () => {
    if (isCurrentSubscriptionLoading) return null;
    const hasPremium =
      currentSubscription.subscriptionTier === SubscriptionTier.PREMIUM;

    const label = hasPremium ? "Premium" : "Free";
    const color = hasPremium ? "orange" : themeOptions.palette.primary.main;

    return (
      <Grid
        container
        direction="row"
        xs={3}
        justifyContent={"flex-end"}
        sx={{ paddingTop: "5px" }}
      >
        <Grid item xs={2}>
          {backArrowVisible || matches400 ? (
            hasPremium ? (
              <StarBorderIcon
                color="info"
                onClick={goToSettings}
                fontSize={"small"}
                sx={{ paddingRight: "5px" }}
              />
            ) : (
              <TollIcon
                color="primary"
                onClick={goToSettings}
                fontSize="small"
                sx={{ paddingRight: "5px" }}
              />
            )
          ) : null}
        </Grid>
        <Grid item>
          <Typography
            color={color}
            onClick={goToSettings}
            sx={{
              paddingRight: backArrowVisible ? "0px" : "10px",
              paddingTop: "3px",
            }}
            fontSize={12}
            textAlign={"right"}
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchCurrentSubscription(budgetId, dispatch, isCurrentSubscriptionLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  if (location.pathname === "/auth") {
    return (
      <Box>
        <CssBaseline />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        // backgroundColor: "#7eb8cc"
      }}
    >
      <CssBaseline />

      <Box sx={{ flexGrow: 1 }}>
        <Toolbar>
          {backArrowVisible ? (
            <IconButton color="inherit" onClick={goBackCallback}>
              <ArrowBackIosIcon />
            </IconButton>
          ) : null}

          <Grid container xs={6} md={4} justifyContent={"flex-end"}>
            <Grid item xs={12}>
              <Typography
                fontSize={30}
                onClick={goBackCallback}
                textAlign="left"
              >
                Budgetar
              </Typography>

              <Box sx={{ paddingLeft: "80px" }}>
                <SecondaryTypography
                  fontSize={12}
                  onClick={() => setOpenAboutDialog(true)}
                  textAlign={"left"}
                >
                  What is this?
                </SecondaryTypography>
              </Box>
              <AboutDialog
                open={openAboutDialog}
                setOpen={setOpenAboutDialog}
              />
            </Grid>

            <Grid item xs={3}></Grid>

            <Grid item xs={6}></Grid>
          </Grid>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />

          <SubscriptionTierComponent />

          {hamburgerHidden ? null : (
            <Hamburger
              toggled={!isHamburgerClosed}
              onToggle={onHamburgerClick}
            />
          )}
        </Toolbar>
      </Box>
    </Box>
  );
};
// });

export default TopNavigationBar;
