import { ActionButton } from "../common/ActionButton";
import { AccessRoleItem } from "@backend/access-role.type";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { TableCell, TableRow, Typography } from "@mui/material";

export const SingleAccessRolesTableRow = (props: {
  accessRole: AccessRoleItem;
  revokeAccessRoleCall: (granteeId: string, accessRoleSK: string) => void;
}) => {
  const { accessRole, revokeAccessRoleCall } = props;
  const { granteeId, SK, accessRole: role } = accessRole;

  return (
    <TableRow hover={true} key={SK}>
      <TableCell>
        <Typography textAlign={"center"}>{role}</Typography>
      </TableCell>
      <TableCell align="center">{granteeId}</TableCell>
      <TableCell>
        <ActionButton
          Icon={PersonRemoveIcon}
          tooltip="Revoke access"
          onIconClick={() => revokeAccessRoleCall(granteeId, SK)}
        />
      </TableCell>
    </TableRow>
  );
};
