type Dimensions = {
  desktop: number;
  mobile: number;
};

type FontSize = {
  title: Dimensions;
  subtitle: Dimensions;
  text: Dimensions;
};

export const fonts: FontSize = {
  title: {
    desktop: 24,
    mobile: 16,
  },
  subtitle: {
    desktop: 18,
    mobile: 14,
  },
  text: {
    desktop: 14,
    mobile: 10,
  },
};

function getFontSize(key: keyof FontSize, mini: boolean) {
  const size = mini ? "mobile" : "desktop";
  return fonts[key][size];
}

export function getTitleSize(mini: boolean) {
  return getFontSize("title", mini);
}

export function getSubtitleSize(mini: boolean) {
  return getFontSize("subtitle", mini);
}

export function getTextSize(mini: boolean) {
  return getFontSize("text", mini);
}
