import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV012 = () => {
  return (
    <Release version="v0.1.2" releaseDate="2022-10-23">
      <ReleaseNotesSection title="Loans">
        <ReleaseNotesEntry>Add loans tracking</ReleaseNotesEntry>
        <ReleaseNotesEntry>Repayments</ReleaseNotesEntry>
        <ReleaseNotesEntry>Interest fees</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Categories">
        <ReleaseNotesEntry>Enable editing</ReleaseNotesEntry>
        <ReleaseNotesEntry>Pagination</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Misc.">
        <ReleaseNotesEntry>UI fixes</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
