import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV02 = () => {
  return (
    <Release version="v0.2.0" releaseDate="2023-02-18">
      <ReleaseNotesSection title="Multi-account access">
        <ReleaseNotesEntry>Enable granting access to budget</ReleaseNotesEntry>
        <ReleaseNotesEntry>Enable switching budgets</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Technical">
        <ReleaseNotesEntry>Major mobile-first re-design</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Accounts">
        <ReleaseNotesEntry>Add transfers</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Expenses and incomes">
        <ReleaseNotesEntry>Add filtering</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Misc.">
        <ReleaseNotesEntry>Update dashboard UI</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add logo</ReleaseNotesEntry>
        <ReleaseNotesEntry>Update theme</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
