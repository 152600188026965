import { AccountAPI } from "../api/accounts/accounts.api";
import { AssetAPI } from "../api/assets.api";
import { removeCredentialsFromSessionStorage } from "../api/auth/auth.util";
import { CategoryAPI } from "../api/categories/categories.api";
import { CreditAPI } from "../api/credit.api";
import { EventAPI } from "../api/events.api";
import { ExpenseAPI } from "../api/expenses/expenses.api";
import {
  fetchExpenses,
  fetchIncomes,
  fetchCategories,
  fetchAccounts,
  fetchSoldInvestments,
  fetchGoals,
  fetchAssets,
  fetchInvestments,
  fetchLoans,
  fetchUserProfile,
  fetchCredits,
  fetchInvestmentPrices,
  fetchCurrentSubscription,
  fetchEvents,
} from "../api/fetch.redux";
import { GoalAPI } from "../api/goals.api";
import { IncomeAPI } from "../api/incomes/incomes.api";
import { InvestmentAPI } from "../api/investments.api";
import { LoanAPI } from "../api/loans.api";
import { MortgageAPI } from "../api/mortgages.api";
import { logout as accessRoleLogout } from "../redux/reducers/accessRoleSlice";
import {
  setAccounts,
  setAccountsLoading,
  logout as accountsLogout,
} from "../redux/reducers/accountsSlice";
import {
  setAssets,
  setAssetsLoading,
  logout as assetsLogout,
} from "../redux/reducers/assetsSlice";
import { selectBudgetToUse, budgetLogout } from "../redux/reducers/budgetSlice";
import {
  setCategories,
  setCategoriesLoading,
  logout as categoriesLogout,
} from "../redux/reducers/categoriesSlice";
import {
  setCreditsLoading,
  setCredits,
  logout as creditsLogout,
} from "../redux/reducers/creditsSlice";
import {
  setEvents,
  setEventsLoading,
  logout as eventsLogout,
} from "../redux/reducers/eventsSlice";
import {
  setExpensesLoading,
  setSimpleExpenses,
  logout as expensesLogout,
} from "../redux/reducers/expensesSlice";
import {
  setGoals,
  setGoalsLoading,
  logout as goalsLogout,
} from "../redux/reducers/goalsSlice";
import {
  setIncomesLoading,
  setSimpleIncomes,
  logout as incomesLogout,
} from "../redux/reducers/incomesSlice";
import {
  setInvestments,
  setInvestmentsLoading,
  setInvestmentsPrices,
  setInvestmentsPricesLoading,
  logout as investmentsLogout,
} from "../redux/reducers/investmentsSlice";
import {
  setLoans,
  setLoansLoading,
  logout as loansLogout,
} from "../redux/reducers/loansSlice";
import {
  setMortgages,
  setMortgagesLoading,
} from "../redux/reducers/mortgagesSlice";
import { logout as reportingLogout } from "../redux/reducers/reportingSlice";
import { logout as subscriptionLogout } from "../redux/reducers/subscriptionSlice";
import {
  logout as userLogout,
  logout as authLogout,
} from "../redux/reducers/userSlice";
import { AppDispatch } from "../redux/store";
import { Callbacks } from "../types/util";
import { NavigateFunction } from "react-router-dom";

function call(
  budgetId: string,
  dispatch: AppDispatch,
  setLoading: any,
  apiCall: (
    budgetId: string,
    callbacks: Callbacks,
    logoutCallback: () => void
  ) => Promise<void>,
  resultAttributeName: string,
  setResult: any,
  callbacks?: Callbacks
) {
  dispatch(setLoading({ status: true }));

  apiCall(
    budgetId,
    {
      onSuccess: (result) => {
        dispatch(setResult({ [resultAttributeName]: result }));
        dispatch(setLoading({ status: false }));

        if (callbacks && callbacks.onSuccess) callbacks.onSuccess(result);
      },
      onFailed: (err: any) => {
        if (callbacks && callbacks.onFailed) callbacks.onFailed(err);
      },
    },
    () =>
      logout(dispatch, ((path: string) =>
        console.warn("No need to implement here.")) as any)
  );
}

export function getBudgetId(appSelector: any): string {
  return appSelector(selectBudgetToUse);
}

export function loadExpenses(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setExpensesLoading,
    ExpenseAPI.getExpenses,
    "expenses",
    setSimpleExpenses,
    callbacks
  );
}

export function loadIncomes(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setIncomesLoading,
    IncomeAPI.getIncomes,
    "incomes",
    setSimpleIncomes,
    callbacks
  );
}

export function loadCategories(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setCategoriesLoading,
    CategoryAPI.getCategories,
    "categories",
    setCategories,
    callbacks
  );
}

export function loadAccounts(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setAccountsLoading,
    AccountAPI.getAccounts,
    "accounts",
    setAccounts,
    callbacks
  );
}

export function loadGoals(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setGoalsLoading,
    GoalAPI.getGoals,
    "goals",
    setGoals,
    callbacks
  );
}

export function loadAssets(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setAssetsLoading,
    AssetAPI.getAssets,
    "assets",
    setAssets,
    callbacks
  );
}

export function loadInvestments(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setInvestmentsLoading,
    InvestmentAPI.getInvestments,
    "investments",
    setInvestments,
    callbacks
  );
}

export function loadInvestmentsPrices(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setInvestmentsPricesLoading,
    InvestmentAPI.getInvestmentsPrices,
    "investmentsPrices",
    setInvestmentsPrices,
    callbacks
  );
}

export function loadLoans(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setLoansLoading,
    LoanAPI.getLoans,
    "loans",
    setLoans,
    callbacks
  );
}

export function loadCredits(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setCreditsLoading,
    CreditAPI.getCredits,
    "credits",
    setCredits,
    callbacks
  );
}

export function loadEvents(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setEventsLoading,
    EventAPI.getEvents,
    "events",
    setEvents,
    callbacks
  );
}

export function loadMortgages(
  budgetId: string,
  dispatch: AppDispatch,
  callbacks?: Callbacks
) {
  call(
    budgetId,
    dispatch,
    setMortgagesLoading,
    MortgageAPI.getMortgages,
    "mortgages",
    setMortgages,
    callbacks
  );
}

export function forceFetchBaseData(budgetId: string, dispatch: AppDispatch) {
  fetchUserProfile(dispatch, false);
  fetchExpenses(budgetId, dispatch, false);
  fetchIncomes(budgetId, dispatch, false);
  fetchCategories(budgetId, dispatch, false);
  fetchAccounts(budgetId, dispatch, false);
  fetchGoals(budgetId, dispatch, false);
  fetchAssets(budgetId, dispatch, false);
  fetchInvestments(budgetId, dispatch, false);
  fetchSoldInvestments(budgetId, dispatch, false);
  fetchInvestmentPrices(budgetId, dispatch, false);
  fetchLoans(budgetId, dispatch, false);
  fetchCurrentSubscription(budgetId, dispatch, false);
  fetchCredits(budgetId, dispatch, false);
  fetchEvents(budgetId, dispatch, false);
}

export function logout(dispatch: AppDispatch, navigate: NavigateFunction) {
  dispatch(userLogout());
  dispatch(accessRoleLogout());
  dispatch(accountsLogout());
  dispatch(assetsLogout());
  dispatch(authLogout());
  dispatch(categoriesLogout());
  dispatch(expensesLogout());
  dispatch(goalsLogout());
  dispatch(incomesLogout());
  dispatch(investmentsLogout());
  dispatch(loansLogout());
  dispatch(reportingLogout());
  dispatch(budgetLogout());
  dispatch(subscriptionLogout());
  dispatch(creditsLogout());
  dispatch(eventsLogout());

  removeCredentialsFromSessionStorage();
  navigate("/auth");
}

export function logoutCurrencyData(dispatch: AppDispatch) {
  dispatch(accountsLogout());
  dispatch(assetsLogout());
  dispatch(categoriesLogout());
  dispatch(expensesLogout());
  dispatch(goalsLogout());
  dispatch(incomesLogout());
  dispatch(investmentsLogout());
  dispatch(loansLogout());
  dispatch(reportingLogout());
}
