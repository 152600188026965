import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { createSlice } from "@reduxjs/toolkit";

export interface BudgetState {
  adminBudgetId: SingleLoad<string>;
  budgetInUse: { budgetId: string; granterEmail: string };
}

export const initialState: BudgetState = {
  adminBudgetId: singleLoadDefaultValue(""),
  budgetInUse: { budgetId: "", granterEmail: "" },
};

export const budgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {
    setAdminBudgetId: (
      state: BudgetState,
      action: { payload: { budget: { budgetId: string } } }
    ) => {
      state.adminBudgetId = getSingleLoadResponse(
        action.payload.budget.budgetId
      );
    },
    setAdminBudgetIdLoading: (
      state: BudgetState,
      action: { payload: { newStatus: boolean } }
    ) => {
      state.adminBudgetId.loading = action.payload.newStatus;
    },
    budgetLogout: (state: BudgetState) => {
      return initialState;
    },
    setBudgetInUse: (
      state: BudgetState,
      action: {
        payload: { budget: { budgetId: string; granterEmail: string } };
      }
    ) => {
      state.budgetInUse = action.payload.budget;
    },
    switchToAdmin: (state: BudgetState) => {
      state.budgetInUse = { budgetId: "", granterEmail: "" };
    },
  },
});

export const {
  setAdminBudgetId,
  setAdminBudgetIdLoading,
  budgetLogout,
  setBudgetInUse,
  switchToAdmin,
} = budgetSlice.actions;

export const budgetState = (state: RootState) => state.budget;

export const selectAdminBudgetId = (state: RootState): SingleLoad<string> =>
  budgetState(state).adminBudgetId;

export const selectBudgetToUse = (state: RootState): string => {
  const budgetInUse = budgetState(state).budgetInUse.budgetId;

  if (budgetInUse.length) return budgetInUse;

  return selectAdminBudgetId(state).data;
};
export const selectBudgetGranter = (state: RootState): string => {
  return budgetState(state).budgetInUse.granterEmail;
};

export const BudgetReducer = budgetSlice.reducer;
