import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, Grid, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import * as React from "react";

interface PopoverProps {
  IconOpen?: JSX.Element;
  IconClosed?: JSX.Element;
  children: JSX.Element | JSX.Element[];
  label?: string;
}

export const BudgetarPopover = (props: PopoverProps) => {
  const { IconOpen, IconClosed, children, label } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const OpenIcon = () => (IconOpen ? IconOpen : <KeyboardArrowUpIcon />);
  const ClosedIcon = () =>
    IconClosed ? IconClosed : <KeyboardArrowDownIcon />;

  return (
    <Grid>
      <Grid container xs={12} alignContent={"right"}>
        <Grid item xs={10}>
          <Typography
            paddingLeft="20px"
            paddingTop={label ? "5px" : "0px"}
            textAlign={"left"}
          >
            {label || ""}
          </Typography>
        </Grid>

        <Grid item xs={2} alignItems="right" justifyContent="right">
          <IconButton size="small" color="primary" onClick={handleClick}>
            {open ? <OpenIcon /> : <ClosedIcon />}
          </IconButton>
        </Grid>
      </Grid>

      <Popover
        sx={{ opacity: 0.9 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {children}
      </Popover>
    </Grid>
  );
};
