import { CategoryAPI } from "../../../api/categories/categories.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { getBudgetId, logout } from "../../utils.api";
import { CategoryTypeDropdown } from "../dropdowns/CategoryTypeDropdown";
import { CurrencyDropdown } from "../dropdowns/CurrencyDropdown";
import { PeriodDropdown } from "../dropdowns/PeriodDropdown";
import { CategoryType } from "@backend/category.type";
import { Currency } from "@backend/currency.type";
import { Period } from "@backend/period.type";
import {
  Button,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/system";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const NewCategoryDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: (err: any) => void;
  validate: (categoryName: string, categoryType: string) => string | null;
}) => {
  const { open, setOpen, onSuccess, onFailed, validate } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const preferredCurrency = useAppSelector(selectCurrency);

  const [categoryName, setCategoryName] = React.useState("");
  const [categoryType, setCategoryType] = React.useState(CategoryType.EXPENSE);
  const [assignedPeriod, setAssignedPeriod] = React.useState(Period.MONTHLY);
  const [periodBudget, setPeriodBudget] = React.useState(0);
  const [isEssential, setEssential] = React.useState(false);
  const [periodBudgetCurrency, setPeriodBudgetCurrency] =
    React.useState<Currency>(preferredCurrency);

  const budgetId = getBudgetId(useAppSelector);

  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCategoryNameChange = (newName: string) => {
    setCategoryName(newName);

    const validationResult = validate(newName, categoryType);

    setError(validationResult ? validationResult : null);
  };

  const handlePeriodBudgetChange = (newBudgetBudget: string) =>
    setPeriodBudget(Number(newBudgetBudget));

  const handleCategoryTypeChange = (newType: CategoryType) => {
    setCategoryType(newType);

    const validationResult = validate(categoryName, newType);

    setError(validationResult ? validationResult : null);
  };

  const submit = () => {
    createCategory();
    handleClose();
  };

  const createCategory = async () => {
    CategoryAPI.createCategory(
      budgetId,
      {
        categoryName,
        periodBudget,
        periodBudgetCurrency,
        assignedPeriod,
        isEssential,
      },
      categoryType,
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const handleEssentialSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEssential(event.target.checked);
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New category</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Enter category name"
              variant="outlined"
              onChange={(e) => handleCategoryNameChange(e.target.value)}
            />

            <Box sx={{ paddingTop: "10px" }}>
              <CategoryTypeDropdown
                categoryTypeSelected={handleCategoryTypeChange}
              />
            </Box>

            <Box sx={{ paddingTop: "10px" }}>
              <PeriodDropdown periodSelected={setAssignedPeriod} />
            </Box>

            <TextField
              placeholder="Enter period budget"
              type={"number"}
              variant="outlined"
              onChange={(e) => handlePeriodBudgetChange(e.target.value)}
              sx={{ paddingTop: "10px" }}
            />
            <Grid container xs={12} sx={{ paddingTop: "10px" }}>
              <Grid item xs={12}>
                <CurrencyDropdown
                  label="Period budget currency"
                  onCurrencySelected={setPeriodBudgetCurrency}
                />
              </Grid>
            </Grid>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isEssential}
                    onChange={handleEssentialSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={"Essential"}
              ></FormControlLabel>
            </FormGroup>

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!!error || !categoryName || !categoryName.length}
                onClick={submit}
              >
                Create category
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
