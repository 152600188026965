import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { ConvertedCategory } from "@backend/category.type";
import { createSlice } from "@reduxjs/toolkit";

interface CategoriesState {
  categories: SingleLoad<ConvertedCategory[]>;
}

export const initialState: CategoriesState = {
  categories: singleLoadDefaultValue([]),
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (
      state: CategoriesState,
      action: { payload: { categories: ConvertedCategory[] } }
    ) => {
      const { categories } = action.payload;

      state.categories = getSingleLoadResponse(categories);
    },
    setCategoriesLoading: (
      state: CategoriesState,
      action: { payload: { status: boolean } }
    ) => {
      state.categories.loading = action.payload.status;
    },
    logout: (state: CategoriesState) => {
      return initialState;
    },
  },
});

export const { setCategories, setCategoriesLoading, logout } =
  categoriesSlice.actions;

export const categoriesState = (state: RootState) => state.categories;

export const selectCategories = (
  state: RootState
): SingleLoad<ConvertedCategory[]> => categoriesState(state).categories;
export const selectExpenseCategories = (
  state: RootState
): ConvertedCategory[] =>
  selectCategories(state).data.filter(
    (e) => e.categoryType.toLowerCase() === "expense"
  );
export const selectIncomeCategories = (state: RootState): ConvertedCategory[] =>
  selectCategories(state).data.filter(
    (e) => e.categoryType.toLowerCase() === "income"
  );
export const selectCategoriesLoading = (state: RootState): boolean =>
  categoriesState(state).categories.loading;

export const CategoriesReducer = categoriesSlice.reducer;
