import { Chart, EasingFunction } from "chart.js";

export const CustomChartOptions = {
  plugins: {
    centerText: {
      text: "No data available",
      fontColor: "rgba(255,255,255,0.6)",
      fontSize: 22,
    },
  },
};

export const CenterTextPlugin = {
  id: "centerText",
  afterDraw: (chart: Chart, easing: EasingFunction) => {
    if ((chart.config.options as any).plugins.centerText) {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;
      const centerX = chartArea.left + chartArea.right / 2;
      const centerY = chartArea.top + chartArea.bottom / 2;
      const text =
        (chart.config.options as any).plugins?.centerText?.text || "No data";
      const fontColor =
        (chart.config.options as any).plugins?.centerText?.fontColor || "white";
      const fontSize =
        (chart.config.options as any).plugins?.centerText?.fontSize || 20;

      if (
        chart.config.data?.datasets?.every(
          (dataset) => dataset.data?.length === 0
        )
      ) {
        ctx.save();
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = fontColor;
        ctx.font = `${fontSize}px Arial`;
        ctx.fillText(text, centerX, centerY);
        ctx.restore();
      }
    }
  },
};
