import { AccessRoleReducer } from "./reducers/accessRoleSlice";
import { AccountsReducer } from "./reducers/accountsSlice";
import { AssetsReducer } from "./reducers/assetsSlice";
import { BudgetReducer } from "./reducers/budgetSlice";
import { CategoriesReducer } from "./reducers/categoriesSlice";
import { CreditsReducer } from "./reducers/creditsSlice";
import { EventsReducer } from "./reducers/eventsSlice";
import { SimpleExpensesReducer } from "./reducers/expensesSlice";
import { GoalsReducer } from "./reducers/goalsSlice";
import { SimpleIncomesReducer } from "./reducers/incomesSlice";
import { InvestmentsReducer } from "./reducers/investmentsSlice";
import { LoansReducer } from "./reducers/loansSlice";
import { MortgagesReducer } from "./reducers/mortgagesSlice";
import { ReportingReducer } from "./reducers/reportingSlice";
import { SettingsReducer } from "./reducers/settingsSlice";
import { SubscriptionReducer } from "./reducers/subscriptionSlice";
import { UserReducer } from "./reducers/userSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  expires: 3600,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    categories: CategoriesReducer,
    accounts: AccountsReducer,
    expenses: SimpleExpensesReducer,
    incomes: SimpleIncomesReducer,
    goals: GoalsReducer,
    assets: AssetsReducer,
    settings: SettingsReducer,
    investments: InvestmentsReducer,
    loans: LoansReducer,
    credits: CreditsReducer,
    user: UserReducer,
    reporting: ReportingReducer,
    budget: BudgetReducer,
    accessRole: AccessRoleReducer,
    subscription: SubscriptionReducer,
    events: EventsReducer,
    mortgages: MortgagesReducer,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
