import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV021 = () => {
  return (
    <Release version="v0.2.1" releaseDate="2023-03-10">
      <ReleaseNotesSection title="UI/UX">
        {" "}
        <ReleaseNotesEntry>Improve loading indicators</ReleaseNotesEntry>
        <ReleaseNotesEntry>Improve sign-up process</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Technical">
        {" "}
        <ReleaseNotesEntry>Add e-mail client</ReleaseNotesEntry>
        <ReleaseNotesEntry>Create e-mail templates</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Developer QoL">
        <ReleaseNotesEntry>Create data generator script</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
