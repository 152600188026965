import { IncomesPerMonthChart } from "./IncomesPerMonthChart";
import { Grid } from "@mui/material";

export const IncomesReporting = () => {
  return (
    <Grid container xs={12}>
      <Grid item xs={12} md={12}>
        <IncomesPerMonthChart />
      </Grid>
    </Grid>
  );
};
