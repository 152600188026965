import { AssetAPI } from "../../../api/assets.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { CurrencyDropdown } from "../dropdowns/CurrencyDropdown";
import { GenericDropdown } from "../dropdowns/GenericDropdown";
import { AssetType } from "@backend/asset.type";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const NewAssetDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const preferredCurrency = useAppSelector(selectCurrency);

  const [assetName, setAssetName] = React.useState("");
  const [assetType, setAssetType] = React.useState<AssetType>(
    AssetType.REAL_ESTATE
  );
  const [purchaseDate, setPurchaseDate] = React.useState<DateTime>(
    DateTime.now()
  );
  const [purchasePrice, setPurchasePrice] = React.useState(0);
  const [assetCurrency, setAssetCurrency] = React.useState(preferredCurrency);

  const budgetId = getBudgetId(useAppSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAssetTypeChange = (newAmount: string) =>
    setPurchasePrice(Number(newAmount));

  const canSubmit = () => {
    return assetName && purchaseDate && purchasePrice && purchasePrice;
  };

  const reset = () => {
    setAssetName("");
    setAssetType(AssetType.OTHER);
    setPurchaseDate(DateTime.now());
    setPurchasePrice(0);
    setPurchasePrice(0);
    setAssetCurrency(preferredCurrency);
  };

  const submit = () => {
    createAsset();
    handleClose();
    reset();
  };

  const createAsset = async () => {
    AssetAPI.createAsset(
      budgetId,
      {
        assetName,
        assetType,
        purchaseDate: purchaseDate.toMillis(),
        purchasePrice,
        currency: assetCurrency,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New asset</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Enter asset name"
              variant="outlined"
              onChange={(e) => setAssetName(e.target.value)}
              sx={{ paddingBottom: "10px" }}
            />

            <GenericDropdown
              label="Asset type"
              options={[
                AssetType.REAL_ESTATE,
                AssetType.VEHICLE,
                AssetType.OTHER,
              ]}
              onSelection={handleAssetTypeChange}
            />

            <BudgetarDatePicker
              onSelect={(date: Date) =>
                setPurchaseDate(DateTime.fromISO(date.toISOString()))
              }
            />

            <Grid container xs={12} sx={{ paddingTop: "10px" }}>
              <Grid item xs={12}>
                <CurrencyDropdown
                  label="Asset currency"
                  onCurrencySelected={setAssetCurrency}
                  preferredCurrency={assetCurrency}
                />
              </Grid>
            </Grid>

            <TextField
              placeholder="Purchase price"
              variant="outlined"
              onChange={(e) => setPurchasePrice(Number(e.target.value))}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Create asset
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
