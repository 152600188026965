import { useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/reducers/userSlice";
import { redactString, formatNumberDispatch } from "../../util/Formatter";
import { BudgetarPopover } from "../common/BudgetarPopover";
import ProgressBar from "../common/ProgressBar";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Card, Grid, Typography, Box } from "@mui/material";

interface DashboardSavingsProps {
  currentMonthExpense: number;
  currentMonthIncome: number;
  plannedMonthExpense: number;
  plannedMonthIncome: number;
  hideNumbers: boolean;
}

export const DashboardIncomeExpense = (props: DashboardSavingsProps) => {
  const {
    currentMonthExpense,
    currentMonthIncome,
    plannedMonthExpense,
    plannedMonthIncome,
    hideNumbers,
  } = props;

  const currency = useAppSelector(selectCurrency);

  const format = (n: number) =>
    hideNumbers ? redactString() : formatNumberDispatch(n, currency);

  return (
    <Card sx={{ marginBottom: "20px" }}>
      <Grid container xs={12} justifyContent={"flex-end"}>
        <Grid item xs={12}>
          <BudgetarPopover
            IconOpen={<HelpOutlineIcon />}
            IconClosed={<HelpOutlineIcon />}
            label="Current month"
          >
            <Box>
              <Typography sx={{ padding: "10px" }}>
                The income bar displays the total income you have input for this
                month.
              </Typography>

              <Typography sx={{ padding: "10px" }}>
                The expense bar displays the total expenses you have input this
                month, with the limit being your monthly budget, as declared in
                the expense categories.
              </Typography>
            </Box>
          </BudgetarPopover>
        </Grid>

        <Grid container xs={10}>
          <Grid xs={4} sx={{ marginRight: "5px" }}>
            <Typography textAlign={"right"}>income</Typography>
          </Grid>
          <Grid xs={7}>
            <ProgressBar
              completedPercentage={
                (100 * currentMonthIncome) / plannedMonthIncome
              }
              label={format(currentMonthIncome)}
              // upperThreshold={{ percentageLimit: 100, color: "#6EB173" }}
            />
          </Grid>
        </Grid>

        <Grid container xs={10}>
          <Grid xs={4} sx={{ marginRight: "5px" }}>
            <Typography textAlign={"right"}>expense</Typography>
          </Grid>
          <Grid xs={7}>
            <ProgressBar
              completedPercentage={
                100 - (100 * currentMonthExpense) / plannedMonthExpense
              }
              // bottomThreshold={{ percentageLimit: 15, color: "#b54d48" }}
              label={format(currentMonthExpense)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
