import { themeOptions } from "../../../ThemeConfig";
import { useAppSelector } from "../../../redux/hooks";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { getTitleSize } from "../../../style/fonts";
import { formatNumberDispatch } from "../../../util/Formatter";
import { colorGenerator, borderColorGenerator } from "../../../util/Functions";
import { CustomChartOptions } from "../../charts/chart-plugins";
import { NetWorthInterface } from "@backend/reporting.type";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const NetWorthPieChart = (props: NetWorthInterface) => {
  const {
    investments,
    loans,
    accounts,
    assets,
    credits,
    mortgages,
    //  other
  } = props;

  const dataPoints = [investments, loans, mortgages, accounts, assets, credits];
  const labels = [
    "Investments",
    "Loans",
    "Mortgages",
    "Accounts",
    "Assets",
    "Credits",
  ];

  const netWorthSum =
    investments + loans + accounts + assets + credits + mortgages;

  const datasets: any = [
    {
      label: "Net worth",
      data: dataPoints,
      backgroundColor: colorGenerator(dataPoints.length),
      borderColor: borderColorGenerator(dataPoints.length),
      borderWidth: 1,
    },
  ];

  const data = { labels, datasets };

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  return (
    <Doughnut
      data={data}
      options={CustomChartOptions as any}
      plugins={[
        {
          id: "textCenter",
          afterDraw(chart, args, pluginOptions) {
            const { ctx } = chart;

            ctx.save();

            ctx.font = `${getTitleSize(true)}px sans-serif`;
            ctx.fillStyle = themeOptions.palette.primary.main;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(
              f(netWorthSum),
              chart.getDatasetMeta(0).data[0].x,
              chart.getDatasetMeta(0).data[0].y
            );
          },
        },
      ]}
    />
  );
};
