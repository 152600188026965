import { AccessRoleAPI } from "../../api/access-roles/access-roles.api";
import { fetchGrantedAccesses } from "../../api/fetch.redux";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectedGrantedBudgetAccesses } from "../../redux/reducers/accessRoleSlice";
import { AccentButton } from "../utility/buttons/AccentButton";
import { GrantAccessDialog } from "../utility/dialogs/GrantAccessDialog";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { logout } from "../utils.api";
import { SingleAccessRolesTableRow } from "./SingleAccessRolesTableRow";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const GrantedAccessRolesTable = () => {
  const [openGrantAccessDialog, setOpenGrantAccessDialog] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: grantedAccessRoles, loaded: areAccessRolesLoaded } =
    useAppSelector(selectedGrantedBudgetAccesses);

  const revokeAccessRole = (granteeId: string, accessRoleSK: string) => {
    AccessRoleAPI.revokeAccessRole(
      granteeId,
      accessRoleSK,
      {
        onSuccess: () => {
          toast.success("AccessRole deleted.");
          fetchGrantedAccesses(dispatch, false);
        },
        onFailed: (err) => toast.error(err),
      },
      () => logout(dispatch, navigate)
    );
  };

  const refreshGrantedAccessRoles = () => fetchGrantedAccesses(dispatch, false);

  const renderGrantButton = () => (
    <AccentButton onClick={() => setOpenGrantAccessDialog(true)}>
      Grant access
    </AccentButton>
  );

  const renderDialog = () => (
    <GrantAccessDialog
      open={openGrantAccessDialog}
      setOpen={setOpenGrantAccessDialog}
      onSuccess={refreshGrantedAccessRoles}
      onFailed={() => toast.error(`Could not grant access role.`)}
    />
  );

  const mappedItems = grantedAccessRoles
    .filter((e) => e.granteeId !== e.granterId)
    .map((accessRole) => (
      <SingleAccessRolesTableRow
        key={accessRole.SK}
        accessRole={accessRole}
        revokeAccessRoleCall={revokeAccessRole}
      />
    ));

  if (areAccessRolesLoaded && !mappedItems.length) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography>No accesses granted.</Typography>
        </Grid>

        {renderDialog()}

        <Grid item xs={12}>
          {renderGrantButton()}
        </Grid>
      </Grid>
    );
  }

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <BudgetarToast />

      {renderDialog()}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderGrantButton()}
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="accessRoles table">
              <TableHead>
                <TableRow hover={true}>
                  <TableCell>
                    <Typography textAlign="center">Role</Typography>
                  </TableCell>
                  <TableCell align="center">Granted to</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>{mappedItems}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
