import { SecondaryButton } from "../utility/buttons/SecondaryButton";
import ErrorIcon from "@mui/icons-material/Error";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Box, Typography, Grid, Card, Button, IconButton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

export const SubscriptionFailed = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const canceled = queryParams.get("canceled");

  const goToDashboard = () => navigate("/");
  const goToSubscriptions = () => navigate("/settings");

  if (canceled) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="20px"
      >
        <Card sx={{ padding: "20px", maxWidth: "600px" }}>
          <Grid container xs={12} sx={{ padding: "20px" }}>
            <Grid xs={12}>
              <IconButton>
                <NotInterestedIcon fontSize="large" color="primary" />
              </IconButton>
            </Grid>

            <Grid xs={12}>
              <Typography color="#ff9000" fontSize={22}>
                Subscription payment canceled.
              </Typography>
            </Grid>

            <Grid xs={12} sx={{ paddingTop: "20px" }}>
              <Typography fontSize={18} textAlign="center">
                Your subscription payment process has been canceled.
              </Typography>
              <Typography fontSize={18} textAlign="center">
                You can try again any time you want.
              </Typography>
            </Grid>

            <Grid xs={12} sx={{ paddingTop: "20px" }}>
              <SecondaryButton onClick={goToSubscriptions}>
                Try again
              </SecondaryButton>
            </Grid>

            <Grid xs={12}>
              <Button onClick={goToDashboard}>Maybe later</Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="20px"
    >
      <Card sx={{ padding: "20px", maxWidth: "600px" }}>
        <Grid container xs={12} sx={{ padding: "20px" }}>
          <Grid xs={12}>
            <IconButton>
              <ErrorIcon fontSize="large" color="primary" />
            </IconButton>
          </Grid>

          <Grid xs={12}>
            <Typography color="#ff9000" fontSize={22}>
              Sorry, we were unable to process your payment.
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: "20px" }}>
            <Typography fontSize={18} textAlign="left">
              Please make sure that the payment information you provided is
              correct and up-to-date. If the problem persists, please contact
              our customer support team for further assistance.
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: "20px" }}>
            <SecondaryButton onClick={goToSubscriptions}>
              Try again
            </SecondaryButton>
          </Grid>

          <Grid xs={12}>
            <Button onClick={goToDashboard}>Maybe later</Button>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
