import { TextField } from "@mui/material";
import { evaluate } from "mathjs";
import React, { useState } from "react";

interface CalculatorTextFieldProps {
  placeholder: string;
  variant: "outlined" | "standard";
  onChange: (newValue: number) => void;
  setInvalidExpression: (status: boolean) => void;
}
const CalculatorTextField = (props: CalculatorTextFieldProps) => {
  const { placeholder, variant, onChange, setInvalidExpression } = props;

  const [expression, setExpression] = useState<string>("");
  const [result, setResult] = useState<string>("");

  const handleExpressionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setExpression(value);
    try {
      const result = evaluate(value);

      setResult(result.toString());
      onChange(Number(result));
      setInvalidExpression(false);
    } catch (err) {
      setInvalidExpression(true);
      setResult("");
    }
  };

  return (
    <TextField
      variant={variant}
      placeholder={placeholder}
      value={expression}
      onChange={handleExpressionChange}
      helperText={result}
      sx={{ paddingTop: "10px" }}
    />
  );
};

export default CalculatorTextField;
