import { Callbacks } from "../types/util";
import { postRequest, getRequest } from "./index";
import {
  CreateCreditInterface,
  CreateCreditPaymentInterface,
} from "@backend/credit.type";

const getCreditUrl = (route: string, budgetId: string) =>
  `${budgetId}/credit/${route}`;

async function createCredit(
  budgetId: string,
  args: CreateCreditInterface,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getCreditUrl("create", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function createCreditPayment(
  budgetId: string,
  args: CreateCreditPaymentInterface,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getCreditUrl("payment/add", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getCredits(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/credits`, logoutCallback, onSuccess, onFailed);
}

export const CreditAPI = {
  createCredit,
  getCredits,

  createCreditPayment,
};
