import { AccountAPI } from "../../../api/accounts/accounts.api";
import {
  fetchCurrentSubscription,
  fetchAdminBudget,
} from "../../../api/fetch.redux";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { selectAdminBudgetId } from "../../../redux/reducers/budgetSlice";
import { selectCurrentSubscription } from "../../../redux/reducers/subscriptionSlice";
import { BudgetarPopover } from "../../common/BudgetarPopover";
import { getBudgetId, logout } from "../../utils.api";
import { AccountTypeDropdown } from "../dropdowns/AccountTypeDropdown";
import { CurrencyDropdown } from "../dropdowns/CurrencyDropdown";
import { LoadingDialog } from "./LoadingDialog";
import { AccountType } from "@backend/account.type";
import { Currency } from "@backend/currency.type";
import { SubscriptionTier } from "@backend/subscription.type";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Button,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

export const NewAccountDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: (err: any) => void;
  validate: (accountName: string) => string | null;
}) => {
  const { open, setOpen, onSuccess, onFailed, validate } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const {
    data: currentSubscription,
    loaded: isCurrentSubscriptionLoaded,
    loading: isCurrentSubscriptionLoading,
  } = useAppSelector(selectCurrentSubscription);

  const [accountName, setAccountName] = useState("");
  const [accountType, setAccountType] = useState(AccountType.BANKACCOUNT);
  const [startingBalance, setStartingBalance] = useState(0);
  const [monthlyAllowance, setMonthlyAllowance] = useState(0);
  const [isPrimary, setPrimary] = useState<boolean | undefined>(undefined);
  const [startingBalanceCurrency, setStartingBalanceCurrency] =
    useState<Currency | null>(null);
  //
  const [error, setError] = useState<string | null>(null);

  const budgetId = getBudgetId(useAppSelector);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchCurrentSubscription(budgetId, dispatch, isCurrentSubscriptionLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const handlePrimaryChangeEvent = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPrimary(event.target.checked);
  };

  const handleAccountNameChange = (newName: string) => {
    setAccountName(newName);

    const validationResult = validate(newName);

    setError(validationResult ? validationResult : null);
  };

  const handleStartingBalanceChange = (newValue: number) =>
    setStartingBalance(newValue);
  const handleMonthlyAllowanceChange = (newValue: number) =>
    setMonthlyAllowance(newValue);

  const submit = () => {
    createAccount();
    handleClose();
  };

  const createAccount = async () => {
    AccountAPI.createAccount(
      budgetId,
      {
        accountName,
        accountType,
        startingBalance,
        isPrimary,
        monthlyAllowance,
        startingBalanceCurrency,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  if (isCurrentSubscriptionLoading)
    return <LoadingDialog open={true} key={uuid()} />;

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New account</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <Box sx={{ paddingTop: "10px" }}>
              <TextField
                label="Enter account name"
                variant="outlined"
                onChange={(e) => handleAccountNameChange(e.target.value)}
              />
            </Box>

            <Box sx={{ paddingTop: "10px" }}>
              <TextField
                label="Enter starting balance"
                variant="outlined"
                onChange={(e) =>
                  handleStartingBalanceChange(Number(e.target.value))
                }
              />
            </Box>
            {startingBalance ? (
              <Grid container xs={12} sx={{ paddingTop: "10px" }}>
                <Grid item xs={12}>
                  <CurrencyDropdown
                    label="Starting balance currency"
                    onCurrencySelected={setStartingBalanceCurrency}
                  />
                </Grid>
              </Grid>
            ) : null}

            <Box sx={{ paddingTop: "10px" }}>
              <AccountTypeDropdown
                accountTypeSelected={setAccountType}
                isPremiumSubscription={
                  currentSubscription.subscriptionTier ===
                  SubscriptionTier.PREMIUM
                }
              />
            </Box>

            {accountType === AccountType.DISCRETIONARY ? (
              <Grid
                container
                sx={{ paddingTop: "10px", justifyContent: "flex-end" }}
              >
                <Grid item xs={9}>
                  <TextField
                    label="Enter monthly allowance"
                    variant="outlined"
                    onChange={(e) =>
                      handleMonthlyAllowanceChange(Number(e.target.value))
                    }
                  />
                </Grid>

                <Grid item xs={1} sx={{ padding: "10px" }}>
                  <BudgetarPopover
                    IconOpen={<HelpOutlineIcon />}
                    IconClosed={<HelpOutlineIcon />}
                    label=""
                  >
                    <Box>
                      <Typography sx={{ padding: "10px" }}>
                        Monthly allowance is the amount that will be added to
                        the discretionary account at the start of each month.
                      </Typography>
                    </Box>
                  </BudgetarPopover>
                </Grid>
              </Grid>
            ) : null}

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPrimary}
                    onChange={handlePrimaryChangeEvent}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={"Primary account"}
              ></FormControlLabel>
            </FormGroup>

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!!error || !accountName || !accountName.length}
                onClick={submit}
              >
                Create account
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
