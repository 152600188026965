import { Box } from "@mui/system";

interface BlurredComponentInterface {
  blurred: boolean;
  children: any | any[];
}
const BlurredComponent = (props: BlurredComponentInterface) => {
  return (
    <Box sx={{ filter: props.blurred ? "blur(5px)" : null }}>
      {props.children}
    </Box>
  );
};

export default BlurredComponent;
