import { InvestmentAPI } from "../../../api/investments.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccounts } from "../../../redux/reducers/accountsSlice";
import { selectCategories } from "../../../redux/reducers/categoriesSlice";
import {
  selectInvestments,
  selectSoldInvestments,
} from "../../../redux/reducers/investmentsSlice";
import { uniqueArray } from "../../../util/Functions";
import { BudgetarPopover } from "../../common/BudgetarPopover";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { AccountsDropdown } from "../dropdowns/AccountsDropdown";
import { CategoriesDropdown } from "../dropdowns/CategoriesDropdown";
import { GenericDropdown } from "../dropdowns/GenericDropdown";
import { AccountType } from "@backend/account.type";
import { CategoryType } from "@backend/category.type";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const SellInvestmentDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const budgetId = getBudgetId(useAppSelector);
  const { data: investments } = useAppSelector(selectInvestments);
  const { data: soldInvestments } = useAppSelector(selectSoldInvestments);
  const incomeCategories = useAppSelector(selectCategories).data.filter(
    (e) => e.categoryType.toLowerCase() === CategoryType.INCOME.toLowerCase()
  );
  const { data: accounts } = useAppSelector(selectAccounts);

  const [error, setError] = useState<string | null>(null);
  const [investmentName, setInvestmentName] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState<
    number | undefined
  >(undefined);
  const [saleDate, setSaleDate] = useState(DateTime.now().toISODate());
  const [quantitySold, setSoldQuantity] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [incomeAccountId, setIncomeAccountId] = useState(accounts[0].SK);
  const [incomeCategoryId, setIncomeCategoryId] = useState(
    incomeCategories[0].SK
  );

  const resetError = () => setError(null);

  const investmentNames = uniqueArray(investments.map((e) => e.investmentName));

  const handleClose = () => {
    setOpen(false);
  };

  const handleAmountChange = (newAmount: string) => {
    resetError();
    setSoldQuantity(Number(newAmount));
  };

  const getAvailableQuantity = (investmentName: string) => {
    const filter = (item: { investmentName: string }) =>
      item.investmentName === investmentName;
    const owned = investments
      .filter(filter)
      .reduce((acc, e) => acc + e.amountPurchased, 0);
    const sold = soldInvestments
      .filter(filter)
      .reduce((acc, e) => acc + e.quantitySold, 0);

    return owned - sold;
  };

  const canSubmit = () => {
    if (!error && quantitySold > (availableQuantity ?? 0)) {
      setError("Not enough quantity owned.");
      return false;
    }

    return (
      investmentName &&
      saleDate &&
      quantitySold &&
      salePrice &&
      incomeAccountId &&
      incomeCategoryId &&
      availableQuantity &&
      (quantitySold <= availableQuantity ?? 0)
    );
  };

  const reset = () => {
    resetError();
    setInvestmentName("");
    setSaleDate(DateTime.now().toISODate());
    setSoldQuantity(0);
    setSalePrice(0);
    setIncomeAccountId(accounts[0].SK);
    setIncomeCategoryId(incomeCategories[0].SK);
    setAvailableQuantity(undefined);
  };

  const submit = () => {
    sellInvestment();
    handleClose();
    reset();
  };

  const sellInvestment = async () => {
    InvestmentAPI.sellInvestment(
      budgetId,
      {
        investmentName,
        saleDate: DateTime.fromISO(saleDate).toMillis(),
        salePrice,
        quantitySold,
        incomeAccountId,
        incomeCategoryId,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const selectInvestmentToSell = (investment: string) => {
    setAvailableQuantity(getAvailableQuantity(investment));

    setInvestmentName(investment);
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>Sell investment</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <Grid container xs={12} sx={{ paddingTop: "10px" }}>
              <Grid item xs={12}>
                <GenericDropdown
                  options={investmentNames}
                  label="Select investment"
                  onSelection={selectInvestmentToSell}
                />
              </Grid>
            </Grid>

            {availableQuantity !== undefined ? (
              <Typography>Owned: {availableQuantity.toFixed(4)}</Typography>
            ) : null}

            <BudgetarDatePicker onSelect={setSaleDate} />

            <Grid container xs={12} spacing={2} sx={{ paddingTop: "20px" }}>
              <Grid item xs={12} md={6} sx={{ paddingTop: "20px" }}>
                <TextField
                  label="Quantity"
                  variant="outlined"
                  onChange={(e) => handleAmountChange(e.target.value)}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6} sx={{ paddingTop: "20px" }}>
                <TextField
                  label="Sale price / unit"
                  variant="outlined"
                  onChange={(e) => setSalePrice(Number(e.target.value))}
                  type="number"
                />
              </Grid>
            </Grid>

            <Grid container sx={{ paddingTop: "10px" }}>
              <Grid item xs={11}>
                <AccountsDropdown
                  accountSelected={setIncomeAccountId}
                  label="Income account"
                  initialOptionIndex={0}
                />
              </Grid>
              <Grid item xs={1} sx={{ padding: "10px" }}>
                <BudgetarPopover
                  IconOpen={<HelpOutlineIcon />}
                  IconClosed={<HelpOutlineIcon />}
                  label=""
                >
                  <Box>
                    <Typography sx={{ padding: "10px" }}>
                      Account with which to record the sale income.
                    </Typography>
                  </Box>
                </BudgetarPopover>
              </Grid>
            </Grid>

            <Grid container sx={{ paddingTop: "10px" }}>
              <Grid item xs={11}>
                <CategoriesDropdown
                  categorySelected={setIncomeCategoryId}
                  categoryTypeFilter="income"
                  label="Income category"
                  initialOptionIndex={0}
                />
              </Grid>
              <Grid item xs={1} sx={{ padding: "10px" }}>
                <BudgetarPopover
                  IconOpen={<HelpOutlineIcon />}
                  IconClosed={<HelpOutlineIcon />}
                  label=""
                >
                  <Box>
                    <Typography sx={{ padding: "10px" }}>
                      Income category under which to record the sale income.
                    </Typography>
                  </Box>
                </BudgetarPopover>
              </Grid>
            </Grid>

            {error ? <Typography>{error}</Typography> : null}

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Sell investment
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
