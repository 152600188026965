import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV04 = () => {
  return (
    <Release version="v0.4.0" releaseDate="2023-04-21">
      <ReleaseNotesSection title="Crediting">
        <ReleaseNotesEntry>Add crediting v1</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Reporting">
        <ReleaseNotesEntry>
          Add assets and goals data to yearly report
        </ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Ads">
        <ReleaseNotesEntry>
          Add ads to overlay menu - provisory
        </ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
