import SavingsIcon from "@mui/icons-material/Savings";
import { Card, Grid, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const FreeSubscriptionTierViewCard = () => {
  return (
    <Box sx={{ minWidth: "300px" }}>
      <Card sx={{ height: "300px", maxWidth: "375px" }}>
        <Grid container xs={12}>
          <Grid item xs={4}></Grid>

          <Grid item xs={4} sx={{ paddingBottom: "10px", paddingTop: "10px" }}>
            <Typography fontSize={30} fontStyle="italic">
              Basic
            </Typography>
          </Grid>

          <Grid item xs={1} paddingTop="22px">
            <SavingsIcon sx={{ color: "white" }} />
          </Grid>

          <Grid item xs={12}>
            <Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: "orange" }} fontSize={20}>
                  Free forever
                </Typography>
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ paddingTop: "20px" }}>
              <Grid item xs={12} sx={{ marginLeft: "20px" }}>
                <ListItemText sx={{ display: "list-item", marginLeft: "20px" }}>
                  <Typography textAlign={"left"}>
                    up to 600 expense/income items per month
                  </Typography>
                </ListItemText>

                <ListItemText sx={{ display: "list-item", marginLeft: "20px" }}>
                  <Typography textAlign={"left"}>
                    unlimited categories and accounts
                  </Typography>
                </ListItemText>

                <ListItemText sx={{ display: "list-item", marginLeft: "20px" }}>
                  <Typography textAlign={"left"}>
                    unlimited investments, assets, loans and goals
                  </Typography>
                </ListItemText>

                <ListItemText sx={{ display: "list-item", marginLeft: "20px" }}>
                  <Typography textAlign={"left"}>
                    multi-currency support
                  </Typography>
                </ListItemText>

                <ListItemText sx={{ display: "list-item", marginLeft: "20px" }}>
                  <Typography textAlign={"left"}>
                    basic reporting, limited to 1 year of data
                  </Typography>
                </ListItemText>

                <ListItemText sx={{ display: "list-item", marginLeft: "20px" }}>
                  <Typography textAlign={"left"}>minimal ads</Typography>
                </ListItemText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
