import { Currency } from "./currency.type";

export interface SavingsSummaryInterface {
  plannedExpense: number;
  actualExpense: number;
  expenseDelta: number;
  plannedIncome: number;
  actualIncome: number;
  incomeDelta: number;
  plannedSavings: number;
  actualSavings: number;
  savingsDelta: number;
  monthsSinceFirstEntry: number;
}

export const EmptySavingsSummaryInterface: SavingsSummaryInterface = {
  plannedExpense: 0,
  actualExpense: 0,
  expenseDelta: 0,
  plannedIncome: 0,
  actualIncome: 0,
  incomeDelta: 0,
  plannedSavings: 0,
  actualSavings: 0,
  savingsDelta: 0,
  monthsSinceFirstEntry: 1,
};

export interface MonthlySum {
  date: string;
  sum: number;
}

export interface MonthlyExpenses extends MonthlySum {}
export interface MonthlyIncomes extends MonthlySum {}

export interface MonthlySaving {
  date: string;
  saving: number;
}

export interface MonthAmount {
  month: number;
  amount: number;
}

interface ReportBase {
  stored: boolean;
}

export interface YearlyReport extends ReportBase {
  year: number;
  highestExpenseMonth: MonthAmount;
  highestIncomeMonth: MonthAmount;
  //
  totalExpense: number;
  totalIncome: number;
  totalSavings: number;
  //
  totalExpensePreviousYear: number;
  totalIncomePreviousYear: number;
  totalExpenseVsPreviousYear: number;
  totalIncomeVsPreviousYear: number;
  //
  mostExpensiveCategories: { categoryName: string; total: number }[];
  topFiveMostExpensivePurchases: {
    label: string;
    amount: number;
    date: number;
  }[];
  newInvestments: {
    capitalInvested: number;
    valueAtEndOfYear: number;
    capitalVsValueDelta: number;
  };
  assetsData: {
    assetsAcquired: number;
    assetsValueAtStartOfYear: number;
    assetsValueAtEndOfYear: number;
    assetsValuePercentageChange: number;
  };
  goalsData: {
    goalsReached: number;
  };
  netWorthData: {
    netWorthEndOfLastYear: number;
    netWorthEndOfThisYear: number;
    netWorthLastVsThisYear: number;
  };
}

export interface MonthTotals {
  totals: {
    expense: number;
    income: number;
    savings: number;
  };
}

export interface MonthlyReport extends MonthTotals, ReportBase {
  year: number;
  month: string;
  mostExpensivePurchase: {
    label: string;
    amount: number;
    date: number;
  };
  highestExpenseDay: {
    date: number;
    amount: number;
  };
  monthVsLastYear: {
    lastYearMonthExpense: number;
    lastYearMonthIncome: number;
    lastYearMonthSavings: number;
    thisVsLastExpense: number;
    thisVsLastIncome: number;
    thisVsLastSavings: number;
  };
  zeroExpenseDays: number[];
  monthVsAverage: {
    averageMonthExpense: number;
    averageMonthIncome: number;
    averageMonthSavings: number;
    thisVsAverageExpense: number;
    thisVsAverageIncome: number;
    thisVsAverageSavings: number;
  };
  currency: Currency;
}

export interface NetWorthInterface {
  accounts: number;
  loans: number;
  investments: number;
  assets: number;
  credits: number;
  mortgages: number;
  other: number;
}

export interface CurrentMonthOverview {
  currentMonthExpense: number;
  currentMonthIncome: number;
}

export const MONTHS_TO_STORE_REPORT = 3;

export interface ReportsMetadataResponse {
  yearly: YearlyReportMetadata[];
  monthly: MonthlyReportMetadata[];
}

interface YearlyReportMetadata {
  year: number;
  stored: boolean;
}

interface MonthlyReportMetadata {
  year: number;
  month: string;
  stored: boolean;
}

export interface NetWorthAtDate {
  month: number;
  netWorth: number;

  investments: number;
  loans: number;
  accounts: number;
  assets: number;
}

export type SimpleNetWorthAtDate = {
  date: number;
  netWorth: number;
};
