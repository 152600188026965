import {
  fetchLoans,
  fetchCategories,
  fetchAccounts,
  fetchAdminBudget,
} from "../api/fetch.redux";
import { LoansTable } from "../components/loans/LoansTable";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewLoanDialog } from "../components/utility/dialogs/NewLoanDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAccounts } from "../redux/reducers/accountsSlice";
import {
  selectAdminBudgetId,
  selectBudgetToUse,
} from "../redux/reducers/budgetSlice";
import { selectCategories } from "../redux/reducers/categoriesSlice";
import { selectLoans } from "../redux/reducers/loansSlice";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface LoansPageProps {
  openHowToDialog: () => void;
}
export const Loans = (props: LoansPageProps) => {
  const { openHowToDialog } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const [openNewLoanDialog, setOpenNewLoanDialog] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: loans,
    loaded: areLoansLoaded,
    loading: areLoansLoading,
  } = useSelector(selectLoans);
  const { loaded: areCategoriesLoaded } = useSelector(selectCategories);
  const { loaded: areAccountsLoaded } = useSelector(selectAccounts);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchLoans(budgetId, dispatch, areLoansLoaded);
      fetchCategories(budgetId, dispatch, areCategoriesLoaded);
      fetchAccounts(budgetId, dispatch, areAccountsLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const newLoansCreated = () => {
    fetchLoans(budgetId, dispatch, false);
    fetchAccounts(budgetId, dispatch, false);
    fetchCategories(budgetId, dispatch, false);
    toast.success("New loan created.");
  };

  return (
    <Box>
      <LoadingDialog key={uuid()} open={areLoansLoading} toDisplay="loans" />
      <BudgetarToast />
      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <SecondaryButton size="small" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            Back
          </SecondaryButton>
        </Box>

        <Button
          variant="contained"
          size="small"
          onClick={() => fetchLoans(budgetId, dispatch, false)}
          sx={{ marginRight: "10px" }}
        >
          <RefreshIcon />
        </Button>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewLoanDialog(true)}
          sx={{ marginRight: "10px" }}
        >
          <AddIcon />
          New loan
        </Button>

        <Box sx={{ paddingLeft: "10px" }} />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}
      </Box>

      <NewLoanDialog
        open={openNewLoanDialog}
        setOpen={setOpenNewLoanDialog}
        onSuccess={newLoansCreated}
        onFailed={() => toast.error("Error while creating loan.")}
      />

      {loans ? <LoansTable loans={loans} /> : <Box>No loans</Box>}
    </Box>
  );
};
