import { SecondaryButton } from "../utility/buttons/SecondaryButton";
import { AboutDialog } from "../utility/dialogs/AboutDialog";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Grid,
  Divider,
  Tooltip,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";

const budgetarSubscriptionImage =
  require("../../../src/budgetar-auth-page.png").default;
// const budgetarBackgroundImage = require("../../../src/bckg2.jpg").default;

interface SubComponentInterface {
  action: (email: string, password: string, code?: string) => void;
}

export interface AuthParentInterface {
  error: string | null;
  setError: (error: string | null) => void;
  email: string;
  setEmail: (email: string) => void;
  code: string;
  setCode: (code: string) => void;
  password: string;
  repassword: string;
  setPassword: (password: string) => void;
  setRePassword: (repassword: string) => void;
  changeComponent: () => void;
  pending: boolean;
  setPending: (newValue: boolean) => void;
  doublePasswordInput?: boolean;
  showPassword: boolean;
  setShowPassword: (newVal: boolean) => void;
  setRecoverPasswordDisplay: (newVal: boolean) => void;
}

interface AuthCommonProps {
  title: string;
  subtitle?: string;
  submitTitle: string;
  submittingString: string;
  parent: AuthParentInterface;
  subComponent: SubComponentInterface;
  // optionals
  alternative?: string;
  question?: string;
  descriptionText?: string;
  hideAllPasswords?: boolean;
  hideForgotPassword?: boolean;
  hideEmail?: boolean;
  codeMode?: boolean;
}

export const AuthCommon = (props: AuthCommonProps) => {
  const matches400 = useMediaQuery("(min-width:800px)");
  const matches900Height = useMediaQuery("(min-height:900px)");

  const {
    title,
    subtitle,
    submitTitle,
    submittingString,
    question,
    alternative,
    parent,
    subComponent,
    //
    descriptionText,
    hideAllPasswords,
    hideForgotPassword,
    hideEmail,
    codeMode,
  } = props;

  const {
    error,
    setError,
    changeComponent,
    email,
    setEmail,
    code,
    setCode,
    password,
    repassword,
    setPassword,
    setRePassword,
    pending,
    setPending,
    doublePasswordInput,
    showPassword,
    setShowPassword,
    setRecoverPasswordDisplay,
  } = parent;

  const { action } = subComponent;

  const [openAboutDialog, setOpenAboutDialog] = useState(false);
  const resetError = () => setError(null);

  const updateEmail = (newValue: string) => {
    resetError();
    setEmail(newValue);
  };

  const updateCode = (newValue: string) => {
    resetError();
    setCode(newValue);
  };

  const updatePassword = (newValue: string) => {
    resetError();
    setPassword(newValue);
  };

  const updateRePassword = (newValue: string) => {
    resetError();
    setRePassword(newValue);
  };

  const submitAction = (
    action: (_email: string, _password: string, _code?: string) => void
  ) => {
    if (codeMode) {
      if (code.length < 5) {
        setError("Please enter a valid code.");
      }
    } else {
      if (isEmailWrong()) {
        setError("Please enter a valid e-mail address.");
        return;
      }
    }

    if (!password.length && !hideAllPasswords) {
      setError("Must provide a password.");
      return;
    }

    if (!hideAllPasswords && doublePasswordInput && password !== repassword) {
      setError("The two password inputs must match.");
      return;
    }

    setPending(true);
    action(email, password, code);
  };

  const isEmailWrong = (_email: string = email) => {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return !expression.test(_email);
  };

  const EndAdornment = () => (
    <InputAdornment position="end" sx={{ width: "20px" }}>
      <IconButton
        sx={{ width: "20px", color: "white" }}
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={() => setShowPassword(!showPassword)}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const renderInputFields = () => (
    <Grid
      container
      sx={{ paddingBottom: "20px" }}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {descriptionText ? (
        <Typography sx={{ paddingBottom: "20px" }}>
          {descriptionText}
        </Typography>
      ) : null}

      {hideEmail ? null : (
        <TextField
          label="E-mail"
          type="text"
          value={email}
          onChange={(ev) => {
            updateEmail(ev.target.value);
            resetError();
          }}
          sx={{
            paddingBottom: hideAllPasswords ? "10px" : "30px",
            width: "300px",
          }}
          disabled={pending}
        />
      )}

      {codeMode ? (
        <TextField
          label="Code"
          type="text"
          value={code}
          onChange={(ev) => {
            updateCode(ev.target.value);
            resetError();
          }}
          sx={{
            width: "300px",
            paddingBottom: hideAllPasswords ? "10px" : "30px",
          }}
          disabled={pending}
        />
      ) : null}

      {hideAllPasswords ? null : (
        <TextField
          label="Password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(ev) => {
            updatePassword(ev.target.value);
            resetError();
          }}
          disabled={pending}
          InputProps={{
            endAdornment: <EndAdornment />,
          }}
          sx={{ paddingBottom: "10px", width: "300px" }}
        />
      )}

      {doublePasswordInput ? (
        <TextField
          label="Re-enter password"
          type={showPassword ? "text" : "password"}
          value={repassword}
          onChange={(ev) => {
            updateRePassword(ev.target.value);
            resetError();
          }}
          disabled={pending}
          InputProps={{
            endAdornment: <EndAdornment />,
          }}
          sx={{ width: "300px" }}
        />
      ) : null}

      {error ? <Typography>{error}</Typography> : null}
    </Grid>
  );

  return (
    <Grid
      container
      alignItems={matches400 ? "center" : "baseline"}
      height="100vh"
      sx={{
        // backgroundImage: `url(${budgetarBackgroundImage})`,
        backgroundSize: "100% calc(100%)",
        backgroundPosition: "center",
      }}
    >
      <BudgetarToast />
      <AboutDialog
        open={openAboutDialog}
        setOpen={setOpenAboutDialog}
        hideButtons={true}
      />

      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ paddingTop: matches900Height ? "0px" : "100px" }}
        >
          <Typography
            fontSize={matches400 ? 40 : 25}
            color="lightgray"
            sx={{
              paddingTop: matches400 ? "0px" : "50px",
              paddingRight: "10px",
            }}
          >
            Welcome to{" "}
          </Typography>

          <Typography
            fontSize={matches400 ? 40 : 25}
            color="lightgray"
            sx={{ paddingTop: matches400 ? "0px" : "50px" }}
          >
            {" "}
          </Typography>
          <Tooltip title="What is this?">
            <Typography
              fontSize={matches400 ? 40 : 25}
              color={"orange"}
              sx={{
                paddingTop: matches400 ? "0px" : "50px",
                textDecoration: "underline dashed",
              }}
              onClick={() => setOpenAboutDialog(true)}
            >
              {" "}
              budgetar
            </Typography>
          </Tooltip>
        </Box>

        <Typography color="gray" fontSize={20}>
          budgeting, simple and easy
        </Typography>

        {matches400 ? (
          <img
            style={{ display: "block", margin: "auto" }}
            className="content-center"
            width={"50%"}
            src={budgetarSubscriptionImage}
            alt="Budgetar Premium logo"
          />
        ) : null}
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography
          fontSize={matches400 ? 30 : 22}
          sx={{ paddingTop: matches400 ? "20px" : "0px" }}
        >
          {title}
        </Typography>
        <Typography fontSize={18} color="gray" sx={{ paddingBottom: "20px" }}>
          {subtitle}
        </Typography>

        {renderInputFields()}

        <SecondaryButton
          variant="contained"
          disabled={pending}
          onClick={() => submitAction(action)}
          // autoFocus={true} // TODO enable when material ui upgraded
        >
          {pending ? submittingString : submitTitle}
        </SecondaryButton>

        {!question && !alternative && hideForgotPassword
          ? null
          : [
              <Box sx={{ paddingTop: "20px" }} />,
              <Divider color="#507f9f" sx={{ width: "33%", margin: "auto" }} />,
            ]}

        {question ? (
          <Typography sx={{ paddingTop: "20px", paddingBottom: "5px" }}>
            {question}
          </Typography>
        ) : null}

        {alternative ? (
          <Box sx={{ paddingTop: question ? "0px" : "20px" }}>
            <SecondaryButton
              onClick={
                hideAllPasswords || codeMode
                  ? () => setRecoverPasswordDisplay(false)
                  : changeComponent
              }
              disabled={pending}
            >
              {alternative}
            </SecondaryButton>
          </Box>
        ) : null}

        {hideForgotPassword ? null : (
          <Box sx={{ paddingTop: "20px" }}>
            <Button
              onClick={() => setRecoverPasswordDisplay(true)}
              disabled={pending}
            >
              Forgot password?
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
