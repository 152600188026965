import { useAppSelector } from "../redux/hooks";
import { selectAccounts } from "../redux/reducers/accountsSlice";
import { selectCurrency } from "../redux/reducers/userSlice";
import { redactString, formatNumberDispatch } from "../util/Formatter";
import { AccountWithBalance } from "./accounts/AccountsTable";
import ProgressBar from "./common/ProgressBar";
import { SecondaryTypography } from "./common/SecondaryTypography";
import { AccountType } from "@backend/account.type";
import { Card, Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";

interface Props {
  hideNumbers: boolean;
  onClickCallback: (account: AccountWithBalance) => void;
}
export const DiscretionaryAccountsOverview = (props: Props) => {
  const { hideNumbers, onClickCallback } = props;

  const { data: accounts } = useAppSelector(selectAccounts);
  const discretionaryAccounts = accounts.filter(
    (e) => e.accountType === AccountType.DISCRETIONARY
  );

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) =>
    hideNumbers ? redactString() : formatNumberDispatch(n, currency);

  const renderAccount = (
    account: AccountWithBalance,
    idx: number,
    arr: any[]
  ) => {
    const limit = account.rollingBalance || 0;
    const balance = account.accountBalance;

    return (
      <Card
        key={account.SK}
        sx={{
          padding: "10px",
          marginRight: "5px",
          marginLeft: "5px",
          paddingLeft: "5px",
          paddingRight: "5px",
          ":hover": {
            boxShadow: 20, // theme.shadows[20]
          },
        }}
        onClick={() => {
          onClickCallback(account);
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography fontSize={12} textAlign="left">
              Monthly limit {f(limit)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SecondaryTypography>{account.accountName}</SecondaryTypography>
          </Grid>
          <Grid item xs={12} alignItems="center" justifyContent="center">
            <ProgressBar
              completedPercentage={(100 * balance) / limit}
              label={f(balance)}
              // upperThreshold={{ percentageLimit: 100, color: "#6EB173" }}
            />
          </Grid>
        </Grid>
      </Card>
    );
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", justifyItems: "center" }}
    >
      {discretionaryAccounts.map((e, idx, arr) => renderAccount(e, idx, arr))}
    </Box>
  );
};
