import { AccessRoleAPI } from "../../../api/access-roles/access-roles.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getBudgetId, logout } from "../../utils.api";
import { GenericDropdown } from "../dropdowns/GenericDropdown";
import { AccessRole } from "@backend/access-role.type";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const GrantAccessDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = React.useState("");
  const [accessRole, setAccessRole] = React.useState<AccessRole>(
    AccessRole.ADMIN
  );

  const budgetId = getBudgetId(useAppSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserEmailChange = (newName: string) => setUserEmail(newName);

  const submit = () => {
    grantAccess();
    handleClose();
  };

  const grantAccess = async () => {
    AccessRoleAPI.grantAccessRole(
      budgetId,
      {
        granteeEmail: userEmail,
        accessRole,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const canSubmit = () => {
    return userEmail && userEmail.length;
  };

  const handleRoleSelection = (choice: AccessRole) => {
    setAccessRole(choice);
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>Grant access role</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="User e-mail"
              variant="outlined"
              onChange={(e) => handleUserEmailChange(e.target.value)}
              sx={{ paddingTop: "10px", marginBottom: "10px" }}
            />

            <GenericDropdown
              label="Role"
              options={Object.values(AccessRole)}
              onSelection={handleRoleSelection}
            />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Grant access
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
