import { Typography } from "@mui/material";

interface ActionButtonProps {
  fontSize?: number;
  children: string;
  textAlign?: any;
}

export const AccentTypography = (props: ActionButtonProps) => {
  const { fontSize, children, textAlign } = props;

  return (
    <Typography
      fontSize={fontSize || 14}
      color={"orange"}
      textAlign={textAlign || "center"}
    >
      {children}
    </Typography>
  );
};
