import { useAppSelector } from "../redux/hooks";
import { selectGoals } from "../redux/reducers/goalsSlice";
import { selectCurrency } from "../redux/reducers/userSlice";
import {
  timeUntilGoalReached,
  redactString,
  formatNumberDispatch,
} from "../util/Formatter";
import { AccentButton } from "./utility/buttons/AccentButton";
import { Goal } from "@backend/goal.type";
import { Typography, Card, Grid } from "@mui/material";
import { Box } from "@mui/system";

interface BaseProps {
  currentSavings: number;
  averageMonthlySavings: number;
}

interface DashboardLoansProps extends BaseProps {
  targetMonthlySavings: number;
  monthsSinceStart: number;
  savingsGoal: number;
  nextGoal: Goal;
  dailyEstimatedChange: number;
  hideNumbers: boolean;
  onAddGoalClick: () => void;
}
export const DashboardGoals = (props: DashboardLoansProps) => {
  const {
    nextGoal,
    currentSavings,
    hideNumbers,
    averageMonthlySavings,
    dailyEstimatedChange,
    onAddGoalClick,
  } = props;

  const { data: goals } = useAppSelector(selectGoals);
  const currency = useAppSelector(selectCurrency);
  const format = (n: number) =>
    hideNumbers ? redactString() : formatNumberDispatch(n, currency, 0);

  const dailyEstimatedChangeGt0 = () => (
    <Box>
      <Typography fontSize={30} textAlign={"center"}>
        {format(nextGoal.amount)}
      </Typography>
      <Typography fontSize={14} textAlign={"center"}>
        reach goal in{" "}
        {timeUntilGoalReached(
          (nextGoal.amount - currentSavings) / (averageMonthlySavings / 30)
        )}
      </Typography>
    </Box>
  );

  const hasNextGoal = () =>
    dailyEstimatedChange >= 0 ? (
      dailyEstimatedChangeGt0()
    ) : (
      <Typography>Negative monthly change. Goals unreachable.</Typography>
    );

  const hasUnreachedGoal: boolean =
    nextGoal && currentSavings <= nextGoal.amount;
  const goalsReachedCount = goals.reduce(
    (acc, e) => (e.amount <= currentSavings ? acc + 1 : acc),
    0
  );

  return (
    <Card sx={{ marginBottom: "20px", padding: "5px" }}>
      {goals.length && hasUnreachedGoal ? (
        <Grid container xs={12}>
          <Grid item xs={6}>
            <Typography paddingLeft="20px" paddingTop="5px" textAlign={"left"}>
              Goals
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {goalsReachedCount ? (
              <Typography textAlign="right" color="darkgray">
                {goalsReachedCount} {goalsReachedCount === 1 ? "goal" : "goals"}{" "}
                reached.
              </Typography>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            {nextGoal ? hasNextGoal() : null}
          </Grid>

          <Grid item xs={12} alignItems={"right"}></Grid>
        </Grid>
      ) : (
        <Box>
          <Typography>No goals {goalsReachedCount ? "set" : "yet"}.</Typography>
          <AccentButton onClick={onAddGoalClick}>
            Click to add goal{" "}
          </AccentButton>
        </Box>
      )}
    </Card>
  );
};
