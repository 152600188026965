import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV041 = () => {
  return (
    <Release version="v0.4.1" releaseDate="2022-04-22">
      <ReleaseNotesSection title="UI/UX">
        <ReleaseNotesEntry>
          Selectable components for yearly report
        </ReleaseNotesEntry>
        <ReleaseNotesEntry>Better state management</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
