import { useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../util/Formatter";
import { ActionButton } from "../common/ActionButton";
import { SecondaryButton } from "../utility/buttons/SecondaryButton";
import { AccountBudgetEntriesDialog } from "../utility/dialogs/AccountBudgetEntriesDialog";
import { AccountTransferDialog } from "../utility/dialogs/AccountTransferDialog";
import { ConfirmActionDialog } from "../utility/dialogs/ConfirmActionDialog";
import { AccountWithBalance } from "./AccountsTable";
import { UpdateAccountData } from "@backend/account.type";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import LockIcon from "@mui/icons-material/Lock";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ReorderIcon from "@mui/icons-material/Reorder";
import {
  TableCell,
  TableRow,
  TextField,
  Typography,
  Switch,
  FormGroup,
  Card,
  Grid,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/system";
import { useState } from "react";
import { toast } from "react-toastify";

interface SingleAccountsTableRowProps {
  matches: boolean;
  account: AccountWithBalance;
  updateAccountCall: (
    accountSK: string,
    updateAccountData: UpdateAccountData,
    cb: () => void
  ) => void;
  deleteAccountCall: (sk: string) => void;
}
export const SingleAccountsTableRow = (props: SingleAccountsTableRowProps) => {
  const { matches, account, deleteAccountCall, updateAccountCall } = props;
  const {
    accountBalance,
    SK,
    accountName,
    accountType,
    isPrimary,
    deleteProtected,
  } = account;

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAccountTransfer, setOpenAccountTransfer] = useState(false);
  const [openBudgetEntriesDialog, setOpenBudgetEntriesDialog] = useState(false);
  const [editing, setEditing] = useState(false);
  const [updatingAccount, setUpdatingAccount] = useState(false);
  //
  const [newName, setNewName] = useState<string | null>(null);
  const [newType, setNewType] = useState<string | null>(null);
  const [newPrimary, setNewPrimary] = useState<string>(isPrimary || "false");

  const handlePrimaryChangeEvent = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPrimary(event.target.checked ? "true" : "false");
  };

  const toggleEditing = () => setEditing(!editing);

  const updateSuccessfulCallback = () => {
    setUpdatingAccount(false);
    setEditing(false);
  };

  const submitEditing = () => {
    setUpdatingAccount(true);
    updateAccountCall(
      SK,
      { newName, newType, newPrimary },
      updateSuccessfulCallback
    );
  };

  const cancelEditing = () => {
    setEditing(false);
    setNewName(null);
    setNewType(null);
  };

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  const renderNameCell = (mini = false) => {
    if (editing) {
      return (
        <Box>
          <TextField
            placeholder="New account name"
            variant="outlined"
            value={newName ?? accountName}
            onChange={(e) => setNewName(e.target.value)}
            sx={{ paddingTop: "10px" }}
            disabled={updatingAccount}
          />
        </Box>
      );
    }

    return (
      <Box onClick={mini ? () => {} : toggleEditing}>
        <Grid container>
          <Grid item xs={12}>
            <Typography textAlign={"center"}>{accountName}</Typography>
          </Grid>
          <Grid item>
            {isPrimary && isPrimary === "true" ? (
              <LocalParkingIcon color="info" />
            ) : null}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderActionsCell = (mini = false) => {
    if (deleteProtected) {
      return (
        <ActionButton
          key={"modify"}
          disabled={deleteProtected}
          Icon={LockIcon}
          onIconClick={() => setEditing(true)}
          tooltip={
            deleteProtected ? "Cannot modify protected accounts." : undefined
          }
        />
      );
    }

    if (editing) {
      return (
        <Box>
          <SecondaryButton onClick={submitEditing}>Update</SecondaryButton>
          <SecondaryButton onClick={cancelEditing}>Cancel</SecondaryButton>
        </Box>
      );
    }

    return [
      <ActionButton
        key={"edit"}
        disabled={deleteProtected}
        Icon={ModeEditIcon}
        onIconClick={() => setEditing(true)}
        tooltip={
          deleteProtected ? "Cannot edit protected accounts." : undefined
        }
      />,
      <ActionButton
        key={"delete"}
        disabled={deleteProtected}
        Icon={DeleteIcon}
        onIconClick={() => setOpenConfirmDialog(true)}
        tooltip={
          deleteProtected ? "Cannot delete protected accounts." : undefined
        }
      />,
      <ActionButton
        key={"list"}
        Icon={ReorderIcon}
        onIconClick={() => setOpenBudgetEntriesDialog(true)}
      />,
      <ActionButton
        key="transfer"
        Icon={CompareArrowsIcon}
        onIconClick={() => setOpenAccountTransfer(true)}
      />,
    ];
  };

  const renderPrimaryCell = (mini = false) => {
    if (editing) {
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={newPrimary === "true"}
                onChange={handlePrimaryChangeEvent}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={"Primary account"}
          ></FormControlLabel>
        </FormGroup>
      );
    }

    return <Box />;
  };

  const renderConfirmDialog = () => (
    <ConfirmActionDialog
      open={openConfirmDialog}
      setOpen={setOpenConfirmDialog}
      title={"Confirm account deletion"}
      texts={[
        "Are you sure you want to delete this account?",
        'All recorded expense/income entries will be recorded under the "Other" account.',
      ]}
      onConfirm={() => deleteAccountCall(SK)}
      onCancel={() => setOpenConfirmDialog(false)}
    />
  );

  const renderAccountTransferDialog = () => (
    <AccountTransferDialog
      open={openAccountTransfer}
      setOpen={setOpenAccountTransfer}
      onSuccess={() => toast.success("Transfer successful.")}
      onFailed={(err) => toast.error("Error while executing transfer.")}
    />
  );

  const renderBudgetEntriesDialog = () => (
    <AccountBudgetEntriesDialog
      open={openBudgetEntriesDialog}
      setOpen={setOpenBudgetEntriesDialog}
      account={account}
    />
  );

  const renderDialogs = () => [
    renderConfirmDialog(),
    renderAccountTransferDialog(),
    renderBudgetEntriesDialog(),
  ];

  const renderFullSize = () => {
    return (
      <TableRow hover={true} key={SK}>
        {/* <BudgetarToast /> */}

        {renderDialogs()}

        <TableCell align="center">{renderNameCell()}</TableCell>
        <TableCell>
          <Typography textAlign="center">{accountType}</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign="right">{f(accountBalance)}</Typography>
        </TableCell>
        <TableCell>{renderPrimaryCell()}</TableCell>
        <TableCell align="center">{renderActionsCell()}</TableCell>
      </TableRow>
    );
  };

  const renderMini = () => {
    return (
      <TableRow hover={false}>
        {renderDialogs()}

        <TableCell>
          <Card>
            <Grid container xs={12}>
              <Grid item xs={12} sx={{ paddingBottom: "10px" }}>
                {renderNameCell(true)}
              </Grid>

              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  {f(accountBalance)}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider light={true}></Divider>
              </Grid>

              <Grid item xs={12}>
                {renderActionsCell(true)}
              </Grid>
            </Grid>
          </Card>
        </TableCell>
      </TableRow>
    );
  };

  return matches ? renderFullSize() : renderMini();
};
