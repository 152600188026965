import { fetchAdminBudget } from "../../api/fetch.redux";
import { ReportingApi } from "../../api/reporting.api";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../redux/reducers/budgetSlice";
import {
  selectAverageExpensesPerMonth,
  selectSummedExpensesPerMonth,
  setAverageExpensesPerMonth,
  setLoading,
  setSummedExpensesPerMonth,
} from "../../redux/reducers/reportingSlice";
import { logout } from "../utils.api";
import { ExpensesPerPeriodChartProps } from "./ExpensesPerDayChart";
import { ItemsPerMonthChart } from "./ItemsPerMonthChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export function ExpensesPerMonthChart(props: ExpensesPerPeriodChartProps) {
  const { displayMode } = props || { displayMode: "global-average" };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: monthlyExpenses,
    loaded: monthlyExpensesLoaded,
    loading: monthlyExpensesLoading,
  } = useAppSelector(selectSummedExpensesPerMonth);
  const {
    data: averageExpensesPerMonth,
    loaded: averageExpensesPerMonthLoaded,
    loading: averageExpensesPerMonthLoading,
  } = useAppSelector(selectAverageExpensesPerMonth);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!monthlyExpensesLoaded) {
        dispatch(setLoading({ key: "summarizedExpensesPerDay", status: true }));

        ReportingApi.loadSummedExpensesPerMonth(
          budgetId,
          {
            onSuccess: (summedExpensesPerMonth) =>
              dispatch(setSummedExpensesPerMonth({ summedExpensesPerMonth })),
          },
          () => logout(dispatch, navigate),
          12
        );
      }

      if (!averageExpensesPerMonthLoaded) {
        dispatch(setLoading({ key: "averageExpensesPerMonth", status: true }));

        ReportingApi.getAverageExpensesPerMonth(
          budgetId,
          {
            onSuccess: (averageExpensesPerMonth) =>
              dispatch(setAverageExpensesPerMonth({ averageExpensesPerMonth })),
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, []);

  return (
    <ItemsPerMonthChart
      items={monthlyExpenses}
      monthPerMonthItems={averageExpensesPerMonth}
      displayMode={displayMode}
      itemsLoading={monthlyExpensesLoading}
      label="expenses"
      chartColor="darkRed"
    />
  );
}
