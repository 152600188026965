import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { EventWithTotal } from "@backend/event.type";
import { createSlice } from "@reduxjs/toolkit";

interface EventsState {
  events: SingleLoad<EventWithTotal[]>;
}

export const initialState: EventsState = {
  events: singleLoadDefaultValue([]),
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEvents: (
      state: EventsState,
      action: { payload: { events: EventWithTotal[] } }
    ) => {
      const { events } = action.payload;

      state.events = getSingleLoadResponse(events);
    },
    setEventsLoading: (
      state: EventsState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.events.loading = status;
    },
    logout: (state: EventsState) => {
      return initialState;
    },
  },
});

export const { setEvents, setEventsLoading, logout } = eventsSlice.actions;

export const eventsState = (state: RootState) => state.events;

export const selectEvents = (state: RootState): SingleLoad<EventWithTotal[]> =>
  eventsState(state).events;
export const selectEventsLoading = (state: RootState): boolean =>
  eventsState(state).events.loading;

export const EventsReducer = eventsSlice.reducer;
