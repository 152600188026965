import { fetchAdminBudget } from "../../../api/fetch.redux";
import { ReportingApi } from "../../../api/reporting.api";
import { LoadingDialog } from "../../../components/utility/dialogs/LoadingDialog";
import { logout } from "../../../components/utils.api";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../../redux/reducers/budgetSlice";
import {
  selectInvestmentsNetWorth,
  selectSummedSavingsPerMonth,
  setAllExpensesAndIncomes,
  setInvestmentsNetWorth,
  setLoading,
  setMetadata,
  setSummedSavingsPerMonth,
} from "../../../redux/reducers/reportingSlice";
import {
  selectAllExpensesAndIncomes,
  selectMetadata,
} from "../../../redux/reducers/reportingSlice";
import {
  CenterTextPlugin,
  CustomChartOptions,
} from "../../charts/chart-plugins";
import { AllExpensesAndIncomes } from "./SavingsProjectionTable";
import { useMediaQuery } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { Chart } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function SavingsMonthlyChart() {
  const matches = useMediaQuery("(min-width:400px)");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: savingsPerMonth,
    loaded: areSavingsPerMonthLoaded,
    loading: areSavingsPerMonthLoading,
  } = useAppSelector(selectSummedSavingsPerMonth);
  const {
    data: expensesAndIncomes,
    loaded: areExpensesAndIncomesLoaded,
    loading: areExpensesAndIncomesLoading,
  } = useAppSelector(selectAllExpensesAndIncomes);
  const { data: investmentsNetWorth, loaded: investmentsNetWorthLoaded } =
    useAppSelector(selectInvestmentsNetWorth);
  const {
    data: metadata,
    loaded: isMetadataLoaded,
    loading: isMetadataLoading,
  } = useAppSelector(selectMetadata);

  const firstEntry = DateTime.fromMillis(metadata.firstEntry);
  const daysSinceFirstEntry = DateTime.now().diff(firstEntry, "days").days;
  const averageDailySavings =
    (expensesAndIncomes.savingsSum + investmentsNetWorth) /
    (daysSinceFirstEntry / 30);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!investmentsNetWorthLoaded) {
        ReportingApi.getInvestmentsNetWorth(
          budgetId,
          {
            onSuccess: (investmentsNetWorth: number) =>
              dispatch(setInvestmentsNetWorth({ investmentsNetWorth })),
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!areSavingsPerMonthLoaded) {
        dispatch(setLoading({ key: "summedSavingsPerMonth", status: true }));

        ReportingApi.loadSummedSavingsPerMonth(
          budgetId,
          {
            onSuccess: (summedSavingsPerMonth: any) => {
              dispatch(setSummedSavingsPerMonth({ summedSavingsPerMonth }));
            },
          },
          () => logout(dispatch, navigate),
          12
        );
      }

      if (!areExpensesAndIncomesLoaded) {
        dispatch(setLoading({ key: "allExpensesAndIncomes", status: true }));

        ReportingApi.loadAllExpensesAndIncomes(
          budgetId,
          {
            onSuccess: (allExpensesAndIncomes: AllExpensesAndIncomes) =>
              dispatch(setAllExpensesAndIncomes(allExpensesAndIncomes)),
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!isMetadataLoaded) {
        dispatch(setLoading({ key: "metadata", status: true }));

        ReportingApi.getFirstEntryAndMonthsSince(
          budgetId,
          {
            onSuccess: (data: {
              firstEntry: any;
              monthsSinceFirstEntry: number;
            }) => {
              dispatch(setMetadata(data));
            },
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Monthly savings",
      },
    },
  };

  const labels = savingsPerMonth.map((e) => e.date);

  const data = {
    labels,
    datasets: [
      // {
      //   label: "Expenses",
      //   data: expensesPerMonth.map((e) => e.sum),
      //   backgroundColor: "darkred",
      // },
      // {
      //   label: "Incomes",
      //   data: incomesPerMonth.map((e) => e.sum),
      //   backgroundColor: "darkgreen",
      // },
      {
        label: "Savings",
        data: savingsPerMonth.map((e) => e.saving),
        backgroundColor: "orange",
        type: "bar" as const,
        order: 1,
      },
      {
        label: "Monthly average",
        data: savingsPerMonth.map((_) => averageDailySavings),
        borderColor: "white",
        type: "line" as const,
        order: 0,
      },
    ],
  };

  const props = { ...(!matches && { height: 400 }) };

  if (
    areSavingsPerMonthLoading ||
    areExpensesAndIncomesLoading ||
    isMetadataLoading
  )
    return <LoadingDialog open={true} key={uuid()} />;

  return (
    <Chart
      {...props}
      type="bar"
      options={{ ...options, ...CustomChartOptions } as any}
      data={data}
      plugins={[CenterTextPlugin as any]}
    />
  );
}
