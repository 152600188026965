import { VERSION } from "../../../util/Version";
import AboutTabs from "../../about-us/AboutTabs";
import { Button, DialogActions, DialogTitle, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export const AboutDialog = (props: {
  open: boolean;
  setOpen: (status: boolean) => void;
  hideButtons?: boolean;
}) => {
  const { open, setOpen, hideButtons } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: "center" }}>
          <Typography fontSize="20px">Welcome to Budgetar!</Typography>
          <Typography color="orange">v{VERSION}</Typography>
        </DialogTitle>
        <DialogContent>
          <AboutTabs closeDialog={handleClose} hideButtons={hideButtons} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return renderDialog();
};
