import { DateTime } from "luxon";

export function deepCopy<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export const getImageSrc = (imageName: string) =>
  `${process.env.PUBLIC_URL}/${imageName}`;

export const capitalizeWord = (word: string) => {
  if (!word.length) return "";

  const lowerCased = word.toLowerCase();

  const firstLetter = lowerCased.split("")[0];

  return `${firstLetter.toUpperCase()}${lowerCased.substring(
    1,
    lowerCased.length
  )}`;
};

/**
 * Parses the contents of an uploaded file.
 * The contents are returned via a callback.
 * @param {*} ev
 * @param {*} cb
 */
export const getUploadedFileContents = async (
  ev: { target: any; preventDefault?: any },
  cb: (data: any) => void
) => {
  ev.preventDefault();
  const reader = new FileReader();

  reader.onload = async (e) => {
    if (e && e.target) {
      const text = e.target.result;

      if (text) {
        const data = JSON.parse(text as string);

        cb(data);
      } else {
        alert("No data to load.");
      }
    }
  };

  reader.readAsText(ev.target.files[0]);
};

export function uniqueArray(arr: string[]): string[] {
  return arr.filter((val, idx, self) => self.indexOf(val) === idx);
}

const colors = [
  "255,0,0",
  "0,128,0",
  "0,0,128",
  "255,255,0",
  "0,255,255",
  "128,0,128",
  "255,218,185",
  "210,105,30",
  "100,149,237",
  "0,100,0",
  "75,0,130",
];

export function colorGenerator(neededColorsNr: number) {
  const rgbColors = colors.map((e) => `rgba(${e},0.2)`);

  return rgbColors.slice(0, neededColorsNr);
}

export function borderColorGenerator(neededColorsNr: number) {
  const rgbStrongColors = colors.map((e) => `rgba(${e},0.1)`);

  return rgbStrongColors.slice(0, neededColorsNr);
}

export function dateTimeToMonthYear(date: string) {
  const { year, month } = DateTime.fromISO(date);

  return `${year}-${padNumber(month)}`;
}

export function padNumber(n: number) {
  if (n < 10) return `0${n}`;

  return n;
}

export function generateMonthYearArray(
  starting: string | DateTime,
  months: number
) {
  const startingDate =
    starting instanceof DateTime ? starting : DateTime.fromISO(starting);

  const dates = [dateTimeToMonthYear(startingDate.toISODate())];

  for (let i = 1; i < months; i++) {
    const date = startingDate.minus({ months: i });

    dates.push(dateTimeToMonthYear(date.toISODate()));
  }

  return dates;
}

export function sortString(a: string, b: string, direction: "ASC" | "DESC") {
  if (direction === "ASC") return a.localeCompare(b);

  return b.localeCompare(a);
}

export function sortNumber(a: number, b: number, direction: "ASC" | "DESC") {
  const A = a ?? 0;
  const B = b ?? 0;

  if (direction === "ASC") return A - B;

  return B - A;
}

export function sortValues(
  a: number | string,
  b: number | string,
  direction: "ASC" | "DESC"
) {
  if (typeof a !== typeof b) throw new Error("Cannot compare different types.");

  if (typeof a === "number") return sortNumber(a, b as number, direction);

  return sortString(a, b as string, direction);
}

export function optionalTruncate(str: string, limit?: number) {
  if (!limit) return str;

  if (str.length > limit) return `${str.substring(0, limit)}...`;

  return str;
}

export async function delay(ms: number = 1000, cb?: () => void) {
  new Promise((resolve) => setTimeout(resolve, ms)).then(() =>
    cb ? cb() : null
  );
}

export function appendDaySuffix(number: number): string {
  const j = number % 10;
  const k = number % 100;

  if (j === 1 && k !== 11) {
    return number + "st";
  }
  if (j === 2 && k !== 12) {
    return number + "nd";
  }
  if (j === 3 && k !== 13) {
    return number + "rd";
  }
  return number + "th";
}

export function shiftArrayElements<T>(arr: T[], shiftIndex: number): T[] {
  const length = arr.length;
  const normalizedShiftIndex = shiftIndex % length;

  if (normalizedShiftIndex === 0) {
    return arr;
  }

  const shiftedArr = [
    ...arr.slice(length - normalizedShiftIndex),
    ...arr.slice(0, length - normalizedShiftIndex),
  ];
  return shiftedArr;
}
