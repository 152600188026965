import { SecondaryButton } from "./utility/buttons/SecondaryButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Typography, Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

export const PremiumFeatureDisclaimer = () => {
  const navigate = useNavigate();

  return (
    <Grid
      item
      xs={12}
      textAlign={"center"}
      sx={{ paddingTop: "50px", paddingBottom: "50px" }}
    >
      <IconButton>
        <StarBorderIcon color="info" />
      </IconButton>

      <Typography fontSize={20}>This is a Premium feature.</Typography>
      <Typography fontSize={20}>
        To access it, please consider upgrading your subscription tier.
      </Typography>

      <Box sx={{ paddingTop: "20px" }}>
        <SecondaryButton onClick={() => navigate("/settings")}>
          To subscriptions
        </SecondaryButton>
      </Box>
    </Grid>
  );
};
