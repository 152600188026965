import { Box, Slider, Typography, TextField, Divider } from "@mui/material";
import { useEffect } from "react";

interface CreditInterestCalculatorProps {
  interestRate: number;
  setInterestRate: (newValue: number) => void;
  principal: number;
  setprincipal: (newValue: number) => void;
  repaymentMonths: number;
  setRepaymentMonths: (newValue: number) => void;
  monthlyPayment: number;
  setMonthlyPayment: (newValue: number) => void;
}
export const CreditInterestCalculator = (
  props: CreditInterestCalculatorProps
) => {
  const {
    interestRate,
    setInterestRate,
    principal,
    setprincipal,
    repaymentMonths,
    setRepaymentMonths,
    monthlyPayment,
    setMonthlyPayment,
  } = props;

  const calculateMonthlyPayment = () => {
    if (!interestRate) return principal / repaymentMonths;

    const monthlyInterestRate = interestRate / 100 / 12;
    const totalInterestPaid = principal * monthlyInterestRate * repaymentMonths;

    return (principal + totalInterestPaid) / repaymentMonths;
  };

  useEffect(() => {
    setMonthlyPayment(calculateMonthlyPayment());
  }, [interestRate, principal, repaymentMonths]);

  const handleInterestRateChange = (event: any) => {
    setInterestRate(event.target.value);
  };
  const handlePrincipalChange = (event: any) => {
    const value = event.target.value;
    setprincipal(value !== "" ? Number(value) : value);
  };

  const handleMonthlyRepaymentsChange = (event: any) => {
    setRepaymentMonths(event.target.value);
  };

  return (
    <Box
      maxWidth="400px"
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
    >
      <Box sx={{ paddingTop: "20px" }}>
        <Typography>Monthly repayments - {repaymentMonths}</Typography>
        <Slider
          min={1}
          max={36}
          step={1}
          value={repaymentMonths}
          onChange={handleMonthlyRepaymentsChange}
        />
      </Box>

      <Box sx={{ paddingTop: "20px" }}>
        <TextField
          label="Principal"
          variant="outlined"
          onChange={handlePrincipalChange}
          type="number"
          value={principal}
        />
      </Box>

      <Box sx={{ paddingTop: "20px" }}>
        <Typography>Interest rate p.a. - {interestRate}%</Typography>
        <Slider
          min={0}
          max={20}
          step={0.5}
          value={interestRate}
          onChange={handleInterestRateChange}
        />
      </Box>

      <Divider />

      <Typography textAlign="center">Monthly payment</Typography>
      <Typography textAlign="center" fontSize={20} color="orange">
        {monthlyPayment.toFixed(2)}
      </Typography>
      <Typography textAlign="center" fontSize={16} color="darkgray">
        Total to repay:
      </Typography>
      <Typography textAlign="center" fontSize={16} color="darkgray">
        {(monthlyPayment * repaymentMonths).toFixed(2)}
      </Typography>
    </Box>
  );
};
