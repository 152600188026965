import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV017 = () => {
  return (
    <Release version="v0.1.7" releaseDate="2023-04-14">
      <ReleaseNotesSection title="Asset">
        <ReleaseNotesEntry>Add new asset</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add asset price at date</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Accounts">
        <ReleaseNotesEntry>Declare primary account</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Reporting">
        <ReleaseNotesEntry>
          Add assets value to existing net-worth calculations
        </ReleaseNotesEntry>
        <ReleaseNotesEntry>Net-worth per-month chart</ReleaseNotesEntry>
        <ReleaseNotesEntry>
          Essential category spending per month
        </ReleaseNotesEntry>
        <ReleaseNotesEntry>Improve existing charts</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
