import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { SingleLoansTableRow } from "./SingleLoansTableRow";
import { LoanPopulated, LoanStatus } from "@backend/loan.type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";

export const LoansTable = (props: { loans: LoanPopulated[] }) => {
  const matches = useMediaQuery("(min-width:760px)");

  const { loans } = props;

  // const deleteLoan = (loanSK: string) => {
  //   LoanAPI.deleteLoan(
  //     { loanSK },
  //     {
  //       onSuccess: () => {
  //         toast.success("Loan deleted.");
  //         loadLoans(dispatch);
  //       },
  //       onFailed: (err) => toast.error(err),
  //     }
  //   );
  // };

  const mappedItems = loans
    .filter((e) => e.status == LoanStatus.OPEN)
    .map((loan) => (
      <SingleLoansTableRow
        key={loan.SK}
        loan={loan}
        // deleteLoanCall={deleteLoan}
        matches={matches}
      />
    ));

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <BudgetarToast />
      <TableContainer component={Paper}>
        <Table aria-label="loans table">
          <TableHead>
            {matches ? (
              <TableRow hover={true}>
                <TableCell>
                  <Typography textAlign="center">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Principal</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Interest rate</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Months</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Monthly payments</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Total to re-pay</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Remaining</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Actions</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>{mappedItems}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
