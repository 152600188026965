import { useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../util/Formatter";
import { ActionButton } from "../common/ActionButton";
import { Goal } from "@backend/goal.type";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableCell, TableRow, Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";

export const SingleGoalsTableRow = (props: {
  goal: Goal;
  deleteGoalCall: (goalSK: string) => void;
}) => {
  const { goal, deleteGoalCall } = props;
  const { SK, goalName, amount, completedOn } = goal;

  const currency = useAppSelector(selectCurrency);

  const format = (n: number) => formatNumberDispatch(n, currency);

  const CompletedGoal = () => (
    <TableRow key={SK} sx={{ backgroundColor: "lightgray" }}>
      <TableCell>
        <Box>
          <Grid container>
            <Grid item>
              <Typography textAlign={"left"}>{goalName}</Typography>
            </Grid>
            <Grid item>
              <CheckIcon color="info" />
            </Grid>
          </Grid>
        </Box>
      </TableCell>
      <TableCell align="right">{format(amount)}</TableCell>
      <TableCell align="right">
        {completedOn ? DateTime.fromMillis(completedOn).toISODate() : null}
      </TableCell>
      <TableCell align="center">
        {/* <ActionButton
          Icon={DeleteIcon}
          onIconClick={() => deleteGoalCall(SK)}
        /> */}
      </TableCell>
    </TableRow>
  );

  const IncompletedGoal = () => (
    <TableRow hover={true} key={SK}>
      <TableCell>
        <Typography textAlign={"left"}>{goalName}</Typography>
      </TableCell>
      <TableCell align="right">{format(amount)}</TableCell>
      <TableCell align="right"></TableCell>
      <TableCell align="center">
        <ActionButton
          Icon={DeleteIcon}
          onIconClick={() => deleteGoalCall(SK)}
        />
      </TableCell>
    </TableRow>
  );

  return completedOn ? <CompletedGoal /> : <IncompletedGoal />;
};
