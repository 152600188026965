export enum Currency {
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP",
  CHF = "CHF",
  CAD = "CAD",
}

export interface ExchangeRate {
  from: Currency;
  to: Currency;
  rate: number;
  midday: number;
}
