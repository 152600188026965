import { Callbacks } from "../../types/util";
import { postRequest, getRequest } from "../index";
import { AddExpenseArgs, UpdateExpenseInterface } from "@backend/expense.type";

const getExpenseUrl = (route: string, budgetId: string) =>
  `${budgetId}/expense/${route}`;

async function addExpense(
  budgetId: string,
  args: AddExpenseArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getExpenseUrl("add", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export interface UpdateExpenseArgs extends UpdateExpenseInterface {}
async function updateExpense(
  budgetId: string,
  args: UpdateExpenseArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getExpenseUrl("update", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface DeleteExpenseArgs {
  expenseSK: string;
}
async function deleteExpense(
  budgetId: string,
  args: DeleteExpenseArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getExpenseUrl("delete", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getExpenses(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/expenses`, logoutCallback, onSuccess, onFailed);
}

interface MoveExpenseToCategory {
  itemSK: string;
  targetCategorySK: string;
}

async function moveExpenseToCategory(
  budgetId: string,
  args: MoveExpenseToCategory,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getExpenseUrl("move/category", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export const ExpenseAPI = {
  addExpense,
  deleteExpense,
  getExpenses,
  moveExpenseToCategory,
  updateExpense,
};
