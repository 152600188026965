export interface SingleLoad<T> {
  data: T;
  loaded: boolean;
  loading: boolean;
}

export function singleLoadDefaultValue<T>(data: T) {
  return {
    data,
    loaded: false,
    loading: false,
  };
}

export function getSingleLoadResponse<T>(
  obj: T,
  loading: boolean = false
): {
  data: T;
  loaded: boolean;
  loading: boolean;
} {
  return { data: obj, loaded: true, loading };
}
