import { Period } from "@backend/period.type";
import { GenericDropdown } from "./GenericDropdown";

export const PeriodDropdown = (props: { periodSelected: any }) => {
  const { periodSelected } = props;

  const periods = [Period.MONTHLY, Period.BIWEEKLY, Period.WEEKLY];

  return (
    <GenericDropdown
      label="Period"
      options={periods}
      onSelection={periodSelected}
    />
  );
};
