import { fetchMortgages } from "../../api/fetch.redux";
import { MortgageAPI } from "../../api/mortgages.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/reducers/userSlice";
import { Mortgage } from "../../types";
import { formatNumberDispatch } from "../../util/Formatter";
import { ActionButton } from "../common/ActionButton";
import { NewMortgagePaymentDialog } from "../utility/dialogs/NewMortgagePaymentDialog";
import { getBudgetId } from "../utils.api";
import PaymentIcon from "@mui/icons-material/Payment";
import { TableCell, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { toast } from "react-toastify";

export const SingleMortgagesTableRow = (props: {
  mini: boolean;
  mortgage: Mortgage;
}) => {
  const { mortgage, mini } = props;
  const {
    mortgage: {
      monthlyPayment,
      interestRatePerAnnum,
      principal,
      termMonths,
      totalOutstanding,
      totalInterest,
    },
    summary,
  } = mortgage;

  const [openMortgagePaymentDialog, setOpenMortgagePaymentDialog] =
    useState(false);

  const appDispatch = useAppDispatch();

  const budgetId = getBudgetId(useAppSelector);
  const currency = useAppSelector(selectCurrency);

  const format = (n: number) => formatNumberDispatch(n, currency);

  const renderAddMortgagePaymentDialog = () => (
    <NewMortgagePaymentDialog
      mortgage={mortgage.mortgage}
      open={openMortgagePaymentDialog}
      setOpen={setOpenMortgagePaymentDialog}
      action={MortgageAPI.addMortgagePayment}
      onSuccess={() => {
        toast.success("Mortgage payment added.");
        setOpenMortgagePaymentDialog(false);
        fetchMortgages(budgetId, appDispatch, false);
      }}
      onFailed={() => {
        toast.error("Could not add loan payment.");
        setOpenMortgagePaymentDialog(false);
      }}
    />
  );

  const renderActionButtons = (mini = false) => [
    <ActionButton
      key="payment"
      Icon={PaymentIcon}
      onIconClick={() => setOpenMortgagePaymentDialog(true)}
      tooltip="Add loan payment"
    />,
  ];

  return (
    <TableRow key={totalOutstanding}>
      <TableCell align="center">
        <Box>
          <Typography textAlign={"center"}>{format(monthlyPayment)}</Typography>
        </Box>
      </TableCell>

      <TableCell align="center">
        <Box>
          <Typography textAlign={"center"}>{format(principal)}</Typography>
        </Box>
      </TableCell>

      <TableCell align="center">
        <Box>
          <Typography>{termMonths} months</Typography>
        </Box>
      </TableCell>

      <TableCell align="center">
        <Box>
          <Typography>{interestRatePerAnnum}%</Typography>
        </Box>
      </TableCell>

      <TableCell align="center">
        <Box>
          <Typography textAlign={"center"}>{format(totalInterest)}</Typography>
        </Box>
      </TableCell>

      <TableCell align="center">
        <Box>
          <Typography textAlign={"center"}>
            {format(totalOutstanding)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="center">
        <Box>
          <Typography textAlign={"center"}>
            {format(summary.remainingTotal)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="center">{...renderActionButtons()}</TableCell>

      {renderAddMortgagePaymentDialog()}
    </TableRow>
  );
};
