import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV016 = () => {
  return (
    <Release version="v0.1.6" releaseDate="2023-01-08">
      <ReleaseNotesSection title="Accounts">
        <ReleaseNotesEntry>Add discretionary account type</ReleaseNotesEntry>
        <ReleaseNotesEntry>
          Add automated discr. account expense sorting
        </ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Categories">
        <ReleaseNotesEntry>
          Essential & non-essential categories
        </ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Expenses reporting">
        <ReleaseNotesEntry>Add yearly reports</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add expenses-per-week chart</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
