import { Box, Typography } from "@mui/material";
import React from "react";

export type CollapsibleComponentProps = {
  title: string;
  children: any;
};

const ReleaseNotesSection: React.FC<CollapsibleComponentProps> = ({
  title,
  children,
}) => {
  return (
    <Box>
      <Typography fontWeight={500} fontSize={"18px"}>
        {title}
      </Typography>

      {children}
    </Box>
  );
};

export default ReleaseNotesSection;
