import { dateSorter } from "../../imported-logic/common";
import { useAppSelector } from "../../redux/hooks";
import {
  selectEvolutionData,
  selectInvestmentsCapitalEvolutionData,
} from "../../redux/reducers/reportingSlice";
import { uniqueArray } from "../../util/Functions";
import { CustomChartOptions, CenterTextPlugin } from "../charts/chart-plugins";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { DateTime } from "luxon";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const InvestmentsPriceEvolutionGraph = () => {
  const {
    data: { dates, investmentsData },
  } = useAppSelector(selectEvolutionData);
  const {
    data: { dates: capitalDates, investmentsCapitalData },
  } = useAppSelector(selectInvestmentsCapitalEvolutionData);

  const numberOfDataPoints = investmentsData.length
    ? investmentsData[0].netWorths.length
    : 0;

  const sums = [];
  for (let i = 0; i < numberOfDataPoints; i++) {
    sums.push(investmentsData.reduce((acc, e) => acc + e.netWorths[i], 0));
  }

  const commonDates = uniqueArray([...dates, ...capitalDates]).sort(dateSorter);

  let capitalDataToUse = [...investmentsCapitalData];
  let investmentsDataToUse = [...sums];
  if (dates.length && capitalDates.length) {
    if (commonDates.length > capitalDates.length) {
      commonDates.forEach((date) => {
        if (!capitalDates.includes(date)) {
          const firstGreaterIdx = capitalDates.findIndex(
            (e) =>
              DateTime.fromISO(e).toMillis() -
                DateTime.fromISO(date).toMillis() >
              0
          );

          if (firstGreaterIdx === -1) {
            capitalDataToUse.splice(0, 0, 0);
          } else {
            capitalDataToUse.splice(
              firstGreaterIdx,
              0,
              capitalDataToUse[firstGreaterIdx - 1]
            );
          }
        }
      });
    }

    if (commonDates.length > dates.length) {
      commonDates.forEach((date) => {
        if (!dates.includes(date)) {
          const firstGreaterIdx = dates.findIndex(
            (e) =>
              DateTime.fromISO(e).toMillis() -
                DateTime.fromISO(date).toMillis() >
              0
          );

          if (firstGreaterIdx === -1) {
            investmentsDataToUse.splice(0, 0, 0);
          } else {
            investmentsDataToUse.splice(
              firstGreaterIdx,
              0,
              investmentsDataToUse[firstGreaterIdx - 1]
            );
          }
        }
      });
    }
  }

  const datasets = [
    {
      label: "Market value",
      data: investmentsDataToUse,
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    },
    {
      label: "Capital",
      data: capitalDataToUse,
      fill: false,
      borderColor: "green",
      tension: 0.1,
    },
  ];

  const labels = commonDates;
  const data = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Investments evolution",
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        display: true,
        position: "right" as const,
      },
    },
  };

  return (
    <Line
      data={data}
      options={{ ...options, ...CustomChartOptions } as any}
      plugins={[CenterTextPlugin as any]}
    />
  );
};
