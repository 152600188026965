import { subAppTheme } from "../../Theme";
import { ThemeContext } from "../../ThemeContext";
import {
  fetchAccessibleBudgets,
  fetchAdminBudget,
} from "../../api/fetch.redux";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { selectAccessibleBudgets } from "../../redux/reducers/accessRoleSlice";
import {
  selectAdminBudgetId,
  setBudgetInUse,
} from "../../redux/reducers/budgetSlice";
import { delay } from "../../util/Functions";
import { ActionButton } from "../common/ActionButton";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { AccessRoleItem } from "@backend/access-role.type";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { Card, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface AccessibleBudgetsProps {
  reload: (budgetId: string) => void;
}

export const AccessibleBudgets = (props: AccessibleBudgetsProps) => {
  const { reload } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ctx = useContext(ThemeContext);

  const { data: accessibleBudgets, loaded: areAccessibleBudgetsLoaded } =
    useAppSelector(selectAccessibleBudgets);
  const { data: adminBudgetId, loaded: isAdminBudgetIdLoaded } =
    useAppSelector(selectAdminBudgetId);

  const switchToBudget = async (budgetId: string, granterEmail: string) => {
    dispatch(setBudgetInUse({ budget: { budgetId, granterEmail } }));
    reload(budgetId);

    toast.info("Switching budget...");

    await delay(2000, () => {
      ctx.setTheme(subAppTheme);
      navigate("/");
    });
  };

  const renderAccessibleBudget = (accessibleBudget: AccessRoleItem) => (
    <Grid
      container
      xs={12}
      sx={{ direction: "row", justify: "flex-end", alignItems: "center" }}
    >
      <BudgetarToast />

      <Grid item xs={8} alignItems={"center"}>
        <Card>{accessibleBudget.granterEmail}'s</Card>
      </Grid>
      <Grid item xs={2}>
        <ActionButton
          size="small"
          Icon={SwitchAccountIcon}
          tooltip="Switch to"
          onIconClick={() =>
            switchToBudget(
              accessibleBudget.budgetId,
              accessibleBudget.granterEmail
            )
          }
        />
      </Grid>
    </Grid>
  );

  const items = accessibleBudgets
    .filter((e) => e.budgetId !== adminBudgetId)
    .map(renderAccessibleBudget);

  useEffect(() => {
    fetchAccessibleBudgets(dispatch, areAccessibleBudgetsLoaded);
    fetchAdminBudget(dispatch, isAdminBudgetIdLoaded);
  }, []);

  if (areAccessibleBudgetsLoaded && !items.length) {
    return <Box>No shared budgets.</Box>;
  }

  return (
    <Box sx={{ padding: "10px" }}>
      <Grid xs={8}>
        <Typography>Accessible budgets:</Typography>
      </Grid>
      <Box>{items}</Box>
    </Box>
  );
};
