import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV051 = (collapsedOnStart: boolean = true) => {
  return (
    <Release
      version="v0.5.1"
      releaseDate="2023-06-24"
      collapsedOnStart={collapsedOnStart}
    >
      <ReleaseNotesSection title="Investments">
        <ReleaseNotesEntry>Allow selling investments</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Reporting">
        <ReleaseNotesEntry>
          Add average expense per day of week
        </ReleaseNotesEntry>
        <ReleaseNotesEntry>
          Add average expense per month of year
        </ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="UI/UX">
        <ReleaseNotesEntry>
          Add more icons and graphical elements
        </ReleaseNotesEntry>
        <ReleaseNotesEntry>Update homepage logo</ReleaseNotesEntry>
        <ReleaseNotesEntry>Update release notes mechanism</ReleaseNotesEntry>
        <ReleaseNotesEntry>Minor fixes</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
