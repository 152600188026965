import { CategoriesReportingPeriod } from "../../components/reporting/categories/CategoriesReporting";
import { padNumber } from "../../util/Functions";
import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { Currency } from "@backend/currency.type";
import {
  InvestmentsCapitalInvestedEvolutionGraphProps,
  InvestmentsPriceEvolutionGraphProps,
  SummarizedInvestments,
} from "@backend/investment.type";
import {
  SavingsSummaryInterface,
  EmptySavingsSummaryInterface,
  MonthlyExpenses,
  MonthlyIncomes,
  MonthlySaving,
  YearlyReport,
  NetWorthInterface,
  CurrentMonthOverview,
  ReportsMetadataResponse,
  MonthlyReport,
} from "@backend/reporting.type";
import { TrendsProjectionsSummarized } from "@backend/trend.type";
import { createSlice, current } from "@reduxjs/toolkit";
import { random } from "mathjs";
import { AllExpensesAndIncomes } from "src/components/reporting/savings/SavingsProjectionTable";

export interface SavingsSummary extends SavingsSummaryInterface {}

interface ReportingCategoriesInterface {
  reportingCategoriesExpense: any[];
  reportingCategoriesIncome: any[];
  targetEssentialExpensesPerMonth: number;
}

interface ReportingState {
  savingsSummary: SingleLoad<SavingsSummary>;
  summedExpensesPerMonth: SingleLoad<MonthlyExpenses[]>;
  summedIncomesPerMonth: SingleLoad<MonthlyIncomes[]>;
  summedSavingsPerMonth: SingleLoad<MonthlySaving[]>;
  //
  investmentsEvolutionData: SingleLoad<InvestmentsPriceEvolutionGraphProps>;
  investmentsCapitalEvolutionData: SingleLoad<InvestmentsCapitalInvestedEvolutionGraphProps>;
  summarizedExpensesPerDay: SingleLoad<number[]>;
  summarizedExpensesPerWeek: SingleLoad<number[]>;
  summarizedInvestments: SingleLoad<SummarizedInvestments>;
  trendsProjection: SingleLoad<TrendsProjectionsSummarized>;
  allExpensesAndIncomes: SingleLoad<AllExpensesAndIncomes>;
  investmentsNetWorth: SingleLoad<number>;
  netWorth: SingleLoad<NetWorthInterface>;
  netWorthPerMonth: SingleLoad<{ date: number; netWorth: number }[]>;
  savingsEstimates: SingleLoad<{
    6: number;
    12: number;
    60: number;
    120: number;
  }>;
  //
  netSavingsPerMonthTotal: SingleLoad<number>;
  expensesSum: SingleLoad<number>;
  incomesSum: SingleLoad<number>;
  savingsSum: SingleLoad<number>;
  //
  yearlyReports: Record<number, SingleLoad<YearlyReport>>;
  monthlyReports: Record<string, SingleLoad<MonthlyReport>>;
  reportsMetadata: SingleLoad<ReportsMetadataResponse>;
  //
  //
  reportingCategories: {
    "All-time": SingleLoad<ReportingCategoriesInterface>;
    "Last month": SingleLoad<ReportingCategoriesInterface>;
    "Last 3 months": SingleLoad<ReportingCategoriesInterface>;
    "Last 6 months": SingleLoad<ReportingCategoriesInterface>;
    "Last year": SingleLoad<ReportingCategoriesInterface>;
  };
  metadata: SingleLoad<{
    firstEntry: number;
    monthsSinceFirstEntry: number;
  }>;
  target: SingleLoad<{
    monthlySavings: number;
  }>;
  targetMonthlyExpenses: SingleLoad<number>;
  targetMonthlyIncomes: SingleLoad<number>;
  savingsToday: SingleLoad<number>;
  currentMonthOverview: SingleLoad<CurrentMonthOverview>;
  averageExpensesPerWeekDay: SingleLoad<
    Record<string, { averageExpensesPerDay: number }>
  >;
  averageExpensesPerMonth: SingleLoad<
    Record<string, { averageExpensesPerMonth: number }>
  >;
}

export type SavingsEstimateMonths = 6 | 10 | 60 | 120;

export const initialState: ReportingState = {
  savingsSummary: singleLoadDefaultValue(EmptySavingsSummaryInterface),
  summedExpensesPerMonth: singleLoadDefaultValue([]),
  summedIncomesPerMonth: singleLoadDefaultValue([]),
  summedSavingsPerMonth: singleLoadDefaultValue([]),
  //
  investmentsEvolutionData: singleLoadDefaultValue({
    dates: [],
    investmentsData: [],
  }),
  investmentsCapitalEvolutionData: singleLoadDefaultValue({
    dates: [],
    investmentsCapitalData: [],
  }),
  summarizedExpensesPerDay: singleLoadDefaultValue([0, 0, 0, 0, 0, 0, 0]),
  summarizedExpensesPerWeek: singleLoadDefaultValue([0, 0, 0]),
  trendsProjection: singleLoadDefaultValue({
    expensesTrend: 0,
    savingsTrend: 0,
    lastMonthExpenses: 0,
    lastMonthSavings: 0,
    currentMonthExpenses: 0,
    currentMonthSavings: 0,
    projectedMonthExpenses: 0,
    projectedMonthSavings: 0,
  }),
  summarizedInvestments: singleLoadDefaultValue({
    totalCapitalInvested: 0,
    totalCurrentValue: 0,
    totalFees: 0,
    currentValue: 0,
    totalValueDelta: 0,
    totalExitFees: 0,
    reportingInvestments: [],
  }),
  allExpensesAndIncomes: singleLoadDefaultValue({
    expensesSum: 0,
    incomesSum: 0,
    savingsSum: 0,
  }),
  investmentsNetWorth: singleLoadDefaultValue(0),
  netWorth: singleLoadDefaultValue({
    loans: 0,
    accounts: 0,
    investments: 0,
    assets: 0,
    credits: 0,
    mortgages: 0,
    other: 0,
  }),
  netWorthPerMonth: singleLoadDefaultValue([]),
  savingsEstimates: singleLoadDefaultValue({ 6: 0, 12: 0, 60: 0, 120: 0 }),
  //
  netSavingsPerMonthTotal: singleLoadDefaultValue(0),
  expensesSum: singleLoadDefaultValue(0),
  incomesSum: singleLoadDefaultValue(0),
  savingsSum: singleLoadDefaultValue(0),
  //
  yearlyReports: {},
  monthlyReports: {},
  reportsMetadata: singleLoadDefaultValue({ yearly: [], monthly: [] }),
  //
  //
  reportingCategories: {
    "All-time": singleLoadDefaultValue({
      reportingCategoriesIncome: [],
      reportingCategoriesExpense: [],
      targetEssentialExpensesPerMonth: 0,
    }),
    "Last month": singleLoadDefaultValue({
      reportingCategoriesIncome: [],
      reportingCategoriesExpense: [],
      targetEssentialExpensesPerMonth: 0,
    }),
    "Last 3 months": singleLoadDefaultValue({
      reportingCategoriesIncome: [],
      reportingCategoriesExpense: [],
      targetEssentialExpensesPerMonth: 0,
    }),
    "Last 6 months": singleLoadDefaultValue({
      reportingCategoriesIncome: [],
      reportingCategoriesExpense: [],
      targetEssentialExpensesPerMonth: 0,
    }),
    "Last year": singleLoadDefaultValue({
      reportingCategoriesIncome: [],
      reportingCategoriesExpense: [],
      targetEssentialExpensesPerMonth: 0,
    }),
  },
  metadata: singleLoadDefaultValue({
    firstEntry: 0,
    monthsSinceFirstEntry: 0,
  }),
  target: singleLoadDefaultValue({
    monthlySavings: 0,
    monthlyExpenses: 0,
    monthlyIncomes: 0,
  }),
  targetMonthlyExpenses: singleLoadDefaultValue(0),
  targetMonthlyIncomes: singleLoadDefaultValue(0),
  savingsToday: singleLoadDefaultValue(0),
  currentMonthOverview: singleLoadDefaultValue<CurrentMonthOverview>({
    currentMonthExpense: 0,
    currentMonthIncome: 0,
  }),
  averageExpensesPerWeekDay: singleLoadDefaultValue({}),
  averageExpensesPerMonth: singleLoadDefaultValue({}),
};

export const reportingSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    setSavingsSummary: (
      state: ReportingState,
      action: { payload: { savingsSummary: SavingsSummary } }
    ) => {
      const { savingsSummary } = action.payload;

      state.savingsSummary = getSingleLoadResponse(savingsSummary);
    },
    setSummedExpensesPerMonth: (
      state: ReportingState,
      action: { payload: { summedExpensesPerMonth: MonthlyExpenses[] } }
    ) => {
      const { summedExpensesPerMonth } = action.payload;

      state.summedExpensesPerMonth = getSingleLoadResponse(
        summedExpensesPerMonth
      );
    },
    setSummedIncomesPerMonth: (
      state: ReportingState,
      action: { payload: { summedIncomesPerMonth: MonthlyIncomes[] } }
    ) => {
      const { summedIncomesPerMonth } = action.payload;

      state.summedIncomesPerMonth = getSingleLoadResponse(
        summedIncomesPerMonth
      );
    },
    setSummedSavingsPerMonth: (
      state: ReportingState,
      action: { payload: { summedSavingsPerMonth: MonthlySaving[] } }
    ) => {
      const { summedSavingsPerMonth } = action.payload;

      state.summedSavingsPerMonth = getSingleLoadResponse(
        summedSavingsPerMonth
      );
    },
    //
    setInvestmentsEvolutionData: (
      state: ReportingState,
      action: {
        payload: InvestmentsPriceEvolutionGraphProps;
      }
    ) => {
      const investmentsEvolutionData = action.payload;

      state.investmentsEvolutionData = getSingleLoadResponse(
        investmentsEvolutionData
      );
    },
    setInvestmentsCapitalEvolutionData: (
      state: ReportingState,
      action: {
        payload: InvestmentsCapitalInvestedEvolutionGraphProps;
      }
    ) => {
      const investmentsCapitalEvolutionData = action.payload;

      state.investmentsCapitalEvolutionData = getSingleLoadResponse(
        investmentsCapitalEvolutionData
      );
    },
    setSummarizedExpensesPerDay: (
      state: ReportingState,
      action: {
        payload: { summarizedExpensesPerDay: number[] };
      }
    ) => {
      const { summarizedExpensesPerDay } = action.payload;

      state.summarizedExpensesPerDay = getSingleLoadResponse(
        summarizedExpensesPerDay
      );
    },
    setSummarizedExpensesPerWeek: (
      state: ReportingState,
      action: {
        payload: { summarizedExpensesPerWeek: number[] };
      }
    ) => {
      const { summarizedExpensesPerWeek } = action.payload;

      state.summarizedExpensesPerWeek = getSingleLoadResponse(
        summarizedExpensesPerWeek
      );
    },
    setTrendsProjection: (
      state: ReportingState,
      action: {
        payload: { trendsProjection: TrendsProjectionsSummarized };
      }
    ) => {
      const { trendsProjection } = action.payload;

      state.trendsProjection = getSingleLoadResponse(trendsProjection);
    },
    setSummarizedInvestments: (
      state: ReportingState,
      action: {
        payload: { summarizedInvestments: SummarizedInvestments };
      }
    ) => {
      const { summarizedInvestments } = action.payload;

      state.summarizedInvestments = getSingleLoadResponse(
        summarizedInvestments
      );
    },
    setAllExpensesAndIncomes: (
      state: ReportingState,
      action: {
        payload: AllExpensesAndIncomes;
      }
    ) => {
      state.allExpensesAndIncomes = getSingleLoadResponse(action.payload);
    },
    setInvestmentsNetWorth: (
      state: ReportingState,
      action: {
        payload: { investmentsNetWorth: number };
      }
    ) => {
      state.investmentsNetWorth = getSingleLoadResponse(
        action.payload.investmentsNetWorth
      );
    },
    setNetworth: (
      state: ReportingState,
      action: {
        payload: { netWorth: NetWorthInterface };
      }
    ) => {
      state.netWorth = getSingleLoadResponse(action.payload.netWorth);
    },
    setDefaultNetworth: (state: ReportingState) => {
      state.netWorth = getSingleLoadResponse({
        accounts: random(0, 100),
        loans: random(0, 100),
        investments: random(0, 100),
        assets: random(0, 100),
        credits: random(0, 100),
        mortgages: random(0, 100),
        other: random(0, 100),
      });
    },
    setNetworthPerMonth: (
      state: ReportingState,
      action: {
        payload: { netWorthPerMonth: { date: number; netWorth: number }[] };
      }
    ) => {
      state.netWorthPerMonth = getSingleLoadResponse(
        action.payload.netWorthPerMonth
      );
    },
    setSavingsEstimate: (
      state: ReportingState,
      action: {
        payload: { months: SavingsEstimateMonths; estimate: number };
      }
    ) => {
      const { months, estimate } = action.payload;

      const oldEstimate = current(state).savingsEstimates.data;
      const newEstimate = { ...oldEstimate, [months]: estimate };

      state.savingsEstimates = getSingleLoadResponse(newEstimate);
    },
    //
    setNetSavingsPerMonthTotal: (
      state: ReportingState,
      action: { payload: { netSavingsPerMonthTotal: number } }
    ) => {
      const { netSavingsPerMonthTotal } = action.payload;

      state.netSavingsPerMonthTotal = getSingleLoadResponse(
        netSavingsPerMonthTotal
      );
    },
    setYearlyReport: (
      state: ReportingState,
      action: { payload: { year: number; yearlyReport: YearlyReport } }
    ) => {
      const { year, yearlyReport } = action.payload;
      const oldYearlyReports = current(state).yearlyReports;

      state.yearlyReports = {
        ...oldYearlyReports,
        [year]: { data: yearlyReport, loaded: true, loading: false },
      };
    },
    setMonthlyReport: (
      state: ReportingState,
      action: {
        payload: { month: number; year: number; monthlyReport: MonthlyReport };
      }
    ) => {
      const { month, year, monthlyReport } = action.payload;
      const oldMonthlyReports = current(state).monthlyReports;

      state.monthlyReports = {
        ...oldMonthlyReports,
        [`${year}-${padNumber(month)}`]: {
          data: monthlyReport,
          loaded: true,
          loading: false,
        },
      };
    },
    setDefaultMonthlyReport: (
      state: ReportingState,
      action: { payload: { month: number; year: number } }
    ) => {
      const { month, year } = action.payload;
      const oldMonthlyReports = current(state).monthlyReports;

      state.monthlyReports = {
        ...oldMonthlyReports,
        [`${year}-${padNumber(month)}`]: {
          data: {
            totals: {
              expense: 0,
              income: 0,
              savings: 0,
            },
            year: 0,
            month: "",
            mostExpensivePurchase: {
              label: "",
              amount: 0,
              date: 0,
            },
            highestExpenseDay: {
              date: 0,
              amount: 0,
            },
            monthVsLastYear: {
              lastYearMonthExpense: 0,
              lastYearMonthIncome: 0,
              lastYearMonthSavings: 0,
              thisVsLastExpense: 0,
              thisVsLastIncome: 0,
              thisVsLastSavings: 0,
            },
            zeroExpenseDays: [],
            monthVsAverage: {
              averageMonthExpense: 0,
              averageMonthIncome: 0,
              averageMonthSavings: 0,
              thisVsAverageExpense: 0,
              thisVsAverageIncome: 0,
              thisVsAverageSavings: 0,
            },
            stored: false,
            currency: Currency.EUR,
          },
          loaded: true,
          loading: false,
        },
      };
    },
    setReportsMetadata: (
      state: ReportingState,
      action: {
        payload: { reportsMetadata: ReportsMetadataResponse };
      }
    ) => {
      const { reportsMetadata } = action.payload;

      state.reportsMetadata = getSingleLoadResponse(reportsMetadata);
    },
    setReportingCategories: (
      state: ReportingState,
      action: {
        payload: {
          period: string;
          reportingCategories: ReportingCategoriesInterface;
        };
      }
    ) => {
      const { period, reportingCategories } = action.payload;

      const old = current(state).reportingCategories;
      const newReportingCategories = {
        ...old,
        [period]: getSingleLoadResponse(reportingCategories),
      };

      state.reportingCategories = newReportingCategories;
    },
    setMetadata: (
      state: ReportingState,
      action: {
        payload: {
          firstEntry: any;
          monthsSinceFirstEntry: number;
        };
      }
    ) => {
      state.metadata = getSingleLoadResponse(action.payload);
    },
    setTargetValues: (
      state: ReportingState,
      action: {
        payload: {
          monthlySavings: number;
        };
      }
    ) => {
      state.target = getSingleLoadResponse({
        monthlySavings: action.payload.monthlySavings,
      });
    },
    setSavingsToday: (
      state: ReportingState,
      action: {
        payload: {
          savingsToday: number;
        };
      }
    ) => {
      state.savingsToday = getSingleLoadResponse(action.payload.savingsToday);
    },
    setCurrentMonthOverview: (
      state: ReportingState,
      action: {
        payload: { currentMonthOverview: CurrentMonthOverview };
      }
    ) => {
      state.currentMonthOverview = getSingleLoadResponse(
        action.payload.currentMonthOverview
      );
    },
    setTargetMonthlyExpenses: (
      state: ReportingState,
      action: {
        payload: { targetMonthlyExpenses: number };
      }
    ) => {
      state.targetMonthlyExpenses = getSingleLoadResponse(
        action.payload.targetMonthlyExpenses
      );
    },
    setTargetMonthlyIncomes: (
      state: ReportingState,
      action: {
        payload: { targetMonthlyIncomes: number };
      }
    ) => {
      state.targetMonthlyIncomes = getSingleLoadResponse(
        action.payload.targetMonthlyIncomes
      );
    },
    setAverageExpensesPerWeekDay: (
      state: ReportingState,
      action: {
        payload: {
          averageExpensesPerWeekDay: Record<
            string,
            { averageExpensesPerDay: number }
          >;
        };
      }
    ) => {
      state.averageExpensesPerWeekDay = getSingleLoadResponse(
        action.payload.averageExpensesPerWeekDay
      );
    },
    setAverageExpensesPerMonth: (
      state: ReportingState,
      action: {
        payload: {
          averageExpensesPerMonth: Record<
            string,
            { averageExpensesPerMonth: number }
          >;
        };
      }
    ) => {
      state.averageExpensesPerMonth = getSingleLoadResponse(
        action.payload.averageExpensesPerMonth
      );
    },
    setLoading: (
      state: ReportingState,
      action: {
        payload: {
          key: keyof ReportingState;
          status: boolean;
        };
      }
    ) => {
      const { key, status } = action.payload;

      const curr = current(state);
      const obj = { ...curr[key], loading: status };
      const n = { ...curr, [key]: obj };

      return { ...state, ...n };
    },
    setLoadingReportingCategories: (
      state: ReportingState,
      action: {
        payload: {
          period: CategoriesReportingPeriod;
          status: boolean;
        };
      }
    ) => {
      const { period, status } = action.payload;

      state.reportingCategories[period].loading = status;
    },
    logout: (state: ReportingState) => {
      return initialState;
    },
  },
});

export const {
  setSavingsSummary,
  setSummedExpensesPerMonth,
  setSummedIncomesPerMonth,
  setNetSavingsPerMonthTotal,
  //
  setInvestmentsEvolutionData,
  setInvestmentsCapitalEvolutionData,
  setSummarizedExpensesPerDay,
  setSummarizedExpensesPerWeek,
  setTrendsProjection,
  setSummarizedInvestments,
  setAllExpensesAndIncomes,
  setInvestmentsNetWorth,
  setNetworth,
  setDefaultNetworth,
  setNetworthPerMonth,
  setSavingsEstimate,
  //
  setDefaultMonthlyReport,
  //
  setSummedSavingsPerMonth,
  setYearlyReport,
  setMonthlyReport,
  setReportsMetadata,
  //
  //
  setReportingCategories,
  setMetadata,
  setTargetValues,
  setSavingsToday,
  setAverageExpensesPerWeekDay,
  setAverageExpensesPerMonth,
  //
  setLoading,
  setLoadingReportingCategories,
  //
  setCurrentMonthOverview,
  setTargetMonthlyExpenses,
  setTargetMonthlyIncomes,
  logout,
} = reportingSlice.actions;

export const reportingState = (state: RootState): ReportingState =>
  state.reporting;

export const selectSavingsSummary = (
  state: RootState
): SingleLoad<SavingsSummary> => reportingState(state).savingsSummary;
export const selectSummedExpensesPerMonth = (
  state: RootState
): SingleLoad<MonthlyExpenses[]> =>
  reportingState(state).summedExpensesPerMonth;
export const selectSummedIncomesPerMonth = (
  state: RootState
): SingleLoad<MonthlyIncomes[]> => reportingState(state).summedIncomesPerMonth;
export const selectSummedSavingsPerMonth = (
  state: RootState
): SingleLoad<MonthlySaving[]> => reportingState(state).summedSavingsPerMonth;
export const selectNetSavingsPerMonthTotal = (
  state: RootState
): SingleLoad<number> => reportingState(state).netSavingsPerMonthTotal;
export const selectEvolutionData = (
  state: RootState
): SingleLoad<InvestmentsPriceEvolutionGraphProps> =>
  reportingState(state).investmentsEvolutionData;
export const selectInvestmentsCapitalEvolutionData = (
  state: RootState
): SingleLoad<InvestmentsCapitalInvestedEvolutionGraphProps> =>
  reportingState(state).investmentsCapitalEvolutionData;
export const selectSummarizedExpensesPerDay = (
  state: RootState
): SingleLoad<number[]> => reportingState(state).summarizedExpensesPerDay;
export const selectSummarizedExpensesPerWeek = (
  state: RootState
): SingleLoad<number[]> => reportingState(state).summarizedExpensesPerWeek;
export const selectTrendsProjection = (
  state: RootState
): SingleLoad<TrendsProjectionsSummarized> =>
  reportingState(state).trendsProjection;
export const selectSummarizedInvestments = (
  state: RootState
): SingleLoad<SummarizedInvestments> =>
  reportingState(state).summarizedInvestments;
export const selectAllExpensesAndIncomes = (
  state: RootState
): SingleLoad<AllExpensesAndIncomes> =>
  reportingState(state).allExpensesAndIncomes;
export const selectInvestmentsNetWorth = (
  state: RootState
): SingleLoad<number> => reportingState(state).investmentsNetWorth;
export const selectNetWorth = (
  state: RootState
): SingleLoad<NetWorthInterface> => reportingState(state).netWorth;
export const selectNetWorthPerMonth = (
  state: RootState
): SingleLoad<{ date: number; netWorth: number }[]> =>
  reportingState(state).netWorthPerMonth;
export const selectSavingsEstimates = (state: RootState) =>
  reportingState(state).savingsEstimates;
export const selectAllExpenses = (state: RootState): SingleLoad<number> =>
  reportingState(state).expensesSum;
export const selectAllIncomes = (state: RootState): SingleLoad<number> =>
  reportingState(state).incomesSum;
export const selectAllSavings = (state: RootState): SingleLoad<number> =>
  reportingState(state).savingsSum;
export const selectYearlyReports = (state: RootState) =>
  reportingState(state).yearlyReports;
export const selectMonthlyReports = (state: RootState) =>
  reportingState(state).monthlyReports;
export const selectReportingCategories = (state: RootState) =>
  reportingState(state).reportingCategories;
export const selectMetadata = (state: RootState) =>
  reportingState(state).metadata;
export const selectTargetValues = (state: RootState) =>
  reportingState(state).target;
export const selectSavingsToday = (state: RootState) =>
  reportingState(state).savingsToday;
export const selectCurrentMonthOverview = (state: RootState) =>
  reportingState(state).currentMonthOverview;
export const selectTargetMonthlyExpenses = (state: RootState) =>
  reportingState(state).targetMonthlyExpenses;
export const selectTargetMonthlyIncomes = (state: RootState) =>
  reportingState(state).targetMonthlyIncomes;
export const selectReportMetadata = (state: RootState) =>
  reportingState(state).reportsMetadata;
export const selectAverageExpensesPerWeekDay = (state: RootState) =>
  reportingState(state).averageExpensesPerWeekDay;
export const selectAverageExpensesPerMonth = (state: RootState) =>
  reportingState(state).averageExpensesPerMonth;

export const ReportingReducer = reportingSlice.reducer;
