import { ThemeProvider } from "@emotion/react";
import { createContext, useState } from "react";

export const ThemeContext = createContext<any>(null);

export const ThemeContextProvider = (props: { theme: any; children: any }) => {
  const [theme, setTheme] = useState(props.theme);

  return (
    <ThemeContext.Provider value={{ setTheme, theme }}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
