import {
  fetchMortgages,
  fetchCategories,
  fetchAccounts,
  fetchAdminBudget,
} from "../api/fetch.redux";
import { MortgagesTable } from "../components/mortgages/MortgagesTable";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewMortgageDialog } from "../components/utility/dialogs/NewMortgageDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAccounts } from "../redux/reducers/accountsSlice";
import {
  selectAdminBudgetId,
  selectBudgetToUse,
} from "../redux/reducers/budgetSlice";
import { selectCategories } from "../redux/reducers/categoriesSlice";
import { selectMortgages } from "../redux/reducers/mortgagesSlice";
import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface MortgagesPageProps {
  openHowToDialog: () => void;
}
export const Mortgages = (props: MortgagesPageProps) => {
  const { openHowToDialog } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const [openNewMortgageDialog, setOpenNewMortgageDialog] = useState(false);

  const dispatch = useAppDispatch();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: mortgages,
    loaded: areMortgagesLoaded,
    loading: areMortgagesLoading,
  } = useSelector(selectMortgages);
  const { loaded: areCategoriesLoaded } = useSelector(selectCategories);
  const { loaded: areAccountsLoaded } = useSelector(selectAccounts);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchMortgages(budgetId, dispatch, areMortgagesLoaded);
      fetchCategories(budgetId, dispatch, areCategoriesLoaded);
      fetchAccounts(budgetId, dispatch, areAccountsLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const newMortgagesCreated = () => {
    fetchMortgages(budgetId, dispatch, false);
    fetchAccounts(budgetId, dispatch, false);
    fetchCategories(budgetId, dispatch, false);
    toast.success("New mortgage created.");
  };

  return (
    <Box>
      <LoadingDialog
        key={uuid()}
        open={areMortgagesLoading}
        toDisplay="mortgages"
      />
      <BudgetarToast />
      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Button
          variant="contained"
          size="small"
          onClick={() => fetchMortgages(budgetId, dispatch, false)}
          sx={{ marginRight: "10px" }}
        >
          <RefreshIcon />
        </Button>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewMortgageDialog(true)}
          sx={{ marginRight: "10px" }}
        >
          <AddIcon />
          New mortgage
        </Button>

        <Box sx={{ paddingLeft: "10px" }} />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}
      </Box>

      <NewMortgageDialog
        open={openNewMortgageDialog}
        setOpen={setOpenNewMortgageDialog}
        onSuccess={newMortgagesCreated}
        onFailed={() => toast.error("Error while creating mortgage.")}
      />

      {mortgages ? (
        <Box>
          <MortgagesTable mortgages={mortgages} />
        </Box>
      ) : (
        <Box>No mortgages</Box>
      )}
    </Box>
  );
};
