import { useAppSelector } from "../../../redux/hooks";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../../util/Formatter";
import { optionalTruncate, appendDaySuffix } from "../../../util/Functions";
import { SimpleRow, AccentRow, EmptyRow, ValueType } from "./YearlyReportTable";
import { MonthlyReport } from "@backend/reporting.type";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMediaQuery, Card } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import { DateTime } from "luxon";

export const MonthlyReportTable = (props: {
  report: MonthlyReport;
  children: any;
}) => {
  const matches = useMediaQuery("(min-width:700px)");

  const { report, children } = props;

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  const {
    year,
    month,
    totals: {
      expense: thisMonthExpense,
      income: thisMonthIncome,
      savings: thisMonthSavings,
    },
    mostExpensivePurchase,
    highestExpenseDay,
    monthVsLastYear: {
      lastYearMonthExpense,
      lastYearMonthIncome,
      lastYearMonthSavings,
      thisVsLastExpense,
      thisVsLastIncome,
      thisVsLastSavings,
    },
    zeroExpenseDays,
    monthVsAverage: {
      averageMonthExpense,
      averageMonthIncome,
      averageMonthSavings,
      thisVsAverageExpense,
      thisVsAverageIncome,
      thisVsAverageSavings,
    },
  } = report;

  const renderMostExpensivePurchases = () =>
    mostExpensivePurchase ? (
      <SimpleRow
        f={f}
        columns={[
          {
            text: `${mostExpensivePurchase.label}`,
            align: "left",
          },
          {
            text: appendDaySuffix(
              Number(DateTime.fromMillis(mostExpensivePurchase.date).day)
            ),
            align: "right",
          },
          {
            text: mostExpensivePurchase.amount,
            isValue: true,
          },
        ]}
      />
    ) : null;

  const LeftTable = () =>
    matches ? (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell width={"400px"} />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <AccentRow title="Totals" columnsCount={2} />
              <SimpleRow
                f={f}
                columns={[
                  { text: `Income ` },
                  { text: `` },
                  { text: thisMonthIncome, isValue: true, align: "right" },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  { text: `Expense ` },
                  { text: `` },
                  { text: thisMonthExpense, isValue: true, align: "right" },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  { text: `Savings ` },
                  { text: `` },
                  {
                    text: thisMonthSavings,
                    isValue: true,
                    explicitPlus: true,
                    align: "right",
                  },
                ]}
              />

              <EmptyRow columnsCount={3} />
              <AccentRow title="Misc" columnsCount={2} />
              {highestExpenseDay ? (
                <SimpleRow
                  f={f}
                  columns={[
                    { text: `Highest expense day ` },
                    {
                      text: appendDaySuffix(
                        DateTime.fromMillis(highestExpenseDay.date).day
                      ),
                      align: "right",
                    },
                    {
                      text: highestExpenseDay.amount,
                      isValue: true,
                      align: "right",
                    },
                  ]}
                />
              ) : null}

              <SimpleRow
                f={f}
                columns={[
                  { text: `Zero-expense days` },
                  {
                    text: zeroExpenseDays.length,
                    align: "right",
                  },
                  {
                    text: "",
                  },

                  // {
                  //   text: zeroExpenseDays.join(", "),
                  // },
                ]}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Box sx={{ paddingBottom: "10px", margin: "5px" }}>
        <Card>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={18} lineHeight={2.5}>
                Totals
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={4}>Incomes</Grid>
            <Grid xs={4}>Expenses</Grid>
            <Grid xs={4}>Savings</Grid>
            <Grid xs={4}>{f(thisMonthIncome)}</Grid>
            <Grid xs={4}>{f(thisMonthExpense)}</Grid>
            <Grid xs={4}>{f(thisMonthSavings)}</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Card>
      </Box>
    );

  const MiddleTable = () =>
    matches ? (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell width={"400px"} />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <AccentRow title="Most expensive purchases" columnsCount={2} />
              {renderMostExpensivePurchases()}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Box sx={{ paddingBottom: "10px", margin: "5px" }}>
        <Card>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={18} lineHeight={2.5}>
                Most expensive purchases
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={4}>Item</Grid>
            <Grid xs={4}>Date</Grid>
            <Grid xs={4}>Amount</Grid>
            <Grid xs={4}>
              {optionalTruncate(mostExpensivePurchase.label, 10)}
            </Grid>
            <Grid xs={4}>
              {appendDaySuffix(
                DateTime.fromMillis(mostExpensivePurchase.date).day
              )}
            </Grid>
            <Grid xs={4}>{f(mostExpensivePurchase.amount)}</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Card>
      </Box>
    );

  const BottomLeftTable = () =>
    matches ? (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <AccentRow
                title={`Comparison month vs. month of previous year`}
                columnsCount={3}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: "",
                  },
                  {
                    text: "Month of previous year",
                  },
                  {
                    text: "Month",
                  },
                  {
                    text: "change",
                  },
                ]}
              />

              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Income`,
                  },
                  {
                    text: lastYearMonthIncome,
                    isValue: true,
                  },
                  {
                    text: thisMonthIncome,
                    isValue: true,
                  },
                  {
                    text: ((thisVsLastIncome * 100) / 100).toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                    align: "right",
                  },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Expense`,
                  },
                  {
                    text: lastYearMonthExpense,
                    isValue: true,
                  },
                  {
                    text: thisMonthExpense,
                    isValue: true,
                  },
                  {
                    text: ((thisVsLastExpense * 100) / 100).toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                    align: "right",
                  },
                ]}
              />

              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Savings`,
                  },
                  {
                    text: lastYearMonthSavings,
                    isValue: true,
                  },
                  {
                    text: thisMonthSavings,
                    isValue: true,
                  },
                  {
                    text: ((thisVsLastSavings * 100) / 100).toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                    align: "right",
                  },
                ]}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Box sx={{ paddingBottom: "10px", margin: "5px" }}>
        <Card>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={18} lineHeight={2.5}>
                Month vs. month of previous year
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={4}>This month</Grid>
            <Grid xs={4}>Last year</Grid>
            <Grid xs={4}>Change</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>Expenses</Grid>

            <Grid xs={4}>{f(thisMonthExpense)}</Grid>
            <Grid xs={4}>{f(lastYearMonthExpense)}</Grid>
            <Grid xs={4}>{thisVsLastExpense.toFixed(0)}%</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <Typography></Typography>
            </Grid>
            <Grid xs={12}>Incomes</Grid>

            <Grid xs={4}>{f(thisMonthIncome)}</Grid>
            <Grid xs={4}>{f(lastYearMonthIncome)}</Grid>
            <Grid xs={4}>{thisVsLastIncome.toFixed(0)}%</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12}>Savings</Grid>

            <Grid xs={4}>{f(thisMonthSavings)}</Grid>
            <Grid xs={4}>{f(lastYearMonthSavings)}</Grid>
            <Grid xs={4}>{thisVsLastSavings.toFixed(0)}%</Grid>
          </Grid>
        </Card>
      </Box>
    );

  const BottomRightTable = () =>
    matches ? (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="goals table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <AccentRow
                title={`Comparison month vs. average month`}
                columnsCount={3}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: "",
                  },
                  {
                    text: "Average month",
                  },
                  {
                    text: "Month",
                  },
                  {
                    text: "change",
                  },
                ]}
              />

              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Income`,
                  },
                  {
                    text: averageMonthIncome,
                    isValue: true,
                  },
                  {
                    text: thisMonthIncome,
                    isValue: true,
                  },
                  {
                    text: ((thisVsAverageIncome * 100) / 100).toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                    align: "right",
                  },
                ]}
              />
              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Expense`,
                  },
                  {
                    text: averageMonthExpense,
                    isValue: true,
                  },
                  {
                    text: thisMonthExpense,
                    isValue: true,
                  },
                  {
                    text: ((thisVsAverageExpense * 100) / 100).toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                    align: "right",
                  },
                ]}
              />

              <SimpleRow
                f={f}
                columns={[
                  {
                    text: `Savings`,
                  },
                  {
                    text: averageMonthSavings,
                    isValue: true,
                  },
                  {
                    text: thisMonthSavings,
                    isValue: true,
                  },
                  {
                    text: ((thisVsAverageSavings * 100) / 100).toFixed(0),
                    isValue: true,
                    valueType: ValueType.PERCENT,
                    explicitPlus: true,
                    align: "right",
                  },
                ]}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Box sx={{ paddingBottom: "10px", margin: "5px" }}>
        <Card>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={18} lineHeight={2.5}>
                Month vs. average month
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={4}>This month</Grid>
            <Grid xs={4}>Last year</Grid>
            <Grid xs={4}>Change</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>Expenses</Grid>

            <Grid xs={4}>{f(thisMonthExpense)}</Grid>
            <Grid xs={4}>{f(averageMonthExpense)}</Grid>
            <Grid xs={4}>{thisVsAverageExpense.toFixed(0)}%</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <Typography></Typography>
            </Grid>
            <Grid xs={12}>Incomes</Grid>

            <Grid xs={4}>{f(thisMonthIncome)}</Grid>
            <Grid xs={4}>{f(averageMonthIncome)}</Grid>
            <Grid xs={4}>{thisVsAverageIncome.toFixed(0)}%</Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12}>Savings</Grid>

            <Grid xs={4}>{f(thisMonthSavings)}</Grid>
            <Grid xs={4}>{f(averageMonthSavings)}</Grid>
            <Grid xs={4}>{thisVsAverageSavings.toFixed(0)}%</Grid>
          </Grid>
        </Card>
      </Box>
    );

  return (
    <Grid container xs={12}>
      {children}

      <Grid item xs={12}>
        <Typography fontSize={matches ? 30 : 22}>
          Monthly report - {year}/{month}
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <LeftTable />
      </Grid>

      <Grid item xs={12} md={4}>
        <MiddleTable />
      </Grid>

      <Grid item xs={12} md={6}>
        <BottomLeftTable />
      </Grid>

      <Grid item xs={12} md={6}>
        <BottomRightTable />
      </Grid>
    </Grid>
  );
};
