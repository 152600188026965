import { AccountAPI } from "../api/accounts/accounts.api";
import {
  fetchAccounts,
  fetchExpenses,
  fetchCategories,
} from "../api/fetch.redux";
import { fetchAdminBudget } from "../api/fetch.redux";
import { AccountsTable } from "../components/accounts/AccountsTable";
import { ActionButton } from "../components/common/ActionButton";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewAccountDialog } from "../components/utility/dialogs/NewAccountDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { loadAccounts, logout } from "../components/utils.api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAccounts } from "../redux/reducers/accountsSlice";
import {
  selectAdminBudgetId,
  selectBudgetToUse,
} from "../redux/reducers/budgetSlice";
import { selectCategories } from "../redux/reducers/categoriesSlice";
import { selectSimpleExpenses } from "../redux/reducers/expensesSlice";
import { UpdateAccountData } from "@backend/account.type";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface AccountsPageProps {
  openHowToDialog: () => void;
}
export const Accounts = (props: AccountsPageProps) => {
  const { openHowToDialog } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const [openNewAccountDialog, setOpenNewAccountDialog] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: accounts,
    loaded: areAccountsLoaded,
    loading: areAccountsLoading,
  } = useSelector(selectAccounts);
  const { loaded: areExpensesLoaded } = useAppSelector(selectSimpleExpenses);
  const { loaded: areCategoriesLoaded } = useAppSelector(selectCategories);

  const loadAccountsCall = () => {
    loadAccounts(budgetId, dispatch);
  };

  const newAccountsCreated = () => {
    loadAccountsCall();
    toast.success("New account created.");
  };

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchAccounts(budgetId, dispatch, areAccountsLoaded);
      fetchExpenses(budgetId, dispatch, areExpensesLoaded);
      fetchCategories(budgetId, dispatch, areCategoriesLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const validateInput = (accountName: string) => {
    if (accounts.find((e) => e.accountName === accountName))
      return "Category already exists.";

    return null;
  };

  const updateAccount = (
    accountSK: string,
    updateAccountData: UpdateAccountData,
    cb: () => void
  ) => {
    AccountAPI.updateAccount(
      budgetId,
      { accountSK, ...updateAccountData },
      {
        onSuccess: () => {
          fetchAccounts(budgetId, dispatch, false);
          toast.success("Account updated.");
          cb();
        },
        onFailed: (err) => {
          toast.error(err);
        },
      },
      () => logout(dispatch, navigate)
    );
  };

  const deleteAccount = (accountSK: string) => {
    AccountAPI.deleteAccount(
      budgetId,
      { accountSK },
      {
        onSuccess: () => {
          fetchAccounts(budgetId, dispatch, false);
          toast.success("Account deleted.");
        },
        onFailed: (err) => toast.error(err),
      },
      () => logout(dispatch, navigate)
    );
  };

  const PrimaryAccountReminder = () => {
    if (accounts.some((e) => e.isPrimary && e.isPrimary === "true"))
      return null;

    return (
      <ActionButton
        Icon={WarningAmberIcon}
        onIconClick={() => {}}
        tooltip="No primary account selected."
      ></ActionButton>
    );
  };

  return (
    <Box>
      <LoadingDialog
        key={uuid()}
        open={areAccountsLoading}
        toDisplay="accounts"
      />
      <BudgetarToast />
      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <SecondaryButton size="small" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            Back
          </SecondaryButton>
        </Box>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewAccountDialog(true)}
        >
          <AddIcon />
          New account
        </Button>

        <Box sx={{ paddingLeft: "10px" }} />

        <PrimaryAccountReminder />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}
      </Box>

      <NewAccountDialog
        open={openNewAccountDialog}
        setOpen={setOpenNewAccountDialog}
        onSuccess={newAccountsCreated}
        onFailed={(err: any) =>
          toast.error(err || `Error while creating account.`)
        }
        validate={validateInput}
      />
      {accounts ? (
        <AccountsTable
          accounts={accounts}
          updateAccount={updateAccount}
          deleteAccount={deleteAccount}
        />
      ) : (
        <Box>No accounts</Box>
      )}
    </Box>
  );
};
