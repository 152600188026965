import { useAppSelector } from "../../../redux/hooks";
import { selectCategories } from "../../../redux/reducers/categoriesSlice";
import { GenericDropdown } from "./GenericDropdown";
import { Category } from "@backend/category.type";

export const CategoriesDropdown = (props: {
  categorySelected: any;
  categoryTypeFilter: "expense" | "income";
  label?: string;
  initialOptionIndex?: number;
  disabled?: boolean;
}) => {
  const {
    categorySelected,
    categoryTypeFilter,
    label,
    initialOptionIndex,
    disabled,
  } = props;

  const categories = useAppSelector(selectCategories).data.filter(
    (category: Category) =>
      category.categoryType.toLowerCase() === categoryTypeFilter
  );

  return (
    <GenericDropdown
      label={label || "Category"}
      options={categories
        .slice()
        .sort((a, b) => a.categoryName.localeCompare(b.categoryName))}
      onSelection={categorySelected}
      optionId={"SK"}
      optionValue={"categoryName"}
      initialOptionIndex={initialOptionIndex}
      disabledOverride={disabled}
    />
  );
};
