import { DateTime } from "luxon";
import { DatedBudgetEntry } from "@backend/common.type";
import { Period } from "@backend/period.type";

function diffDates(from: DateTime, to: DateTime, timeUnit: "weeks" | "months") {
  return Math.ceil(Number(to.diff(from, timeUnit).toObject()[timeUnit]));
}

function diffWeeks(from: DateTime, to: DateTime) {
  return diffDates(from, to, "weeks");
}

function diffMonthly(from: DateTime, to: DateTime) {
  return diffDates(from, to, "months");
}

export function calculatePeriodsBetweenDates(
  period: Period,
  from: DateTime,
  to: DateTime
) {
  let diff;

  switch (period) {
    case "WEEKLY": {
      diff = diffWeeks(from, to);
      break;
    }
    case "BIWEEKLY": {
      diff = Math.ceil(diffWeeks(from, to) / 2);
      break;
    }

    default: {
      diff = Math.ceil(diffMonthly(from, to));
      break;
    }
  }

  return Math.max(diff, 1);
}

export function getPeriodMultiplier(period: Period) {
  switch (period) {
    case "MONTHLY":
      return 1;
    case "BIWEEKLY":
      return 2;
    default:
      return 4;
  }
}

export function groupPerMonth(items: DatedBudgetEntry[]) {
  return items.reduce(
    (dateGroups: { date: string; entries: DatedBudgetEntry[] }[], item) => {
      const date = DateTime.fromISO(item.budgetEntryDate);
      const dateGroupLabel = `${padNumber(date.month)}#${date.year}`;

      let dateGroupIndex = dateGroups.findIndex(
        (e) => e.date === dateGroupLabel
      );
      let dateGroup;

      if (dateGroupIndex < 0) {
        dateGroupIndex = dateGroups.length;
        dateGroup = { date: dateGroupLabel, entries: [] };
      } else {
        dateGroup = dateGroups[dateGroupIndex];
      }

      dateGroup.entries.push(item);

      dateGroups.splice(dateGroupIndex, 1, dateGroup);

      return dateGroups;
    },
    []
  );
}

function padNumber(n: number) {
  if (n < 10) return `0${n}`;

  return `${n}`;
}

export function dateSorter(dateA: DateTime | string, dateB: DateTime | string) {
  const a = dateA instanceof DateTime ? dateA : DateTime.fromISO(dateA);
  const b = dateB instanceof DateTime ? dateB : DateTime.fromISO(dateB);

  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
}

interface EarliestEntryArgumentsProps {
  budgetEntryDate: string;
}

export function findEarliestEntry(
  expenses: EarliestEntryArgumentsProps[],
  incomes: EarliestEntryArgumentsProps[]
) {
  const entries = [...expenses]
    .concat(incomes)
    .map((e) => e.budgetEntryDate)
    .sort((a, b) => dateSorter(a, b));

  const firstEntry =
    entries && entries.length ? DateTime.fromISO(entries[0]) : DateTime.now();

  const monthsSinceFirstEntry =
    Math.ceil(DateTime.now().diff(firstEntry).as("months")) || 1;

  return { firstEntry, monthsSinceFirstEntry };
}
