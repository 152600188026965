import { Goal } from "@backend/goal.type";

export function findNextGoal(savings: number, goals: Goal[]) {
  const index = goals.findIndex((e) => e.amount > savings);

  return goals[index];
}

export function goalReachedInDays(
  savings: number,
  monthlyNet: number,
  goal: number
) {
  return (goal - savings) / (monthlyNet / 30);
}
