import { fetchAssets } from "../api/fetch.redux";
import { AssetsTable } from "../components/asset-items/AssetsTable";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewAssetDialog } from "../components/utility/dialogs/NewAssetDialog";
import { NewAssetPriceDialog } from "../components/utility/dialogs/NewAssetPriceDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { getBudgetId } from "../components/utils.api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAssets } from "../redux/reducers/assetsSlice";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface AssetsPageProps {
  openHowToDialog: () => void;
}
export const Assets = (props: AssetsPageProps) => {
  const { openHowToDialog } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");
  const [openNewAssetDialog, setOpenNewAssetDialog] = useState(false);
  const [openNewAssetPriceDialog, setOpenNewAssetPriceDialog] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const budgetId = getBudgetId(useAppSelector);
  const {
    data: assets,
    loaded: areAssetsLoaded,
    loading: areAssetsLoading,
  } = useSelector(selectAssets);

  useEffect(() => {
    fetchAssets(budgetId, dispatch, areAssetsLoaded);
  }, []);

  const newAssetsCreated = () => {
    fetchAssets(budgetId, dispatch, false);
    toast.success("New asset created.");
  };

  const newAssetPriceAdded = () => {
    fetchAssets(budgetId, dispatch, false);
    toast.success("New asset price added created.");
  };

  return (
    <Box>
      <LoadingDialog key={uuid()} open={areAssetsLoading} toDisplay="assets" />
      <BudgetarToast />

      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <SecondaryButton size="small" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            Back
          </SecondaryButton>
        </Box>

        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewAssetDialog(true)}
          sx={{ marginRight: "10px" }}
        >
          <AddIcon />
          New asset
        </Button>

        <Box sx={{ paddingRight: "10px" }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => setOpenNewAssetPriceDialog(true)}
          >
            <AddIcon />
            Add asset price
          </Button>
        </Box>

        <Box sx={{ paddingLeft: "10px" }} />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}
      </Box>

      <NewAssetDialog
        open={openNewAssetDialog}
        setOpen={setOpenNewAssetDialog}
        onSuccess={newAssetsCreated}
        onFailed={() => toast.error("Error while creating asset.")}
      />

      <NewAssetPriceDialog
        open={openNewAssetPriceDialog}
        setOpen={setOpenNewAssetPriceDialog}
        onSuccess={newAssetPriceAdded}
        onFailed={() => toast.error("Error while adding asset price.")}
      />

      {assets ? <AssetsTable assets={assets} /> : <Box>No assets</Box>}
    </Box>
  );
};
