import {
  fetchAccessibleBudgets,
  fetchGrantedAccesses,
} from "../api/fetch.redux";
import { fetchAccounts, fetchCategories, fetchGoals } from "../api/fetch.redux";
import { ReportingApi } from "../api/reporting.api";
import { AccessibleBudgets } from "../components/access-roles/AccessibleBudgets";
import { GrantedAccessRolesTable } from "../components/access-roles/GrantedAccessRolesTable";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { logout } from "../components/utils.api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  selectAccessibleBudgets,
  selectedGrantedBudgetAccesses,
} from "../redux/reducers/accessRoleSlice";
import {
  setAllExpensesAndIncomes,
  setCurrentMonthOverview,
  setInvestmentsNetWorth,
  setMetadata,
  setTargetMonthlyExpenses,
  setTargetMonthlyIncomes,
  setTargetValues,
} from "../redux/reducers/reportingSlice";
import { CurrentMonthOverview } from "@backend/reporting.type";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

export const AccessManagement = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loaded: areAccessesLoaded } = useSelector(selectAccessibleBudgets);
  const { loaded: areGrantedAccessesLoaded } = useAppSelector(
    selectedGrantedBudgetAccesses
  );

  const reload = (budgetId: string) => {
    fetchAccounts(budgetId, dispatch, false);
    fetchCategories(budgetId, dispatch, false);
    fetchGoals(budgetId, dispatch, false);

    ReportingApi.getFirstEntryAndMonthsSince(
      budgetId,
      {
        onSuccess: (data: {
          firstEntry: any;
          monthsSinceFirstEntry: number;
        }) => {
          dispatch(setMetadata(data));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getInvestmentsNetWorth(
      budgetId,
      {
        onSuccess: (investmentsNetWorth: any) => {
          dispatch(setInvestmentsNetWorth({ investmentsNetWorth }));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.loadAllExpensesAndIncomes(
      budgetId,
      {
        onSuccess: (data: any) => {
          dispatch(setAllExpensesAndIncomes(data));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getTargetMonthlySavings(
      budgetId,
      {
        onSuccess: (monthlySavings: number) => {
          dispatch(setTargetValues({ monthlySavings }));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getCurrentMonthOverview(
      budgetId,
      {
        onSuccess: (currentMonthOverview: CurrentMonthOverview) => {
          dispatch(setCurrentMonthOverview({ currentMonthOverview }));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getTargetMonthlyExpenses(
      budgetId,
      {
        onSuccess: (targetMonthlyExpenses: number) => {
          dispatch(setTargetMonthlyExpenses({ targetMonthlyExpenses }));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getTargetMonthlyIncomes(
      budgetId,
      {
        onSuccess: (targetMonthlyIncomes: number) => {
          dispatch(setTargetMonthlyIncomes({ targetMonthlyIncomes }));
        },
      },
      () => logout(dispatch, navigate)
    );
  };

  useEffect(() => {
    fetchAccessibleBudgets(dispatch, areAccessesLoaded);
    fetchGrantedAccesses(dispatch, areGrantedAccessesLoaded);
  }, []);

  return (
    <Box>
      <LoadingDialog
        key={uuid()}
        open={!areAccessesLoaded || !areGrantedAccessesLoaded}
        toDisplay="access roles"
      />

      <BudgetarToast />

      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <SecondaryButton size="small" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            Back
          </SecondaryButton>
        </Box>
      </Box>

      <Typography fontSize={24}>Access roles management</Typography>

      <Grid container>
        <Grid item xs={12} md={6}>
          <AccessibleBudgets reload={reload} />
        </Grid>
        <Grid item xs={12} md={6}>
          <GrantedAccessRolesTable />
        </Grid>
      </Grid>
    </Box>
  );
};
