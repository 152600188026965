import LockIcon from "@mui/icons-material/Lock";
import { ListItemIcon, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";

interface GenericDropdownProps {
  label: string;
  options: (any | { disabled: boolean; label: string; tooltip: string })[];
  onSelection: (choice: any) => void;
  optionId?: string;
  optionValue?: string;
  initialOptionIndex?: number;
  disabledOverride?: boolean;
  fullWidth?: boolean;
}

export const GenericDropdown = (props: GenericDropdownProps) => {
  const {
    label,
    options,
    onSelection,
    optionId,
    optionValue,
    initialOptionIndex,
    disabledOverride,
    fullWidth = true,
  } = props;

  const firstIndex =
    initialOptionIndex && initialOptionIndex > 0 ? initialOptionIndex : 0;
  const optionsExist = options.length > 0;
  let disabled = false;
  let usableOptions = options;
  let firstEntry;

  if (!optionsExist) {
    usableOptions = ["No entries available."];
    firstEntry = usableOptions[firstIndex];
    disabled = true;
  } else {
    firstEntry = optionId
      ? usableOptions[firstIndex][optionId]
      : usableOptions[firstIndex];
  }

  const [selection, setSelection] = React.useState(firstEntry);

  const handleChange = (event: { target: { value: any } }) => {
    const { value } = event.target;

    setSelection(value);
    onSelection(value);
  };

  return (
    <Box>
      <FormControl fullWidth={fullWidth}>
        <InputLabel
          id="demo-simple-select-label"
          color="info"
          sx={{ color: "white", marginRight: "20px" }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selection}
          label={label}
          onChange={handleChange}
          fullWidth
          disabled={disabled || disabledOverride}
          color="info"
        >
          {usableOptions.map((e: any) => {
            let key, value, label;

            if (disabled) {
              key = value = label = usableOptions[0];
            } else {
              key = value = optionId ? e[optionId] : e;
              label = optionValue ? e[optionValue] : e;
            }

            const item = (
              <MenuItem key={key} value={value} disabled={e.disabled}>
                {e.stored ? (
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                ) : null}

                {e.disabled ? (
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                ) : null}

                {e.label || label}
              </MenuItem>
            );

            if (e.tooltip) {
              return (
                <Tooltip title={e.tooltip} enterTouchDelay={0}>
                  <div>{item}</div>
                </Tooltip>
              );
            }

            return item;
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
