import { AccountAPI } from "../../../api/accounts/accounts.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getBudgetId, logout } from "../../utils.api";
import { AccountsDropdown } from "../dropdowns/AccountsDropdown";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const AccountTransferDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: (err: any) => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [amount, setAmount] = useState(0);
  const [sourceAccountId, setSourceAccountId] = useState("");
  const [destinationAccountId, setDestinationAccountId] = useState("");

  const budgetId = getBudgetId(useAppSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAmountChange = (newValue: number) => setAmount(newValue);

  const submit = () => {
    transfer();
    handleClose();
  };

  const transfer = async () => {
    AccountAPI.transfer(
      budgetId,
      {
        sourceAccountId: sourceAccountId.split("acc#")[1],
        destinationAccountId: destinationAccountId.split("acc#")[1],
        amount,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New account</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Enter transfer amount"
              variant="outlined"
              onChange={(e) => handleAmountChange(Number(e.target.value))}
            />

            <Box sx={{ paddingTop: "10px" }}>
              <AccountsDropdown
                accountSelected={setSourceAccountId}
                label="Source account"
              />
            </Box>

            <Box sx={{ paddingTop: "10px" }}>
              <AccountsDropdown
                accountSelected={setDestinationAccountId}
                label="Destination account"
              />
            </Box>

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!amount || !sourceAccountId || !destinationAccountId}
                onClick={submit}
              >
                Transfer account
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
