import { Callbacks } from "../../types/util";
import { postRequest } from "../index";

const getItemsUrl = (route: string, budgetId: string) =>
  `${budgetId}/items/${route}`;

async function cloneItem(
  budgetId: string,
  args: { itemSK: string },
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getItemsUrl("clone", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export const BudgetItemsAPI = { cloneItem };
