import { GoalAPI } from "../../../api/goals.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getBudgetId, logout } from "../../utils.api";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const NewGoalDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [goalName, setGoalName] = React.useState("");
  const [amount, setAmount] = React.useState(0);

  const budgetId = getBudgetId(useAppSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoalNameChange = (newName: string) => setGoalName(newName);
  const handleAmountChange = (newAmount: string) =>
    setAmount(Number(newAmount));

  const submit = () => {
    createGoal();
    handleClose();
  };

  const createGoal = async () => {
    GoalAPI.createGoal(
      budgetId,
      {
        goalName,
        amount,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const canSubmit = () => {
    return goalName && goalName.length && amount;
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New goal</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Goal label"
              variant="outlined"
              onChange={(e) => handleGoalNameChange(e.target.value)}
              sx={{ paddingTop: "10px" }}
            />

            <TextField
              placeholder="Amount"
              variant="outlined"
              onChange={(e) => handleAmountChange(e.target.value)}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Create goal
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
