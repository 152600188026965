import { Typography } from "@mui/material";
import React from "react";

export type CollapsibleComponentProps = {
  children: any;
};

export const ReleaseNotesEntry: React.FC<CollapsibleComponentProps> = ({
  children,
}) => {
  return (
    <Typography fontWeight={500} fontSize={"18px"}>
      {children}
    </Typography>
  );
};
