import { fetchAdminBudget } from "../../../api/fetch.redux";
import { ReportingApi } from "../../../api/reporting.api";
import { InvestmentsPriceEvolutionGraph } from "../../../components/investments/InvestmentsPriceEvolutionGraph";
import { logout } from "../../../components/utils.api";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../../redux/reducers/budgetSlice";
import {
  selectEvolutionData,
  setSummarizedInvestments,
  selectSummarizedInvestments,
  setInvestmentsEvolutionData,
  setLoading,
  setInvestmentsCapitalEvolutionData,
} from "../../../redux/reducers/reportingSlice";
import { selectInvestmentsCapitalEvolutionData } from "../../../redux/reducers/reportingSlice";
import { LoadingDialog } from "../../utility/dialogs/LoadingDialog";
import { InvestmentsReportingTable } from "./InvestmentsReportingTable";
import {
  InvestmentsCapitalInvestedEvolutionGraphProps,
  SummarizedInvestments,
} from "@backend/investment.type";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

export const InvestmentsReporting = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);

  const {
    data: { investmentsData },
    loaded: evolutionDataLoaded,
    loading: isEvolutionDataLoading,
  } = useAppSelector(selectEvolutionData);

  const {
    data: { investmentsCapitalData },
    loaded: investmentsCapitalDataLoaded,
    loading: investmentsCapitalDataLoading,
  } = useAppSelector(selectInvestmentsCapitalEvolutionData);

  const {
    data: summarizedInvestments,
    loaded: summarizedInvestmentsLoaded,
    loading: areSummarizedInvestmentsLoading,
  } = useAppSelector(selectSummarizedInvestments);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!evolutionDataLoaded) {
        dispatch(setLoading({ key: "investmentsEvolutionData", status: true }));

        ReportingApi.getInvestmentsEvolution(
          budgetId,
          {
            onSuccess: ({ dates, investmentsData }: any) => {
              dispatch(setInvestmentsEvolutionData({ dates, investmentsData }));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!investmentsCapitalDataLoaded) {
        dispatch(
          setLoading({ key: "investmentsCapitalEvolutionData", status: true })
        );

        ReportingApi.getInvestmentsCapitalEvolution(
          budgetId,
          {
            onSuccess: (
              payload: InvestmentsCapitalInvestedEvolutionGraphProps
            ) => {
              dispatch(setInvestmentsCapitalEvolutionData(payload));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!summarizedInvestmentsLoaded) {
        dispatch(setLoading({ key: "summarizedInvestments", status: true }));

        ReportingApi.getSummarizedInvestments(
          budgetId,
          {
            onSuccess: (summarizedInvestments: SummarizedInvestments) => {
              dispatch(setSummarizedInvestments({ summarizedInvestments }));
            },
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [
    investmentsData,
    summarizedInvestments,
    budgetId,
    investmentsCapitalData,
  ]);

  return (
    <Box>
      <LoadingDialog
        key={uuid()}
        open={
          areSummarizedInvestmentsLoading ||
          isEvolutionDataLoading ||
          investmentsCapitalDataLoading
        }
        toDisplay="investment data"
      />
      <Grid container xs={12}>
        <Grid item xs={12}>
          <InvestmentsReportingTable />
        </Grid>

        <Grid item xs={12}>
          <InvestmentsPriceEvolutionGraph />
        </Grid>
      </Grid>
    </Box>
  );
};
