import {
  InterestRateType,
  Mortgage,
} from "../../../../../../aws/budgetar-back/src/types/mortgage.type";
import { calculateMortgage } from "../../../../../../aws/budgetar-back/src/util/mortgage";
import { MortgageAPI } from "../../../api/mortgages.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { CurrencyDropdown } from "../dropdowns/CurrencyDropdown";
import { GenericDropdown } from "../dropdowns/GenericDropdown";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const NewMortgageDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const budgetId = getBudgetId(useAppSelector);
  const preferredCurrency = useAppSelector(selectCurrency);

  const [creatingMortgage, setCreatingMortgage] = useState(false);
  const [mortgageLabel, setMortgageLabel] = useState("");
  const [principal, setPrincipal] = useState(0);
  const [interestRatePerAnnum, setInterestRatePerAnnum] = useState(0);
  const [termMonths, setTermMonths] = useState(0);
  const [interestRateType, setInterestRateType] = useState<InterestRateType>(
    InterestRateType.FIXED
  );
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [effectiveStartDate, setEffectiveStartDate] = useState(
    DateTime.now().toISODate()
  );
  const [mortgageCurrency, setMortgageCurrency] = useState(preferredCurrency);

  const handleClose = () => {
    setOpen(false);

    setMortgageCurrency(preferredCurrency);
  };

  const submit = () => {
    setCreatingMortgage(true);
    createMortgage();
  };

  const createMortgage = async () => {
    MortgageAPI.createMortgage(
      budgetId,
      {
        mortgageLabel,
        principal,
        interestRatePerAnnum,
        interestRateType,
        termMonths,
        effectiveStartDate,
        currency: mortgageCurrency,
      },
      {
        onSuccess: () => {
          setCreatingMortgage(false);
          onSuccess();
          handleClose();
        },
        onFailed,
      },
      () => logout(dispatch, navigate)
    );
  };

  const canSubmit = () => {
    return mortgageLabel?.length && principal && termMonths && monthlyPayment;
  };

  const calculate = (): Mortgage | undefined => {
    try {
      return calculateMortgage({
        principal,
        interestRatePerAnnum,
        termMonths,
        interestRateType,
        effectiveStartDate: DateTime.now().toISODate(),
      });
    } catch (err) {
      console.error(err);

      return undefined;
    }
  };

  useEffect(() => {
    const output = calculate();

    setMonthlyPayment(output?.monthlyPayment ?? 0);
  }, [principal, interestRatePerAnnum, termMonths]);

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <Grid item>
              <TextField
                label="Mortgage label"
                variant="outlined"
                onChange={(e) => setMortgageLabel(e.target.value)}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Principal"
                variant="outlined"
                onChange={(e) => setPrincipal(Number(e.target.value))}
                type="number"
              />
            </Grid>

            <Grid item>
              <TextField
                label="Interest rate"
                variant="outlined"
                onChange={(e) =>
                  setInterestRatePerAnnum(Number(e.target.value))
                }
                type="number"
              />
            </Grid>

            <Grid item>
              <TextField
                label="Months"
                variant="outlined"
                onChange={(e) => setTermMonths(Number(e.target.value))}
                type="number"
              />
            </Grid>

            <Grid item>
              <TextField
                placeholder="Monthly payment"
                variant="outlined"
                label="Monthly payment"
                onChange={(e) => setMonthlyPayment(Number(e.target.value))}
                type="number"
                value={monthlyPayment ? monthlyPayment.toFixed(2) : ""}
              />
            </Grid>

            <Grid item xs={12}>
              <CurrencyDropdown
                label="Currency"
                onCurrencySelected={setMortgageCurrency}
                preferredCurrency={mortgageCurrency}
              />
            </Grid>

            <Grid item xs={12}>
              <GenericDropdown
                label="Interest type"
                options={[InterestRateType.FIXED, InterestRateType.VARIABLE]}
                onSelection={setInterestRateType}
              />
            </Grid>

            <BudgetarDatePicker onSelect={setEffectiveStartDate} />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit() || creatingMortgage}
                onClick={submit}
              >
                {creatingMortgage ? "Submitting..." : "Create mortgage"}
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
