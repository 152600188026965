import { GeneralSettings } from "..//components/settings/GeneralSettings";
import { SubscriptionSettings } from "../components/settings/SubscriptionSettings";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { AccessManagement } from "./AccessManagement";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function TabPanel(props: {
  children: JSX.Element;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Settings = () => {
  const location = useLocation();

  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if ((location.state as any)?.forSubscription) {
      setValue(1);
    }
  }, []);

  return (
    <Box>
      <BudgetarToast />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Subscription" {...a11yProps(1)} />
          {/* <Tab label="Interface" {...a11yProps(1)} /> */}
          <Tab label="Access management" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <GeneralSettings />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <SubscriptionSettings />
      </TabPanel>

      {/* <TabPanel value={value} index={1}>
        <InterfaceSettings />
      </TabPanel> */}

      <TabPanel value={value} index={2}>
        <AccessManagement />
      </TabPanel>
    </Box>
  );
};
