import { activateUser } from "../api/users";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { logout } from "../components/utils.api";
import { useAppDispatch } from "../redux/hooks";
import { UserActivatedResponse } from "@backend/user.type";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TimerIcon from "@mui/icons-material/Timer";
import { Typography, Card, Grid, IconButton, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

export const UserActivation = () => {
  const { code } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [response, setResponse] = useState<UserActivatedResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (code) {
      activateUser(
        code,
        {
          onSuccess: (response: { data: UserActivatedResponse }) => {
            setResponse(response.data);
          },
          onFailed: (err: any) => {
            setError(err);
          },
        },
        () => logout(dispatch, navigate)
      );
    }
  }, []);

  if (response) {
    setTimeout(
      // () => navigate(`/auth/?activated=true&email=${response.userEmail}`),
      () => navigate(`/`),
      2500
    );
  }

  const renderExpiredActivationLink = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="20px"
    >
      <Card sx={{ padding: "20px", maxWidth: "400px" }}>
        <Grid container xs={12} sx={{ padding: "20px" }}>
          <Grid xs={12}>
            <IconButton>
              <TimerIcon fontSize="large" color="info" />
            </IconButton>
          </Grid>

          <Grid xs={12}>
            <Typography color="#ff9000" fontSize={22}>
              Activation link expired
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: "20px" }}>
            <Typography fontSize={18}>
              We have sent a new activation link to your e-mail address.
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: "20px" }}>
            <Typography fontSize={18}>
              Please use the new link to activate your account.
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );

  const renderSuccessfulActivation = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="20px"
    >
      <Card sx={{ padding: "20px", maxWidth: "400px" }}>
        <Grid container xs={12} sx={{ padding: "20px" }}>
          <Grid xs={12}>
            <IconButton>
              <CheckCircleOutlineIcon fontSize="large" color="info" />
            </IconButton>
          </Grid>

          <Grid xs={12}>
            <Typography color="#ff9000" fontSize={22}>
              Account activation successful
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: "20px" }}>
            <Typography fontSize={18}>
              You will be redirected to your dashboard shortly.
            </Typography>{" "}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );

  const renderFailedActivation = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="20px"
    >
      <Card sx={{ padding: "20px", maxWidth: "400px" }}>
        <Grid container xs={12} sx={{ padding: "20px" }}>
          <Grid xs={12}>
            <IconButton>
              <TimerIcon fontSize="large" color="info" />
            </IconButton>
          </Grid>

          <Grid xs={12}>
            <Typography color="#ff9000" fontSize={22}>
              Account activation failed
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: "20px" }}>
            <Typography fontSize={18}>
              Unfortunately, your account activation failed. Please try again
              shortly.
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: "20px" }}>
            <Typography fontSize={18}>
              If the error persists, feel free to contact our customer support.
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: "20px" }}>
            <SecondaryButton onClick={() => navigate("/")}>
              To dashboard
            </SecondaryButton>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );

  if (
    error ===
    "Activation link expired. System will automatically send another one."
  ) {
    return renderExpiredActivationLink();
  } else if (error) {
    return renderFailedActivation();
  }

  if (response) {
    return renderSuccessfulActivation();
  }

  return <LoadingDialog open={true} key={uuid()} />;
};
