import { Currency } from "@backend/currency.type";

function format(
  n: number,
  currency: string,
  maximumFractionDigits = 2
): string {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency,
    maximumFractionDigits,
  }).format(n);
}

export function formatNumberDispatch(
  n: number,
  currency: Currency,
  decimals?: number
) {
  return format(n, currency, decimals);
}

export function timeUntilGoalReached(days: number): string {
  if (days < 365 * 0.8) return `${days.toFixed(2)} days`;

  return `${(days / 365).toFixed(2)} years`;
}

export function redactString(): string {
  return "xxxxx";
}
