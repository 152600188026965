import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { Goal } from "@backend/goal.type";
import { createSlice } from "@reduxjs/toolkit";

interface GoalsState {
  goals: SingleLoad<Goal[]>;
}

export const initialState: GoalsState = {
  goals: singleLoadDefaultValue([]),
};

export const goalsSlice = createSlice({
  name: "goals",
  initialState,
  reducers: {
    setGoals: (state: GoalsState, action: { payload: { goals: Goal[] } }) => {
      const { goals } = action.payload;

      state.goals = getSingleLoadResponse(goals);
    },
    setGoalsLoading: (
      state: GoalsState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.goals.loading = status;
    },
    logout: (state: GoalsState) => {
      return initialState;
    },
  },
});

export const { setGoals, setGoalsLoading, logout } = goalsSlice.actions;

export const goalsState = (state: RootState) => state.goals;

export const selectGoals = (state: RootState): SingleLoad<Goal[]> =>
  goalsState(state).goals;
export const selectGoalsLoading = (state: RootState): boolean =>
  goalsState(state).goals.loading;

export const GoalsReducer = goalsSlice.reducer;
