import { fetchAdminBudget } from "../../../api/fetch.redux";
import { ReportingApi } from "../../../api/reporting.api";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { selectAdminBudgetId } from "../../../redux/reducers/budgetSlice";
import {
  selectMonthlyReports,
  setDefaultMonthlyReport,
  setMonthlyReport,
} from "../../../redux/reducers/reportingSlice";
import { padNumber } from "../../../util/Functions";
import { LoadingDialog } from "../../utility/dialogs/LoadingDialog";
import { BudgetarToast } from "../../utility/misc/BudgetarToast";
import { getBudgetId, logout } from "../../utils.api";
import { MonthlyReportTable } from "./MonthlyReportTable";
import { MonthlyReport } from "@backend/reporting.type";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface MonthlyReportChartProps {
  year: number;
  month: number;
}

export const MonthlyReportChart = (props: MonthlyReportChartProps) => {
  const { year, month } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const reports = useAppSelector(selectMonthlyReports);
  const budgetId = getBudgetId(useAppSelector);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!isMonthlyReportLoaded) {
        ReportingApi.getMonthlyReport(
          budgetId,
          year,
          month,
          {
            onSuccess: (monthlyReport: MonthlyReport) =>
              dispatch(setMonthlyReport({ monthlyReport, month, year })),
            onFailed: async (err: any) => {
              dispatch(setDefaultMonthlyReport({ month, year }));
            },
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId, year, month]);

  if (!reports[`${year}-${padNumber(month)}`])
    return <LoadingDialog key={uuid()} open={true} toDisplay="report" />;

  const {
    data,
    loaded: isMonthlyReportLoaded,
    loading: isMonthlyReportLoading,
  } = reports[`${year}-${padNumber(month)}`];

  if (isMonthlyReportLoading) return <LoadingDialog key={uuid()} open={true} />;

  return (
    <MonthlyReportTable report={data}>
      <BudgetarToast />
    </MonthlyReportTable>
  );
};
