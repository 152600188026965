export const themeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#8bb5c9",
      light: "#2e45c3",
      dark: "#2e45c3",
    },
    secondary: {
      main: "#039be5",
      contrastText: "#ffffff",
    },
    background: {
      paper: "#5b626b",
      default: "#212121",
    },
    info: {
      main: "#ff9000",
      contrastText: "rgba(75,113,133,255)",
    },
    warning: {
      main: "#ff9800",
    },
    error: {
      main: "#b51408",
    },
    accent: {
      main: "orange",
    },
    darkAccent: {
      main: "black",
    },
    text: {
      primary: "#ffffff",
      secondary: "rgba(197,195,195,0.54)",
      hint: "#4fc3f7",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  shape: {
    borderRadius: 15,
  },
};

export const SuperAccentColor = "black";

export const subThemeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#8bb5c9",
      light: "#2e45c3",
      dark: "#2e45c3",
    },
    secondary: {
      main: "#039be5",
      contrastText: "#ffffff",
    },
    background: {
      paper: "#7aa9c4",
      default: "#d5dadf",
    },
    info: {
      main: "#ff9000",
      contrastText: "rgba(75,113,133,255)",
    },
    warning: {
      main: "#ff9800",
    },
    error: {
      main: "#b51408",
    },
    accent: {
      main: "orange",
    },
    darkAccent: {
      main: "black",
    },
    text: {
      primary: "#ffffff",
      secondary: "rgba(197,195,195,0.54)",
      hint: "#4fc3f7",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  shape: {
    borderRadius: 15,
  },
};
