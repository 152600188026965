import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV024 = () => {
  return (
    <Release version="v0.2.4" releaseDate="2023-04-01">
      <ReleaseNotesSection title="Reporting">
        <ReleaseNotesEntry>
          Add "Capital invested" line to chart
        </ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Developer QoL">
        <ReleaseNotesEntry>
          Implement database read usage metrics
        </ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
