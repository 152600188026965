import { Identifiable } from "./common.type";
import { Currency } from "./currency.type";

export enum AssetType {
  VEHICLE = "VEHICLE",
  REAL_ESTATE = "REAL_ESTATE",
  OTHER = "OTHER",
}

export interface Asset extends Identifiable {
  assetName: string;
  assetType: AssetType;
  purchaseDate: number;
  purchasePrice: number;
  currency: Currency;
}

export interface PopulatedAsset extends Asset {
  currentValue: number;
}

export interface AssetPrice extends Identifiable {
  assetId: string;
  currentAssetPrice: number;
  assetPriceDate: number;
}

// interface UpdateAssetData {
//   newName?: string | null;
//   newType?: number | null;
// }

// interface RawAsset extends Asset {
//   PK: string;
// }

export interface CreateAssetPayload {
  assetName: string;
  assetType: AssetType;
  purchaseDate: number;
  purchasePrice: number;
  currency: Currency;
}

export interface AddAssetPriceInterface {
  assetSK: string;
  price: number;
  date: number;
}
