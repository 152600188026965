import { ExpenseAPI } from "../../../api/expenses/expenses.api";
import { fetchEvents } from "../../../api/fetch.redux";
import CalculatorTextField from "../../../components/common/CalculatorTextField";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccounts } from "../../../redux/reducers/accountsSlice";
import { selectCategories } from "../../../redux/reducers/categoriesSlice";
import { selectEvents } from "../../../redux/reducers/eventsSlice";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { AccountWithBalance } from "../../accounts/AccountsTable";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { SecondaryButton } from "../buttons/SecondaryButton";
import { AccountsDropdown } from "../dropdowns/AccountsDropdown";
import { CategoriesDropdown } from "../dropdowns/CategoriesDropdown";
import { CurrencyDropdown } from "../dropdowns/CurrencyDropdown";
import { AccountType } from "@backend/account.type";
import { Currency } from "@backend/currency.type";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export const NewExpenseDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
  presetAccount?: AccountWithBalance | null;
}) => {
  const { open, setOpen, onSuccess, onFailed, presetAccount } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const budgetId = getBudgetId(useAppSelector);
  const preferredCurrency = useAppSelector(selectCurrency);
  const { data: categories } = useAppSelector(selectCategories);
  const { data: accounts } = useAppSelector(selectAccounts);
  const { data: events } = useAppSelector(selectEvents);

  const [openAdvanced, setOpenAdvanced] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [eventName, setEventName] = useState<string | undefined>();
  const [expenseName, setExpenseName] = useState("");
  const [amount, setAmount] = useState(0);
  const [amountInvalid, setAmountInvalid] = useState(false);
  const [categoryId, setCategoryId] = useState(
    categories.length ? categories[0].SK : ""
  );

  const exclusiveAccountTypes = [
    AccountType.CREDITING,
    AccountType.INVESTMENTS,
    AccountType.LOAN,
  ];

  const [accountsToUse, setAccountsToUser] = useState(accounts);
  useMemo(() => {
    setAccountsToUser(
      accounts
        ? accounts.filter((e) => !exclusiveAccountTypes.includes(e.accountType))
        : []
    );
  }, []);

  const [expenseSourceAccountId, setExpenseSourceAccountId] = useState(
    presetAccount
      ? presetAccount.SK
      : accountsToUse.length
      ? accountsToUse[0].SK
      : ""
  );
  const [date, setDate] = useState(DateTime.now().toISODate());
  const [expenseCurrency, setExpenseCurrency] =
    useState<Currency>(preferredCurrency);

  const handleClose = () => {
    setSubmitting(false);
    setOpen(false);

    setExpenseCurrency(preferredCurrency);
    setEventName(undefined);
  };

  const handleExpenseNameChange = (newName: string) => setExpenseName(newName);
  const handleAmountChange = (newAmount: number) =>
    setAmount(Number(newAmount));
  const handleEventNameChange = (newName: string) => setEventName(newName);

  const submit = () => {
    setSubmitting(true);
    createExpense();
  };

  const createExpense = async () => {
    ExpenseAPI.addExpense(
      budgetId,
      {
        expenseName,
        amount,
        categoryId,
        expenseSourceAccountId,
        date,
        currency: expenseCurrency,
        eventName,
      },
      {
        onSuccess: () => {
          if (onSuccess) onSuccess();

          fetchEvents(budgetId, dispatch, false);

          handleClose();
        },
        onFailed,
      },
      () => logout(dispatch, navigate)
    );
  };

  const canSubmit = () => {
    return (
      expenseName &&
      expenseName.length &&
      amount &&
      categoryId &&
      categoryId.length &&
      expenseSourceAccountId &&
      expenseSourceAccountId.length &&
      !amountInvalid
    );
  };

  useEffect(() => {
    // TODO fix this for defaults
    setCategoryId(categories.length ? categories[0].SK : "");
    setExpenseSourceAccountId(
      presetAccount
        ? presetAccount.SK
        : accountsToUse.length
        ? accountsToUse[0].SK
        : ""
    );
  }, [accountsToUse, presetAccount, categories]);

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New expense</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Expense label"
              variant="outlined"
              onChange={(e) => handleExpenseNameChange(e.target.value)}
              sx={{ paddingTop: "10px" }}
            />

            <CalculatorTextField
              placeholder="Amount"
              variant="outlined"
              onChange={(e: number) => handleAmountChange(e)}
              setInvalidExpression={setAmountInvalid}
            />

            <Box sx={{ paddingTop: "10px" }}>
              <CategoriesDropdown
                categorySelected={setCategoryId}
                categoryTypeFilter="expense"
              />
            </Box>

            <Box sx={{ paddingTop: "10px" }}>
              <AccountsDropdown
                accountSelected={setExpenseSourceAccountId}
                initialOptionIndex={
                  accountsToUse.findIndex((e) => e.SK === presetAccount?.SK) ||
                  0
                }
                exclusiveAccountTypeFilters={exclusiveAccountTypes}
              />
            </Box>

            <BudgetarDatePicker onSelect={setDate} />

            {openAdvanced ? (
              <Box>
                <Autocomplete
                  fullWidth
                  freeSolo
                  id="event-name"
                  disableClearable
                  options={events.map((e) => e.eventName)}
                  onChange={(e, value) => handleEventNameChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Event name"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      fullWidth
                    />
                  )}
                />

                <Grid container xs={12} sx={{ paddingTop: "10px" }}>
                  <Grid item xs={12}>
                    <CurrencyDropdown
                      label="Expense currency"
                      onCurrencySelected={setExpenseCurrency}
                      preferredCurrency={expenseCurrency}
                    />
                  </Grid>
                </Grid>

                <SecondaryButton
                  onClick={() => setOpenAdvanced(false)}
                  sx={{ marginTop: "10px" }}
                >
                  Close advanced view
                </SecondaryButton>
              </Box>
            ) : (
              <SecondaryButton onClick={() => setOpenAdvanced(true)}>
                Advanced view
              </SecondaryButton>
            )}

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit() || submitting}
                onClick={submit}
              >
                {submitting ? "Creating expense..." : "Create expense"}
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
