import { CreditAPI } from "../../../api/credit.api";
import { logout } from "../../../components/utils.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { CreditInterestCalculator } from "../../credits/CreditInterestCalculator";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { CurrencyDropdown } from "../dropdowns/CurrencyDropdown";
import { Currency } from "@backend/currency.type";
import { Period } from "@backend/period.type";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const NewCreditDialog = (props: {
  budgetId: string;
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { budgetId, open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const preferredCurrency = useAppSelector(selectCurrency);

  const [creditName, setCreditName] = useState("");
  const [interestRate, setInterestRate] = useState(5);
  const [principal, setprincipal] = useState(1000);
  const [repaymentMonths, setRepaymentMonths] = useState(5);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [date, setDate] = useState(DateTime.now().toISODate());
  const [creditCurrency, setCreditCurrency] =
    useState<Currency>(preferredCurrency);

  const handleClose = () => {
    setOpen(false);

    setCreditCurrency(preferredCurrency);
  };

  const handleCreditNameChange = (newName: string) => setCreditName(newName);

  const submit = () => {
    createCredit();
    handleClose();
  };

  const createCredit = async () => {
    CreditAPI.createCredit(
      budgetId,
      {
        budgetId,
        creditName,
        amount: principal,
        createdOn: DateTime.fromISO(date).toMillis(),
        interestRate: interestRate,
        totalDue: monthlyPayment * repaymentMonths,
        paymentPeriod: Period.MONTHLY,
        paymentPerPeriod: monthlyPayment,
        currency: creditCurrency,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const canSubmit = () => {
    return creditName && creditName.length;
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New credit</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Credit label"
              variant="outlined"
              onChange={(e) => handleCreditNameChange(e.target.value)}
              sx={{ paddingTop: "10px" }}
            />

            <Grid container xs={12} sx={{ paddingTop: "10px" }}>
              <Grid item xs={12}>
                <CurrencyDropdown
                  label="Credit currency"
                  onCurrencySelected={setCreditCurrency}
                  preferredCurrency={creditCurrency}
                />
              </Grid>
            </Grid>

            <CreditInterestCalculator
              interestRate={interestRate}
              setInterestRate={setInterestRate}
              principal={principal}
              setprincipal={setprincipal}
              repaymentMonths={repaymentMonths}
              setRepaymentMonths={setRepaymentMonths}
              monthlyPayment={monthlyPayment}
              setMonthlyPayment={setMonthlyPayment}
            />

            <BudgetarDatePicker
              onSelect={(date: Date) =>
                setDate(date.toISOString().split("T")[0])
              }
            />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Create credit
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
