import { appTheme } from "../Theme";
import { ThemeContext } from "../ThemeContext";
import {
  fetchIncomes,
  fetchExpenses,
  fetchAccounts,
  fetchCategories,
  fetchGoals,
  fetchAdminBudget,
  fetchCurrentSubscription,
  fetchUserProfile,
} from "../api/fetch.redux";
import { ReportingApi } from "../api/reporting.api";
import { validateToken } from "../api/users";
import { DashboardGoals } from "../components/DashboardGoals";
import { DiscretionaryAccountsOverview } from "../components/DiscretionaryAccountsOverview";
import { AccountWithBalance } from "../components/accounts/AccountsTable";
import { DashboardIncomeExpense } from "../components/dashboard/DashboardIncomeExpense";
import { DashboardSavings } from "../components/dashboard/DashboardSavings";
import { AccentButton } from "../components/utility/buttons/AccentButton";
import { SuperAccentButton } from "../components/utility/buttons/SuperAccentButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewExpenseDialog } from "../components/utility/dialogs/NewExpenseDialog";
import { NewGoalDialog } from "../components/utility/dialogs/NewGoalDialog";
import { NewIncomeDialog } from "../components/utility/dialogs/NewIncomeDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { logout } from "../components/utils.api";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import {
  logout as accountsLogout,
  selectAccounts,
} from "../redux/reducers/accountsSlice";
import { logout as assetsLogout } from "../redux/reducers/assetsSlice";
import {
  selectAdminBudgetId,
  selectBudgetToUse,
  switchToAdmin,
  selectBudgetGranter,
} from "../redux/reducers/budgetSlice";
import {
  selectCategories,
  logout as categoriesLogout,
} from "../redux/reducers/categoriesSlice";
import { logout as expensesLogout } from "../redux/reducers/expensesSlice";
import {
  logout as goalsLogout,
  selectGoals,
} from "../redux/reducers/goalsSlice";
import { logout as incomesLogout } from "../redux/reducers/incomesSlice";
import { logout as investmentsLogout } from "../redux/reducers/investmentsSlice";
import { logout as loansLogout } from "../redux/reducers/loansSlice";
import {
  setMetadata,
  selectMetadata,
  selectInvestmentsNetWorth,
  setInvestmentsNetWorth,
  setAllExpensesAndIncomes,
  setTargetValues,
  setSavingsToday,
  setCurrentMonthOverview,
  setTargetMonthlyExpenses,
  setTargetMonthlyIncomes,
  selectCurrentMonthOverview,
  selectTargetMonthlyExpenses,
  selectTargetMonthlyIncomes,
  selectAllExpensesAndIncomes,
  selectTargetValues,
  logout as reportingLogout,
} from "../redux/reducers/reportingSlice";
import {
  logout as subscriptionLogout,
  selectCurrentSubscription,
} from "../redux/reducers/subscriptionSlice";
import { selectUserProfile } from "../redux/reducers/userSlice";
import { delay } from "../util/Functions";
import { findNextGoal } from "../util/Goals.util";
import { CurrentMonthOverview } from "@backend/reporting.type";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

export const Dashboard = () => {
  const [openNewIncomeDialog, setOpenNewIncomeDialog] = useState(false);
  const [openNewExpenseDialog, setOpenNewExpenseDialog] = useState(false);
  const [presetAccountExpenseDialog, setPresetAccountExpenseDialog] =
    useState<AccountWithBalance | null>(null);
  const [hideNumbers, setHideNumbers] = useState(false);
  const [openNewGoalDialog, setOpenNewGoalDialog] = useState(false);
  const [loadingDataGeneral, setLoadingDataGeneral] = useState(false);

  const ctx = useContext(ThemeContext);

  const reload = () => {
    dispatch(accountsLogout());
    dispatch(assetsLogout());
    dispatch(categoriesLogout());
    dispatch(expensesLogout());
    dispatch(goalsLogout());
    dispatch(incomesLogout());
    dispatch(investmentsLogout());
    dispatch(loansLogout());
    dispatch(reportingLogout());
    dispatch(subscriptionLogout());
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    data: adminBudgetId,
    loaded: isBudgetIdLoaded,
    loading: isBudgetIdLoading,
  } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const budgetGranter = useAppSelector(selectBudgetGranter);
  const { loaded: isCurrentSubscriptionLoaded } = useAppSelector(
    selectCurrentSubscription
  );

  const isUsingDifferentBudget = adminBudgetId !== budgetId;

  const {
    data: userProfile,
    loaded: userProfileLoaded,
    loading: userProfileLoading,
  } = useAppSelector(selectUserProfile);
  const {
    data: { firstEntry, monthsSinceFirstEntry },
    loaded: isMetadataLoaded,
    loading: isMetadataLoading,
  } = useAppSelector(selectMetadata);
  const {
    data: investmentsBalance,
    loaded: isInvestmentsNetWorthLoaded,
    loading: isInvestmentsNetWorthLoading,
  } = useAppSelector(selectInvestmentsNetWorth);
  const {
    data: allExpensesAndIncomes,
    loaded: areExpensesAndIncomesLoaded,
    loading: areExpensesAndIncomesLoading,
  } = useAppSelector(selectAllExpensesAndIncomes);
  const {
    data: targetValues,
    loaded: areTargetValuesLoaded,
    loading: areTargetValuesLoading,
  } = useAppSelector(selectTargetValues);
  const { loaded: areAccountsLoaded, loading: areAccountsLoading } =
    useAppSelector(selectAccounts);
  const { loaded: areCategoriesLoaded, loading: areCategoriesLoading } =
    useAppSelector(selectCategories);
  const {
    data: goals,
    loaded: areGoalsLoaded,
    loading: areGoalsLoading,
  } = useAppSelector(selectGoals);
  const {
    data: currentMonthOverview,
    loaded: isCurrentMonthOverviewLoaded,
    loading: isCurrentMonthOverviewLoading,
  } = useAppSelector(selectCurrentMonthOverview);
  const {
    data: targetMonthlyExpenses,
    loaded: areTargetMonthlyExpensesLoaded,
    loading: areTargetMonthlyExpensesLoading,
  } = useAppSelector(selectTargetMonthlyExpenses);
  const {
    data: targetMonthlyIncomes,
    loaded: areTargetMonthlyIncomesLoaded,
    loading: areTargetMonthlyIncomesLoading,
  } = useAppSelector(selectTargetMonthlyIncomes);

  const actualTotalIncomes = allExpensesAndIncomes.incomesSum;
  const actualTotalExpenses = allExpensesAndIncomes.expensesSum;
  const actualTotalSavings = actualTotalIncomes - actualTotalExpenses;
  const savingsAndInvestments = actualTotalSavings + investmentsBalance;
  const targetMonthlySavings = targetValues.monthlySavings;

  const nextGoal = findNextGoal(
    savingsAndInvestments,
    [...goals].sort((a, b) => a.amount - b.amount)
  );

  useEffect(() => {
    setLoadingDataGeneral(true);
    validateToken(
      {
        onSuccess: () => {
          setLoadingDataGeneral(false);
        },
        onFailed: () => {
          setLoadingDataGeneral(false);
        },
      },
      () => logout(dispatch, navigate)
    );

    if (isBudgetIdLoaded) {
      fetchUserProfile(dispatch, userProfileLoaded);
      fetchAccounts(budgetId, dispatch, areAccountsLoaded);
      fetchCategories(budgetId, dispatch, areCategoriesLoaded);
      fetchGoals(budgetId, dispatch, areGoalsLoaded);
      fetchCurrentSubscription(budgetId, dispatch, isCurrentSubscriptionLoaded);

      if (!isMetadataLoaded) {
        ReportingApi.getFirstEntryAndMonthsSince(
          budgetId,
          {
            onSuccess: (data: {
              firstEntry: any;
              monthsSinceFirstEntry: number;
            }) => {
              dispatch(setMetadata(data));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!isInvestmentsNetWorthLoaded) {
        ReportingApi.getInvestmentsNetWorth(
          budgetId,
          {
            onSuccess: (investmentsNetWorth: any) => {
              dispatch(setInvestmentsNetWorth({ investmentsNetWorth }));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!areExpensesAndIncomesLoaded) {
        ReportingApi.loadAllExpensesAndIncomes(
          budgetId,
          {
            onSuccess: (data: any) => {
              dispatch(setAllExpensesAndIncomes(data));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!areTargetValuesLoaded) {
        ReportingApi.getTargetMonthlySavings(
          budgetId,
          {
            onSuccess: (monthlySavings: number) => {
              dispatch(setTargetValues({ monthlySavings }));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!isCurrentMonthOverviewLoaded) {
        ReportingApi.getCurrentMonthOverview(
          budgetId,
          {
            onSuccess: (currentMonthOverview: CurrentMonthOverview) => {
              dispatch(setCurrentMonthOverview({ currentMonthOverview }));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!areTargetMonthlyExpensesLoaded) {
        ReportingApi.getTargetMonthlyExpenses(
          budgetId,
          {
            onSuccess: (targetMonthlyExpenses: number) => {
              dispatch(setTargetMonthlyExpenses({ targetMonthlyExpenses }));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!areTargetMonthlyIncomesLoaded) {
        ReportingApi.getTargetMonthlyIncomes(
          budgetId,
          {
            onSuccess: (targetMonthlyIncomes: number) => {
              dispatch(setTargetMonthlyIncomes({ targetMonthlyIncomes }));
            },
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const averageMonthlyIncome = actualTotalIncomes / monthsSinceFirstEntry;
  const averageMonthlySavings = savingsAndInvestments / monthsSinceFirstEntry;
  const targetSavingsTotal =
    monthsSinceFirstEntry * targetValues.monthlySavings;
  const dailyEstimatedChange = averageMonthlySavings / 30;

  const savingsPercentage = averageMonthlyIncome
    ? 100 * (averageMonthlySavings / averageMonthlyIncome)
    : 0;

  const onBudgetItemChange = () => {
    ReportingApi.loadAllExpensesAndIncomes(
      budgetId,
      {
        onSuccess: (data: any) => {
          dispatch(setAllExpensesAndIncomes(data));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getSavingsToday(
      budgetId,
      {
        onSuccess: (savingsToday: number) => {
          dispatch(setSavingsToday({ savingsToday }));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getCurrentMonthOverview(
      budgetId,
      {
        onSuccess: (currentMonthOverview: CurrentMonthOverview) => {
          dispatch(setCurrentMonthOverview({ currentMonthOverview }));
        },
      },
      () => logout(dispatch, navigate)
    );
  };

  const newIncomeAdded = () => {
    fetchIncomes(budgetId, dispatch, false);

    onBudgetItemChange();
    toast.success("New income added.");
  };

  const newExpenseAdded = () => {
    fetchExpenses(budgetId, dispatch, false);
    fetchAccounts(budgetId, dispatch, false);

    onBudgetItemChange();
    toast.success("New expense added.");
  };

  const switchBack = async () => {
    toast.info("Switching back to your budget...");

    await delay(1000, () => {
      dispatch(switchToAdmin());

      ctx.setTheme(appTheme);

      reload();

      toast.success("Switch done.");
    });
  };

  const BudgetNotification = () => (
    <Grid container justifyContent="flex-end">
      <Grid item xs={12}>
        <Typography fontSize="26" color={"#0a1a39"} textAlign={"center"}>
          Using budget of {budgetGranter}
        </Typography>
      </Grid>

      <Grid item xs={6} sx={{ margin: "2px" }}>
        <SuperAccentButton onClick={switchBack}>Switch back</SuperAccentButton>
      </Grid>
    </Grid>
  );

  const SavingsCard = () => (
    <DashboardSavings
      savings={savingsAndInvestments}
      numberOfMonths={monthsSinceFirstEntry}
      hideNumbers={hideNumbers}
      averageMonthlySavings={averageMonthlySavings}
      averageMonthlySavingsPercentage={savingsPercentage}
      firstEntryDate={firstEntry}
    />
  );

  const IncomeExpenseCard = () => (
    <DashboardIncomeExpense
      hideNumbers={hideNumbers}
      currentMonthIncome={currentMonthOverview.currentMonthIncome}
      currentMonthExpense={currentMonthOverview.currentMonthExpense}
      plannedMonthIncome={targetMonthlyIncomes}
      plannedMonthExpense={targetMonthlyExpenses}
    />
  );

  const DiscretionaryAccountsCard = () => (
    <Box sx={{ marginBottom: "20px", paddingBottom: "10px" }}>
      <DiscretionaryAccountsOverview
        hideNumbers={hideNumbers}
        onClickCallback={(account: AccountWithBalance) => {
          setPresetAccountExpenseDialog(account);
          setOpenNewExpenseDialog(true);
        }}
      />
    </Box>
  );

  const DashboardGoalsCard = () => (
    <DashboardGoals
      currentSavings={savingsAndInvestments}
      averageMonthlySavings={averageMonthlySavings}
      savingsGoal={targetSavingsTotal}
      nextGoal={nextGoal}
      dailyEstimatedChange={dailyEstimatedChange}
      hideNumbers={hideNumbers}
      targetMonthlySavings={targetMonthlySavings}
      monthsSinceStart={monthsSinceFirstEntry}
      onAddGoalClick={() => setOpenNewGoalDialog(true)}
    />
  );

  const newGoalsCreated = () => {
    fetchGoals(budgetId, dispatch, false);
    toast.success("New goal created.");
  };

  return (
    <Box>
      <Grid
        container
        sx={{ display: "flex", alignItems: "center", height: "80vh" }}
      >
        {
          isUsingDifferentBudget ? (
            <Grid xs={12} sx={{ marginBottom: "20px" }}>
              <BudgetNotification />
            </Grid>
          ) : null
          // <Grid item xs={6} sx={{ marginBottom: "20px", alignSelf: "right" }}>
          //   <SecondaryTypography textAlign="left">
          //     Welcome back
          //   </SecondaryTypography>
          //   <Typography textAlign="right" sx={{ marginRight: "20px" }}>
          //     {(currentUserEmail || "").split("@")[0]}
          //   </Typography>
          // </Grid>
        }

        <Grid
          xs={12}
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              paddingBottom: "20px",
              marginLeft: "20px",
              marginRight: "20px",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <SavingsCard />
            <IncomeExpenseCard />
            <DashboardGoalsCard />
            <DiscretionaryAccountsCard />

            <AccentButton
              variant="contained"
              size="small"
              sx={{ marginRight: "2.5px", marginLeft: "2.5px" }}
              onClick={() => setOpenNewIncomeDialog(true)}
            >
              <AddIcon />
              Add income
            </AccentButton>

            <AccentButton
              variant="contained"
              size="small"
              sx={{ marginRight: "2.5px", marginLeft: "2.5px" }}
              onClick={() => setOpenNewExpenseDialog(true)}
            >
              <RemoveIcon />
              Add expense
            </AccentButton>
          </Box>
        </Grid>
      </Grid>

      <BudgetarToast />

      <LoadingDialog
        key={uuid()}
        open={
          isBudgetIdLoading ||
          isMetadataLoading ||
          isInvestmentsNetWorthLoading ||
          areExpensesAndIncomesLoading ||
          areTargetValuesLoading ||
          areAccountsLoading ||
          areCategoriesLoading ||
          areGoalsLoading ||
          isCurrentMonthOverviewLoading ||
          areTargetMonthlyExpensesLoading ||
          areTargetMonthlyIncomesLoading ||
          loadingDataGeneral
        }
      />

      <NewIncomeDialog
        open={openNewIncomeDialog}
        setOpen={setOpenNewIncomeDialog}
        onSuccess={newIncomeAdded}
        onFailed={() => toast.error("Error while adding income.")}
      />
      <NewExpenseDialog
        open={openNewExpenseDialog}
        setOpen={setOpenNewExpenseDialog}
        onSuccess={newExpenseAdded}
        onFailed={() => toast.error("Error while adding expense.")}
        presetAccount={presetAccountExpenseDialog}
      />

      <NewGoalDialog
        open={openNewGoalDialog}
        setOpen={setOpenNewGoalDialog}
        onSuccess={newGoalsCreated}
        onFailed={() => toast.error("Error while creating goal.")}
      />
    </Box>
  );
};
