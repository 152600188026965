import { Callbacks } from "../../types/util";
import { postRequest, getRequest } from "../index";
import { GrantAccessRole } from "@backend/access-role.type";

const getAccessRolesUrl = (route: string) => `access-role/${route}`;

async function grantAccessRole(
  budgetId: string,
  args: GrantAccessRole,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getAccessRolesUrl(`grant/${budgetId}`),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function revokeAccessRole(
  granteeId: string,
  accessRoleSK: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getAccessRolesUrl(`revoke`),
    { granteeId, accessRoleSK },
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getAccessibleBudgets(
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(
    getAccessRolesUrl(`received`),
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getGrantedAccessRoles(
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(getAccessRolesUrl(`granted`), logoutCallback, onSuccess, onFailed);
}

export const AccessRoleAPI = {
  grantAccessRole,
  revokeAccessRole,
  getAccessibleBudgets,
  getGrantedAccessRoles,
};
