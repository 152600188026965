import { fetchAdminBudget } from "../../api/fetch.redux";
import { ReportingApi } from "../../api/reporting.api";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  selectBudgetToUse,
  selectAdminBudgetId,
} from "../../redux/reducers/budgetSlice";
import {
  selectAverageExpensesPerWeekDay,
  selectSummarizedExpensesPerDay,
  setAllExpensesAndIncomes,
  setAverageExpensesPerWeekDay,
  setLoading,
  setMetadata,
  setSummarizedExpensesPerDay,
  selectMetadata,
  selectAllExpensesAndIncomes,
} from "../../redux/reducers/reportingSlice";
import { shiftArrayElements } from "../../util/Functions";
import { CustomChartOptions, CenterTextPlugin } from "../charts/chart-plugins";
import { LoadingDialog } from "../utility/dialogs/LoadingDialog";
import { logout } from "../utils.api";
import { AllExpensesAndIncomes } from "./savings/SavingsProjectionTable";
import { useMediaQuery } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { Chart } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export interface ExpensesPerPeriodChartProps {
  displayMode?: "global-average" | "per-period";
}

export function ExpensesPerDayChart(
  componentProps?: ExpensesPerPeriodChartProps
) {
  const matches = useMediaQuery("(min-width:300px)");

  const { displayMode } = componentProps || { displayMode: "global-average" };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: expensesPerDay,
    loaded: expensesPerDayLoaded,
    loading: expensesPerDayLoading,
  } = useAppSelector(selectSummarizedExpensesPerDay);
  const {
    data: expensesAndIncomes,
    loaded: areExpensesAndIncomesLoaded,
    loading: areExpensesAndIncomesLoading,
  } = useAppSelector(selectAllExpensesAndIncomes);
  const {
    data: metadata,
    loaded: isMetadataLoaded,
    loading: isMetadataLoading,
  } = useAppSelector(selectMetadata);
  const {
    data: averageExpensesPerWeekDay,
    loaded: averageExpensesPerWeekDayLoaded,
    loading: averageExpensesPerWeekDayLoading,
  } = useAppSelector(selectAverageExpensesPerWeekDay);

  const firstEntry = DateTime.fromMillis(metadata.firstEntry);
  const daysSinceFirstEntry = DateTime.now().diff(firstEntry, "days").days;
  const averageDailyExpense =
    expensesAndIncomes.expensesSum / daysSinceFirstEntry;

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!expensesPerDayLoaded) {
        dispatch(setLoading({ key: "summarizedExpensesPerDay", status: true }));

        ReportingApi.getSummarizedExpensesPerDay(
          budgetId,
          {
            onSuccess: (summarizedExpensesPerDay: number[]) =>
              dispatch(
                setSummarizedExpensesPerDay({ summarizedExpensesPerDay })
              ),
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!areExpensesAndIncomesLoaded) {
        dispatch(setLoading({ key: "allExpensesAndIncomes", status: true }));

        ReportingApi.loadAllExpensesAndIncomes(
          budgetId,
          {
            onSuccess: (allExpensesAndIncomes: AllExpensesAndIncomes) =>
              dispatch(setAllExpensesAndIncomes(allExpensesAndIncomes)),
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!isMetadataLoaded) {
        dispatch(setLoading({ key: "metadata", status: true }));

        ReportingApi.getFirstEntryAndMonthsSince(
          budgetId,
          {
            onSuccess: (data: {
              firstEntry: any;
              monthsSinceFirstEntry: number;
            }) => {
              dispatch(setMetadata(data));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!averageExpensesPerWeekDayLoaded) {
        dispatch(
          setLoading({ key: "averageExpensesPerWeekDay", status: true })
        );

        ReportingApi.getAverageExpensesPerWeekDay(
          budgetId,
          {
            onSuccess: (averageExpensesPerWeekDay) =>
              dispatch(
                setAverageExpensesPerWeekDay({ averageExpensesPerWeekDay })
              ),
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Expenses per day",
      },
    },
  };

  const labels = [
    { date: DateTime.now().minus({ days: 6 }).weekdayLong, expenses: 0 },
    { date: DateTime.now().minus({ days: 5 }).weekdayLong, expenses: 0 },
    { date: DateTime.now().minus({ days: 4 }).weekdayLong, expenses: 0 },
    { date: DateTime.now().minus({ days: 3 }).weekdayLong, expenses: 0 },
    { date: DateTime.now().minus({ days: 2 }).weekdayLong, expenses: 0 },
    { date: "Yesterday", expenses: 0 },
    { date: "Today", expenses: 0 },
  ].map((e) => e.date);

  let expensesPerDayToUse = expensesPerDay;
  if (expensesPerDay.join("") === "0000000") {
    expensesPerDayToUse = [];
  }

  const shiftNumber = 7 - DateTime.now().weekday;
  const averageExpensesPerDayShifted = shiftArrayElements(
    Object.values(averageExpensesPerWeekDay).map(
      (e) => e.averageExpensesPerDay
    ),
    shiftNumber
  );

  const datasets: any = [
    {
      label: "Expenses",
      data: expensesPerDayToUse,
      backgroundColor: "darkred",
      type: "bar" as const,
      order: 2,
    },
  ];

  if (displayMode === "global-average") {
    datasets.push({
      label: "Daily average",
      data: Array.from(Array(7).keys()).map((_) => averageDailyExpense),
      borderColor: "white",
      type: "line" as const,
      order: 1,
    });
  } else {
    datasets.push({
      label: "Average per day",
      data: averageExpensesPerDayShifted,
      borderColor: "white",
      type: "line" as const,
      order: 0,
    });
  }

  const data = {
    labels,
    datasets,
  };

  const props = {
    ...(!matches && { height: 400 }),
  };

  if (
    areExpensesAndIncomesLoading ||
    expensesPerDayLoading ||
    isMetadataLoading ||
    averageExpensesPerWeekDayLoading
  )
    return <LoadingDialog key={uuid()} open={true} toDisplay="data" />;

  return (
    <Chart
      {...props}
      type="bar"
      options={{ ...options, ...CustomChartOptions } as any}
      data={data}
      plugins={[CenterTextPlugin as any]}
    />
  );
}
