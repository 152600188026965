import { IRoadmapQuarter } from "./AboutRoadmap";
import { DateTime } from "luxon";

const Q32023: IRoadmapQuarter = {
  quarterLabel: "Q3 2023",
  roadmapItems: [
    {
      targetDate: DateTime.fromISO("2023-07-20"),
      label: "Emergency funds",
    },
  ],
};

const Q42023: IRoadmapQuarter = {
  quarterLabel: "Q4 2023",
  roadmapItems: [
    {
      targetDate: DateTime.fromISO("2023-10-01"),
      label: "Savings guidelines",
    },
    {
      targetDate: DateTime.fromISO("2023-11-01"),
      label: "Frontend optimization - pagination on expense & income items",
    },
    {
      targetDate: DateTime.fromISO("2023-12-20"),
      label: "Sell assets",
    },
  ],
};

const Q12024: IRoadmapQuarter = {
  quarterLabel: "Q1 2024",
  roadmapItems: [
    {
      targetDate: DateTime.fromISO("2024-02-10"),
      label: "Frontend improvements - more hints and tips throughout",
    },
    {
      targetDate: DateTime.fromISO("2024-03-25"),
      label: "Templates for quick user setup",
    },
  ],
};

const Q22024: IRoadmapQuarter = {
  quarterLabel: "Q2 2024",
  roadmapItems: [
    {
      targetDate: DateTime.fromISO("2024-04-15"),
      label: "MFA",
    },
    {
      targetDate: DateTime.fromISO("2024-05-20"),
      label: "Crediting - add actions & other improvements",
    },
    {
      targetDate: DateTime.fromISO("2024-06-20"),
      label: "Submit feature request",
    },
  ],
};

const Q32024: IRoadmapQuarter = {
  quarterLabel: "Q3 2024",
  roadmapItems: [
    {
      targetDate: DateTime.fromISO("2024-07-20"),
      label: "Tutorials",
    },
    {
      targetDate: DateTime.fromISO("2024-09-15"),
      label: "Subscription improvements - referrals and trial mode",
    },
  ],
};

const Q42024: IRoadmapQuarter = {
  quarterLabel: "Q4 2024",
  roadmapItems: [
    {
      targetDate: DateTime.fromISO("2024-10-15"),
      label: "Multi-language support",
    },
    {
      targetDate: DateTime.fromISO("2024-12-20"),
      label: "Data import from various formats",
    },
  ],
};

const Q12025: IRoadmapQuarter = {
  quarterLabel: "Q1 2025",
  roadmapItems: [
    {
      targetDate: DateTime.fromISO("2025-02-01"),
      label: "Multi-language support",
    },
    {
      targetDate: DateTime.fromISO("2025-02-20"),
      label: "Category grouping",
    },
    {
      targetDate: DateTime.fromISO("2025-03-30"),
      label: "Support investment ISIN attribute",
    },
  ],
};

const Q22025: IRoadmapQuarter = {
  quarterLabel: "Q2 2025",
  roadmapItems: [
    {
      targetDate: DateTime.fromISO("2025-06-30"),
      label: "Offline-only mode",
    },
  ],
};

export const roadmapQuarters = [
  Q32023,
  Q42023,
  Q12024,
  Q22024,
  Q32024,
  Q42024,
  Q12025,
  Q22025,
];
