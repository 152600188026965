import { EventAPI } from "../../../api/events.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getBudgetId, logout } from "../../utils.api";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const NewEventDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [eventName, setEventName] = React.useState("");

  const budgetId = getBudgetId(useAppSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEventNameChange = (newName: string) => setEventName(newName);

  const submit = () => {
    createEvent();
    handleClose();
  };

  const createEvent = async () => {
    EventAPI.createEvent(
      budgetId,
      {
        eventName,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const canSubmit = () => {
    return eventName?.length;
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New event</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Event name"
              variant="outlined"
              onChange={(e) => handleEventNameChange(e.target.value)}
              sx={{ paddingTop: "10px" }}
            />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Create event
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
