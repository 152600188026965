import { fetchUserProfile } from "../../api/fetch.redux";
import { setUserSettings } from "../../api/users";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  selectUserProfile,
  setPreferredCurrency,
} from "../../redux/reducers/userSlice";
import { BudgetarPopover } from "../common/BudgetarPopover";
import { LoadingDialog } from "../utility/dialogs/LoadingDialog";
import { CurrencyDropdown } from "../utility/dropdowns/CurrencyDropdown";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { logout, logoutCurrencyData } from "../utils.api";
import { Currency } from "@backend/currency.type";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

export const GeneralSettings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    data: userProfile,
    loaded: userProfileLoaded,
    loading: userProfileLoading,
  } = useAppSelector(selectUserProfile);

  const changeCurrency = (newCurrency: Currency) => {
    setUserSettings(
      { preferredCurrency: newCurrency },
      {
        onSuccess: () => {
          dispatch(setPreferredCurrency({ newCurrency }));
          logoutCurrencyData(dispatch);
        },
      },
      () => logout(dispatch, navigate)
    );
  };

  useEffect(() => {
    fetchUserProfile(dispatch, userProfileLoaded);
  }, []);

  return (
    <Box>
      <LoadingDialog key={uuid()} open={userProfileLoading} />

      <BudgetarToast />
      <Box
        sx={{ display: "flex", paddingTop: "60px", paddingLeft: "10px" }}
      ></Box>

      <Grid container sx={{ paddingTop: "30px" }}>
        <Grid item xs={3}>
          <CurrencyDropdown
            onCurrencySelected={changeCurrency}
            preferredCurrency={userProfile.preferredCurrency}
          />
        </Grid>

        <Grid item xs={1} sx={{ paddingTop: "10px" }}>
          <BudgetarPopover
            IconOpen={<HelpOutlineIcon />}
            IconClosed={<HelpOutlineIcon />}
            label=""
          >
            <Box>
              <Typography sx={{ padding: "10px" }}>
                Your primary currency is used as base when doing currency
                conversion.
              </Typography>
            </Box>
          </BudgetarPopover>
        </Grid>
      </Grid>
    </Box>
  );
};
