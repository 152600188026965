import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { SingleCreditsTableRow } from "./SingleCreditTableRow";
import { CreditWithBalance } from "@backend/credit.type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { toast } from "react-toastify";

export const CreditsTable = (props: {
  credits: CreditWithBalance[];
  refreshCredits: () => void;
}) => {
  const { credits, refreshCredits } = props;

  const refresh = (message?: string) => {
    if (message) toast.success(message);

    refreshCredits();
  };

  const mappedItems = credits.map((credit) => (
    <SingleCreditsTableRow
      key={credit.SK}
      credit={credit}
      deleteCreditCall={() => console.error("Not yet implemented")}
      newCreditPaymentCreatedCallback={() => refresh("Credit payment added.")}
    />
  ));

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <BudgetarToast />

      <TableContainer component={Paper}>
        <Table size="small" aria-label="credits table">
          <TableHead>
            <TableRow hover={true}>
              <TableCell>
                <Typography textAlign="center">Credit</Typography>
              </TableCell>
              <TableCell>
                <Typography textAlign="center">Created on</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography textAlign="center">Credit principal</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography textAlign="center">Interest rate</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography textAlign="center">Total to repay</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography textAlign="center">Monthly repayments</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography textAlign="center">Repayments done</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography textAlign="center">Outstanding balance</Typography>
              </TableCell>
              <TableCell>
                <Typography textAlign={"center"}>Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {mappedItems.length ? (
            <TableBody>{mappedItems}</TableBody>
          ) : (
            <Typography>No credits recorded.</Typography>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
