import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { LoanPopulated } from "@backend/loan.type";
import { createSlice } from "@reduxjs/toolkit";

interface LoansState {
  loans: SingleLoad<LoanPopulated[]>;
}

export const initialState: LoansState = {
  loans: singleLoadDefaultValue([]),
};

export const loansSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    setLoans: (
      state: LoansState,
      action: { payload: { loans: LoanPopulated[] } }
    ) => {
      const { loans } = action.payload;

      state.loans = getSingleLoadResponse(loans);
    },
    setLoansLoading: (
      state: LoansState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.loans.loading = status;
    },
    logout: (state: LoansState) => {
      return initialState;
    },
  },
});

export const { setLoans, setLoansLoading, logout } = loansSlice.actions;

export const loansState = (state: RootState) => state.loans;

export const selectLoans = (state: RootState): SingleLoad<LoanPopulated[]> =>
  loansState(state).loans;
export const selectLoansLoading = (state: RootState): boolean =>
  loansState(state).loans.loading;

export const LoansReducer = loansSlice.reducer;
