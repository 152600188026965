import { ReleaseV01a } from "./release-notes/ReleaseV01a";
import { ReleaseV011 } from "./release-notes/ReleaseV011";
import { ReleaseV012 } from "./release-notes/ReleaseV012";
import { ReleaseV013 } from "./release-notes/ReleaseV013";
import { ReleaseV014 } from "./release-notes/ReleaseV014";
import { ReleaseV015 } from "./release-notes/ReleaseV015";
import { ReleaseV016 } from "./release-notes/ReleaseV016";
import { ReleaseV017 } from "./release-notes/ReleaseV017";
import { ReleaseV018 } from "./release-notes/ReleaseV018";
import { ReleaseV02 } from "./release-notes/ReleaseV02";
import { ReleaseV021 } from "./release-notes/ReleaseV021";
import { ReleaseV022 } from "./release-notes/ReleaseV022";
import { ReleaseV023 } from "./release-notes/ReleaseV023";
import { ReleaseV024 } from "./release-notes/ReleaseV024";
import { ReleaseV025 } from "./release-notes/ReleaseV025";
import { ReleaseV03 } from "./release-notes/ReleaseV03";
import { ReleaseV031 } from "./release-notes/ReleaseV031";
import { ReleaseV032 } from "./release-notes/ReleaseV032";
import { ReleaseV033 } from "./release-notes/ReleaseV033";
import { ReleaseV04 } from "./release-notes/ReleaseV04";
import { ReleaseV041 } from "./release-notes/ReleaseV041";
import { ReleaseV05 } from "./release-notes/ReleaseV05";
import { ReleaseV051 } from "./release-notes/ReleaseV051";
import { Box } from "@mui/system";

export const AboutReleaseNotes = () => {
  const releases: any[] = [
    ReleaseV01a(),
    ReleaseV011(),
    ReleaseV012(),
    ReleaseV013(),
    ReleaseV014(),
    ReleaseV015(),
    ReleaseV016(),
    ReleaseV017(),
    ReleaseV018(),
    ReleaseV02(),
    ReleaseV021(),
    ReleaseV022(),
    ReleaseV023(),
    ReleaseV024(),
    ReleaseV025(),
    ReleaseV03(),
    ReleaseV031(),
    ReleaseV032(),
    ReleaseV033(),
    ReleaseV04(),
    ReleaseV041(),
    ReleaseV05(),
    ReleaseV051(false),
  ].reverse();

  return <Box>{releases}</Box>;
};
