import { GenericDropdown } from "./GenericDropdown";
import { AccountType } from "@backend/account.type";

export const AccountTypeDropdown = (props: {
  accountTypeSelected: any;
  isPremiumSubscription: boolean;
}) => {
  const { accountTypeSelected, isPremiumSubscription } = props;

  const accountTypes: (
    | string
    | { disabled: boolean; label: string; tooltip: string }
  )[] = [
    AccountType.BANKACCOUNT,
    AccountType.CREDITCARD,
    AccountType.CASH,
    AccountType.INVESTMENTS,
    AccountType.LOAN,
  ];

  if (isPremiumSubscription) {
    accountTypes.push(AccountType.DISCRETIONARY);
  } else {
    accountTypes.push({
      disabled: true,
      label: AccountType.DISCRETIONARY,
      tooltip: "This is a Premium feature.",
    });
  }

  return (
    <GenericDropdown
      label={"Account type"}
      options={accountTypes}
      onSelection={accountTypeSelected}
    />
  );
};
