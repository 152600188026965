import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { Asset } from "@backend/asset.type";
import { createSlice } from "@reduxjs/toolkit";

interface AssetsState {
  assets: SingleLoad<Asset[]>;
}

export const initialState: AssetsState = {
  assets: singleLoadDefaultValue([]),
};

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setAssets: (
      state: AssetsState,
      action: { payload: { assets: Asset[] } }
    ) => {
      const { assets } = action.payload;

      state.assets = getSingleLoadResponse(assets);
    },
    setAssetsLoading: (
      state: AssetsState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.assets.loading = status;
    },
    logout: (state: AssetsState) => {
      return initialState;
    },
  },
});

export const { setAssets, setAssetsLoading, logout } = assetsSlice.actions;

export const assetsState = (state: RootState) => state.assets;

export const selectAssets = (state: RootState): SingleLoad<Asset[]> =>
  assetsState(state).assets;
export const selectAssetsLoading = (state: RootState): boolean =>
  assetsState(state).assets.loading;

export const AssetsReducer = assetsSlice.reducer;
