import { IconButton, Tooltip } from "@mui/material";

interface ActionButtonProps {
  Icon: any;
  onIconClick: (data: any) => void;
  tooltip?: string | undefined;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
}

export const ActionButton = (props: ActionButtonProps) => {
  const { Icon, onIconClick, tooltip, disabled, size } = props;

  const color = disabled ? "gray" : "warning";

  if (tooltip) {
    if (disabled) {
      return (
        <Tooltip title={tooltip} enterTouchDelay={0}>
          <span>
            <IconButton disabled={disabled} size={size || "medium"}>
              <Icon onClick={onIconClick} color={color} />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    return (
      <IconButton disabled={disabled} size={size || "medium"}>
        <Tooltip title={tooltip} enterTouchDelay={0}>
          <Icon onClick={onIconClick} color={color} />
        </Tooltip>
      </IconButton>
    );
  }

  return (
    <IconButton disabled={disabled}>
      <Icon onClick={onIconClick} color={color} />
    </IconButton>
  );
};
