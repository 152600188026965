import { loginUser } from "../../api/auth";
import { getAdminUser } from "../../api/users";
import { useAppDispatch } from "../../redux/hooks";
import { setCurrentUser } from "../../redux/reducers/userSlice";
import { forceFetchBaseData, logout } from "../utils.api";
import { AuthCommon, AuthParentInterface } from "./AuthCommon";
import { useNavigate } from "react-router-dom";

export const LoginComponent = (props: AuthParentInterface) => {
  const { setError, setPending } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const action = (email: string, password: string) =>
    loginUser(
      { email, password },
      {
        onSuccess: (response) => {
          if (response.data && response.data.errorMessage) {
            setError(response.data.errorMessage);
            return;
          }

          const { token, username } = response;
          dispatch(setCurrentUser({ email, username, token }));

          getAdminUser(
            {
              onSuccess: (data: { budgetId: string }) => {
                forceFetchBaseData(data.budgetId, dispatch);
              },
              onFailed: (err: string) => console.error(err),
            },
            () => logout(dispatch, navigate)
          );

          setPending(false);
          navigate("/");
        },
        onFailed: (err) => {
          setPending(false);
          console.error(err);
          setError(err);
        },
      }
    );

  return (
    <AuthCommon
      title={"User login"}
      submitTitle={"Login"}
      submittingString={"Signing in..."}
      question={"Not signed up yet?"}
      alternative={"Create an account"}
      parent={props}
      subComponent={{ action }}
    />
  );
};
