import { LoginComponent } from "../components/auth/LoginComponent";
import { RecoverComponent } from "../components/auth/RecoverComponent";
import { SignUpComponent } from "../components/auth/SignUpComponent";
import { Grid } from "@mui/material";
import { useState } from "react";

export function Authentication() {
  const [onLogin, setOnLogin] = useState(true);
  const [recoverPasswordDisplay, setRecoverPasswordDisplay] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [pending, setPending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const clearInputs = () => {
    setEmail("");
    setPassword("");
    setRePassword("");
  };

  const changeComponent = () => {
    clearInputs();
    setOnLogin(!onLogin);
  };

  const parentProps = {
    error,
    setError,
    changeComponent,
    email,
    setEmail,
    code,
    setCode,
    password,
    repassword,
    setPassword,
    setRePassword,
    pending,
    setPending,
    showPassword,
    setShowPassword,
    setRecoverPasswordDisplay,
  };

  if (recoverPasswordDisplay) return <RecoverComponent {...parentProps} />;

  return (
    <Grid xs={12}>
      <Grid xs={12}>
        {onLogin ? (
          <LoginComponent {...parentProps} />
        ) : (
          <SignUpComponent {...parentProps} doublePasswordInput={true} />
        )}
      </Grid>
    </Grid>
  );
}
