import { useAppSelector } from "../../../redux/hooks";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../../util/Formatter";
import { ReportingInvestment } from "@backend/investment.type";
import { TableCell, TableRow, Typography, Grid, Card } from "@mui/material";

export const SingleInvestmentsReportingExpenseEmptyTableRow = () => (
  <TableRow hover={true}>
    <TableCell colSpan={20}>
      <Typography textAlign={"center"} color="orange">
        No investment data yet.
      </Typography>
    </TableCell>
  </TableRow>
);

export const SingleInvestmentsReportingExpenseTableRow = (props: {
  investment: ReportingInvestment;
  matches: boolean;
}) => {
  const { investment, matches } = props;

  const {
    investmentName,
    owned,
    averagePurchasePrice,
    currentPrice,
    currentValue,
    entryFeeCost,
    valueDelta,
    percentageDelta,
    breakEven,
    feesUponExit,
  } = investment;

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  const renderFullSize = () => {
    return (
      <TableRow hover={true}>
        <TableCell>{investmentName}</TableCell>
        <TableCell>{f(averagePurchasePrice)}</TableCell>
        <TableCell>{owned.toFixed(2)}</TableCell>
        <TableCell>{f(owned * averagePurchasePrice)}</TableCell>
        <TableCell>{f(currentPrice)}</TableCell>
        <TableCell>{f(currentValue)}</TableCell>
        <TableCell>{f(entryFeeCost)}</TableCell>
        <TableCell>{f(valueDelta)}</TableCell>
        <TableCell>{percentageDelta}%</TableCell>
        <TableCell>{breakEven}</TableCell>
        <TableCell>{f(feesUponExit)}</TableCell>
      </TableRow>
    );
  };

  const renderMini = () => {
    return (
      <TableRow hover={false}>
        <TableCell>
          <Card>
            <Grid container>
              <Grid item xs={12} sx={{ paddingBottom: "10px" }}>
                <Typography textAlign="center">{investmentName}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  Owned {owned.toFixed(2)} at avg. purchase price{" "}
                  {f(averagePurchasePrice)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  Current value: {f(currentValue)}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </TableCell>
      </TableRow>
    );
  };

  return matches ? renderFullSize() : renderMini();
};

interface ReportingInvestmentsSummaryRowProps {
  totalCapitalInvested: number;
  totalCurrentValue: number;
  totalFees: number;
  totalValueDelta: number;
  totalExitFees: number;
  matches: boolean;
}

export const ReportingInvestmentsSummaryRow = ({
  totalCapitalInvested,
  totalCurrentValue,
  totalFees,
  totalValueDelta,
  totalExitFees,
  matches,
}: ReportingInvestmentsSummaryRowProps) => {
  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  if (matches) {
    return (
      <TableRow>
        <TableCell>
          <Typography fontWeight={900}>Totals</Typography>
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell>
          <Typography fontWeight={900}>
            Total invested: {f(totalCapitalInvested)}
          </Typography>
        </TableCell>
        <TableCell />
        <TableCell>
          <Typography fontWeight={900}>
            Current value: {f(totalCurrentValue)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography fontWeight={900}>Fees paid: {f(totalFees)}</Typography>
        </TableCell>
        <TableCell>
          <Typography fontWeight={900}>
            Current delta: {f(totalValueDelta)}
          </Typography>
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell>
          <Typography fontWeight={900}>
            Exit fees due: {f(totalExitFees)}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>
        <Card sx={{ padding: "5px" }}>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography fontWeight={900}>
                Total invested: {f(totalCapitalInvested)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={900}>
                Current value: {f(totalCurrentValue)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={900}>
                Current delta: {f(totalValueDelta)}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography fontWeight={200}>
                Fees paid: {f(totalFees)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight={200}>
                Exit fees due: {f(totalExitFees)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </TableCell>
    </TableRow>
  );
};
