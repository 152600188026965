import { Callbacks } from "../../types/util";
import { postRequest, getRequest } from "../index";
import { UserSettings } from "@backend/user.type";

export async function createUser(
  email: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  postRequest("user/create", { email }, logoutCallback, onSuccess, onFailed);
}

export async function getAdminUser(
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest("user/admin-budget", logoutCallback, onSuccess, onFailed);
}

export async function getUserProfile(
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest("user/profile", logoutCallback, onSuccess, onFailed);
}

export async function setUserSettings(
  userSettings: UserSettings,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  postRequest(
    "user/settings",
    userSettings,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export async function activateUser(
  code: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  postRequest(
    `user/activation/${code}`,
    {},
    logoutCallback,
    onSuccess,
    onFailed
  );
}

export async function validateToken(
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  postRequest(`auth/validate-token`, {}, logoutCallback, onSuccess, onFailed);
}
