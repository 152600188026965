import { useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/reducers/userSlice";
import { redactString, formatNumberDispatch } from "../../util/Formatter";
import { AccentTypography } from "../common/AccentTypography";
import { BudgetarPopover } from "../common/BudgetarPopover";
import { Card, Grid, Typography, Box } from "@mui/material";
import { DateTime } from "luxon";

interface DashboardSavingsProps {
  savings: number;
  averageMonthlySavings: number;
  averageMonthlySavingsPercentage: number;
  firstEntryDate: number;
  numberOfMonths: number;
  hideNumbers: boolean;
}

export const DashboardSavings = (props: DashboardSavingsProps) => {
  const {
    savings,
    averageMonthlySavings,
    averageMonthlySavingsPercentage,
    firstEntryDate,
    numberOfMonths,
    hideNumbers,
  } = props;

  const currency = useAppSelector(selectCurrency);

  const format = (n: number, maxDecimals?: number) =>
    hideNumbers
      ? redactString()
      : formatNumberDispatch(n, currency, maxDecimals);

  return (
    <Card sx={{ marginBottom: "20px" }}>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography paddingLeft="20px" paddingTop="5px" textAlign={"left"}>
            Savings
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <AccentTypography fontSize={30} textAlign={"center"}>
            {format(savings, 0)}
          </AccentTypography>
        </Grid>

        <Grid item xs={12} alignItems={"right"}>
          <BudgetarPopover>
            <Box sx={{ margin: "10px" }}>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography textAlign={"left"} fontSize={12}>
                    Average monthly
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography textAlign={"right"} fontSize={20}>
                    {format(averageMonthlySavings)} (
                    {averageMonthlySavingsPercentage.toFixed(0)}%)
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography textAlign={"left"} fontSize={12}>
                    since{" "}
                    {DateTime.fromMillis(firstEntryDate).toFormat(
                      "dd LLL yyyy"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography textAlign={"right"} fontSize={20}>
                    {numberOfMonths.toFixed(0)} months
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </BudgetarPopover>
        </Grid>
      </Grid>
    </Card>
  );
};
