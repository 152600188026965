import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

const Text = styled(
  Typography,
  {}
)((extraProps: { fontWeight?: number }) => ({
  ...extraProps,
  textAlign: "left",
}));

export const AboutIntro = () => {
  return (
    <Box>
      <Text>
        budgetar is a budgeting app, created to help you track your incomes,
        expenses, savings, and more.
      </Text>
      <br />

      <Text>
        Inspired by various spreadsheets that I have found and used from all
        over the Internet, the app aims to provide as much useful and insightful
        features as possible, all with the goal of helping the user have more
        vision and control over their spending habits, investment movements and
        net worth evolution.
      </Text>
      <br />

      <Text>
        As a user, you can add your incomes and expenses and group them by
        categories. The app then generates various reports and statistics to
        help you better visualize your spending patterns.
      </Text>
      <Text>
        In addition, the app allows you to track your assets, and gives you a
        detailed overview of the evolution of your net worth over time.
      </Text>
      <br />

      <Text>
        Sure, much of the features here can be easily implemented via
        spreadsheets, as I had done for a long time. However, having it all in a
        performant and optimized application helps you get all the important
        information in a faster, more accessible, and more reliable way.
      </Text>
      <br />

      <Text color={"orange"}>budgetar is a free-to-use software.</Text>

      <Text color={"orange"}>All core features are free-to-use.</Text>

      <Text color={"orange"}>
        Some reporting features are only available through a subscription plan.
      </Text>
    </Box>
  );
};
