import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { AccessRoleItem } from "@backend/access-role.type";
import { createSlice } from "@reduxjs/toolkit";

export interface AccessRoleState {
  receivedBudgetIdAccesses: SingleLoad<AccessRoleItem[]>;
  grantedAccesses: SingleLoad<AccessRoleItem[]>;
}

export const initialState: AccessRoleState = {
  receivedBudgetIdAccesses: singleLoadDefaultValue([]),
  grantedAccesses: singleLoadDefaultValue([]),
};

export const accessRoleSlice = createSlice({
  name: "accessRole",
  initialState,
  reducers: {
    setReceivedBudgetAccesses: (
      state: AccessRoleState,
      action: {
        payload: { budgetAccesses: AccessRoleItem[] };
      }
    ) => {
      state.receivedBudgetIdAccesses = getSingleLoadResponse(
        action.payload.budgetAccesses
      );
    },
    setReceivedBudgetIdAccessesLoading: (
      state: AccessRoleState,
      action: { payload: { newStatus: boolean } }
    ) => {
      state.receivedBudgetIdAccesses.loading = action.payload.newStatus;
    },
    setGrantedAccesses: (
      state: AccessRoleState,
      action: {
        payload: { grantedAccesses: AccessRoleItem[] };
      }
    ) => {
      state.grantedAccesses = getSingleLoadResponse(
        action.payload.grantedAccesses
      );
    },
    setGrantedAccessesLoading: (
      state: AccessRoleState,
      action: { payload: { newStatus: boolean } }
    ) => {
      state.grantedAccesses.loading = action.payload.newStatus;
    },
    logout: (state: AccessRoleState) => {
      return initialState;
    },
  },
});

export const {
  setReceivedBudgetAccesses,
  setReceivedBudgetIdAccessesLoading,
  setGrantedAccesses,
  setGrantedAccessesLoading,
  logout,
} = accessRoleSlice.actions;

export const accessRoleState = (state: RootState) => state.accessRole;
export const selectAccessibleBudgets = (state: RootState) =>
  accessRoleState(state).receivedBudgetIdAccesses;
export const selectedGrantedBudgetAccesses = (state: RootState) =>
  accessRoleState(state).grantedAccesses;

export const AccessRoleReducer = accessRoleSlice.reducer;
