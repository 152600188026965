import { formatNumberDispatch } from "../../util/Formatter";
import { ActionButton } from "../common/ActionButton";
import { NewCreditPaymentDialog } from "../utility/dialogs/NewCreditPaymentDialog";
import { CreditWithBalance } from "@backend/credit.type";
import AddCardIcon from "@mui/icons-material/AddCard";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableCell, TableRow, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { toast } from "react-toastify";

export const SingleCreditsTableRow = (props: {
  credit: CreditWithBalance;
  deleteCreditCall: (creditSK: string) => void;
  newCreditPaymentCreatedCallback: () => void;
}) => {
  const { credit, deleteCreditCall, newCreditPaymentCreatedCallback } = props;
  const {
    SK,
    creditName,
    amount,
    interestRate,
    totalDue,
    paymentPerPeriod,
    createdOn,
    closedOn,
    canceledOn, // TODO handle canceled
    creditPayments,
    remainingBalance,
    currency,
  } = credit;

  const [openAddCreditPaymentDialog, setOpenAddCreditPaymentDialog] =
    useState(false);

  const format = (n: number) => formatNumberDispatch(n, currency);

  return (
    <TableRow key={SK}>
      <NewCreditPaymentDialog
        credit={credit}
        open={openAddCreditPaymentDialog}
        setOpen={setOpenAddCreditPaymentDialog}
        onSuccess={newCreditPaymentCreatedCallback}
        onFailed={() => toast.error("Error while creating credit payment.")}
      />

      <TableCell>
        <Typography textAlign={"center"}>{creditName}</Typography>
      </TableCell>
      <TableCell align="center">
        {DateTime.fromMillis(createdOn).toISODate()}
      </TableCell>
      <TableCell align="center">{format(amount)}</TableCell>
      <TableCell align="center">{interestRate}% p.a.</TableCell>
      <TableCell align="center">{format(totalDue)}</TableCell>
      <TableCell align="center">{format(paymentPerPeriod)}</TableCell>
      <TableCell align="center">{creditPayments.length}</TableCell>
      <TableCell align="center">
        {closedOn ? format(0) : format(remainingBalance)}
      </TableCell>
      {closedOn ? (
        <TableCell align="center">
          <Typography>
            Closed {DateTime.fromMillis(closedOn).toISODate()}
          </Typography>
        </TableCell>
      ) : (
        <TableCell align="center">
          <ActionButton
            Icon={AddCardIcon}
            onIconClick={() => setOpenAddCreditPaymentDialog(true)}
            tooltip="Add payment"
          />
          <ActionButton
            Icon={DeleteIcon}
            onIconClick={() => deleteCreditCall(SK)}
            tooltip="Cancel credit"
          />
        </TableCell>
      )}
    </TableRow>
  );
};
