import { dateSorter } from "../../imported-logic/common";
import { Callbacks } from "../../types/util";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { SingleBudgetEntryTableRow } from "./SingleBudgetEntryTableRow";
import { Account } from "@backend/account.type";
import { Category } from "@backend/category.type";
import {
  SimpleBudgetEntry,
  PopulatedBudgetEntry,
  UpdateBudgetEntryItemData,
} from "@backend/common.type";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Paper,
  Typography,
  tableCellClasses,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

export interface CommonBudgetEntriesTableProps {
  items: SimpleBudgetEntry[];
  categories: Category[];
  accounts: Account[];
  filters: {
    filter?: string;
  };
}

export interface BudgetEntriesTableProps extends CommonBudgetEntriesTableProps {
  actions: {
    deleteItem: (args: { itemSK: string }, cbs: Callbacks) => void;
    updateItem: (args: { itemSK: string }, cbs: Callbacks) => void;
    cloneItem: (
      args: { itemSK: string; newDate: number },
      cbs: Callbacks
    ) => void;
    loadItems: () => void;
  };
  categoryTypeFilter: "expense" | "income";
}

export const BudgetEntriesTable = (props: BudgetEntriesTableProps) => {
  const matches = useMediaQuery("(min-width:600px)");

  const { actions, items, categories, accounts, filters, categoryTypeFilter } =
    props;
  const { deleteItem, updateItem, cloneItem, loadItems } = actions;
  const { filter } = filters;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);

  const populatedItems: (PopulatedBudgetEntry | undefined)[] = items.map(
    (item) => {
      const category = categories.find(
        (cat) => cat.SK === item.budgetEntryCategoryId
      );
      if (!category) {
        console.error("Category not found for ", item.budgetEntryCategoryId);
        return undefined;
      }

      const account = accounts.find((acc) => acc.SK === item.accountId);
      if (!account) {
        console.error("Account not found for ", item.accountId);
        return undefined;
      }

      const newItem: PopulatedBudgetEntry = {
        ...item,
        budgetEntryCategory: category ? category : categories[0],
        account: account ? account : accounts[0],
      };

      return newItem;
    }
  );

  const categoryFilteredItems = populatedItems.filter((e) => {
    if (!e) return false;

    const filteredItems = [
      e.budgetEntryCategory.categoryName.toLowerCase(),
      e.budgetEntryName.toLowerCase(),
      e.budgetEntryDate.toLowerCase(),
    ];
    return (
      !filter ||
      !filter.length ||
      filteredItems.some((e) => e.includes(filter.toLowerCase()))
    );
  }) as PopulatedBudgetEntry[];

  const sortedPopulatedItems = categoryFilteredItems.sort((a, b) =>
    dateSorter(b.budgetEntryDate, a.budgetEntryDate)
  );

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteItemCall = (itemSK: string) => {
    deleteItem(
      { itemSK },
      {
        onSuccess: () => {
          loadItems();
        },
        onFailed: (err) => toast.error(err),
      }
    );
  };

  const updateItemCall = (
    itemSK: string,
    args: UpdateBudgetEntryItemData,
    cbs: Callbacks
  ) => {
    updateItem(
      { itemSK, ...args },
      {
        onSuccess: () => {
          if (cbs.onSuccess) cbs.onSuccess({});
        },
        onFailed: (err) => {
          if (cbs.onFailed) cbs.onFailed(err);
        },
      }
    );
  };

  const cloneItemCall = (itemSK: string, newDate: number, cbs: Callbacks) => {
    cloneItem(
      { itemSK, newDate },
      {
        onSuccess: () => {
          if (cbs.onSuccess) cbs.onSuccess({});
        },
        onFailed: (err) => {
          if (cbs.onFailed) cbs.onFailed(err);
        },
      }
    );
  };

  const mappedItems = sortedPopulatedItems.map((item) => (
    <SingleBudgetEntryTableRow
      key={item.SK}
      item={item}
      deleteItemCall={deleteItemCall}
      updateItemCall={updateItemCall}
      cloneItemCall={cloneItemCall}
      categoryTypeFilter={categoryTypeFilter}
      matches={matches}
    />
  ));

  const paginateItems = () => {
    const from = page * rowsPerPage;
    const to = from + rowsPerPage;

    return mappedItems.slice(from, to);
  };

  const itemsToDisplay = paginateItems();

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <BudgetarToast />

      <TableContainer component={Paper}>
        <Table
          size="small"
          aria-label="items table"
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            {matches ? (
              <TableRow hover={true}>
                <TableCell>
                  <Typography textAlign={"center"}>Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign={"center"}>Category</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign={"right"}>Amount</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign={"center"}>Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign={"center"}>Account</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign={"center"}>Actions</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableHead>

          {mappedItems.length ? (
            [
              <TableBody>{itemsToDisplay}</TableBody>,
              <TableFooter>
                <TableRow hover={true}>
                  <TablePagination
                    count={mappedItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 25, 100]}
                    backIconButtonProps={{
                      color: "secondary",
                    }}
                    nextIconButtonProps={{ color: "secondary" }}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "page number",
                      },
                    }}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </TableRow>
              </TableFooter>,
            ]
          ) : (
            <Typography>No items recorded.</Typography>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
