import { themeOptions } from "../../ThemeConfig";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/reducers/userSlice";
import { getSubtitleSize, getTitleSize } from "../../style/fonts";
import { Mortgages } from "../../types";
import { formatNumberDispatch } from "../../util/Formatter";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { getBudgetId } from "../utils.api";
import { SingleMortgagesTableRow } from "./SingleMortgagesTableRow";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PercentIcon from "@mui/icons-material/Percent";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

export const MortgagesTable = (props: { mortgages: Mortgages }) => {
  const mini = !useMediaQuery("(min-width:600px)");

  const { mortgages } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const budgetId = getBudgetId(useAppSelector);

  const currency = useAppSelector(selectCurrency);
  const format = (n: number) => formatNumberDispatch(n, currency);

  const renderMini = () => {
    const mappedItems = mortgages.map(
      ({
        mortgage: {
          label,
          monthlyPayment,
          interestRatePerAnnum,
          principal,
          termMonths,
          totalOutstanding,
          totalInterest,
        },
      }) => (
        <Grid
          container
          xs={12}
          key={label}
          sx={{
            padding: "30px",
            margin: "15px",
            backgroundColor: themeOptions.palette.background.paper,
            borderRadius: 2,
          }}
        >
          <Grid item xs={6}>
            <Typography fontSize={getSubtitleSize(true)} textAlign={"left"}>
              <MonetizationOnIcon />
              {format(principal)}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography fontSize={getSubtitleSize(true)} textAlign={"right"}>
              <PercentIcon />
              {interestRatePerAnnum} p.a.
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography fontSize={getSubtitleSize(true)} textAlign={"left"}>
              <HourglassEmptyIcon /> {termMonths} months
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography fontSize={getTitleSize(true)} textAlign={"right"}>
              {format(monthlyPayment)} / month
            </Typography>
          </Grid>
        </Grid>
      )
    );

    return (
      <Box sx={{ paddingTop: "20px" }}>
        <Grid container spacing={1} xs={12}>
          {mappedItems}
        </Grid>
      </Box>
    );
  };

  const renderDesktop = () => {
    const mappedItems = mortgages.map((mortgage) => (
      <SingleMortgagesTableRow
        key={mortgage.mortgage.id}
        mortgage={mortgage}
        mini={mini}
        // deleteMortgageCall={deleteMortgage}
      />
    ));

    return (
      <Box sx={{ display: "flex", padding: "10px" }}>
        <BudgetarToast />

        <TableContainer component={Paper}>
          <Table size="small" aria-label="mortgages table">
            <TableHead>
              <TableRow hover={true}>
                <TableCell>
                  <Typography textAlign="center">Monthly payment</Typography>
                </TableCell>

                <TableCell>
                  <Typography textAlign="center">Principal</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Term</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Interest rate p.a.</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Total interest</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Total outstanding</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Total remaining</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            {mappedItems.length ? (
              <TableBody>{mappedItems}</TableBody>
            ) : (
              <Typography>No mortgages recorded.</Typography>
            )}
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return mini ? renderMini() : renderDesktop();
};
