import { AccountWithBalance } from "../../components/accounts/AccountsTable";
import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { createSlice } from "@reduxjs/toolkit";

interface AccountsState {
  accounts: SingleLoad<AccountWithBalance[]>;
}

export const initialState: AccountsState = {
  accounts: singleLoadDefaultValue([]),
};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setAccounts: (
      state: AccountsState,
      action: { payload: { accounts: AccountWithBalance[] } }
    ) => {
      const { accounts } = action.payload;

      state.accounts = getSingleLoadResponse(accounts);
    },
    setAccountsLoading: (
      state: AccountsState,
      action: { payload: { status: boolean } }
    ) => {
      state.accounts.loading = action.payload.status;
    },
    logout: (state: AccountsState) => {
      return initialState;
    },
  },
});

export const { setAccounts, setAccountsLoading, logout } =
  accountsSlice.actions;

export const accountsState = (state: RootState) => state.accounts;

export const selectAccounts = (
  state: RootState
): SingleLoad<AccountWithBalance[]> => accountsState(state).accounts;
export const selectAccountsLoading = (state: RootState): boolean =>
  accountsState(state).accounts.loading;

export const AccountsReducer = accountsSlice.reducer;
