import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV022 = () => {
  return (
    <Release version="v0.2.2" releaseDate="2023-03-18">
      <ReleaseNotesSection title="Subscriptions">
        <ReleaseNotesEntry>Add subscriptions</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="General">
        <ReleaseNotesEntry>Add settings page</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Reporting">
        <ReleaseNotesEntry>Add monthly reports</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add expenses-per-month chart</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add incomes-per-month chart</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Technical">
        <ReleaseNotesEntry>Add reports caching</ReleaseNotesEntry>
        <ReleaseNotesEntry>Update overlay design</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
