import { Subscriptions } from "../subscriptions/Subscriptions";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { Box } from "@mui/system";

export const SubscriptionSettings = () => {
  return (
    <Box>
      <BudgetarToast />

      <Box>
        <Subscriptions />
      </Box>
    </Box>
  );
};
