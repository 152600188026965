import { Callbacks } from "../types/util";
import { postRequest, getRequest } from "./index";
import {
  AddLoanInterestFeeInterface,
  AddLoanPaymentServiceInterface,
  LoanData,
} from "@backend/loan.type";

const getLoanUrl = (route: string, budgetId: string) =>
  `${budgetId}/loan/${route}`;

interface AddLoanArgs extends LoanData {}
async function addLoan(
  budgetId: string,
  args: AddLoanArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getLoanUrl("add", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function addLoanPayment(
  budgetId: string,
  args: AddLoanPaymentServiceInterface,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getLoanUrl("payment/add", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function addLoanInterestFee(
  budgetId: string,
  args: AddLoanInterestFeeInterface,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getLoanUrl("interest/add", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface DeleteLoanArgs {
  loanSK: string;
}
async function deleteLoan(
  budgetId: string,
  args: DeleteLoanArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getLoanUrl("delete", budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getLoans(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/loans`, logoutCallback, onSuccess, onFailed);
}

export const LoanAPI = {
  addLoan,
  getLoans,
  deleteLoan,

  addLoanPayment,
  addLoanInterestFee,
};
