import { GenericDropdown } from "./GenericDropdown";
import { CategoryType } from "@backend/category.type";

export const CategoryTypeDropdown = (props: { categoryTypeSelected: any }) => {
  const { categoryTypeSelected } = props;

  const categoryTypes = [CategoryType.EXPENSE, CategoryType.INCOME];

  return (
    <GenericDropdown
      label="Category type"
      options={categoryTypes}
      onSelection={categoryTypeSelected}
    />
  );
};
