import { Account } from "./account.type";
import { Identifiable } from "./common.type";
import { Currency } from "./currency.type";

export interface Loan extends Identifiable, LoanData {}

export interface PopulatedLoan extends Identifiable, LoanBase {
  loanAccount: Account;
}

export interface LoanPopulated extends Loan {
  remainingBalance: number;
}

export enum LoanStatus {
  OPEN,
  CLOSED,
}

interface LoanBase {
  loanName: string;
  loanPrincipal: number;
  interestRate: number;
  months: number;
  monthlyPayment: number;
  creationDate: string;
  status: LoanStatus;
  currency: Currency;
}

export interface LoanData extends LoanBase {
  loanAccountId: string;
  incomeCategoryId: string;
}

export interface LoanPayment extends Identifiable, LoanPaymentData {}

export interface LoanPaymentData {
  loanSK: string;
  paymentAmount: number;
  paymentDate: string;
}

//////
//////

export interface AddLoanPaymentInterface {
  loanSK: string;
  paymentAmount: number;
  paymentDate: string;
}

export interface AddLoanPaymentServiceInterface
  extends AddLoanPaymentInterface {
  userExpenseCategoryId: string;
  expenseAccountId: string;
}

export interface AddLoanInterestFeeInterface extends LoanInterestFee {}

export interface LoanInterestFee {
  loanSK: string;
  feeAmount: number;
  date: string;
}
