import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";

type CollapsibleComponentProps = {
  version: string;
  releaseDate: string;
  children: React.ReactNode | React.ReactNode[];
  collapsedOnStart?: boolean;
};

const Release: React.FC<CollapsibleComponentProps> = ({
  version,
  releaseDate,
  children,
  collapsedOnStart = true,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsedOnStart);
  const isLatestRelease = !collapsedOnStart;

  const toggleCollapsible = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box sx={{}}>
      <Box sx={{ display: "flex", paddingBottom: "5px" }}>
        <Typography fontWeight="900">
          {version}
          {isLatestRelease ? " - latest release" : ""}
          <Typography color="orange" fontSize="12px">
            {releaseDate}
          </Typography>
        </Typography>

        <button onClick={toggleCollapsible}>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </button>
      </Box>

      {!isCollapsed && <Box>{children}</Box>}

      <Box sx={{ paddingBottom: "5px" }} />
      <Divider />
      <Box sx={{ paddingTop: "5px" }} />
    </Box>
  );
};

export default Release;
