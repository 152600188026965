import { buildHeaders } from "..";
import { AuthCredentials } from "../../types/auth.type";
import { Callbacks } from "../../types/util";
import { getAuthUrl } from "../config";
import { setCredentialsInSessionStorage } from "./auth.util";
import axios from "axios";

export async function loginUser(
  credentials: AuthCredentials,
  { onSuccess, onFailed }: Callbacks
) {
  postAuthRequest(
    "login",
    credentials,
    (response: any) => {
      if (
        (response.data && response.data.errorMessage) ||
        response.data.error
      ) {
        const error = response.data.errorMessage || response.data.error.message;
        console.log("[api/auth/loginUser] Login failed", error);
        if (onFailed) onFailed(error);
        return;
      }

      const { username, token, refreshToken } = response.data;
      setCredentialsInSessionStorage(
        credentials.email,
        username,
        token,
        refreshToken
      );

      if (onSuccess) onSuccess({ username, token });
    },
    (err: any) => {
      console.log("err", err);
      console.log("err", err.data.errorMessage);

      if (onFailed) onFailed(err);
    }
  );
}

export async function signupUser(
  credentials: AuthCredentials,
  { onSuccess, onFailed }: Callbacks
) {
  postAuthRequest(
    "signup",
    credentials,
    (response: any) => {
      if (response.data && response.data.errorMessage) {
        if (onFailed) onFailed(response.data.errorMessage);
      } else {
        if (onSuccess) onSuccess(response);
      }
    },
    (err: any) => {
      console.log("err", JSON.stringify(err));
      if (onFailed) onFailed(err);
    }
  );
}

export async function requestPasswordRecovery(
  email: string,
  { onSuccess, onFailed }: Callbacks
) {
  postAuthRequest(
    "auth/recovery/request",
    { email },
    (response: any) => {
      if (response.data && response.data.errorMessage) {
        if (onFailed) onFailed(response.data.errorMessage);
      } else {
        if (onSuccess) onSuccess(response);
      }
    },
    (err: any) => {
      console.log("err", JSON.stringify(err));
      if (onFailed) onFailed(err);
    }
  );
}

export async function confirmPasswordRecovery(
  credentials: AuthCredentials,
  code: string,
  { onSuccess, onFailed }: Callbacks
) {
  postAuthRequest(
    "auth/recovery/confirm",
    { ...credentials, code },
    (response: any) => {
      if (response.data && response.data.errorMessage) {
        if (onFailed) onFailed(response.data.errorMessage);
        return;
      }

      if (onSuccess) onSuccess(response);
    },
    (err: any) => {
      console.log("err", JSON.stringify(err));
      if (onFailed) onFailed(err);
    }
  );
}

export async function refreshIdToken({ onSuccess, onFailed }: Callbacks) {
  const username = window.sessionStorage.getItem("username");
  const refreshToken = window.sessionStorage.getItem("refreshToken");

  postAuthRequest(
    "auth/refresh",
    { username, refreshToken },
    (response: any) => {
      if (response.data && response.data.errorMessage) {
        if (onFailed) onFailed(response.data.errorMessage);
      } else {
        const { token } = response.data;

        window.sessionStorage.setItem("token", token);

        if (onSuccess) onSuccess(token);
      }
    },
    (err: any) => {
      console.log("err", JSON.stringify(err));
      if (onFailed) onFailed(err);
    }
  );
}

const postAuthRequest = (
  route: string,
  data: any,
  successCallback: (data: any) => void,
  failedCallback: (err: string) => void
) => {
  axios
    .post(getAuthUrl(route), data, {
      headers: buildHeaders(),
    })
    .then(successCallback)
    .catch((err) => {
      if (err.response) {
        // request made and server responded
        if (failedCallback) failedCallback(err.response);
      } else if (err.request) {
        // request made, no response
        if (failedCallback) failedCallback(err.response);
      } else {
        // something else
      }
    });
};
