import { fetchEvents } from "../../../api/fetch.redux";
import { IncomeAPI } from "../../../api/incomes/incomes.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccounts } from "../../../redux/reducers/accountsSlice";
import { selectCategories } from "../../../redux/reducers/categoriesSlice";
import { selectEvents } from "../../../redux/reducers/eventsSlice";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { SecondaryButton } from "../buttons/SecondaryButton";
import { AccountsDropdown } from "../dropdowns/AccountsDropdown";
import { CategoriesDropdown } from "../dropdowns/CategoriesDropdown";
import { CurrencyDropdown } from "../dropdowns/CurrencyDropdown";
import { AccountType } from "@backend/account.type";
import { Currency } from "@backend/currency.type";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const NewIncomeDialog = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const budgetId = getBudgetId(useAppSelector);
  const preferredCurrency = useAppSelector(selectCurrency);
  const { data: categories } = useAppSelector(selectCategories);
  const { data: accounts } = useAppSelector(selectAccounts);
  const { data: events } = useAppSelector(selectEvents);

  const [openAdvanced, setOpenAdvanced] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [incomeName, setIncomeName] = useState("");
  const [eventName, setEventName] = useState<string | undefined>();
  const [amount, setAmount] = useState(0);
  const [categoryId, setCategoryId] = useState(
    categories.length ? categories[0].SK : ""
  );

  const exclusiveAccountTypes = [
    AccountType.CREDITING,
    AccountType.INVESTMENTS,
    AccountType.LOAN,
  ];
  const [accountsToUse, setAccountsToUser] = useState(accounts);
  useMemo(() => {
    setAccountsToUser(
      accounts
        ? accounts.filter((e) => !exclusiveAccountTypes.includes(e.accountType))
        : []
    );
  }, []);

  const [accountId, setIncomeTargetAccountId] = useState(
    accountsToUse.length ? accountsToUse[0].SK : ""
  );
  const [date, setDate] = useState(DateTime.now().toISODate());
  const [incomeCurrency, setIncomeCurrency] =
    useState<Currency>(preferredCurrency);

  const handleClose = () => {
    setSubmitting(false);
    setOpen(false);

    setIncomeCurrency(preferredCurrency);
    setEventName(undefined);
  };

  const handleIncomeNameChange = (newName: string) => setIncomeName(newName);
  const handleAmountChange = (newAmount: string) =>
    setAmount(Number(newAmount));
  const handleEventNameChange = (newName: string) => setEventName(newName);

  const submit = () => {
    setSubmitting(true);
    createIncome();
  };

  const createIncome = async () => {
    IncomeAPI.addIncome(
      budgetId,
      {
        incomeName,
        amount,
        categoryId,
        accountId,
        date,
        currency: incomeCurrency,
        eventName,
      },
      {
        onSuccess: () => {
          if (onSuccess) onSuccess();

          fetchEvents(budgetId, dispatch, false);

          handleClose();
        },
        onFailed,
      },
      () => logout(dispatch, navigate)
    );
  };

  const canSubmit = () => {
    return (
      incomeName &&
      incomeName.length &&
      amount &&
      categoryId &&
      categoryId.length &&
      accountId &&
      accountId.length
    );
  };

  useEffect(() => {
    setCategoryId(categories.length ? categories[0].SK : "");
    setIncomeTargetAccountId(accountsToUse.length ? accountsToUse[0].SK : "");
  }, [accountsToUse, categories]);

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>New income</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Income label"
              variant="outlined"
              onChange={(e) => handleIncomeNameChange(e.target.value)}
              sx={{ paddingTop: "10px" }}
            />

            <TextField
              placeholder="Amount"
              variant="outlined"
              onChange={(e) => handleAmountChange(e.target.value)}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <Box sx={{ paddingTop: "10px" }}>
              <CategoriesDropdown
                categorySelected={setCategoryId}
                categoryTypeFilter="income"
              />
            </Box>

            <Box sx={{ paddingTop: "10px" }}>
              <AccountsDropdown
                accountSelected={setIncomeTargetAccountId}
                exclusiveAccountTypeFilters={exclusiveAccountTypes}
              />
            </Box>

            <BudgetarDatePicker onSelect={setDate} />

            {openAdvanced ? (
              <Box>
                <Autocomplete
                  fullWidth
                  freeSolo
                  id="event-name"
                  disableClearable
                  options={events.map((e) => e.eventName)}
                  onChange={(e, value) => handleEventNameChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Event name"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      fullWidth
                    />
                  )}
                />

                <Grid container xs={12} sx={{ paddingTop: "10px" }}>
                  <Grid item xs={12}>
                    <CurrencyDropdown
                      label="Income currency"
                      onCurrencySelected={setIncomeCurrency}
                      preferredCurrency={incomeCurrency}
                    />
                  </Grid>
                </Grid>

                <SecondaryButton
                  onClick={() => setOpenAdvanced(false)}
                  sx={{ marginTop: "10px" }}
                >
                  Close advanced view
                </SecondaryButton>
              </Box>
            ) : (
              <SecondaryButton onClick={() => setOpenAdvanced(true)}>
                Advanced view
              </SecondaryButton>
            )}

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit() || submitting}
                onClick={submit}
              >
                {submitting ? "Creating income..." : "Create income"}
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
