import { selectCategories } from "../../../redux/reducers/categoriesSlice";
import { selectSimpleExpenses } from "../../../redux/reducers/expensesSlice";
import { AccountWithBalance } from "../../accounts/AccountsTable";
import { ExpensesTable } from "../../expenses/ExpensesTable";
import { Button, DialogActions, DialogTitle, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useSelector } from "react-redux";

export const AccountBudgetEntriesDialog = (props: {
  open: boolean;
  setOpen: (status: boolean) => void;
  account: AccountWithBalance;
}) => {
  const { open, setOpen, account } = props;

  const { data: categories } = useSelector(selectCategories);
  const { data: expenses } = useSelector(selectSimpleExpenses);

  const handleClose = () => {
    setOpen(false);
  };

  const filtered = expenses.filter((e) => e.accountId === account.SK);

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: "center" }}>
          <Typography fontSize="20px">Expenses</Typography>
          <Typography color="secondary">{account.accountName}</Typography>
        </DialogTitle>

        <DialogContent>
          <ExpensesTable
            items={filtered}
            categories={categories}
            accounts={[account]}
            filters={{ filter: "" }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return renderDialog();
};
