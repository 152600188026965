import { CollapsibleComponentProps } from "./ReleaseNotesSection";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ReleaseNotesEntry = styled(
  Typography,
  {}
)((extraProps: {}) => ({
  ...extraProps,
  fontWeight: 500,
  fontSize: "14px",
  paddingLeft: "10px",
  color: "lightblue",
}));

export interface ReleaseNotesOutput {
  sections: React.FC<CollapsibleComponentProps>[];
  contents: JSX.Element;
  version: string;
  releaseDate: string;
}
