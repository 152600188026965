import { Callbacks } from "../../types/util";
import { postRequest, getRequest } from "../index";
import { UpdateCategoryData } from "@backend/category.type";
import { Currency } from "@backend/currency.type";

const getCategoryUrl = (route: string, budgetId: string) =>
  `${budgetId}/category/${route}`;

interface CreateCategoryArgs {
  categoryName: string;
  periodBudget: number;
  periodBudgetCurrency: Currency;
  assignedPeriod: string;
  isEssential: boolean;
}

async function createCategory(
  budgetId: string,
  args: CreateCategoryArgs,
  categoryType: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getCategoryUrl(`${categoryType.toLowerCase()}/create`, budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface UpdateCategoryInterface extends UpdateCategoryData {
  categorySK: string;
}

async function updateCategory(
  budgetId: string,
  args: UpdateCategoryInterface,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getCategoryUrl(`update`, budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface DeleteCategoryArgs {
  categorySK: string;
}
async function deleteCategory(
  budgetId: string,
  args: DeleteCategoryArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getCategoryUrl(`delete`, budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getCategories(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/categories`, logoutCallback, onSuccess, onFailed);
}

export const CategoryAPI = {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
};
