import { Callbacks } from "../../types/util";
import { postRequest, getRequest } from "../index";
import { UpdateAccountData } from "@backend/account.type";
import { Currency } from "@backend/currency.type";

const getAccountUrl = (route: string, budgetId: string) =>
  `${budgetId}/account/${route}`;

interface CreateAccountArgs {
  accountName: string;
  accountType: string;
  startingBalance: number;
  monthlyAllowance?: number;
  isPrimary?: boolean;
  startingBalanceCurrency: Currency | null;
}

async function createAccount(
  budgetId: string,
  args: CreateAccountArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getAccountUrl(`create`, budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface UpdateAccountInterface extends UpdateAccountData {
  accountSK: string;
}
async function updateAccount(
  budgetId: string,
  args: UpdateAccountInterface,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getAccountUrl(`update`, budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface DeleteAccountArgs {
  accountSK: string;
}
async function deleteAccount(
  budgetId: string,
  args: DeleteAccountArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  await postRequest(
    getAccountUrl(`delete`, budgetId),
    args,
    logoutCallback,
    onSuccess,
    onFailed
  );
}

interface AccountTransferArgs {
  amount: number;
  sourceAccountId: string;
  destinationAccountId: string;
}
async function transfer(
  budgetId: string,
  args: AccountTransferArgs,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  const { sourceAccountId, destinationAccountId, amount } = args;

  await postRequest(
    getAccountUrl(
      `transfer/${sourceAccountId}/${destinationAccountId}`,
      budgetId
    ),
    { amount },
    logoutCallback,
    onSuccess,
    onFailed
  );
}

async function getAccounts(
  budgetId: string,
  { onSuccess, onFailed }: Callbacks,
  logoutCallback: () => void
) {
  getRequest(`${budgetId}/accounts`, logoutCallback, onSuccess, onFailed);
}

export const AccountAPI = {
  getAccounts,
  createAccount,
  deleteAccount,
  updateAccount,
  transfer,
};
