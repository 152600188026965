import { GoalAPI } from "../../api/goals.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { loadGoals, getBudgetId, logout } from "../utils.api";
import { SingleGoalsTableRow } from "./SingleGoalsTableRow";
import { Goal } from "@backend/goal.type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const GoalsTable = (props: { goals: Goal[] }) => {
  const { goals } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const budgetId = getBudgetId(useAppSelector);

  const sortedGoals = [...goals].sort((a, b) => a.amount - b.amount);
  const completedGoals = sortedGoals.filter((e) => !!e.completedOn);
  const incompletedGoals = sortedGoals.filter((e) => !e.completedOn);

  const deleteGoal = (goalSK: string) => {
    GoalAPI.deleteGoal(
      budgetId,
      { goalSK },
      {
        onSuccess: () => {
          toast.success("Goal deleted.");
          loadGoals(budgetId, dispatch);
        },
        onFailed: (err) => toast.error(err),
      },
      () => logout(dispatch, navigate)
    );
  };

  const incompleteGoals = incompletedGoals.map((goal) => (
    <SingleGoalsTableRow
      key={goal.SK}
      goal={goal}
      deleteGoalCall={deleteGoal}
    />
  ));

  const completeGoals = completedGoals.map((goal) => (
    <SingleGoalsTableRow
      key={goal.SK}
      goal={goal}
      deleteGoalCall={deleteGoal}
    />
  ));

  const mappedItems = [...incompleteGoals, ...completeGoals];

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <BudgetarToast />

      <TableContainer component={Paper}>
        <Table size="small" aria-label="goals table">
          <TableHead>
            <TableRow hover={true}>
              <TableCell>
                <Typography textAlign="center">Name</Typography>
              </TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Completed on</TableCell>
              <TableCell>
                <Typography textAlign={"center"}>Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {mappedItems.length ? (
            <TableBody>{mappedItems}</TableBody>
          ) : (
            <Typography>No goals recorded.</Typography>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
