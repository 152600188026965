import { formatNumberDispatch } from "../../util/Formatter";
import { Investment, InvestmentPriceEntry } from "@backend/investment.type";
import { TableCell, TableRow, Grid, Card, Typography } from "@mui/material";
import { DateTime } from "luxon";

export const SingleInvestmentsTableRow = (props: {
  investment: Investment;
  latestPriceEntry: InvestmentPriceEntry;
  matches: boolean;
}) => {
  const { investment, latestPriceEntry, matches } = props;
  const {
    SK,
    investmentName,
    purchaseDate,
    amountPurchased,
    purchasePrice,
    entryFee,
    exitFee,
    currency,
  } = investment;

  const format = (n: number) => formatNumberDispatch(n, currency);

  const renderFullSize = () => {
    return (
      <TableRow hover={true} key={SK}>
        <TableCell>
          <Typography textAlign={"center"}>{investmentName}</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"center"}>
            {DateTime.fromISO(new Date(purchaseDate).toISOString()).toISODate()}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"right"}>
            {amountPurchased.toFixed(3)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"right"}>{format(purchasePrice)}</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"right"}>
            {format(latestPriceEntry.price)} @{" "}
            {DateTime.fromISO(latestPriceEntry.date).toISODate()}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"right"}>{entryFee.toFixed(2)}%</Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"right"}>{exitFee.toFixed(2)}%</Typography>
        </TableCell>
        {/* <TableCell>{investmentAccountId}</TableCell>
        <TableCell>{userExpenseCategoryId}</TableCell>
        <TableCell>{expenseAccountId}</TableCell> */}
      </TableRow>
    );
  };

  const renderMini = () => {
    return (
      <TableRow hover={false}>
        <TableCell>
          <Card>
            <Grid container>
              <Grid item xs={12} sx={{ paddingBottom: "5px" }}>
                <Typography textAlign={"center"}>{investmentName}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  Purchased {amountPurchased} @ {format(purchasePrice)}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  Value {format(latestPriceEntry.price)} @{" "}
                  {DateTime.fromISO(latestPriceEntry.date).toISODate()}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </TableCell>
      </TableRow>
    );
  };

  return matches ? renderFullSize() : renderMini();
};
