import { ToastContainer, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface BudgetarToastProps {
  autoClose: number;
  position: ToastPosition;
}

export const BudgetarToast = () => {
  const props: BudgetarToastProps = {
    autoClose: 1666,
    position: "bottom-left",
  };

  return <ToastContainer {...props} />;
};
