import { Callbacks } from "../../types/util";
import { SecondaryButton } from "../utility/buttons/SecondaryButton";
import { ConfirmActionDialog } from "../utility/dialogs/ConfirmActionDialog";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { LookupKey } from "@backend/subscription.type";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Card, Grid, Typography, ListItemText } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { Box } from "@mui/system";
import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { toast } from "react-toastify";

export const PremiumSubscriptionTierViewCard = (props: {
  redirectToStripe: (lookupKey: LookupKey, cbs: Callbacks) => void;
}) => {
  const { redirectToStripe } = props;

  const [isFlipped, setIsFlipped] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [subscriptionTierPayment, setSubscriptionTierPayment] =
    useState<LookupKey | null>(null);

  const handleSpin = () => setIsFlipped(!isFlipped);

  const goToStripe = (lookupKey: LookupKey | null) => {
    if (!lookupKey) {
      throw new Error("Lookup key not set.");
    }

    redirectToStripe(lookupKey, {
      onSuccess: () => console.log("success"),
      onFailed: (err: any) => toast.error(err),
    });
  };

  const openSubscriptionDisclaimerDialog = (url: LookupKey) => {
    setSubscriptionTierPayment(url);
    setOpenConfirmDialog(true);
  };

  const OrangeTypography = (props: DefaultComponentProps<any>) => {
    const {
      children,
      fontSize = 16,
      fontStyle = "normal",
      textAlign = "center",
      fontWeight = 400,
    } = props;

    return (
      <Typography
        fontSize={fontSize}
        color="orange"
        fontStyle={fontStyle}
        textAlign={textAlign}
        fontWeight={fontWeight}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        minWidth: "300px",
      }}
    >
      <BudgetarToast />

      <ConfirmActionDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        title={"Subscription payment"}
        texts={[
          "You will be redirected to our payment provider, where you will be able to provide your payment details in a secure manner.",
          " \n",
        ]}
        onConfirm={() => goToStripe(subscriptionTierPayment)}
        onCancel={() => setOpenConfirmDialog(false)}
        titleColor="orange"
        ConfirmButtonIcon={OpenInNewIcon}
      />

      <Card
        sx={{ height: "400px", maxWidth: "375px", backgroundColor: "#f5f5dc" }}
      >
        <Grid container xs={12}>
          <Grid item xs={4} sx={{ padding: "10px" }}></Grid>

          <Grid item xs={4} sx={{ padding: "10px" }}>
            <OrangeTypography fontSize={30} fontStyle="italic">
              Premium
            </OrangeTypography>
          </Grid>

          <Grid item xs={1} paddingTop="22px">
            <StarBorderIcon sx={{ color: "orange" }} />
          </Grid>

          <Grid item xs={12}>
            <Typography color="primary" textAlign={"center"} fontSize={20}>
              Subscription
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
              <Grid container xs={12}>
                <Grid container xs={12} sx={{ paddingTop: "20px" }}>
                  <Grid item xs={12} sx={{ marginLeft: "20px" }}>
                    <ListItemText
                      sx={{
                        color: "orange",
                        display: "list-item",
                        marginLeft: "20px",
                      }}
                    >
                      <OrangeTypography fontWeight={900} textAlign={"left"}>
                        unlimited expense/income items
                      </OrangeTypography>
                    </ListItemText>

                    <ListItemText
                      sx={{
                        color: "orange",
                        display: "list-item",
                        marginLeft: "20px",
                      }}
                    >
                      <OrangeTypography fontWeight={900} textAlign={"left"}>
                        monthly and yearly reports
                      </OrangeTypography>
                    </ListItemText>

                    <ListItemText
                      sx={{
                        color: "orange",
                        display: "list-item",
                        marginLeft: "20px",
                      }}
                    >
                      <OrangeTypography fontWeight={900} textAlign={"left"}>
                        net-worth reports
                      </OrangeTypography>
                    </ListItemText>

                    <ListItemText
                      sx={{
                        color: "orange",
                        display: "list-item",
                        marginLeft: "20px",
                      }}
                    >
                      <OrangeTypography fontWeight={900} textAlign={"left"}>
                        discretionary accounts
                      </OrangeTypography>
                    </ListItemText>

                    <ListItemText
                      sx={{
                        color: "orange",
                        display: "list-item",
                        marginLeft: "20px",
                      }}
                    >
                      <OrangeTypography fontWeight={900} textAlign={"left"}>
                        multi-account access
                      </OrangeTypography>
                    </ListItemText>

                    <ListItemText
                      sx={{
                        color: "orange",
                        display: "list-item",
                        marginLeft: "20px",
                      }}
                    >
                      <OrangeTypography fontWeight={900} textAlign={"left"}>
                        no ads
                      </OrangeTypography>
                    </ListItemText>

                    <ListItemText
                      sx={{
                        color: "orange",
                        display: "list-item",
                        marginLeft: "20px",
                      }}
                    >
                      <OrangeTypography textAlign={"left"}>
                        plus all Free benefits
                      </OrangeTypography>
                    </ListItemText>

                    <Grid item xs={12} sx={{ paddingTop: "30px" }}>
                      <SecondaryButton variant="contained" onClick={handleSpin}>
                        More
                      </SecondaryButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/**
               * PREMIUM BACKSIDE BELOW
               */}
              <Grid container sx={{ paddingTop: "30px" }}>
                <Grid item xs={12}>
                  <OrangeTypography fontSize={18} padding="20px" s>
                    1,99€ / month
                  </OrangeTypography>

                  <Box sx={{ paddingTop: "10px" }}>
                    <SecondaryButton
                      variant="outlined"
                      onClick={() =>
                        openSubscriptionDisclaimerDialog("premium-monthly")
                      }
                    >
                      Subscribe monthly
                    </SecondaryButton>
                  </Box>
                </Grid>

                <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                  <OrangeTypography fontSize={18} padding="10px">
                    9,99€ / year
                  </OrangeTypography>

                  <Box sx={{ paddingTop: "10px" }}>
                    <SecondaryButton
                      variant="outlined"
                      onClick={() =>
                        openSubscriptionDisclaimerDialog("premium-yearly")
                      }
                    >
                      Subscribe yearly
                    </SecondaryButton>
                  </Box>
                </Grid>

                <Grid item xs={12} sx={{ paddingTop: "50px" }}>
                  <SecondaryButton color="primary" onClick={handleSpin}>
                    Back
                  </SecondaryButton>
                </Grid>
              </Grid>
            </ReactCardFlip>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
