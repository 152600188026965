import { Mortgages } from "../../types";
import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { createSlice } from "@reduxjs/toolkit";

interface MortgagesState {
  mortgages: SingleLoad<Mortgages>;
}

export const initialState: MortgagesState = {
  mortgages: singleLoadDefaultValue([]),
};

export const mortgagesSlice = createSlice({
  name: "mortgages",
  initialState,
  reducers: {
    setMortgages: (
      state: MortgagesState,
      action: { payload: { mortgages: Mortgages } }
    ) => {
      const { mortgages } = action.payload;

      state.mortgages = getSingleLoadResponse(mortgages);
    },
    setMortgagesLoading: (
      state: MortgagesState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.mortgages.loading = status;
    },
    logout: (state: MortgagesState) => {
      return initialState;
    },
  },
});

export const { setMortgages, setMortgagesLoading, logout } =
  mortgagesSlice.actions;

export const mortgagesState = (state: RootState) => state.mortgages;

export const selectMortgages = (state: RootState): SingleLoad<Mortgages> =>
  mortgagesState(state).mortgages;
export const selectMortgagesLoading = (state: RootState): boolean =>
  mortgagesState(state).mortgages.loading;

export const MortgagesReducer = mortgagesSlice.reducer;
