import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV033 = () => {
  return (
    <Release version="v0.3.3" releaseDate="2023-04-14">
      <ReleaseNotesSection title="UI/UX">
        <ReleaseNotesEntry>Set new font</ReleaseNotesEntry>
        <ReleaseNotesEntry>Minor improvements</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
