import { Identifiable } from "./common.type";

export interface AccessRoleItem extends Identifiable {
  granterId: string;
  granterEmail: string;
  granteeId: string;
  budgetId: string;
  accessRole: AccessRole;
  accessRoleStatus: AccessRoleStatus;
}

export enum AccessRoleStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
}

export enum AccessRole {
  ADMIN = "ADMIN",
  ITEM_INPUT = "ITEM_INPUT",
  READ_ONLY = "READ_ONLY",
}

export enum AccessAction {
  ALL = "ALL",
  WRITE = "WRITE",
  READ = "READ",
  GRANT = "GRANT",
  REVOKE = "REVOKE",
}

export const ROLE_ACTIONS: Record<AccessRole, AccessAction[]> = {
  [AccessRole.ADMIN]: [
    AccessAction.WRITE,
    AccessAction.READ,
    AccessAction.GRANT,
    AccessAction.REVOKE,
  ],
  [AccessRole.ITEM_INPUT]: [AccessAction.WRITE],
  [AccessRole.READ_ONLY]: [AccessAction.READ],
};

// API stuff below

export interface GrantAccessRole {
  accessRole: AccessRole;
  granteeEmail: string;
}
