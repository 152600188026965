import { deepCopy } from "../../util/Functions";
import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import {
  Investment,
  InvestmentPriceEntry,
  SoldInvestment,
} from "@backend/investment.type";
import { createSlice, current } from "@reduxjs/toolkit";

interface InvestmentsState {
  investments: SingleLoad<Investment[]>;
  soldInvestments: SingleLoad<SoldInvestment[]>;
  currentPrices: SingleLoad<InvestmentPriceEntry[]>;
}

export const initialState: InvestmentsState = {
  investments: singleLoadDefaultValue([]),
  soldInvestments: singleLoadDefaultValue([]),
  currentPrices: singleLoadDefaultValue([]),
};

function updateCurrentPricesSimple(
  state: InvestmentsState,
  newPrices: InvestmentPriceEntry[]
) {
  state.currentPrices = getSingleLoadResponse(newPrices);
}

export const investmentsSlice = createSlice({
  name: "investments",
  initialState,
  reducers: {
    setInvestments: (
      state: InvestmentsState,
      action: { payload: { investments: Investment[] } }
    ) => {
      const { investments } = action.payload;

      state.investments = getSingleLoadResponse(investments);
    },
    setSoldInvestments: (
      state: InvestmentsState,
      action: { payload: { soldInvestments: SoldInvestment[] } }
    ) => {
      const { soldInvestments } = action.payload;

      state.soldInvestments = getSingleLoadResponse(soldInvestments);
    },
    setInvestmentsPrices: (
      state: InvestmentsState,
      action: { payload: { investmentsPrices: InvestmentPriceEntry[] } }
    ) => {
      const { investmentsPrices } = action.payload;

      state.currentPrices = getSingleLoadResponse(investmentsPrices);
    },
    setCurrentPrice: (
      state: InvestmentsState,
      action: {
        payload: { investmentName: string; newPrice: number; date: string };
      }
    ) => {
      const { investmentName, newPrice, date } = action.payload;

      const prices = deepCopy(current(state).currentPrices);
      const idx = prices.data.findIndex(
        (e) => e.investmentName === investmentName
      );

      const obj = { investmentName, price: newPrice, date };

      if (idx > -1) {
        prices.data.splice(idx, 1, obj);
      } else {
        prices.data.push(obj);
      }

      updateCurrentPricesSimple(state, prices.data);
    },
    setInvestmentsLoading: (
      state: InvestmentsState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.investments.loading = status;
    },
    setSoldInvestmentsLoading: (
      state: InvestmentsState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.soldInvestments.loading = status;
    },
    setInvestmentsPricesLoading: (
      state: InvestmentsState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.currentPrices.loading = status;
    },
    logout: (state: InvestmentsState) => {
      return initialState;
    },
  },
});

export const {
  setInvestments,
  setSoldInvestments,
  setInvestmentsPrices,
  setCurrentPrice,
  setInvestmentsLoading,
  setSoldInvestmentsLoading,
  setInvestmentsPricesLoading,
  logout,
} = investmentsSlice.actions;

export const investmentsState = (state: RootState) => state.investments;

export const selectInvestments = (state: RootState): SingleLoad<Investment[]> =>
  investmentsState(state).investments;
export const selectSoldInvestments = (
  state: RootState
): SingleLoad<SoldInvestment[]> => investmentsState(state).soldInvestments;
export const selectCurrentPrices = (
  state: RootState
): SingleLoad<InvestmentPriceEntry[]> => investmentsState(state).currentPrices;
export const selectInvestmentsLoading = (state: RootState): boolean =>
  investmentsState(state).investments.loading;
export const selectSoldInvestmentsLoading = (state: RootState): boolean =>
  investmentsState(state).soldInvestments.loading;
export const selectInvestmentsPricesLoading = (state: RootState): boolean =>
  investmentsState(state).currentPrices.loading;

export const InvestmentsReducer = investmentsSlice.reducer;
