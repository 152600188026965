import { Box } from "@mui/material";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface BudgetarDatePickerProps {
  onSelect: (newDate: any) => void;
}

export const BudgetarDatePicker = (props: BudgetarDatePickerProps) => {
  const { onSelect } = props;

  const [startDate, setStartDate] = useState(new Date());

  return (
    <Box sx={{ padding: "10px" }}>
      <DatePicker
        selected={startDate}
        onChange={(date: Date) => {
          setStartDate(date);
          onSelect(date);
        }}
      />
    </Box>
  );
};
