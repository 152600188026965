import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Callbacks } from "../../../types/util";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { AddLoanInterestFeeInterface } from "@backend/loan.type";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const AddLoanInterestFee = (props: {
  loanSK: string;
  open: boolean;
  setOpen: (newValue: boolean) => void;
  addLoanInterestFeeAction: (
    budgetId: string,
    args: AddLoanInterestFeeInterface,
    { onSuccess, onFailed }: Callbacks,
    logoutCallback: () => void
  ) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const {
    loanSK,
    open,
    setOpen,
    addLoanInterestFeeAction,
    onSuccess,
    onFailed,
  } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [feeAmount, setFeeAmount] = React.useState(0);
  const [date, setDate] = React.useState(DateTime.now().toISODate());

  const budgetId = getBudgetId(useAppSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const submit = () => {
    createLoan();
    handleClose();
  };

  const createLoan = async () => {
    addLoanInterestFeeAction(
      budgetId,
      {
        loanSK,
        feeAmount,
        date,
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const canSubmit = () => {
    return feeAmount;
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>Add interest fee</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <TextField
              placeholder="Payment amount"
              variant="outlined"
              onChange={(e) => setFeeAmount(Number(e.target.value))}
              sx={{ paddingTop: "10px" }}
            />
            <BudgetarDatePicker onSelect={setDate} />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Add interest fee
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
