import { EventAPI } from "../../api/events.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { getBudgetId, loadEvents, logout } from "../utils.api";
import { SingleEventsTableRow } from "./SingleEventsTableRow";
import { EventWithTotal } from "@backend/event.type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const EventsTable = (props: { events: EventWithTotal[] }) => {
  const { events } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const budgetId = getBudgetId(useAppSelector);

  const deleteEvent = (eventSK: string) => {
    EventAPI.deleteEvent(
      budgetId,
      { eventSK },
      {
        onSuccess: () => {
          toast.success("Event deleted.");
          loadEvents(budgetId, dispatch);
        },
        onFailed: (err) => toast.error(err),
      },
      () => logout(dispatch, navigate)
    );
  };

  const mappedItems = events.map((event) => (
    <SingleEventsTableRow
      key={event.SK}
      event={event}
      deleteEventCall={deleteEvent}
    />
  ));

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <BudgetarToast />

      <TableContainer component={Paper}>
        <Table size="small" aria-label="events table">
          <TableHead>
            <TableRow hover={true}>
              <TableCell>
                <Typography textAlign="center">Name</Typography>
              </TableCell>
              <TableCell>
                <Typography textAlign="center">Total</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {mappedItems.length ? (
            <TableBody>{mappedItems}</TableBody>
          ) : (
            <Typography>No events recorded.</Typography>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
