import { SingleSoldInvestmentTableRow } from "./SingleSoldInvestmentTableRow";
import { SoldInvestment } from "@backend/investment.type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

export const SoldInvestmentsTable = (props: {
  soldInvestments: SoldInvestment[];
}) => {
  const matches = useMediaQuery("(min-width:600px)");

  const { soldInvestments } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);

  const mappedItems = [...soldInvestments].map((investment) => {
    return (
      <SingleSoldInvestmentTableRow
        key={investment.investmentName}
        investment={investment}
        matches={matches}
      />
    );
  });

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginateItems = () => {
    const from = page * rowsPerPage;
    const to = from + rowsPerPage;

    return mappedItems.slice(from, to);
  };

  const itemsToDisplay = paginateItems();

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <TableContainer component={Paper}>
        <Table aria-label="sold investments table">
          <TableHead>
            {matches ? (
              <TableRow hover={true}>
                <TableCell>
                  <Typography textAlign="center">Investment</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Sale date</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Quantity sold</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Sale price / unit</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Total sold</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableHead>
          {mappedItems.length ? (
            [
              <TableBody>{itemsToDisplay}</TableBody>,
              <TableFooter>
                <TableRow hover={true}>
                  <TablePagination
                    count={mappedItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 25]}
                    backIconButtonProps={{
                      color: "secondary",
                    }}
                    nextIconButtonProps={{ color: "secondary" }}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "page number",
                      },
                    }}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </TableRow>
              </TableFooter>,
            ]
          ) : (
            <Typography>No items recorded.</Typography>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
