import { RootState } from "../store";
import {
  SingleLoad,
  singleLoadDefaultValue,
  getSingleLoadResponse,
} from "../util";
import { SimpleExpense } from "@backend/expense.type";
import { createSlice } from "@reduxjs/toolkit";

interface SimpleExpensesState {
  expenses: SingleLoad<SimpleExpense[]>;
}

export const initialState: SimpleExpensesState = {
  expenses: singleLoadDefaultValue([]),
};

export const expensesSlice = createSlice({
  name: "expenses",
  initialState,
  reducers: {
    setSimpleExpenses: (
      state: SimpleExpensesState,
      action: { payload: { expenses: SimpleExpense[] } }
    ) => {
      const { expenses } = action.payload;

      state.expenses = getSingleLoadResponse(expenses);
    },
    setExpensesLoading: (
      state: SimpleExpensesState,
      action: { payload: { status: boolean } }
    ) => {
      const { status } = action.payload;

      state.expenses.loading = status;
    },
    logout: (state: SimpleExpensesState) => {
      return initialState;
    },
  },
});

export const { setSimpleExpenses, setExpensesLoading, logout } =
  expensesSlice.actions;

export const expensesState = (state: RootState) => state.expenses;

export const selectSimpleExpenses = (
  state: RootState
): SingleLoad<SimpleExpense[]> => expensesState(state).expenses;
export const selectExpensesLoading = (state: RootState): boolean =>
  expensesState(state).expenses.loading;

export const SimpleExpensesReducer = expensesSlice.reducer;
