import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV032 = () => {
  return (
    <Release version="v0.3.2" releaseDate="2023-04-13">
      <ReleaseNotesSection title="Goals">
        <ReleaseNotesEntry>Add completion date</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="UI/UX">
        <ReleaseNotesEntry>Add blurred components</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add investments filter</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add contact page</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
