import { getPeriodMultiplier } from "../../imported-logic/common";
import { useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../util/Formatter";
import { capitalizeWord } from "../../util/Functions";
import { ActionButton } from "../common/ActionButton";
import { SecondaryButton } from "../utility/buttons/SecondaryButton";
import { ConfirmActionDialog } from "../utility/dialogs/ConfirmActionDialog";
import { PeriodDropdown } from "../utility/dropdowns/PeriodDropdown";
import { Category, UpdateCategoryData } from "@backend/category.type";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  TableCell,
  TableRow,
  Typography,
  TextField,
  FormGroup,
  Switch,
  Card,
  Grid,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/system";
import { useState } from "react";
import { toast } from "react-toastify";

interface SingleCategoriesTableRowProps {
  matches: boolean;
  category: Category;
  updateCategoryCall: (
    categorySK: string,
    updateCategoryData: UpdateCategoryData,
    cb: () => void
  ) => void;
  deleteCategoryCall: (sk: string) => void;
}

export const SingleCategoriesTableRow = (
  props: SingleCategoriesTableRowProps
) => {
  const { matches, category, updateCategoryCall, deleteCategoryCall } = props;
  const {
    SK,
    categoryName,
    categoryType,
    periodBudget,
    periodBudgetCurrency,
    assignedPeriod,
    isEssential,
    deleteProtected,
  } = category;

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [editing, setEditing] = useState(false);
  const [updatingCategory, setUpdatingCategory] = useState(false);
  const [deletingCategory, setDeletingCategory] = useState(false);
  //
  const [newName, setNewName] = useState<string | null>(null);
  const [newAmount, setNewAmount] = useState<number | null>(null);
  const [newPeriod, setNewPeriod] = useState<string | null>(null);
  const [newEssentialValue, setNewEssentialValue] = useState<boolean>(
    isEssential || false
  );

  const handleEssentialSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewEssentialValue(event.target.checked);
  };

  const toggleEditing = () => setEditing(!editing);

  const updateSuccessfulCallback = () => {
    setUpdatingCategory(false);
    setEditing(false);
  };

  const submitEditing = () => {
    setUpdatingCategory(true);
    updateCategoryCall(
      SK,
      {
        newName,
        newAmount,
        newPeriod,
        newType: categoryType,
        newEssentialValue,
      },
      updateSuccessfulCallback
    );
  };

  const cancelEditing = () => {
    setEditing(false);
    setNewName(null);
    setNewAmount(null);
  };

  const f = (n: number) => formatNumberDispatch(n, periodBudgetCurrency);

  const renderNameCell = (mini = false) => {
    if (editing) {
      return (
        <Box>
          <TextField
            placeholder="New category name"
            variant="outlined"
            value={newName ?? categoryName}
            onChange={(e) => setNewName(e.target.value)}
            sx={{ paddingTop: "10px" }}
            disabled={updatingCategory}
          />
        </Box>
      );
    }

    return (
      <Box onClick={mini ? () => {} : toggleEditing}>
        <Typography textAlign={"center"}>{categoryName}</Typography>
      </Box>
    );
  };

  const renderPeriodBudgetCell = (mini = false) => {
    if (editing) {
      return (
        <Box>
          <TextField
            placeholder="New period budget"
            variant="outlined"
            value={f(newAmount ?? periodBudget)}
            onChange={(e) => setNewAmount(Number(e.target.value))}
            type="number"
            sx={{ paddingTop: "10px" }}
            disabled={updatingCategory}
          />
        </Box>
      );
    }

    return (
      <Box onClick={mini ? () => {} : toggleEditing}>
        <Typography textAlign={mini ? "center" : "right"}>
          {f(periodBudget)} {mini ? `/ ${assignedPeriod.toLowerCase()}` : null}
        </Typography>
      </Box>
    );
  };

  const renderPeriodCell = (mini = false) => {
    if (editing) {
      return (
        <Box>
          <PeriodDropdown periodSelected={setNewPeriod} />
        </Box>
      );
    }

    return (
      <Box onClick={mini ? () => {} : toggleEditing}>
        <Typography textAlign="center">
          {capitalizeWord(assignedPeriod)}
        </Typography>
      </Box>
    );
  };

  const renderEssentialCell = () => {
    if (editing) {
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={!!newEssentialValue}
                onChange={handleEssentialSwitchChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={"Essential"}
          ></FormControlLabel>
        </FormGroup>
      );
    }

    return (
      <Box>
        <Typography textAlign={"center"}>{`${!!isEssential}`}</Typography>
      </Box>
    );
  };

  const renderActionsCell = (mini = false) => {
    if (deleteProtected) {
      return (
        <ActionButton
          key={"modify"}
          disabled={deleteProtected}
          Icon={LockIcon}
          onIconClick={() => setEditing(true)}
          tooltip={
            deleteProtected ? "Cannot modify protected categories." : undefined
          }
        />
      );
    }

    if (editing) {
      return (
        <Box>
          <SecondaryButton disabled={deletingCategory} onClick={submitEditing}>
            Update
          </SecondaryButton>
          <SecondaryButton disabled={deletingCategory} onClick={cancelEditing}>
            Cancel
          </SecondaryButton>
        </Box>
      );
    }

    return [
      <ActionButton
        key="edit"
        Icon={ModeEditIcon}
        onIconClick={() => setEditing(true)}
        disabled={deletingCategory || deleteProtected}
        tooltip={
          deleteProtected ? "Cannot edit protected categories." : undefined
        }
      />,
      <ActionButton
        key="delete"
        Icon={DeleteIcon}
        onIconClick={() => setOpenConfirmDialog(true)}
        disabled={deletingCategory || deleteProtected}
        tooltip={
          deleteProtected ? "Cannot delete protected categories." : undefined
        }
      />,
    ];
  };

  const renderConfirmDialog = () => (
    <ConfirmActionDialog
      open={openConfirmDialog}
      setOpen={setOpenConfirmDialog}
      title={"Confirm category deletion"}
      texts={[
        "Are you sure you want to delete this category?",
        'All recorded expense/income entries will be recorded as "Uncategorized".',
      ]}
      onConfirm={() => {
        setDeletingCategory(true);
        toast.info("Deleting category...");
        deleteCategoryCall(SK);
      }}
      onCancel={() => setOpenConfirmDialog(false)}
    />
  );

  const renderFullSize = () => {
    return (
      <TableRow hover={true}>
        {renderConfirmDialog()}
        <TableCell align="center">{renderNameCell()}</TableCell>
        <TableCell>{renderPeriodCell()}</TableCell>
        <TableCell>{renderPeriodBudgetCell()}</TableCell>
        <TableCell>{renderEssentialCell()}</TableCell>
        <TableCell>
          <Typography textAlign="right">
            {f(periodBudget * 12 * getPeriodMultiplier(assignedPeriod))}
          </Typography>
        </TableCell>
        <TableCell align="center">{renderActionsCell()}</TableCell>
      </TableRow>
    );
  };

  const renderMini = () => {
    return (
      <TableRow hover={false}>
        {renderConfirmDialog()}
        <TableCell>
          <Card>
            <Grid container xs={12}>
              <Grid item xs={12} sx={{ paddingBottom: "10px" }}>
                {renderNameCell(true)}
              </Grid>

              <Grid item xs={12}>
                {renderPeriodBudgetCell(true)}
              </Grid>

              <Grid item xs={12}>
                <Divider light={true}></Divider>
              </Grid>

              <Grid item xs={12}>
                {renderActionsCell(true)}
              </Grid>
            </Grid>
          </Card>
        </TableCell>
      </TableRow>
    );
  };

  return matches ? renderFullSize() : renderMini();
};

export const TotalCategoriesAmountRow = ({
  total,
  matches,
}: {
  total: number;
  matches: boolean;
}) => {
  const currency = useAppSelector(selectCurrency);

  if (!matches) {
    return (
      <TableRow>
        <TableCell>
          <Typography fontWeight={900}>
            {formatNumberDispatch(total, currency)}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow hover={true}>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell>
        <Typography fontWeight={900}>
          {formatNumberDispatch(total, currency)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
