import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV025 = () => {
  return (
    <Release version="v0.2.5" releaseDate="2023-04-06">
      <ReleaseNotesSection title="Users">
        <ReleaseNotesEntry>Add user profile API</ReleaseNotesEntry>
        <ReleaseNotesEntry>
          Update user profile activation process
        </ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Reporting">
        <ReleaseNotesEntry>
          Add investment movements to yearly reports
        </ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Developer QoL">
        <ReleaseNotesEntry>Add backend CD/CI pipelines</ReleaseNotesEntry>
        <ReleaseNotesEntry>Add frontend CD/CI pipelines</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
