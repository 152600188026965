import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from "@mui/material";
import React, { useState } from "react";

interface ReportComponentSelectorProps {
  componentLabels: string[];
  setSelectedComponentsParent: (components: string[]) => void;
}
export const ReportComponentSelector = (
  props: ReportComponentSelectorProps
) => {
  const { componentLabels, setSelectedComponentsParent } = props;

  const [selectedComponents, setSelectedComponents] =
    useState<string[]>(componentLabels);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const label = event.target.name;
    const isChecked = event.target.checked;

    let items;
    if (isChecked) {
      items = [...selectedComponents, label];
    } else {
      items = selectedComponents.filter(
        (selectedLabel) => selectedLabel !== label
      );
    }

    setSelectedComponents(items);
    setSelectedComponentsParent(items);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend"></FormLabel>
      <FormGroup>
        <Grid container xs={12}>
          {componentLabels.map((component) => (
            <Grid item xs={6} md={4} spacing={1} justifyContent={"left"}>
              <FormControlLabel
                key={component}
                control={
                  <Checkbox
                    checked={selectedComponents.includes(component)}
                    onChange={handleCheckboxChange}
                    name={component}
                  />
                }
                label={component}
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </FormControl>
  );
};
