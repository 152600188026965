export function redirectNotAuthenticated(
  navigate: (route: string, params?: any) => void
) {
  navigate("/auth");
}

export function checkInvalidRefreshTokenMessage(
  errorMessage: string,
  navigate: (route: string, params?: any) => void,
  callback?: any
) {
  if (errorMessage === "Invalid Refresh token") {
    if (callback) {
      callback();
    } else {
      navigate("/auth");
    }
  }
}

export function getCredentialsFromSessionStorage() {
  const email = window.sessionStorage.getItem("email");
  const username = window.sessionStorage.getItem("username");
  const refreshToken = window.sessionStorage.getItem("refreshToken");
  const token = window.sessionStorage.getItem("token");

  return { email, username, token, refreshToken };
}

export function removeCredentialsFromSessionStorage() {
  window.sessionStorage.removeItem("email");
  window.sessionStorage.removeItem("username");
  window.sessionStorage.removeItem("refreshToken");
  window.sessionStorage.removeItem("token");
}

export function setCredentialsInSessionStorage(
  email: string,
  username: string,
  token: string,
  refreshToken: string
) {
  window.sessionStorage.setItem("email", email);
  window.sessionStorage.setItem("username", username);
  window.sessionStorage.setItem("token", token);
  window.sessionStorage.setItem("refreshToken", refreshToken);
}
