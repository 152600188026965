import { ReleaseNotesEntry } from ".";
import Release from "./Release";
import ReleaseNotesSection from "./ReleaseNotesSection";

export const ReleaseV014 = () => {
  return (
    <Release version="v0.1.4" releaseDate="2022-12-23">
      <ReleaseNotesSection title="Expenses and incomes">
        <ReleaseNotesEntry>Enable cloning entries</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Backend">
        <ReleaseNotesEntry>Minor refactoring</ReleaseNotesEntry>
      </ReleaseNotesSection>

      <ReleaseNotesSection title="Misc.">
        <ReleaseNotesEntry>Minor UI fixes</ReleaseNotesEntry>
      </ReleaseNotesSection>
    </Release>
  );
};
