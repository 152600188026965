import { BudgetarToast } from "../utility/misc/BudgetarToast";
import { SingleAccountsTableRow } from "./SingleAccountsTableRow";
import { Account, UpdateAccountData } from "@backend/account.type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMediaQuery, Typography } from "@mui/material";
import { Box } from "@mui/system";

export interface AccountWithBalance extends Account {
  accountBalance: number;
}

export const AccountsTable = (props: {
  accounts: AccountWithBalance[];
  updateAccount: (
    accountSK: string,
    updateAccountData: UpdateAccountData,
    cb: () => void
  ) => void;
  deleteAccount: (sk: string) => void;
}) => {
  const matches = useMediaQuery("(min-width:600px)");

  const { accounts, updateAccount, deleteAccount } = props;

  const mappedItems = accounts.map((account) => (
    <SingleAccountsTableRow
      key={account.SK}
      account={account}
      updateAccountCall={updateAccount}
      deleteAccountCall={deleteAccount}
      matches={matches}
    />
  ));

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <BudgetarToast />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="accounts table">
          <TableHead>
            {matches ? (
              <TableRow hover={true}>
                <TableCell>
                  <Typography textAlign="center">Account</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Type</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="right">Amount</Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center"></Typography>
                </TableCell>
                <TableCell>
                  <Typography textAlign="center">Actions</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>{mappedItems}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
