import { ReportingApi } from "../../../api/reporting.api";
import { logout } from "../../../components/utils.api";
import { getPeriodMultiplier } from "../../../imported-logic/common";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { selectBudgetToUse } from "../../../redux/reducers/budgetSlice";
import {
  selectMetadata,
  setMetadata,
} from "../../../redux/reducers/reportingSlice";
import { capitalizeWord, sortValues } from "../../../util/Functions";
import { SecondaryButton } from "../../utility/buttons/SecondaryButton";
import { CategoriesReportingCategoryPieChart } from "./CategoriesReportingPieChart";
import {
  SingleCategoriesReportingTableRow,
  ShowAllReportingCategoriesRow,
} from "./SingleCategoriesReportingTableRow";
import { ReportingCategory } from "@backend/category.type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Base {
  budgetDisplay: "simple" | "normalized";
}

interface CategoriesReportingCategorySubtableProps extends Base {
  reportingCategoriesCategory: ReportingCategory[];
  label: "Expense categories" | "Income categories";
  matches: boolean;
  collapsed: boolean;
  setCollapsed: (newCollapsed: boolean) => void;
}

export type AttributeNameType =
  | "total"
  | "periodBudget"
  | "categoryName"
  | "averageMonthlyExpenditure";
export interface ReportingCategoryCategoriesFilter {
  attributeName: AttributeNameType;
  direction: "ASC" | "DESC";
}

export const CategoriesReportingCategoryTable = ({
  reportingCategoriesCategory,
  budgetDisplay,
  label,
  matches,
  collapsed,
  setCollapsed,
}: CategoriesReportingCategorySubtableProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [attributeName, setAttributeName] =
    useState<AttributeNameType>("total");
  const [direction, setFilterDirection] = useState<"ASC" | "DESC">("DESC");

  const budgetId = useAppSelector(selectBudgetToUse);
  const { data: metadata, loaded: isMetadataLoaded } =
    useAppSelector(selectMetadata);

  useEffect(() => {
    if (!isMetadataLoaded) {
      ReportingApi.getFirstEntryAndMonthsSince(
        budgetId,
        {
          onSuccess: (data: {
            firstEntry: any;
            monthsSinceFirstEntry: number;
          }) => {
            dispatch(setMetadata(data));
          },
        },
        () => logout(dispatch, navigate)
      );
    }
  }, []);

  const toggleDirection = () =>
    setFilterDirection(direction === "ASC" ? "DESC" : "ASC");

  const rows = [...reportingCategoriesCategory]
    .map((e) => {
      const { periodBudget, assignedPeriod, total, delta } = e;
      const periodMult = getPeriodMultiplier(assignedPeriod);

      const totalPlannedExpenditure = total - delta;
      const monthlyBudget = periodBudget * periodMult;
      const months = monthlyBudget
        ? totalPlannedExpenditure / monthlyBudget
        : metadata.monthsSinceFirstEntry;
      const averageMonthlyExpenditure = total / months;
      const monthlyDeviation = averageMonthlyExpenditure - monthlyBudget;

      return {
        ...e,
        monthlyBudget,
        totalPlannedExpenditure,
        averageMonthlyExpenditure,
        monthlyDeviation,
      };
    })
    .filter((e) => e.total || e.totalPlannedExpenditure)
    .sort((a, b) => sortValues(a[attributeName], b[attributeName], direction))
    .filter((e, idx) => !collapsed || idx < 5)
    .map((category, idx) => (
      <SingleCategoriesReportingTableRow
        key={idx}
        category={category}
        budgetDisplay={budgetDisplay}
        matches={matches}
        sortingAttribute={attributeName}
      />
    ));

  rows.push(
    <ShowAllReportingCategoriesRow
      key={rows.length + 1}
      setCollapsed={setCollapsed}
      collapsed={collapsed}
    />
  );

  const handleHeaderClick = (key: AttributeNameType) => {
    if (attributeName === key) {
      toggleDirection();
    }

    setAttributeName(key);
  };

  const renderColumnHeader = (label: string, key?: AttributeNameType) => (
    <Typography>
      {key ? (
        attributeName === key ? (
          <Typography sx={{ textDecoration: "underline" }}>{label}</Typography>
        ) : (
          label
        )
      ) : (
        label
      )}
    </Typography>
  );

  const CategorySorter = () => (
    <Grid
      xs={12}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingBottom="10px"
    >
      <Grid xs={3}>
        <Typography>Sort</Typography>
      </Grid>
      <Grid xs={3}>
        <SecondaryButton
          onClick={() => handleHeaderClick("categoryName")}
          variant={attributeName === "categoryName" ? "contained" : "outlined"}
        >
          Name
        </SecondaryButton>
      </Grid>
      <Grid xs={3}>
        <SecondaryButton
          onClick={() => handleHeaderClick("total")}
          variant={attributeName === "total" ? "contained" : "outlined"}
        >
          Total
        </SecondaryButton>
      </Grid>
      <Grid xs={3}>
        <SecondaryButton
          onClick={() => handleHeaderClick("averageMonthlyExpenditure")}
          variant={
            attributeName === "averageMonthlyExpenditure"
              ? "contained"
              : "outlined"
          }
        >
          Avg.
        </SecondaryButton>
      </Grid>
    </Grid>
  );

  const renderContent = () => {
    return (
      <Grid container sx={{ padding: "0px" }} xs={12}>
        <Grid xs={12}>
          <CategorySorter />
        </Grid>

        <Grid item xs={12} md={8}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="requirements table">
              <TableHead>
                {matches ? (
                  <TableRow hover={true}>
                    <TableCell>{capitalizeWord(label.split(" ")[0])}</TableCell>
                    <TableCell
                      onClick={() => handleHeaderClick("categoryName")}
                    >
                      {renderColumnHeader("Name", "categoryName")}
                    </TableCell>
                    <TableCell
                      onClick={() => handleHeaderClick("periodBudget")}
                    >
                      {renderColumnHeader(
                        "Planned period budget",
                        "periodBudget"
                      )}
                    </TableCell>
                    <TableCell onClick={() => handleHeaderClick("total")}>
                      {renderColumnHeader(
                        "Total expenditure / planned expenditure",
                        "total"
                      )}
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        handleHeaderClick("averageMonthlyExpenditure")
                      }
                    >
                      {renderColumnHeader(
                        "Average monthly",
                        "averageMonthlyExpenditure"
                      )}
                    </TableCell>
                    <TableCell>Budget cap reached</TableCell>
                  </TableRow>
                ) : (
                  <TableRow hover={false}>
                    <TableCell>
                      {capitalizeWord(label.split(" ")[0])} categories
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>{[rows]}</TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={4}>
          <CategoriesReportingCategoryPieChart
            label={label}
            reportingCategoryCategories={reportingCategoriesCategory}
          />
        </Grid>
      </Grid>
    );
  };

  return reportingCategoriesCategory ? (
    renderContent()
  ) : (
    <Box>
      <Typography>No reporting category data</Typography>{" "}
    </Box>
  );
};
