import { fetchAdminBudget } from "../../../api/fetch.redux";
import { ReportingApi } from "../../../api/reporting.api";
import { LoadingDialog } from "../../../components/utility/dialogs/LoadingDialog";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { selectAdminBudgetId } from "../../../redux/reducers/budgetSlice";
import {
  selectSavingsSummary,
  setLoading,
  setSavingsSummary,
  setTrendsProjection,
} from "../../../redux/reducers/reportingSlice";
import { selectTrendsProjection } from "../../../redux/reducers/reportingSlice";
import { BudgetarToast } from "../../utility/misc/BudgetarToast";
import { getBudgetId, logout } from "../../utils.api";
import { CurrentPeriodTrends } from "../CurrentPeriodTrends";
import { SavingsProjectionsTable } from "./SavingsProjectionTable";
import { SavingsSummaryTable } from "./SavingsSummaryTable";
import { TrendsProjectionsSummarized } from "@backend/trend.type";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

export interface SavingsSummaryComponentProps {}

export const SavingsSummary = (props: SavingsSummaryComponentProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = getBudgetId(useAppSelector);
  const {
    data: savingsSummary,
    loaded: isSavingsSummaryLoaded,
    loading: areSavingsSummaryLoading,
  } = useAppSelector(selectSavingsSummary);
  const {
    data: trends,
    loaded: areTrendsLoaded,
    loading: areTrendsLoading,
  } = useAppSelector(selectTrendsProjection);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!isSavingsSummaryLoaded) {
        dispatch(setLoading({ key: "savingsSummary", status: true }));

        ReportingApi.loadSavingsSummary(
          budgetId,
          {
            onSuccess: (savingsSummary) => {
              dispatch(setSavingsSummary({ savingsSummary }));
            },
          },
          () => logout(dispatch, navigate)
        );
      }

      if (!areTrendsLoaded) {
        dispatch(setLoading({ key: "trendsProjection", status: true }));

        ReportingApi.getTrendsProjectionsOverview(
          budgetId,
          {
            onSuccess: (trendsProjection: TrendsProjectionsSummarized) =>
              dispatch(setTrendsProjection({ trendsProjection })),
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  return (
    <Box sx={{ display: "flex" }}>
      <BudgetarToast />
      <LoadingDialog
        key={uuid()}
        open={areSavingsSummaryLoading || areTrendsLoading}
      />

      <Grid container xs={12} spacing={1}>
        <Grid item xs={12}>
          <SavingsSummaryTable savingsSummary={savingsSummary} />
        </Grid>
        <Grid item xs={12}>
          <CurrentPeriodTrends {...trends} />
        </Grid>
        <Grid item xs={12} md={12}>
          <SavingsProjectionsTable {...props} />
        </Grid>
      </Grid>
    </Box>
  );
};
