import { loginUser, signupUser } from "../../api/auth";
import { createUser } from "../../api/users";
import { useAppDispatch } from "../../redux/hooks";
import { setCurrentUser } from "../../redux/reducers/userSlice";
import { logout } from "../utils.api";
import { AuthCommon, AuthParentInterface } from "./AuthCommon";
import { useNavigate } from "react-router-dom";

export const SignUpComponent = (props: AuthParentInterface) => {
  const { setError, setPending } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const action = (email: string, password: string) =>
    signupUser(
      { email, password },
      {
        onSuccess: (data) => {
          loginUser(
            { email, password },
            {
              onSuccess: (loginResponse) => {
                const { token, username } = loginResponse;
                dispatch(setCurrentUser({ email, username, token }));

                createUser(
                  email,
                  {
                    onSuccess: () => {
                      setPending(false);
                      navigate("/");
                    },
                    onFailed: (err) => {
                      setPending(false);
                      setError(err.data.message);
                    },
                  },
                  () => logout(dispatch, navigate)
                );
              },
              onFailed: (err: any) => {
                console.error(err);
                setError(err);
              },
            }
          );
        },
        onFailed: (err: any) => {
          setPending(false);
          console.error("sign up failed", err);
          setError(err.data.message);
        },
      }
    );

  return (
    <AuthCommon
      title={"Create a new account"}
      subtitle={"It's free, forever"}
      submitTitle={"Sign up"}
      submittingString={"Creating account..."}
      question={"Already have an account?"}
      alternative={"Sign in"}
      parent={props}
      subComponent={{ action }}
    />
  );
};
