import { CreditAPI } from "../../../api/credit.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrency } from "../../../redux/reducers/userSlice";
import { formatNumberDispatch } from "../../../util/Formatter";
import { getBudgetId, logout } from "../../utils.api";
import { BudgetarDatePicker } from "../BudgetarDatePicker";
import { CreditWithBalance } from "@backend/credit.type";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const NewCreditPaymentDialog = (props: {
  credit: CreditWithBalance;
  open: boolean;
  setOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  onFailed: () => void;
}) => {
  const { credit, open, setOpen, onSuccess, onFailed } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currency = useAppSelector(selectCurrency);
  const f = (n: number) => formatNumberDispatch(n, currency);

  const budgetId = getBudgetId(useAppSelector);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [date, setDate] = useState(DateTime.now().toISODate());

  const handleClose = () => {
    setOpen(false);
  };

  const handleAmountChange = (newPrice: string) =>
    setPaymentAmount(Number(newPrice));

  const canSubmit = () => {
    return date && paymentAmount;
  };

  const reset = () => {
    setPaymentAmount(0);
    setDate(DateTime.now().toISODate());
  };

  const submit = () => {
    addCreditPayment();
    handleClose();
    reset();
  };

  const addCreditPayment = async () => {
    CreditAPI.createCreditPayment(
      budgetId,
      {
        budgetId,
        creditId: credit.creditId,
        paymentAmount,
        paymentDate: DateTime.fromISO(date).toMillis(),
      },
      { onSuccess, onFailed },
      () => logout(dispatch, navigate)
    );
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>Add credit payment</Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "10px", flexGrow: 1, flexDirection: "column" }}
          >
            <Typography color="darkgray">
              Remaining balance: {f(credit.remainingBalance)}
            </Typography>

            <BudgetarDatePicker
              onSelect={(date: Date) =>
                setDate(date.toISOString().split("T")[0])
              }
            />

            <TextField
              label="Payment amount"
              variant="outlined"
              onChange={(e) => handleAmountChange(e.target.value)}
              type="number"
              sx={{ paddingTop: "10px" }}
            />

            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                component="label"
                disabled={!canSubmit()}
                onClick={submit}
              >
                Add payment
              </Button>
            </Box>

            <Box sx={{ paddingLeft: "10px" }}>
              <Button
                variant="outlined"
                component="label"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
