import { SecondaryButton } from "../utility/buttons/SecondaryButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Typography, Grid, Card, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const SubscriptionSuccessful = () => {
  const navigate = useNavigate();

  const goToDashboard = () => navigate("/");
  const goToSubscriptions = () => navigate("/settings");

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="20px"
    >
      <Card sx={{ padding: "20px", maxWidth: "400px" }}>
        <Grid container xs={12} sx={{ padding: "20px" }}>
          <Grid xs={12}>
            <IconButton>
              <CheckCircleOutlineIcon fontSize="large" color="info" />
            </IconButton>
          </Grid>

          <Grid xs={12}>
            <Typography color="#ff9000" fontSize={22}>
              Thank you!
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: "20px" }}>
            <Typography fontSize={18}>
              Your subscription is successful.
            </Typography>{" "}
          </Grid>

          <Grid xs={12}>
            <Typography fontSize={18}>
              You can now use all Premium benefits.
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: "20px" }}>
            <SecondaryButton onClick={goToDashboard}>
              To your Dashboard
            </SecondaryButton>
          </Grid>

          <Grid xs={12}>
            <Button onClick={goToSubscriptions}>Manage subscription</Button>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
