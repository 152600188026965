import { CurrentMonthOverview } from "../../../../aws/budgetar-back/src/types/reporting.type";
import {
  fetchExpenses,
  fetchCategories,
  fetchAccounts,
  fetchAdminBudget,
} from "../api/fetch.redux";
import { ReportingApi } from "../api/reporting.api";
import { ExpensesTable } from "../components/expenses/ExpensesTable";
import { SecondaryButton } from "../components/utility/buttons/SecondaryButton";
import { LoadingDialog } from "../components/utility/dialogs/LoadingDialog";
import { NewExpenseDialog } from "../components/utility/dialogs/NewExpenseDialog";
import { BudgetarToast } from "../components/utility/misc/BudgetarToast";
import { loadExpenses, logout } from "../components/utils.api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAccounts } from "../redux/reducers/accountsSlice";
import {
  selectAdminBudgetId,
  selectBudgetToUse,
} from "../redux/reducers/budgetSlice";
import { selectCategories } from "../redux/reducers/categoriesSlice";
import { selectSimpleExpenses } from "../redux/reducers/expensesSlice";
import {
  setAllExpensesAndIncomes,
  setCurrentMonthOverview,
  setSavingsToday,
} from "../redux/reducers/reportingSlice";
import { getTextSize } from "../style/fonts";
import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button, TextField, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

interface ExpensesPageProps {
  openHowToDialog: () => void;
}
export const Expenses = (props: ExpensesPageProps) => {
  const matches = useMediaQuery("(min-width:600px)");

  const { openHowToDialog } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const [openNewExpenseDialog, setOpenNewExpenseDialog] = useState(false);
  const [filter, setFilter] = useState("");

  const dispatch = useAppDispatch();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const budgetId = useAppSelector(selectBudgetToUse);
  const {
    data: expenses,
    loaded: areExpensesLoaded,
    loading: areExpensesLoading,
  } = useSelector(selectSimpleExpenses);
  const {
    data: categories,
    loaded: areCategoriesLoaded,
    loading: areCategoriesLoading,
  } = useSelector(selectCategories);
  const {
    data: accounts,
    loaded: areAccountsLoaded,
    loading: areAccountsLoading,
  } = useSelector(selectAccounts);

  const isDataLoaded = () =>
    !areExpensesLoading && !areCategoriesLoading && !areAccountsLoading;

  const newExpenseAdded = () => {
    loadExpenses(budgetId, dispatch);

    ReportingApi.loadAllExpensesAndIncomes(
      budgetId,
      {
        onSuccess: (data: any) => {
          dispatch(setAllExpensesAndIncomes(data));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getSavingsToday(
      budgetId,
      {
        onSuccess: (savingsToday: number) => {
          dispatch(setSavingsToday({ savingsToday }));
        },
      },
      () => logout(dispatch, navigate)
    );

    ReportingApi.getCurrentMonthOverview(
      budgetId,
      {
        onSuccess: (currentMonthOverview: CurrentMonthOverview) => {
          dispatch(setCurrentMonthOverview({ currentMonthOverview }));
        },
      },
      () => logout(dispatch, navigate)
    );

    toast.success("New expense added.");
  };

  const handleFilterChange = (newInput: string) => {
    setFilter(newInput);
  };

  useEffect(() => {
    if (isBudgetIdLoaded) {
      fetchExpenses(budgetId, dispatch, areExpensesLoaded);
      fetchCategories(budgetId, dispatch, areCategoriesLoaded);
      fetchAccounts(budgetId, dispatch, areAccountsLoaded);
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId]);

  return (
    <Box>
      <LoadingDialog
        key={uuid()}
        open={areExpensesLoading}
        toDisplay="expenses"
      />
      <BudgetarToast />
      <Box sx={{ display: "flex", paddingLeft: "10px" }}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenNewExpenseDialog(true)}
        >
          <AddIcon />
          <Typography fontSize={getTextSize(!matches)}>Add expense</Typography>
        </Button>

        <Box sx={{ paddingLeft: "10px" }} />

        {source === "howto" ? (
          <SecondaryButton
            variant="contained"
            size="small"
            onClick={openHowToDialog}
          >
            <ArrowUpwardIcon />
            Back to how-to
          </SecondaryButton>
        ) : null}

        <Box sx={{ paddingLeft: "10px" }}>
          <TextField
            placeholder="Filter"
            onChange={(e) => handleFilterChange(e.target.value)}
          ></TextField>
        </Box>
      </Box>

      <NewExpenseDialog
        open={openNewExpenseDialog}
        setOpen={setOpenNewExpenseDialog}
        onSuccess={newExpenseAdded}
        onFailed={() => toast.error("Error while adding expense.")}
      />

      {isDataLoaded() ? (
        <ExpensesTable
          items={expenses}
          categories={categories}
          accounts={accounts}
          filters={{ filter }}
        />
      ) : (
        <Box>Loading data...</Box>
      )}
    </Box>
  );
};
