import { Currency } from "./currency.type";

export type MortgageCalculationInput = {
  principal: number;
  interestRateType: InterestRateType;
  termMonths: number;
  interestRatePerAnnum: number;
  effectiveStartDate: string;
};

type MortgageBase<
  PaymentSchedule extends MortgagePaymentSchedule | MortgagePaymentScheduleDB
> = {
  // mortgageLabel: string;
  principal: number;
  totalInterest: number;
  totalOutstanding: number;
  effectiveStartDate: string;
  monthlyPayment: number;
  paymentSchedule: PaymentSchedule;
} & MortgageCalculationInput;

export type Mortgage = MortgageBase<MortgagePaymentSchedule>;

export type MortgageDB = {
  id: string;
  label: string;
  mortgageAccountId: string;
  currency: Currency;
} & MortgageBase<MortgagePaymentScheduleDB>;

export type MortgagePaymentSchedule = {
  creationDate: string;
  scheduledPayments: Array<MortgageScheduledPayment>;
};

export type MortgagePaymentScheduleDB = {
  id: string;
  mortgageId: string;
} & MortgagePaymentSchedule;

export type MortgageScheduledPayment = {
  date: string;
  amount: number;
  interest: number;
  principal: number;
};

export enum InterestRateType {
  FIXED = "FIXED",
  VARIABLE = "VARIABLE",
}

export type MortgageExecutedPayment = {
  id: string;
  mortgageId: string;
  amount: number;
  interest: number;
  principal: number;
  paymentDate: string;
};

export type CreateMortgageInput = {
  currency: Currency;
  mortgageLabel: string;
} & MortgageCalculationInput;

export interface AddMortgagePaymentData {
  mortgageId: string;
  amount: number;
  paymentDate: string;
  expenseAccountId: string;
}

export type GetMortgageResponse = {
  mortgage: MortgageDB;
  summary: MortgageSummary;
};

export type MortgageSummary = {
  remainingInterest: number;
  remainingPrincipal: number;
  remainingTotal: number;

  paidInterest: number;
  paidPrincipal: number;
  paidTotal: number;
};
