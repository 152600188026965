import { fetchAdminBudget } from "../../../api/fetch.redux";
import { ReportingApi } from "../../../api/reporting.api";
import { LoadingDialog } from "../../../components/utility/dialogs/LoadingDialog";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { selectAdminBudgetId } from "../../../redux/reducers/budgetSlice";
import {
  selectYearlyReports,
  setYearlyReport,
} from "../../../redux/reducers/reportingSlice";
import { getBudgetId, logout } from "../../utils.api";
import { YearlyReportTable } from "./YearlyReportTable";
import { YearlyReport } from "@backend/reporting.type";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

interface YearlyReportChartProps {
  year: number;
}

export const YearlyReportChart = (props: YearlyReportChartProps) => {
  const { year } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loaded: isBudgetIdLoaded } = useAppSelector(selectAdminBudgetId);
  const reports = useAppSelector(selectYearlyReports);
  const budgetId = getBudgetId(useAppSelector);

  useEffect(() => {
    if (isBudgetIdLoaded) {
      if (!isYearlyReportLoaded) {
        ReportingApi.getYearlyReport(
          budgetId,
          year,
          {
            onSuccess: (yearlyReport: YearlyReport) =>
              dispatch(setYearlyReport({ yearlyReport, year })),
          },
          () => logout(dispatch, navigate)
        );
      }
    } else {
      fetchAdminBudget(dispatch, false);
    }
  }, [budgetId, year]);

  if (!reports[year])
    return <LoadingDialog key={uuid()} open={true} toDisplay="report" />;

  const {
    data,
    loaded: isYearlyReportLoaded,
    loading: isYearlyReportLoading,
  } = reports[year];

  if (isYearlyReportLoading) return <LoadingDialog key={uuid()} open={true} />;

  return <YearlyReportTable report={data} />;
};
