import "./App.css";
import { appTheme } from "./Theme";
import { ThemeContextProvider } from "./ThemeContext";
import AppContent from "./components/appContent/AppContent";
import { SubscriptionFailed } from "./components/subscriptions/SubscriptionFailed";
import { SubscriptionSuccessful } from "./components/subscriptions/SubscriptionSuccessful";
import { FocusProvider } from "./components/utility/hoc/FocusableComponent";
import "./favicon2.ico";
import "./index.css";
import { Accounts } from "./pages/Accounts";
import { Assets } from "./pages/Assets";
import { Authentication } from "./pages/Authentication";
import { Categories } from "./pages/Categories";
import { Credits } from "./pages/Credits";
import { Dashboard } from "./pages/Dashboard";
import { Events } from "./pages/Events";
import { Expenses } from "./pages/Expenses";
import { Goals } from "./pages/Goals";
import { Incomes } from "./pages/Incomes";
import { Investments } from "./pages/Investments";
import { Loans } from "./pages/Loans";
import { Mortgages } from "./pages/Mortgages";
import { Reporting } from "./pages/Reporting";
import { Settings } from "./pages/Settings";
import { UserActivation } from "./pages/UserActivation";
import { store } from "./redux/store";
import { useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const [openAboutDialog, setOpenAboutDialog] = useState(false);

  const openHowToDialog = () => setOpenAboutDialog(true);

  return (
    <div
    // style={{
    //   backgroundImage: `url(/bckg.jpg)`,
    //   height: "auto",
    //   maxWidth: "100%",
    // }}
    >
      <Provider store={store}>
        <ThemeContextProvider theme={appTheme}>
          <FocusProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <div className="App">
                      <AppContent
                        openAboutDialog={openAboutDialog}
                        setOpenAboutDialog={setOpenAboutDialog}
                      />
                    </div>
                  }
                >
                  <Route path="auth" element={<Authentication />}></Route>
                  <Route index element={<Dashboard />} />
                  <Route
                    path="categories"
                    element={<Categories openHowToDialog={openHowToDialog} />}
                  />
                  <Route
                    path="accounts"
                    element={<Accounts openHowToDialog={openHowToDialog} />}
                  />
                  <Route
                    path="expenses"
                    element={<Expenses openHowToDialog={openHowToDialog} />}
                  />
                  <Route
                    path="incomes"
                    element={<Incomes openHowToDialog={openHowToDialog} />}
                  />
                  <Route
                    path="investments"
                    element={<Investments openHowToDialog={openHowToDialog} />}
                  />
                  <Route
                    path="events"
                    element={<Events openHowToDialog={openHowToDialog} />}
                  />
                  <Route
                    path="loans"
                    element={<Loans openHowToDialog={openHowToDialog} />}
                  />
                  <Route
                    path="mortgages"
                    element={<Mortgages openHowToDialog={openHowToDialog} />}
                  />
                  <Route path="reporting" element={<Reporting />} />
                  <Route
                    path="goals"
                    element={<Goals openHowToDialog={openHowToDialog} />}
                  />
                  <Route
                    path="assets"
                    element={<Assets openHowToDialog={openHowToDialog} />}
                  />
                  <Route
                    path="credits"
                    element={<Credits openHowToDialog={openHowToDialog} />}
                  />
                  <Route path="settings" element={<Settings />} />
                  <Route
                    path="user/activation/:code"
                    element={<UserActivation />}
                  />

                  <Route
                    path="subscription/success"
                    element={<SubscriptionSuccessful />}
                  />
                  <Route
                    path="subscription/failed"
                    element={<SubscriptionFailed />}
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </FocusProvider>
        </ThemeContextProvider>
      </Provider>
    </div>
  );
}

export default App;
